import axiosInstance from "./AxiosInstance";

export function createLabelZebra(data) {
  return axiosInstance.post(`/label-zebra`, data);
}
export function createLabelBrother(data) {
  return axiosInstance.post(`/label-brother`, data);
}

export function createIELSLabelZebra(data) {
  return axiosInstance.post(`/iels-zebra-label`, data);
}
export function createIELSLabelBrother(data) {
  return axiosInstance.post(`/iels-brothers-label`, data);
}

export function getLabelList(data) {
  return axiosInstance.post(`/label/list`, data);
}

export function createLabelZebras(data) {
  return axiosInstance.post(`/label-zebra`, data);
}

export function createLabelBrothers(data) {
  return axiosInstance.post(`/label-brother`, data);
}

export function CreateRePrint(data) {
  return axiosInstance.post(`/label-re-print`, data);
}
