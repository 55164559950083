import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import iconImG from "../../asset/images/icon-425.svg";
import footerlogo from "../../asset/images/footer-logo.png";
import validator from "validator";
import { actionAddSubscribe } from "../../services/settingsServices";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { Form } from "reactstrap";

const Subscribe = ({ settingDetails }) => {
  const Subscribe = [
    {
      text: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. ",
    },
  ];
  const subDes = {
    description:
      "Join our subscription and get instant updates about offers and discounts",
  };

  const [subscribeData, setSubscribeData] = useState({
    email: "",
  });

  let errorsObj = {
    email: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);

  const handleSubscribe = (e) => {
    e.preventDefault();
    let error = false;
    let errorObj = { ...errorsObj };

    if (!subscribeData.email) {
      errorObj.email = toast.error("Email address is required");
      error = true;
    } else if (!validator.isEmail(subscribeData.email)) {
      errorObj.email = toast.error("Please enter valid email address");
      error = true;
    }

    setErrors(errorObj);
    Utils.focusError();

    if (error) return;

    setIsModifyButtonLoad(true);
    let data = {
      email: subscribeData?.email,
    };

    actionAddSubscribe(data)
      .then((response) => {
        toast.success(response.data.message);
        setIsModifyButtonLoad(false);
        setSubscribeData({
          email: "",
        });
      })
      .catch((err) => {
        // toast.error(Utils.getErrorMessage(err));
        setIsModifyButtonLoad(false);
      });
  };

  return (
    <section id="subscribe" className="subscribe-section">
      <Container>
        <div className="subscribe-inner">
          <div className="subscribe-icon">
            <img src={iconImG} alt="" />
          </div>
          <div className="subscribe-bottom">
            <div className="logo-footer">
              <img className="heigh30" src={footerlogo} alt="" />
            </div>
            <div className="dedicated">
              <div className="description">
                <p>
                  {settingDetails?.footer_description
                    ? settingDetails?.footer_description
                    : "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable."}
                </p>
              </div>
            </div>
            <div className="row mt-5">
              <Col lg="6">
                <div className="description-subscription">
                  <p>{subDes.description}</p>
                </div>
              </Col>
              <Col lg="6">
                <Form method={`post`} onSubmit={handleSubscribe}>
                  <div className="subscribe-form-group">
                    <input
                      type="email"
                      className="form-control input-my"
                      value={subscribeData.email}
                      onChange={(e) => {
                        setSubscribeData({
                          ...subscribeData,
                          email: e.target.value,
                        });
                      }}
                      maxLength={40}
                      placeholder="Enter your email address"
                    />
                    {/* {errors.email && (
                      <span className="input-error ms-3">{errors.email}</span>
                    )} */}
                    <button type="submit" className="white-btn">
                      <span className="text">Subscribe</span>
                      <span className="icon">
                        <i className="fa fa-arrow-right"></i>
                      </span>
                    </button>
                  </div>
                </Form>
              </Col>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};
export default Subscribe;
