import axiosInstance from "./AxiosInstance";

export function actionAddDepollutionOpration(data) {
  return axiosInstance.post(`/depollution-log/create`, data);
}

export function actionUpdateDepollutionOpration(data) {
  return axiosInstance.post(`/depollution-log/update`, data);
}

export function getDepollutionOprationList(data) {
  return axiosInstance.post(`/depollution-log/list`, data);
}

export function getDepollutionDetails(data) {
  return axiosInstance.post(`/depollution-log/details`,data)
}

export function actionDelDepollutionOpration(data) {
  return axiosInstance.post(`/depollution-log/delete`, data);
}


export function getLerCodeList(data) {
    return axiosInstance.post(`/ler-catalog-search-list`,data)
}


export function getLerCodeHistoryList(data) {
    return axiosInstance.post(`/ler-log/history`,data)
}

export function getVehicleInventorySerachList(data) {
  return axiosInstance.post(`/vehicle-inventory-search-list`,data)
}

export function getVehicleInventoryOtherSerachList(data) {
  return axiosInstance.post(`/other-vehicle-inventory-search-list`,data)
}