import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import {
  GetPartCatalogDetails,
  getPartGroupAllList,
  getPartLocationAllList,
  getPartSideAllList,
  getPartTypeAllList,
} from "../../services/PartTypeService";
import Utils from "../../utils";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import {
  createPartCatalog,
  getEtaiSearchList,
  getPartCatalogList,
  updatePartCatalog,
} from "../../services/LerCatalogService";
import ConfigDB from "../../config";
import Pagination from "react-js-pagination";

const AddCatalog = () => {
  const navigate = useNavigate();
  const tableLengthList = ConfigDB.data.dataTableLength;

  const [partCatalogData, setPartCatalogData] = useState({
    id: "",
    namePartPT: "",
    namePartES: "",
    namePartEN: "",
    namePartFR: "",
    namePartIT: "",
    namePartDE: "",
    idTypePart: "",
    idLocation: "",
    idGroup: "",
    idSide: "",
    dependsBodyCar: "0",
    numCarDoors: "",
    individual: "0",
    weight: "",
    length: "",
    width: "",
    height: "",
    is_master_part: "0",
    etai_part_id: "",
  });

  let errorsObj = {
    namePartPT: "",
    namePartES: "",
    namePartEN: "",
    namePartFR: "",
    namePartIT: "",
    namePartDE: "",
    idTypePart: "",
    idLocation: "",
    idGroup: "",
    idSide: "",
    dependsBodyCar: "",
    numCarDoors: "",
    individual: "",
    weight: "",
    length: "",
    width: "",
    height: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [partTypeList, setPartTypeList] = useState([]);
  const [partLocationList, setPartLocationList] = useState([]);
  const [partGroupList, setPartGroupList] = useState([]);
  const [partSideList, setPartSideList] = useState([]);
  const [partCatalogDetails, setCatalogDetails] = useState({});
  const [btnLoader, setBtnLoader] = useState(false);

  const [filteredSuggestionsVariants, setFilteredSuggestionsVariants] =
    useState([]);
  const [activeSuggestionIndexVariants, setActiveSuggestionIndexVariants] =
    useState(0);
  const [showSuggestionsVariants, setShowSuggestionsVariants] = useState(false);
  const [vehicleVariantsAuto, setVehicleVariantsAuto] = useState("");
  const [vehicleVariantsId, setVehicleVariantsId] = useState("");
  const [partList, setPartList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [addedParts, setAddedParts] = useState([]);

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    search: "",
    is_reload: false,
  });

  const [VehicleEngineList, setVehicleEngineList] = useState([]);
  const [inputSelctededEngineValue, setInputSelctedEngineValue] = useState("");

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    } else if (type === "paginations") {
      paramsObj.pages = e;
    }
    setParams(paramsObj);
  };

  const handlePageChange = (pageNumber) => {
    setParams((prev) => ({
      ...prev,
      page: pageNumber,
    }));
  };

  useEffect(function () {
    const obj = {
        search: "",
      limit: tableLengthList[0],
      page: 1,
    };
    getEtaiSearchList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].product_label,
          });
        }

        setVehicleEngineList(list);
      })
      .catch(() => {});
  }, []);

  const handleInputSelectedChangeEngineList = (inputSelctededEngineValue) => {
    setInputSelctedEngineValue(inputSelctededEngineValue);

    if (inputSelctededEngineValue.length > 0) {
      const obj = {
          search: inputSelctededEngineValue,
        limit: tableLengthList[0],
        page: 1,
      };

      getEtaiSearchList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].product_label,
            });
          }
          setVehicleEngineList(list);
        })
        .catch((err) => {});
    }
  };

  const { uuid } = useParams();

  useEffect(() => {
    let data = {
      id: uuid,
    };
    GetPartCatalogDetails(data)
      .then((response) => {
        setCatalogDetails(response.data.data);
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  }, []);

  useEffect(() => {
    if (partCatalogDetails) {
      setPartCatalogData({
        id: partCatalogDetails?.id,
        namePartPT: partCatalogDetails?.namePartPT,
        namePartES: partCatalogDetails?.namePartES,
        namePartEN: partCatalogDetails?.namePartEN,
        namePartFR: partCatalogDetails?.namePartFR,
        namePartIT: partCatalogDetails?.namePartIT,
        namePartDE: partCatalogDetails?.namePartDE,
        idTypePart: partCatalogDetails?.idTypePart,
        idLocation: partCatalogDetails?.idLocation,
        idGroup: partCatalogDetails?.idGroup,
        idSide: partCatalogDetails?.idSide,
        dependsBodyCar: partCatalogDetails?.dependsBodyCar,
        numCarDoors: partCatalogDetails?.numCarDoors,
        individual: partCatalogDetails?.individual,
        weight: partCatalogDetails?.weight,
        length: partCatalogDetails?.length,
        width: partCatalogDetails?.width,
        height: partCatalogDetails?.height,
        etai_part_id: partCatalogDetails?.etai_part_id,
        is_master_part: partCatalogDetails?.is_master_part,
        part_list: partCatalogDetails?.part_list,
      });
      setAddedParts(partCatalogDetails?.part_list);
    }
  }, [partCatalogDetails]);

  useEffect(function () {
    getPartTypeAllList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].descriptionUK,
          });
        }

        setPartTypeList(list);
      })
      .catch(() => {});
  }, []);

  useEffect(function () {
    getPartLocationAllList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].descriptionUK,
          });
        }
        setPartLocationList(list);
      })
      .catch(() => {});
  }, []);

  useEffect(function () {
    getPartGroupAllList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].descriptionUK,
          });
        }
        setPartGroupList(list);
      })
      .catch(() => {});
  }, []);

  useEffect(function () {
    getPartSideAllList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].descriptionUK,
          });
        }
        setPartSideList(list);
      })
      .catch(() => {});
  }, []);

  const handleCheckboxChange = (e) => {
    setPartCatalogData({
      ...partCatalogData,
      individual: e.target.checked ? "1" : "0",
    });
  };

  const handleCheckboxChangeMaster = (e) => {
    setPartCatalogData({
      ...partCatalogData,
      is_master_part: e.target.checked ? "1" : "0",
    });
  };

  const handleCheckboxChangeDependCar = (e) => {
    setPartCatalogData({
      ...partCatalogData,
      dependsBodyCar: e.target.checked ? "1" : "0",
    });
  };

  useEffect(() => {
    let data = { ...params };
    getPartCatalogList(data)
      .then((response) => {
        setPartList(response.data.data);
        setTotalRecords(response.data.total);
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  }, [params]); // Add 'params' as a dependency

  const handleAddClick = async (id, namePartPT) => {
    const isAdded = addedParts?.some((part) => part.id === id);

    if (isAdded) {
      try {
        console.log(`Item with ID ${id} deleted successfully.`);
      } catch (error) {
        console.error(`Error deleting item with ID ${id}:`, error);
      }

      setAddedParts((prev = []) => prev.filter((part) => part?.id !== id));
    } else {
      setAddedParts((prev = []) => [...prev, { id, namePartPT }]);
    }
  };

  const filteredPartAssignList = (partList?.length ? partList : [])?.filter(
    (item) =>
      !addedParts?.some((addedPart) => addedPart.id === item.id) &&
      item.namePartPT !== partCatalogDetails?.namePartPT &&
      item.namePartES !== partCatalogDetails?.namePartES &&
      item.namePartEN !== partCatalogDetails?.namePartEN &&
      item.namePartFR !== partCatalogDetails?.namePartFR &&
      item.namePartIT !== partCatalogDetails?.namePartIT &&
      item.namePartDE !== partCatalogDetails?.namePartDE &&
      (item.is_master_part === "0" || item.is_master_part === null)
  );

  const handlePartCatalogForm = (e) => {
    e.preventDefault();

    setBtnLoader(true);

    let error = false;
    let errorObj = { ...errorsObj };
    if (!partCatalogData.namePartPT) {
      errorObj.namePartPT = `Part PT required.`;
      error = true;
      setBtnLoader(false);
    }

    if (!partCatalogData.namePartES) {
      errorObj.namePartES = `Part ES required.`;
      error = true;
      setBtnLoader(false);
    }
    if (!partCatalogData.namePartEN) {
      errorObj.namePartEN = `Part EN required.`;
      error = true;
      setBtnLoader(false);
    }
    if (!partCatalogData.namePartFR) {
      errorObj.namePartFR = `Part FR required.`;
      error = true;
      setBtnLoader(false);
    }
    if (!partCatalogData.namePartIT) {
      errorObj.namePartIT = `Part IT required.`;
      error = true;
      setBtnLoader(false);
    }
    if (!partCatalogData.namePartDE) {
      errorObj.namePartDE = `Part DE required.`;
      error = true;
      setBtnLoader(false);
    }

    if (!partCatalogData.idTypePart) {
      errorObj.idTypePart = `Part Type required.`;
      error = true;
      setBtnLoader(false);
    }
    if (!partCatalogData.idLocation) {
      errorObj.idLocation = `Part Location required.`;
      error = true;
      setBtnLoader(false);
    }
    if (!partCatalogData.idGroup) {
      errorObj.idGroup = `Part Group required.`;
      error = true;
      setBtnLoader(false);
    }
    if (!partCatalogData.idSide) {
      errorObj.idSide = `Part Slide required.`;
      error = true;
      setBtnLoader(false);
    }

    if (!partCatalogData.numCarDoors || isNaN(partCatalogData.numCarDoors)) {
      errorObj.numCarDoors =
        "Number of car doors is required and must be a number.";
      error = true;
      setBtnLoader(false);
    }
    if (!partCatalogData.weight || isNaN(partCatalogData.weight)) {
      errorObj.weight = "Weight is required and must be a number.";
      error = true;
      setBtnLoader(false);
    }
    if (!partCatalogData.length || isNaN(partCatalogData.length)) {
      errorObj.length = "Length is required and must be a number.";
      error = true;
      setBtnLoader(false);
    }
    if (!partCatalogData.width || isNaN(partCatalogData.width)) {
      errorObj.width = "Width is required and must be a number.";
      error = true;
      setBtnLoader(false);
    }
    if (!partCatalogData.height || isNaN(partCatalogData.height)) {
      errorObj.height = "Height is required and must be a number.";
      error = true;
      setBtnLoader(false);
    }

    setErrors(errorObj);
    Utils.focusError();
    if (error) return;

    const part_list = addedParts?.map((item) => ({ id: item.id })) || [];

    let data = {
      namePartPT: partCatalogData?.namePartPT,
      namePartES: partCatalogData?.namePartES,
      namePartEN: partCatalogData?.namePartEN,
      namePartFR: partCatalogData?.namePartFR,
      namePartIT: partCatalogData?.namePartIT,
      namePartDE: partCatalogData?.namePartDE,
      idTypePart: partCatalogData?.idTypePart,
      idLocation: partCatalogData?.idLocation,
      idGroup: partCatalogData?.idGroup,
      idSide: partCatalogData?.idSide,
      dependsBodyCar: partCatalogData?.dependsBodyCar
        ? partCatalogData?.dependsBodyCar
        : "0",
      numCarDoors: partCatalogData?.numCarDoors,
      individual: partCatalogData?.individual,
      weight: partCatalogData?.weight,
      length: partCatalogData?.length,
      width: partCatalogData?.width,
      height: partCatalogData?.height,
      etai_part_id: partCatalogData?.etai_part_id,
      part_list: part_list,
      is_master_part: partCatalogData?.is_master_part,
    };

    let updateData = {
      id: partCatalogData?.id,
      namePartPT: partCatalogData?.namePartPT,
      namePartES: partCatalogData?.namePartES,
      namePartEN: partCatalogData?.namePartEN,
      namePartFR: partCatalogData?.namePartFR,
      namePartIT: partCatalogData?.namePartIT,
      namePartDE: partCatalogData?.namePartDE,
      idTypePart: partCatalogData?.idTypePart,
      idLocation: partCatalogData?.idLocation,
      idGroup: partCatalogData?.idGroup,
      idSide: partCatalogData?.idSide,
      dependsBodyCar: partCatalogData?.dependsBodyCar,
      numCarDoors: partCatalogData?.numCarDoors,
      individual: partCatalogData?.individual,
      weight: partCatalogData?.weight,
      length: partCatalogData?.length,
      width: partCatalogData?.width,
      height: partCatalogData?.height,
      etai_part_id: partCatalogData?.etai_part_id,
      part_list: part_list,
      is_master_part: partCatalogData?.is_master_part,
    };

    if (partCatalogData?.id) {
      updatePartCatalog(updateData)
        .then((response) => {
          toast.success(response.data.message);
          navigate("/part-catalog");
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        })
        .finally(() => {
          setBtnLoader(false);
        });
    } else {
      createPartCatalog(data)
        .then((response) => {
          toast.success(response.data.message);
          navigate("/part-catalog");
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        })
        .finally(() => {
          setBtnLoader(false);
        });
    }
  };

  return (
    <div>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Add Catalog</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/part-catalog">Part Catalog</a>
                </li>
                <li className="breadcrumb-item active">Add PartCatalog</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <Form method={`post`} onSubmit={handlePartCatalogForm}>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Part PT"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control input-search"
                                type="text"
                                value={partCatalogData.namePartPT}
                                onChange={(e) => {
                                  setPartCatalogData({
                                    ...partCatalogData,
                                    namePartPT: e.target.value,
                                  });
                                }}
                                name={`namePartPT`}
                                maxLength={100}
                                placeholder="Part PT"
                              />
                            </div>
                            {errors.namePartPT && (
                              <span className="input-error">
                                {errors.namePartPT}
                              </span>
                            )}
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Part ES"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control input-search"
                                type="text"
                                value={partCatalogData.namePartES}
                                onChange={(e) => {
                                  setPartCatalogData({
                                    ...partCatalogData,
                                    namePartES: e.target.value,
                                  });
                                }}
                                name={`namePartES`}
                                maxLength={100}
                                placeholder="Part ES"
                              />
                            </div>
                            {errors.namePartES && (
                              <span className="input-error">
                                {errors.namePartES}
                              </span>
                            )}
                          </div>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Part UK"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control input-search"
                                type="text"
                                value={partCatalogData.namePartEN}
                                onChange={(e) => {
                                  setPartCatalogData({
                                    ...partCatalogData,
                                    namePartEN: e.target.value,
                                  });
                                }}
                                name={`namePartEN`}
                                maxLength={100}
                                placeholder="Part UK"
                              />
                            </div>
                            {errors.namePartEN && (
                              <span className="input-error">
                                {errors.namePartEN}
                              </span>
                            )}
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Part FR"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control input-search"
                                type="text"
                                value={partCatalogData.namePartFR}
                                onChange={(e) => {
                                  setPartCatalogData({
                                    ...partCatalogData,
                                    namePartFR: e.target.value,
                                  });
                                }}
                                name={`namePartFR`}
                                maxLength={100}
                                placeholder="Part FR"
                              />
                            </div>
                            {errors.namePartFR && (
                              <span className="input-error">
                                {errors.namePartFR}
                              </span>
                            )}
                          </div>
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Part IT"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control input-search"
                                type="text"
                                value={partCatalogData.namePartIT}
                                onChange={(e) => {
                                  setPartCatalogData({
                                    ...partCatalogData,
                                    namePartIT: e.target.value,
                                  });
                                }}
                                name={`namePartIT`}
                                maxLength={100}
                                placeholder="Part IT"
                              />
                            </div>
                            {errors.namePartIT && (
                              <span className="input-error">
                                {errors.namePartIT}
                              </span>
                            )}
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Part DE"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control input-search"
                                type="text"
                                value={partCatalogData.namePartDE}
                                onChange={(e) => {
                                  setPartCatalogData({
                                    ...partCatalogData,
                                    namePartDE: e.target.value,
                                  });
                                }}
                                name={`namePartDE`}
                                maxLength={100}
                                placeholder="Part DE"
                              />
                            </div>
                            {errors.namePartDE && (
                              <span className="input-error">
                                {errors.namePartDE}
                              </span>
                            )}
                          </div>
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Part Type"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <Select
                                options={partTypeList}
                                value={partTypeList.find(
                                  (option) =>
                                    option.value ===
                                    Number(partCatalogData.idTypePart)
                                )}
                                onChange={(selectedOption) =>
                                  setPartCatalogData({
                                    ...partCatalogData,
                                    idTypePart: selectedOption.value,
                                  })
                                }
                                placeholder={"Select Part Type"}
                              />
                            </div>
                            {errors.idTypePart && (
                              <span className="input-error">
                                {errors.idTypePart}
                              </span>
                            )}
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Part Location"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <Select
                                options={partLocationList}
                                value={partLocationList.find(
                                  (option) =>
                                    option.value ===
                                    Number(partCatalogData.idLocation)
                                )}
                                onChange={(selectedOption) =>
                                  setPartCatalogData({
                                    ...partCatalogData,
                                    idLocation: selectedOption.value,
                                  })
                                }
                                placeholder={"Select Part Location"}
                              />
                            </div>
                            {errors.idLocation && (
                              <span className="input-error">
                                {errors.idLocation}
                              </span>
                            )}
                          </div>
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Part Group"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <Select
                                options={partGroupList}
                                value={partGroupList.find(
                                  (option) =>
                                    option.value ===
                                    Number(partCatalogData.idGroup)
                                )}
                                onChange={(selectedOption) =>
                                  setPartCatalogData({
                                    ...partCatalogData,
                                    idGroup: selectedOption.value,
                                  })
                                }
                                placeholder={"Select Part Group"}
                              />
                            </div>
                            {errors.idGroup && (
                              <span className="input-error">
                                {errors.idGroup}
                              </span>
                            )}
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Part Slide"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <Select
                                options={partSideList}
                                value={partSideList.find(
                                  (option) =>
                                    option.value ===
                                    Number(partCatalogData.idSide)
                                )}
                                onChange={(selectedOption) =>
                                  setPartCatalogData({
                                    ...partCatalogData,
                                    idSide: selectedOption.value,
                                  })
                                }
                                placeholder={"Select Part Slide"}
                              />
                            </div>
                            {errors.idSide && (
                              <span className="input-error">
                                {errors.idSide}
                              </span>
                            )}
                          </div>
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Number Of Car Doors"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control input-search"
                                type="number"
                                value={partCatalogData.numCarDoors}
                                onChange={(e) => {
                                  setPartCatalogData({
                                    ...partCatalogData,
                                    numCarDoors: e.target.value,
                                  });
                                }}
                                name={`numCarDoors`}
                                maxLength={100}
                                placeholder="Number Of Car Doors"
                              />
                            </div>
                            {errors.numCarDoors && (
                              <span className="input-error">
                                {errors.numCarDoors}
                              </span>
                            )}
                          </div>
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Height"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control input-search"
                                type="number"
                                value={partCatalogData.height}
                                onChange={(e) => {
                                  setPartCatalogData({
                                    ...partCatalogData,
                                    height: e.target.value,
                                  });
                                }}
                                name={`height`}
                                maxLength={100}
                                placeholder="height"
                              />
                            </div>
                            {errors.height && (
                              <span className="input-error">
                                {errors.height}
                              </span>
                            )}
                          </div>
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Weight"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control input-search"
                                type="number"
                                value={partCatalogData.weight}
                                onChange={(e) => {
                                  setPartCatalogData({
                                    ...partCatalogData,
                                    weight: e.target.value,
                                  });
                                }}
                                name={`weight`}
                                maxLength={100}
                                placeholder="Weight"
                              />
                            </div>
                            {errors.weight && (
                              <span className="input-error">
                                {errors.weight}
                              </span>
                            )}
                          </div>
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Length"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control input-search"
                                type="number"
                                value={partCatalogData.length}
                                onChange={(e) => {
                                  setPartCatalogData({
                                    ...partCatalogData,
                                    length: e.target.value,
                                  });
                                }}
                                name={`length`}
                                maxLength={100}
                                placeholder="Length"
                              />
                            </div>
                            {errors.length && (
                              <span className="input-error">
                                {errors.length}
                              </span>
                            )}
                          </div>
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Width"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control form-control input-search form-control"
                                type="number"
                                value={partCatalogData.width}
                                onChange={(e) => {
                                  setPartCatalogData({
                                    ...partCatalogData,
                                    width: e.target.value,
                                  });
                                }}
                                name={`width`}
                                maxLength={100}
                                placeholder="Width"
                              />
                            </div>
                            {errors.width && (
                              <span className="input-error">
                                {errors.width}
                              </span>
                            )}
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-md-3">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Select ETAI"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <Select
                                className="my-select-v"
                                options={VehicleEngineList}
                                value={VehicleEngineList.find(
                                  (item) =>
                                    item.value == partCatalogData.etai_part_id
                                )}
                                onChange={(selectedOption) => {
                                  setPartCatalogData({
                                    ...partCatalogData,
                                    etai_part_id: selectedOption
                                      ? selectedOption.value
                                      : "",
                                  });
                                }}
                                isSearchable={true}
                                onInputChange={
                                  handleInputSelectedChangeEngineList
                                }
                                inputSelctedValue={inputSelctededEngineValue}
                                placeholder={"Select ETAI"}
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-md-3">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            Individual
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={partCatalogData.individual === "1"}
                                  onChange={handleCheckboxChange}
                                />
                                <label className="form-check-label">
                                  Individual
                                </label>
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-md-3">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Depends Body Car"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={
                                    partCatalogData.dependsBodyCar === "1"
                                  }
                                  onChange={handleCheckboxChangeDependCar}
                                />
                                <label className="form-check-label">
                                  Depends Body Car
                                </label>
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            Is Master Part
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={
                                    partCatalogData.is_master_part === "1"
                                  }
                                  onChange={handleCheckboxChangeMaster}
                                />
                                <label className="form-check-label">
                                  Is Master Part
                                </label>
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                      </div>
                    </div>

                    {partCatalogData.is_master_part === "1" && (
                      <div className="row">
                        <div className="col-md-6">
                          {/* <div className="">
    <input
      className="form-control input-search"
      type="text"
      value={searchQuery}
      onChange={handleSearchChange}
      maxLength={50}
      placeholder="Search"
    />
  </div> */}
                          <div className="vehicleThBg table-responsive">
                            <table className="rounded-table table table-striped table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th style={{ width: "10%" }}>Part Name</th>
                                  <th style={{ width: "10%" }}>Action</th>
                                </tr>
                              </thead>

                              <tbody>
                                {filteredPartAssignList?.length > 0 ? (
                                  filteredPartAssignList?.map((item, index) => (
                                    <tr key={index}>
                                      <td>{item?.namePartPT}</td>

                                      <td>
                                        <Button
                                          className="addBtn"
                                          onClick={() =>
                                            handleAddClick(
                                              item.id,
                                              item.namePartPT
                                            )
                                          }
                                          style={{
                                            backgroundColor: addedParts?.some(
                                              (part) => part?.id === item?.id
                                            )
                                              ? "red"
                                              : "green",
                                            color: "white",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <i className="bi bi-plus-lg"></i>{" "}
                                        </Button>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr className="scrollBrdr">
                                    <td colSpan="100%">No results found</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div className="card-footer clearfix card-footer-part">
                            <div className="pagination pagination-sm m-0  paginationRight">
                              <Pagination
                                activePage={params.page}
                                itemsCountPerPage={parseInt(params.limit)}
                                totalItemsCount={parseInt(totalRecords)}
                                pageRangeDisplayed={5}
                                itemClass={`page-item`}
                                linkClass={`page-link`}
                                onChange={(pageNumber) =>
                                  handlePageChange(pageNumber)
                                } // Add this
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="vehicleThBg table-responsive">
                            <table className="rounded-table table table-striped table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th style={{ width: "10%" }}>Part Name</th>

                                  <th style={{ width: "0%" }}>Action</th>
                                </tr>
                              </thead>

                              <tbody>
                                {addedParts?.length > 0 ? (
                                  addedParts?.map((item, index) => (
                                    <tr key={index}>
                                      <td>{item?.namePartPT}</td>

                                      <td className="">
                                        <button
                                          className="btn-danger-delete"
                                          href="#"
                                          onClick={() =>
                                            handleAddClick(
                                              item.id,
                                              item.namePartPT
                                            )
                                          }
                                        >
                                          <i className="bi bi-trash3"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr className="scrollBrdr">
                                    <td colSpan="100%">No results found</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="col ms-2">
                        <button
                          type="button"
                          className="back-btn"
                          onClick={() => navigate("/part-catalog")}
                        >
                          <i className="bi bi-arrow-left"> </i>Back
                        </button>
                      </div>
                      <div className="col">
                        <div className="d-flex justify-content-end nxt-btn">
                          {btnLoader === true ? (
                            <button type="submit" className="btn btn-primary">
                              Loading...
                            </button>
                          ) : (
                            <button type="submit" className="btn btn-primary">
                              Submit <i className="bi bi-check2"></i>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-footer"></div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AddCatalog;
