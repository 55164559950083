import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";
import { useLocation } from "react-router-dom";
import {
  createPartGroup,
  deletePartgroup,
  getPartgroupList,
  importPartGroup,
  updatePartGroup,
  getExportPartgroupList,
} from "../../services/PartTypeService";
import { toast } from "react-toastify";
import Utils from "../../utils";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import * as XLSX from "xlsx";
import Select from "react-select";
import {
  createWareHouseEntity,
  deleteWareHouseEntity,
  getWareHouseEntityList,
  updateWareHouseEntity,
} from "../../services/WareHouseServices";
import { getCatalogListAuto } from "../../services/PartsInventory";
import { Dropdown } from "react-bootstrap";

const WarehouseEntityType = () => {
  const tableLengthList = ConfigDB.data.dataTableLength;
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const companyId = JSON.parse(data);
  const location = useLocation();

  const RolePermission = Utils.getRecordForCurrentPath(location);

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    company_id: companyId.company_id || 0,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [WareHouseListEntity, setWareHouseListEntity] = useState([]);

  const [delModal, setDelModal] = useState(false);
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [delpart, setDelPart] = useState("");
  const [modifyModal, setModifyModal] = useState(false);
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [wareHouseEntityData, setWareHouseEntityData] = useState({
    uuid: "",
    id: "",
    entity_name: "",
  });
  const [search, setSearch] = useState("");

  let errorsObj = {
    entity_name: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [catalog, setCatalog] = useState("");

  const handleChange = (e) => {
    const userInput = e.target.value;

    let data = {
      limit: params.limit,
      page: params.page,
      search: userInput,
      is_reload: true,
    };
    getCatalogListAuto(data)
      .then((response) => {
        setFilteredSuggestions(response.data.data);
      })
      .catch((err) => {});

    setCatalog(userInput);
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);
  };

  const handleClick = (suggestion) => {
    setCatalog(suggestion.partName);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      if (
        filteredSuggestions &&
        activeSuggestionIndex < filteredSuggestions?.length - 1
      ) {
        setActiveSuggestionIndex(activeSuggestionIndex + 1);
      }
    } else if (e.key === "ArrowUp") {
      if (activeSuggestionIndex > 0) {
        setActiveSuggestionIndex(activeSuggestionIndex - 1);
      }
    } else if (e.key === "Enter") {
      setCatalog(filteredSuggestions[activeSuggestionIndex]);
      setShowSuggestions(false);
      setFilteredSuggestions([]);
    }
  };

  const handleAddModal = () => {
    setModifyModal(true);
  };

  const handleCloseModal = () => {
    setModifyModal(false);
    setWareHouseEntityData({ uuid: "", id: "", entity_name: "" });
    setErrors({
      entity_name: "",
    });
  };

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  const fetchWareHouse = () => {
    let data = { ...params };
    getWareHouseEntityList(data)
      .then((response) => {
        setWareHouseListEntity(response.data.data);
        setTotalRecords(response.data.total);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(
    function () {
      let data = { ...params };

      fetchWareHouse(data);
    },
    [params]
  );

  const onDeleteModalClose = () => {
    setDelModal(false);
  };

  const onEditPartGroup = (e, item) => {
    setModifyModal(true);
    setWareHouseEntityData({
      uuid: item?.uuid,
      id: item?.id,
      entity_name: item?.entity_name,
    });
    setErrors({
      entity_name: "",
    });
  };

  const onDeleteModalOpen = (e, item) => {
    e.preventDefault();
    setDelPart(item.id);
    setDelModal(true);
  };

  const onDestroyPartType = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { id: delpart };
    deleteWareHouseEntity(data)
      .then((response) => {
        let data = { ...params };

        fetchWareHouse(data);
        toast.success(response.data.message);
        setDelPart("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleWarehoueEntity = (e) => {
    e.preventDefault();
    let error = false;
    let errorObj = { ...errorsObj };
    if (!wareHouseEntityData.entity_name) {
      errorObj.entity_name = `Entity name required.`;
      error = true;
    }

    setErrors(errorObj);
    Utils.focusError();
    if (error) return;

    setIsModifyButtonLoad(true);
    let data = {
      entity_name: wareHouseEntityData.entity_name,
      company_id: wareHouseEntityData.company_id,
    };

    let updateData = {
      uuid: wareHouseEntityData?.uuid,
      entity_name: wareHouseEntityData.entity_name,
    };

    if (wareHouseEntityData?.uuid) {
      updateWareHouseEntity(updateData)
        .then((response) => {
          toast.success(response.data.message);
          let data = { ...params };
          fetchWareHouse(data);
          setModifyModal(false);
          setIsModifyButtonLoad(false);
          setWareHouseEntityData({
            uuid: "",
            id: "",
            entity_name: "",
          });
          setErrors({
            entity_name: "",
          });
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
          setIsModifyButtonLoad(false);
        });
    } else {
      createWareHouseEntity(data)
        .then((response) => {
          toast.success(response.data.message);
          let data = { ...params };
          fetchWareHouse(data);
          setModifyModal(false);
          setIsModifyButtonLoad(false);

          setWareHouseEntityData({
            uuid: "",
            id: "",
            entity_name: "",
          });
          setErrors({
            entity_name: "",
          });
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
          setIsModifyButtonLoad(false);
        });
    }
  };

  return (
    <div>
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                {/* <h1 className="page-title">Part Group</h1> */}
              </div>
              <div className="col-sm-6"></div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card fuelCard container-custom-height">
                  <div className="card-header">
                    <div className="card-title m-0 float-left fuelCT" style={{width: "50%"}}>
                      <input
                        type="text"
                        className="form-control"
                        value={params.search}
                        onChange={(e) => handleParams(e, "search")}
                        placeholder="Search"
                      ></input>
                    </div>
                    <div className="card-title m-0 float-right d-flex">
                      <button
                        type="button"
                        className="btn  btn-icon-text mr-2"
                        onClick={handleAddModal}
                      >
                        <i className="bi bi-plus-lg"></i>
                        <span className="ms-2">Add</span>
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div
                      className="vehicleThBg table-responsive table-responsive-vehicle">
                      <table className="table table-bordered rounded-table table table-striped table-hover">
                        <thead>
                          <tr>
                            <th style={{ textAlign: "center", width: "1%" }}>#</th>

                            <th className="col-form-label ml-2 Label-my form-label form-label" style={{ textAlign: "center", width: "10%" }}>
                              Entity Name
                            </th>
                            <th
                              className="col-form-label ml-2 Label-my form-label form-label"
                              style={{ textAlign: "center", width: "0%" }}
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {WareHouseListEntity?.map((item, index) => {
                            return (
                              <>
                                <tr>
                                  <td>{++index}</td>
                                  <td>{item.entity_name}</td>

                                  <td className="d-flex">
                                    <Dropdown className="iconDropdown">
                                      <Dropdown.Toggle
                                        variant="success"
                                        id="dropdown-basic"
                                        className="iconToggle"
                                      >
                                        <i className="bi bi-three-dots-vertical"></i>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          className="d-flex"
                                          onClick={(e) =>
                                            onEditPartGroup(e, item)
                                          }
                                        >
                                          <a className="edit-btn" href="#">
                                            <i className="bi bi-pencil"></i>
                                          </a>

                                          <span>Edit</span>
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                          className="d-flex"
                                          onClick={(e) =>
                                            onDeleteModalOpen(e, item)
                                          }
                                        >
                                          <div className="trash-btn">
                                            <a className="delete-btn" href="#">
                                              <i className="bi bi-trash3"></i>
                                            </a>
                                          </div>

                                          <span>Delete</span>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                        {WareHouseListEntity &&
                          WareHouseListEntity?.length === 0 && (
                            <tr>
                              <td
                                colSpan={7}
                                className={`text-center`}
                              >{`Record Not Found`}</td>
                            </tr>
                          )}
                      </table>
                    </div>
                  </div>
                  {/* /.card-body */}
                </div>
              </div>
            </div>
          </div>
          <div className="fuelCF">
            <div className="card-footer clearfix card-footer-part ">
              <div className="pagination pagination-sm m-0 float-right">
                <Pagination
                  activePage={params.page}
                  itemsCountPerPage={parseInt(params.limit)}
                  totalItemsCount={parseInt(totalRecords)}
                  pageRangeDisplayed={5}
                  itemClass={`page-item`}
                  linkClass={`page-link`}
                  onChange={(e) => handleParams(e, "pagination")}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
      <Modal isOpen={delModal}>
        <ModalHeader>{`Delete WareHouse Entity`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this entity?`}</p>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            disabled={isDelButtonLoad}
            onClick={onDeleteModalClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close <i class="bi bi-x-lg"></i>
          </button>

          {!isDelButtonLoad && (
            <button
              type="button"
              className="btn btn-block bg-red"
              style={{ width: "100px" }}
              onClick={onDestroyPartType}
            >
              Delete <i class="bi bi-trash3"></i>
            </button>
          )}
          {isDelButtonLoad && (
            <button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
            </button>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modifyModal}>
        <ModalHeader>Warehouse Entity Type</ModalHeader>
        <Form method={`post`} onSubmit={handleWarehoueEntity}>
          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Entity Name"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={wareHouseEntityData.entity_name}
                        onChange={(e) => {
                          setWareHouseEntityData({
                            ...wareHouseEntityData,
                            entity_name: e.target.value,
                          });
                        }}
                        name={`entity_name`}
                        maxLength={50}
                        placeholder="Entity Name"
                      />
                    </div>
                    {errors.entity_name && (
                      <span className="input-error">{errors.entity_name}</span>
                    )}
                  </div>
                </FormGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              class="btn btn-block btn-primary"
              disabled={isModifyButtonLoad}
              onClick={handleCloseModal}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-x-lg"></i>
            </button>
            {!isModifyButtonLoad && (
              <button
                type="submit"
                class="btn btn-block btn-primary"
                style={{ width: "100px", marginTop: "10px" }}
              >
                Submit <i class="bi bi-check2"></i>
              </button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default WarehouseEntityType;
