import React, { useEffect, useState } from "react";
import { Form, FormGroup, Label } from "reactstrap";
import { createPartCatalog } from "../../services/LerCatalogService";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useNavigate } from "react-router-dom";
import {
  createVehicleExtraInfo,
  createVehicleOwner,
} from "../../services/VehicleService";



const VehicleInfoExtra = ({
  setActiveTab,
  vehicleProcessList,
  activeTab,
  vehicleVinData,
  vehiclePlateData,
}) => {
  const [vehicleInfoExtraData, setVehicleInfoExtraData] = useState({
    uuid: "",
    tires_front: "",
    tires_rear: "",
    max_weight_front: "",
    max_weight_rear: "",
    is_towable: 0,
    with_brake: 0,
    without_brake: 0,
    power_lifting: "",
    box_type: "",
    comp_max_box: "",
    box_width: "",
    axel_distance: "",
    co2_g_km: "",
    particles_g_km: "",
    no_of_right_doors: "",
    no_of_left_doors: "",
    no_of_rear_doors: "",
    capacity: "",
    previous_registration: "",
    registration_date: "",
    origin_country: "",
    origin_state: "",
    comments: "",
    special_notes: "",
  });

  const navigate = useNavigate();

  let errorsObj = {
    tires_front: "",
    tires_rear: "",
    max_weight_front: "",
    max_weight_rear: "",
    is_towable: "",
    with_brake: "",
    without_brake: "0",
    power_lifting: "",
    box_type: "",
    comp_max_box: "",
    box_width: "",
    axel_distance: "",
    co2_g_km: "",
    particles_g_km: "",
    no_of_right_doors: "",
    no_of_left_doors: "",
    no_of_rear_doors: "",
    capacity: "",
    previous_registration: "",
    registration_date: "",
    origin_country: "",
    origin_state: "",
    comments: "",
    special_notes: "",
  };

  const [errors, setErrors] = useState(errorsObj);
  let vehicleId = localStorage.getItem("vehicleId");

  useEffect(() => {
    if (vehicleProcessList && activeTab === "vehicle_extra_info") {
      setVehicleInfoExtraData((prevData) => ({
        ...prevData,
        uuid: vehicleProcessList?.uuid,
        tires_front: vehicleProcessList?.tires_front,
        tires_rear: vehicleProcessList?.tires_rear,
        max_weight_front: vehicleProcessList?.max_weight_front,
        max_weight_rear: vehicleProcessList?.max_weight_rear,
        is_towable: vehicleProcessList?.is_towable,
        with_brake: vehicleProcessList?.with_brake,
        without_brake: vehicleProcessList?.without_brake,
        power_lifting: vehicleProcessList?.power_lifting,
        box_type: vehicleProcessList?.box_type,
        comp_max_box: vehicleProcessList?.comp_max_box,
        box_width: vehicleProcessList?.box_width,
        axel_distance: vehicleProcessList?.axel_distance,
        co2_g_km: vehicleProcessList?.co2_g_km,
        particles_g_km: vehicleProcessList?.particles_g_km,
        no_of_right_doors: vehicleProcessList?.no_of_right_doors,
        no_of_left_doors: vehicleProcessList?.no_of_left_doors,
        no_of_rear_doors: vehicleProcessList?.no_of_rear_doors,
        capacity: vehicleProcessList?.capacity,
        previous_registration: vehicleProcessList?.previous_registration,
        registration_date: vehicleProcessList?.registration_date,
        origin_country: vehicleProcessList?.origin_country,
        origin_state: vehicleProcessList?.origin_state,
        comments: vehicleProcessList?.comments,
        special_notes: vehicleProcessList?.special_notes,
      }));
    }
  }, [vehicleProcessList, activeTab]);

  useEffect(() => {
    if (
      vehicleVinData &&
      vehicleVinData?.vehicle_general_info &&
      vehicleVinData?.vehicle_general_info?.length > 0
    ) {
      setVehicleInfoExtraData((prevData) => ({
        ...prevData,
        capacity: vehiclePlateData?.vehicle_general_info[0]?.engine_capacity,
        registration_date:
          vehiclePlateData?.vehicle_general_info[0]?.plate_registration,
        // registration:
        //   vehiclePlateData?.vehicle_general_info[0]
        //     ?.vehicle_type_carClassificationRegID,
        box_type: vehiclePlateData?.vehicle_general_info[0]?.gear_box,
      }));
    }

    if (vehiclePlateData && vehiclePlateData?.vehicle_general_info) {
      setVehicleInfoExtraData((prevData) => ({
        ...prevData,
        capacity: vehiclePlateData?.vehicle_general_info?.engine_capacity,
        registration_date:
          vehiclePlateData?.vehicle_general_info?.plate_registration,
        // registration:
        //   vehiclePlateData?.vehicle_general_info
        //     ?.vehicle_type_carClassificationRegID,
        box_type: vehiclePlateData?.vehicle_general_info?.gear_box,
      }));
    }
  }, [vehicleVinData, vehiclePlateData]);


  const [btnLoader, setBtnLoader] = useState(false);

  const handleCheckboxChange = (e) => {
    setVehicleInfoExtraData({
      ...vehicleInfoExtraData,
      is_towable: e.target.checked ? 1 : 0,
    });
  };

  const handleRadioChange = (e) => {
    const value = parseInt(e.target.value, 10); // Convert value to an integer
    setVehicleInfoExtraData({
      ...vehicleInfoExtraData,
      with_brake: value === 1 ? 1 : 0,
      without_brake: value === 0 ? 1 : 0,
    });
  };

  const handleVehicleExtraForm = (e) => {
    e.preventDefault();
    setBtnLoader(true);
    // let error = false;
    // let errorObj = { ...errorsObj };

    // if (!vehicleInfoExtraData.tires_front) {
    //   errorObj.tires_front = `Tires front is required.`;
    //   error = true;
    // }

    // if (!vehicleInfoExtraData.tires_rear) {
    //   errorObj.tires_rear = `Tires rear is required.`;
    //   error = true;
    // }
    // if (!vehicleInfoExtraData.max_weight_front) {
    //   errorObj.max_weight_front = `Max weight front is required.`;
    //   error = true;
    // }
    // if (!vehicleInfoExtraData.max_weight_rear) {
    //   errorObj.max_weight_rear = `Max weight rear is required.`;
    //   error = true;
    // }
    // if (!vehicleInfoExtraData.power_lifting) {
    //   errorObj.power_lifting = `Power lifting is required.`;
    //   error = true;
    // }

    // if (!vehicleInfoExtraData.box_type) {
    //   errorObj.box_type = `Box type is required.`;
    //   error = true;
    // }

    // if (!vehicleInfoExtraData.comp_max_box) {
    //   errorObj.comp_max_box = `Comp max box is required.`;
    //   error = true;
    // }

    // if (!vehicleInfoExtraData.box_width) {
    //   errorObj.box_width = `Box width is required.`;
    //   error = true;
    // }
    // if (!vehicleInfoExtraData.axel_distance) {
    //   errorObj.axel_distance = `Axel distance is required.`;
    //   error = true;
    // }
    // if (!vehicleInfoExtraData.co2_g_km) {
    //   errorObj.co2_g_km = `Co2 gkm is required.`;
    //   error = true;
    // }
    // if (!vehicleInfoExtraData.particles_g_km) {
    //   errorObj.particles_g_km = `Particles gkm is required.`;
    //   error = true;
    // }
    // if (!vehicleInfoExtraData.no_of_right_doors) {
    //   errorObj.no_of_right_doors = `No Of Right Doors is required.`;
    //   error = true;
    // }
    // if (!vehicleInfoExtraData.no_of_left_doors) {
    //   errorObj.no_of_left_doors = `No Of Left Doors  is required.`;
    //   error = true;
    // }

    // if (!vehicleInfoExtraData.no_of_rear_doors) {
    //   errorObj.no_of_rear_doors = `No Of Rear Doors  is required.`;
    //   error = true;
    // }
    // if (!vehicleInfoExtraData.capacity) {
    //   errorObj.capacity = `Capacity is required.`;
    //   error = true;
    // }
    // if (!vehicleInfoExtraData.previous_registration) {
    //   errorObj.previous_registration = `Previous Registration is required.`;
    //   error = true;
    // }
    // if (!vehicleInfoExtraData.registration_date) {
    //   errorObj.registration_date = `Registration Date is required.`;
    //   error = true;
    // }
    // if (!vehicleInfoExtraData.origin_country) {
    //   errorObj.origin_country = `Origin Country is required.`;
    //   error = true;
    // }
    // if (!vehicleInfoExtraData.origin_state) {
    //   errorObj.origin_state = `Origin State is required.`;
    //   error = true;
    // }
    // if (!vehicleInfoExtraData.comments) {
    //   errorObj.comments = `Comments is required.`;
    //   error = true;
    // }
    // if (!vehicleInfoExtraData.special_notes) {
    //   errorObj.special_notes = `Special Notes is required.`;
    //   error = true;
    // }

    // setErrors(errorObj);
    // Utils.focusError();

    // if (error) return;

    let data = {
      vehicle_id: vehicleId,
      tires_front: vehicleInfoExtraData?.tires_front,
      tires_rear: vehicleInfoExtraData?.tires_rear,
      max_weight_front: vehicleInfoExtraData?.max_weight_front,
      max_weight_rear: vehicleInfoExtraData?.max_weight_rear,
      is_towable: vehicleInfoExtraData?.is_towable,
      with_brake: vehicleInfoExtraData?.with_brake,
      without_brake: vehicleInfoExtraData?.without_brake,
      power_lifting: vehicleInfoExtraData?.power_lifting,
      box_type: vehicleInfoExtraData?.box_type,
      comp_max_box: vehicleInfoExtraData?.comp_max_box,
      box_width: vehicleInfoExtraData?.box_width,
      axel_distance: vehicleInfoExtraData?.axel_distance,
      co2_g_km: vehicleInfoExtraData?.co2_g_km,
      particles_g_km: vehicleInfoExtraData?.particles_g_km,
      no_of_right_doors: vehicleInfoExtraData?.no_of_right_doors,
      no_of_left_doors: vehicleInfoExtraData?.no_of_left_doors,
      no_of_rear_doors: vehicleInfoExtraData?.no_of_rear_doors,
      capacity: vehicleInfoExtraData?.capacity,
      previous_registration: vehicleInfoExtraData?.previous_registration,
      registration_date: vehicleInfoExtraData?.registration_date,
      origin_country: vehicleInfoExtraData?.origin_country,
      origin_state: vehicleInfoExtraData?.origin_state,
      comments: vehicleInfoExtraData?.comments,
      special_notes: vehicleInfoExtraData?.special_notes,
      // ...(vehicleProcessList ? { uuid: vehicleProcessList.uuid } : {}),
    };

    let updateData = {
      uuid: vehicleInfoExtraData?.uuid,
      vehicle_id: vehicleId,
      tires_front: vehicleInfoExtraData?.tires_front,
      tires_rear: vehicleInfoExtraData?.tires_rear,
      max_weight_front: vehicleInfoExtraData?.max_weight_front,
      max_weight_rear: vehicleInfoExtraData?.max_weight_rear,
      is_towable: vehicleInfoExtraData?.is_towable,
      with_brake: vehicleInfoExtraData?.with_brake,
      without_brake: vehicleInfoExtraData?.without_brake,
      power_lifting: vehicleInfoExtraData?.power_lifting,
      box_type: vehicleInfoExtraData?.box_type,
      comp_max_box: vehicleInfoExtraData?.comp_max_box,
      box_width: vehicleInfoExtraData?.box_width,
      axel_distance: vehicleInfoExtraData?.axel_distance,
      co2_g_km: vehicleInfoExtraData?.co2_g_km,
      particles_g_km: vehicleInfoExtraData?.particles_g_km,
      no_of_right_doors: vehicleInfoExtraData?.no_of_right_doors,
      no_of_left_doors: vehicleInfoExtraData?.no_of_left_doors,
      no_of_rear_doors: vehicleInfoExtraData?.no_of_rear_doors,
      capacity: vehicleInfoExtraData?.capacity,
      previous_registration: vehicleInfoExtraData?.previous_registration,
      registration_date: vehicleInfoExtraData?.registration_date,
      origin_country: vehicleInfoExtraData?.origin_country,
      origin_state: vehicleInfoExtraData?.origin_state,
      comments: vehicleInfoExtraData?.comments,
      special_notes: vehicleInfoExtraData?.special_notes,
    };

    if (vehicleInfoExtraData?.uuid) {
      createVehicleExtraInfo(updateData)
        .then((response) => {
          toast.success(response.data.message);
          setActiveTab("vehicle_owner");
          // localStorage.setItem("ownerList", response.data.data.id);
          // navigate("/vehicle-process");
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        })
        .finally(() => {
          setBtnLoader(false); 
      });
    } else {
      createVehicleExtraInfo(data)
        .then((response) => {
          toast.success(response.data.message);
          setActiveTab("vehicle_owner");
          // localStorage.setItem("ownerList", response.data.data.id);
          // navigate("/vehicle-process");
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        })
        .finally(() => {
          setBtnLoader(false); 
      });
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary">
            <Form method={`post`} onSubmit={handleVehicleExtraForm}>
              <div className="vehicle-owner-form">
                <div className="card-body">
                  <div className="row justify-content-start">
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Tires Front"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleInfoExtraData.tires_front}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  tires_front: e.target.value,
                                });
                              }}
                              name={`tires_front`}
                              maxLength={100}
                              placeholder="Tires Front"
                            />
                          </div>
                          {/* {errors.tires_front && (
                            <span className="input-error">
                              {errors.tires_front}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>

                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">{"Tires Rear"}</Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleInfoExtraData.tires_rear}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  tires_rear: e.target.value,
                                });
                              }}
                              name={`tires_rear`}
                              maxLength={100}
                              placeholder="Tires Rear"
                            />
                          </div>
                          {/* {errors.tires_rear && (
                            <span className="input-error">
                              {errors.tires_rear}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Max Weight Front"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="number"
                              value={vehicleInfoExtraData.max_weight_front}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  max_weight_front: e.target.value,
                                });
                              }}
                              name={`max_weight_front`}
                              maxLength={100}
                              placeholder="Max Weight Front"
                            />
                          </div>
                          {/* {errors.max_weight_front && (
                            <span className="input-error">
                              {errors.max_weight_front}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Max Weight Rear"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="number"
                              value={vehicleInfoExtraData.max_weight_rear}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  max_weight_rear: e.target.value,
                                });
                              }}
                              name={`max_weight_rear`}
                              maxLength={40}
                              placeholder="Max Weight Rear"
                            />
                          </div>
                          {/* {errors.max_weight_rear && (
                            <span className="input-error">
                              {errors.max_weight_rear}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Power Lifting"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleInfoExtraData.power_lifting}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  power_lifting: e.target.value,
                                });
                              }}
                              name={`power_lifting`}
                              maxLength={100}
                              placeholder="Power Lifting"
                            />
                          </div>
                          {/* {errors.power_lifting && (
                            <span className="input-error">
                              {errors.power_lifting}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">{"Box Type"}</Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleInfoExtraData.box_type}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  box_type: e.target.value,
                                });
                              }}
                              name={`box_type`}
                              maxLength={100}
                              placeholder="Box Type"
                            />
                          </div>
                          {/* {errors.box_type && (
                            <span className="input-error">
                              {errors.box_type}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Comp Max Box"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleInfoExtraData.comp_max_box}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  comp_max_box: e.target.value,
                                });
                              }}
                              name={`comp_max_box`}
                              maxLength={100}
                              placeholder="Comp Max Box"
                            />
                          </div>
                          {/* {errors.comp_max_box && (
                            <span className="input-error">
                              {errors.comp_max_box}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">{"Box Width"}</Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="number"
                              value={vehicleInfoExtraData.box_width}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  box_width: e.target.value,
                                });
                              }}
                              name={`box_width`}
                              maxLength={20}
                              placeholder="Box Width"
                            />
                          </div>
                          {/* {errors.box_width && (
                            <span className="input-error">
                              {errors.box_width}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Axel Distance"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="number"
                              value={vehicleInfoExtraData.axel_distance}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  axel_distance: e.target.value,
                                });
                              }}
                              name={`axel_distance`}
                              maxLength={30}
                              placeholder="Axel Distance"
                            />
                          </div>
                          {/* {errors.axel_distance && (
                            <span className="input-error">
                              {errors.axel_distance}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">{"Co2 gkm"}</Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleInfoExtraData.co2_g_km}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  co2_g_km: e.target.value,
                                });
                              }}
                              name={`co2_g_km`}
                              maxLength={50}
                              placeholder="Co2 gkm"
                            />
                          </div>
                          {/* {errors.co2_g_km && (
                            <span className="input-error">
                              {errors.co2_g_km}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Particles gkm"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleInfoExtraData.particles_g_km}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  particles_g_km: e.target.value,
                                });
                              }}
                              name={`particles_g_km`}
                              maxLength={100}
                              placeholder="Particles gkm"
                            />
                          </div>
                          {/* {errors.particles_g_km && (
                            <span className="input-error">
                              {errors.particles_g_km}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"No Of Right Doors"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="number"
                              value={vehicleInfoExtraData.no_of_right_doors}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  no_of_right_doors: e.target.value,
                                });
                              }}
                              name={`no_of_right_doors`}
                              maxLength={9}
                              placeholder="No Of Right Doors"
                            />
                          </div>
                          {/* {errors.no_of_right_doors && (
                            <span className="input-error">
                              {errors.no_of_right_doors}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"No Of Left Doors"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="number"
                              value={vehicleInfoExtraData.no_of_left_doors}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  no_of_left_doors: e.target.value,
                                });
                              }}
                              name={`no_of_left_doors`}
                              maxLength={9}
                              placeholder="No Of Left Doors"
                            />
                          </div>
                          {/* {errors.no_of_left_doors && (
                            <span className="input-error">
                              {errors.no_of_left_doors}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>

                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"No Of Rear Doors"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="number"
                              value={vehicleInfoExtraData.no_of_rear_doors}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  no_of_rear_doors: e.target.value,
                                });
                              }}
                              name={`no_of_rear_doors`}
                              maxLength={9}
                              placeholder="No Of Rear Doors"
                            />
                          </div>
                          {/* {errors.no_of_rear_doors && (
                            <span className="input-error">
                              {errors.no_of_rear_doors}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">{"Capacity"}</Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="number"
                              value={vehicleInfoExtraData.capacity}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  capacity: e.target.value,
                                });
                              }}
                              name={`capacity`}
                              maxLength={9}
                              placeholder="Capacity"
                            />
                          </div>
                          {/* {errors.capacity && (
                            <span className="input-error">
                              {errors.capacity}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Previous Registration"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleInfoExtraData.previous_registration}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  previous_registration: e.target.value,
                                });
                              }}
                              name={`previous_registration`}
                              placeholder="Previous Registration"
                            />
                          </div>
                          {/* {errors.previous_registration && (
                            <span className="input-error">
                              {errors.previous_registration}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Registration Date"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              type="date"
                              id="registration_date"
                              className="form-control"
                              value={vehicleInfoExtraData.registration_date}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  registration_date: e.target.value,
                                });
                              }}
                            ></input>
                          </div>
                          {/* {errors.registration_date && (
                            <span className="input-error">
                              {errors.registration_date}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Origin Country"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleInfoExtraData.origin_country}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  origin_country: e.target.value,
                                });
                              }}
                              name={`origin_country`}
                              placeholder="Origin Country"
                            />
                          </div>
                          {/* {errors.origin_country && (
                            <span className="input-error">
                              {errors.origin_country}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Origin State"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleInfoExtraData.origin_state}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  origin_state: e.target.value,
                                });
                              }}
                              name={`origin_state`}
                              placeholder="Origin State"
                            />
                          </div>
                          {/* {errors.origin_state && (
                            <span className="input-error">
                              {errors.origin_state}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">{"Comments"}</Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleInfoExtraData.comments}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  comments: e.target.value,
                                });
                              }}
                              name={`comments`}
                              placeholder="Comments"
                            />
                          </div>
                          {/* {errors.comments && (
                            <span className="input-error">
                              {errors.comments}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Special Notes"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleInfoExtraData.special_notes}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  special_notes: e.target.value,
                                });
                              }}
                              name={`special_notes`} placeholder="Special Notes"
                            />
                          </div>
                          {/* {errors.special_notes && (
                            <span className="input-error">
                              {errors.special_notes}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Brake Option"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div radiobtns mt-12">
                            <div className="form-check form-check-radio">
                              <input
                                className="form-check-input custom-radio"
                                type="radio"
                                id="with_brake"
                                name="brake_option"
                                checked={vehicleInfoExtraData.with_brake}
                                onChange={handleRadioChange}
                                value="1"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="with_brake"
                              >
                                With Brake
                              </Label>
                            </div>
                            <div className="form-check form-check-radio">
                              <input
                                className="form-check-input custom-radio"
                                type="radio"
                                id="without_brake"
                                name="brake_option"
                                value="0"
                                checked={vehicleInfoExtraData.without_brake}
                                onChange={handleRadioChange}
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="without_brake"
                              >
                                Without Brake
                              </Label>
                            </div>
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">{"Is Towable"}</Label>
                        <div className="col-xl-12">
                          <div className="input-div mt-12">
                            <div className="form-check">
                              <input
                                className="my-checkbox-1 form-check-input"
                                type="checkbox"
                                checked={vehicleInfoExtraData.is_towable}
                                onChange={handleCheckboxChange}
                              />
                              <label className="form-check-label pl-6">
                                Is Towable
                              </label>
                            </div>
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                  </div>

                  <div className="buttonsB">
                    <div className="col">
                      <button
                        type="button"
                        className="back-btn"
                        onClick={() => setActiveTab("vehicle_images")}
                      >
                        <i class="bi bi-arrow-left"></i> Back
                      </button>
                    </div>
                    <div className="col">
                      <div className="btnNxtFns">
                        {btnLoader === true ? (
                          <button
                          type="submit"
                          className="btn btn-primary nxt-btn"
                        >
                          Loading...</button>
                        ) : (
                          <button
                          type="submit"
                          className="btn btn-primary nxt-btn"
                        >
                          Finish <i class="bi bi-arrow-right"></i>
                        </button>
                        )}
                        
                        <button
                          type="button"
                          className="btn btn-primary nxt-btn"
                          onClick={() => setActiveTab("vehicle_owner")}

                        >
                          Next <i class="bi bi-arrow-right"></i>
                        </button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleInfoExtra;
