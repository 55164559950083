// let environment = "local";
// // let apiUrl = "http://localhost:8000/api/v1/";
// // let apiUrl = "http://192.168.4.4:8000/api/v1/";
// let apiUrl = "http://192.168.4.18:8000/api/v1/";

const environment = process.env.REACT_APP_ENV;
let apiUrl = process.env.REACT_APP_API_URL_LOCAL;

if (environment === "production") {
  apiUrl = process.env.REACT_APP_API_URL;
}

export class ConfigDB {
  static data = {
    app_name: "TP-SOFT",
    api_url: apiUrl,
    login_data: "@secure.@data",
    ROLE_ADMIN: 1,
    dataTableLength: [10, 25, 75, 100],
  };
}

export default ConfigDB;
