import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";

import {
  createPartSlides,
  deletePartsides,
  getPartsidesList,
  importPartSlide,
  updatePartGroup,
  getExportPartsidesList,
} from "../../services/PartTypeService";
import { toast } from "react-toastify";
import Utils from "../../utils";

import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Select from "react-select";

import { useLocation } from "react-router-dom";
import {
  actionAddPark,
  actionDelPark,
  actionUpdatePark,
  getParkList,
} from "../../services/ParkServices";
import {
  actionCityList,
  actionCountryList,
  actionStateList,
} from "../../services/CommonService";
import { getVehicleList } from "../../services/PartsInventory";
import Dropdown from "react-bootstrap/Dropdown";


const Park = () => {
  const location = useLocation();
  const RolePermission = Utils.getRecordForCurrentPath(location);

  const tableLengthList = ConfigDB.data.dataTableLength;
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const companyId = JSON.parse(data);
  const LangData = localStorage.getItem("lng");

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    company_id: companyId.company_id || 0,
  });
  const [totalRecords, setTotalRecords] = useState(0);

  const [parkList, setParkList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [delModal, setDelModal] = useState(false);
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [delpart, setDelPart] = useState("");
  const [modifyModal, setModifyModal] = useState(false);
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [parkData, setParkData] = useState({
    uuid: "",
    vehicle_id: "",
    country_id: "",
    state_id: "",
    city_id: "",
    place: "",
    name: "",
    description: "",
  });
  const [VehicleModelList, setVehicleModelList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const handleAddModal = () => {
    setModifyModal(true);
  };

  const handleCloseModal = () => {
    setModifyModal(false);
    setParkData("");
  };

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  const fetchDataPark = () => {
    let data = { ...params };
    getParkList(data)
      .then((response) => {
        setParkList(response.data.data);
        setTotalRecords(response.data.total);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(
    function () {
      let data = { ...params };

      fetchDataPark(data);
    },
    [params]
  );

  useEffect(function () {
    actionCountryList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }
        setCountryList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (parkData?.country_id) {
      actionStateList({ country_id: parkData?.country_id })
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].name,
            });
          }
          setStateList(list);
        })
        .catch((err) => {});
    }
  }, [parkData]);

  useEffect(() => {
    if (parkData?.state_id) {
      actionCityList({ state_id: parkData?.state_id })
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].name,
            });
          }
          setCityList(list);
        })
        .catch((err) => {});
    }
  }, [parkData]);

  useEffect(function () {
    var obj = {
      search: "",
      limit: tableLengthList[0],
      page: 1,
    };
    getVehicleList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].vehicle_model,
          });
        }
        setVehicleModelList(list);
      })
      .catch((err) => {});
  }, []);

  const handleInputChange = (inputValue) => {
    setInputValue(inputValue);

    if (inputValue.length > 2) {
      const obj = {
        search: inputValue,
        limit: tableLengthList[0],
        page: 1,
      };

      getVehicleList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].vehicle_model,
            });
          }

          setVehicleModelList(list);
        })
        .catch((err) => {
          console.error("Error fetching vehicle model list", err);
        });
    } else {
      setVehicleModelList([]);
      let obj = {
        search: "",
        limit: tableLengthList[0],
        page: 1,
      };
      getVehicleList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].vehicle_model,
            });
          }

          setVehicleModelList(list);
        })
        .catch((err) => {
          console.error("Error fetching vehicle model list", err);
        });
    }
  };

  const handleSelectChange = (selectedOption) => {
    setParkData({
      ...parkData,
      vehicle_id: selectedOption ? selectedOption.value : "",
    });
  };

  const onDeleteModalClose = () => {
    setDelModal(false);
  };

  const onEditPark = (e, item) => {
    setModifyModal(true);
    setParkData({
      uuid: item?.uuid,
      vehicle_id: item?.vehicle_id,
      country_id: item?.country_id,
      state_id: item?.state_id,
      city_id: item?.city_id,
      place: item?.place,
      name: item?.name,
      description: item?.description,
    });
  };

  const onDeleteModalOpen = (e, item) => {
    e.preventDefault();
    setDelPart(item.uuid);
    setDelModal(true);
  };

  const onDestroyPartType = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: delpart };
    actionDelPark(data)
      .then((response) => {
        let data = { ...params };
        fetchDataPark(data);
        toast.success(response.data.message);
        setDelPart("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handlePartSlideForm = (e) => {
    e.preventDefault();

    setIsModifyButtonLoad(true);
    let data = {
      vehicle_id: 0,
      country_id: parkData.country_id,
      state_id: parkData.state_id,
      city_id: parkData.city_id,
      place: parkData.place,
      name: parkData.name,
      description: parkData.description,
    };

    let Updatedata = {
      uuid: parkData?.uuid,
      vehicle_id: 0,
      country_id: parkData.country_id,
      state_id: parkData.state_id,
      city_id: parkData.city_id,
      place: parkData.place,
      name: parkData.name,
      description: parkData.description,
    };

    if (parkData?.uuid) {
      actionUpdatePark(Updatedata)
        .then((response) => {
          toast.success(response.data.message);
          let data = { ...params };
          fetchDataPark(data);
          setModifyModal(false);
          setIsModifyButtonLoad(false);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
          setIsModifyButtonLoad(false);
        });
    } else {
      actionAddPark(data)
        .then((response) => {
          toast.success(response.data.message);
          let data = { ...params };
          fetchDataPark(data);
          setModifyModal(false);
          setIsModifyButtonLoad(false);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
          setIsModifyButtonLoad(false);
        });
    }
  };

  const datsssa = stateList.find((option) => option);

  console.log("datsssa", datsssa);

  return (
    <div>
      {isLoading ? (
        // <div className="text-center pageLoader">
        //   <div className="spinner-border" role="status">
        //     <span className="visually-hidden">Loading...</span>
        //   </div>
        // </div>
        <div className="skeleton-loader p-3 d-none">
          <div className="card">
            <div className="card-header">
              <table className="table rounded-table table-striped table-hover">
                <thead>
                  <tr>
                    <th
                      style={{ width: 10, borderTop: "0", borderBottom: "0" }}
                    >
                      <div className="skeleton"></div>
                    </th>
                    <th style={{ borderTop: "0", borderBottom: "0" }}>
                      <div className="skeleton"></div>
                    </th>
                    <th style={{ borderTop: "0", borderBottom: "0" }}></th>
                    <th
                      class="d-flex"
                      style={{ borderTop: "0", borderBottom: "0" }}
                    >
                      <div class="skeleton me-1"></div>
                      <div class="skeleton me-1"></div>
                      <div class="skeleton"></div>
                    </th>
                    <th
                      style={{ width: 10, borderTop: "0", borderBottom: "0" }}
                    >
                      <div className="skeleton"></div>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <div className="card-body">
              <div className="vehicleThBg table-responsive">
                <table className="table table-bordered rounded-table table table-striped table-hover">
                  <thead>
                    <tr>
                      <th style={{ backgroundColor: "#c0c0c0" }}>
                        <div className="skeleton"></div>
                      </th>
                      <th style={{ backgroundColor: "#c0c0c0" }}>
                        <div className="skeleton"></div>
                      </th>
                      <th style={{ backgroundColor: "#c0c0c0" }}>
                        <div className="skeleton"></div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {parkList?.map((_, index) => (
                      <tr key={index}>
                        <td>
                          <div className="skeleton"></div>
                        </td>
                        <td>
                          <div className="skeleton"></div>
                        </td>
                        <td>
                          <div className="skeleton"></div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card-footer">
              <div className="skeleton col-lg-3 float-right"></div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  {/* <h1 className="page-title">Part Side</h1> */}
                </div>
                <div className="col-sm-6"></div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card fuelCard container-custom-height">
                    <div className="card-header">
                      <div className="card-title m-0 float-left" style={{width: "50%"}}>
                        <input
                          type="text"
                          className="form-control"
                          value={params.search}
                          onChange={(e) => handleParams(e, "search")}
                          placeholder="Search"
                        ></input>
                      </div>
                      <div className="card-title m-0 float-right d-flex">
                        {" "}
                        {RolePermission?.findIndex(
                          (e) => e.permission_name === "add_park"
                        ) > -1 && (
                          <button
                            type="button"
                            className="btn  btn-icon-text mr-2"
                            onClick={handleAddModal}
                          >
                            <i className="bi bi-plus-lg"></i>
                            <span className="ms-2">Add</span>
                          </button>
                        )}{" "}
                      </div>
                    </div>
                    <div className="card-body">
                      <div
                        className="vehicleThBg table-responsive table-responsive-vehicle"
                        
                      >
                        <table className="table table-bordered rounded-table table table-striped table-hover">
                          <thead>
                            <tr>
                              <th style={{ width: "1%" }}>#</th>
                              <th className="col-form-label ml-2 Label-my form-label form-label" style={{ width: "10%" }}>
                                Name
                              </th>

                              <th className="col-form-label ml-2 Label-my form-label form-label" style={{ width: "10%" }}>
                                Place
                              </th>
                              {/* <th className="col-form-label ml-2 Label-my form-label form-label">
                                    Vehicle
                                  </th> */}
                              <th
                                className="col-form-label ml-2 Label-my form-label form-label"
                                style={{ width: "0%" }}
                              >
                                {" "}
                                Action{" "}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {" "}
                            {parkList?.map((item, index) => {
                              return (
                                <>
                                  <tr>
                                    <td>{++index}</td>
                                    <td>{item.name}</td>
                                    <td>{item.place}</td>


                                    <td className="d-flex">
                                        <Dropdown className="iconDropdown"
                                                  onToggle={(isOpen) => {
                                                      if (isOpen) {
                                                          // This logic runs when the dropdown is opened
                                                          const tableResponsive = document.querySelector('.table-responsive');
                                                          if (tableResponsive) {
                                                              tableResponsive.style.overflowX = 'inherit !important';
                                                              tableResponsive.style.overflowY = 'inherit !important';
                                                          }
                                                      }
                                                  }}
                                        >
                                          {(item.company_id > 0 ||
                                            companyId.role_id == 1) && (
                                            <Dropdown.Toggle
                                              variant="success"
                                              id="dropdown-basic"
                                              className="iconToggle"
                                            >
                                              <i className="bi bi-three-dots-vertical"></i>
                                            </Dropdown.Toggle>
                                          )}

                                          <Dropdown.Menu>
                                            <Dropdown.Item
                                              className="d-flex"
                                              onClick={(e) => onEditPark(e, item)}
                                            >
                                              {(item.company_id > 0 ||
                                                companyId.role_id == 1) &&
                                                RolePermission?.findIndex(
                                                  (e) =>
                                                    e.permission_name ===
                                                    "modify_park"
                                                ) > -1 && (
                                                  <a
                                                    className="edit-btn"
                                                    href="#"
                                                  >
                                                    <i className="bi bi-pencil"></i>
                                                  </a>
                                                )}
                                              <span>Edit</span>
                                            </Dropdown.Item>

                                            <Dropdown.Item
                                              className="d-flex"
                                              onClick={(e) =>
                                                onDeleteModalOpen(e, item)
                                              }
                                            >
                                              {(item.company_id > 0 ||
                                                companyId.role_id == 1) &&
                                                RolePermission?.findIndex(
                                                  (e) =>
                                                    e.permission_name ===
                                                    "delete_park"
                                                ) > -1 && (
                                                  <div className="trash-btn">
                                                    <a
                                                      className="delete-btn"
                                                      href="#"
                                                    >
                                                      <i className="bi bi-trash3"></i>
                                                    </a>
                                                  </div>
                                                )}
                                              <span>Delete</span>
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </td>

                                  </tr>
                                </>
                              );
                            })}{" "}
                          </tbody>{" "}
                          {parkList && parkList?.length === 0 && (
                            <tr>
                              <td
                                colSpan={7}
                                className={`text-center`}
                              >{`Record Not Found`}</td>
                            </tr>
                          )}
                        </table>
                      </div>
                    </div>{" "}
                    {/* /.card-body */}{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="fuelCF">
              <div className="card-footer card-footer-part clearfix">
                <div className="pagination pagination-sm m-0 float-right">
                  <Pagination
                    activePage={params.page}
                    itemsCountPerPage={parseInt(params.limit)}
                    totalItemsCount={parseInt(totalRecords)}
                    pageRangeDisplayed={5}
                    itemClass={`page-item`}
                    linkClass={`page-link`}
                    onChange={(e) => handleParams(e, "pagination")}
                  />
                </div>
              </div>
            </div>
          </section>

          <Modal isOpen={delModal}>
            <ModalHeader>{`Delete Park`}</ModalHeader>
            <ModalBody>
              <p
                className={`mb-0`}
              >{`Are you sure you want to delete this park?`}</p>
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                class="btn btn-block btn-primary"
                disabled={isDelButtonLoad}
                onClick={onDeleteModalClose}
                style={{ width: "100px", marginTop: "10px" }}
              >
                Close <i class="bi bi-x-lg"></i>
              </button>

              {!isDelButtonLoad && (
                <button
                  type="button"
                  className="btn btn-block bg-red"
                  style={{ width: "100px" }}
                  onClick={onDestroyPartType}
                >
                  Delete <i class="bi bi-trash3"></i>
                </button>
              )}
              {isDelButtonLoad && (
                <button
                  color="secondary"
                  type={`button`}
                  disabled={true}
                  className={`btn-square`}
                >
                  <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
                </button>
              )}
            </ModalFooter>
          </Modal>

          <Modal isOpen={modifyModal}>
            <ModalHeader>Park</ModalHeader>
            <Form method={`post`} onSubmit={handlePartSlideForm}>
              <ModalBody>
                <div className="row">
                  <div className="col-md-6">
                    <FormGroup>
                      <Label className="col-form-label Label-my ml-2">
                        {"Name"}
                      </Label>
                      <div className="col-xl-12">
                        <div className="input-div">
                          <input
                            className="form-control input-search"
                            type="text"
                            value={parkData.name}
                            onChange={(e) => {
                              setParkData({
                                ...parkData,
                                name: e.target.value,
                              });
                            }}
                            name={`name`}
                            maxLength={50}
                            placeholder="Name"
                          />
                        </div>
                      </div>
                    </FormGroup>
                  </div>

                  <div className="col-md-6">
                    <FormGroup>
                      <Label className="col-form-label Label-my ml-2">
                        {"Place"}
                      </Label>
                      <div className="col-xl-12">
                        <div className="input-div">
                          <input
                            className="form-control input-search"
                            type="text"
                            value={parkData.place}
                            onChange={(e) => {
                              setParkData({
                                ...parkData,
                                place: e.target.value,
                              });
                            }}
                            name={`place`}
                            maxLength={50}
                            placeholder="Place"
                          />
                        </div>
                      </div>
                    </FormGroup>
                  </div>

                  <div className="col-md-6">
                    <FormGroup>
                      <Label className="col-form-label ml-2 Label-my">
                        {"Country"}
                      </Label>
                      <div className="col-xl-12">
                        <div className="input-div">
                          <Select
                            className="my-select-v"
                            options={countryList}
                            value={countryList.find(
                              (option) =>
                                option.value === Number(parkData.country_id)
                            )}
                            onChange={(selectedOption) =>
                              setParkData({
                                ...parkData,
                                country_id: selectedOption.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </FormGroup>
                  </div>

                  <div className="col-md-6">
                    <FormGroup>
                      <Label className="col-form-label ml-2 Label-my">
                        {"State"}
                      </Label>
                      <div className="col-xl-12">
                        <div className="input-div">
                          <Select
                            className="my-select-v"
                            options={stateList}
                            value={stateList.find(
                              (option) =>
                                option.value === Number(parkData.state_id)
                            )}
                            onChange={(selectedOption) =>
                              setParkData({
                                ...parkData,
                                state_id: selectedOption.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </FormGroup>
                  </div>

                  <div className="col-md-6">
                    <FormGroup>
                      <Label className="col-form-label ml-2 Label-my">
                        {"City"}
                      </Label>
                      <div className="col-xl-12">
                        <div className="input-div">
                          <Select
                            className="my-select-v"
                            options={cityList}
                            value={cityList.find(
                              (option) =>
                                option.value === Number(parkData.city_id)
                            )}
                            onChange={(selectedOption) => {
                              setParkData({
                                ...parkData,
                                city_id: selectedOption.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </FormGroup>
                  </div>
                  <div className="col-md-6"></div>

                  {/* <div className="col-md-6">
                    <FormGroup>
                      <Label className="col-form-label ml-2 Label-my form-label form-label">
                        {"Select Vehicle"}
                      </Label>
                      <div className="col-xl-12">
                        <div className="input-div">
                          <Select
                            className="my-Select"
                            options={VehicleModelList}
                            value={VehicleModelList.find(
                              (item) => item.value == parkData.vehicle_id
                            )}
                            onChange={handleSelectChange}
                            placeholder={"Select Vehicle"}
                            isSearchable={true}
                            onInputChange={handleInputChange}
                            inputValue={inputValue}
                            isClearable={true}
                          />
                        </div>
                      </div>
                    </FormGroup>
                  </div> */}

                  <div className="col-md-12">
                    <FormGroup>
                      <Label className="col-form-label ml-2 Label-my">
                        {"Description"}
                      </Label>
                      <div className="col-xl-12">
                        <div className="input-div">
                          <textarea
                            className="form-control input-search"
                            type="text"
                            value={parkData.description}
                            onChange={(e) => {
                              setParkData({
                                ...parkData,
                                description: e.target.value,
                              });
                            }}
                            name={`description`}
                            maxLength={100}
                            placeholder="Description"
                            rows={3}
                          />
                        </div>
                      </div>
                    </FormGroup>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <button
                  type="button"
                  class="btn btn-block btn-primary"
                  disabled={isModifyButtonLoad}
                  onClick={handleCloseModal}
                  style={{ width: "100px", marginTop: "10px" }}
                >
                  Close <i class="bi bi-x-lg"></i>
                </button>
                {!isModifyButtonLoad && (
                  <button
                    type="submit"
                    class="btn btn-block btn-primary"
                    style={{ width: "100px", marginTop: "10px" }}
                  >
                    Submit <i class="bi bi-check2"></i>
                  </button>
                )}
                {isModifyButtonLoad && (
                  <Button
                    color="success"
                    type={`button`}
                    disabled={true}
                    className={`btn-square`}
                  >
                    <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
                  </Button>
                )}
              </ModalFooter>
            </Form>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default Park;
