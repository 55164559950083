import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Logo from "../../asset/images/footer-logo.png";
import ConfigDB from "../../config";
// tooltip
import { Tooltip } from "bootstrap";
import { useEffect } from "react";

const Sidebar = ({ isOpenS, setIsOpens, setIsHovered,isHovered }) => {

  const [activeMenu, setActiveMenu] = useState("");
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownStates, setDropdownStates] = useState([]);

  const data = localStorage.getItem(ConfigDB?.data?.login_data);
  const RoleData = JSON.parse(data);
  const companyId = JSON.parse(data);

  const filterData =
    RoleData?.menus_data
      ?.filter((x) => x.display_name === "View")
      ?.map((item) => item.permission_id) || [];

  const filterList = RoleData?.menus_data?.filter((x) =>
    filterData.includes(x.permission_id)
  );

  const handleMenuClick = (menuName) => {
    setActiveMenu(menuName === activeMenu ? "" : menuName);
  };

  const toggleDropdown = (menuId) => {
    setDropdownStates((prevStates) => ({
      ...prevStates,
      [menuId]: !prevStates[menuId],
    }));
  };

  const toggleDropdowns = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const { t } = useTranslation();
  const toggleSidebar = () => {
    setIsOpens(!isOpenS);
  };
  // tooltip
  // useEffect(() => {
  //   const tooltipTriggerList = document.querySelectorAll(
  //     '[data-bs-toggle="tooltip"]'
  //   );
  //   const tooltipList = [...tooltipTriggerList].map(
  //     (tooltipTriggerEl) => new Tooltip(tooltipTriggerEl)
  //   );
  // }, []);

  return (
    <aside
      className="main-sidebar sidebar-dark-primary elevation-4"
      style={{
        width: isHovered || isOpenS ? "250px" : "60px",
        transition: "width 0.3s ease",
      }}

    >
      <div className="sidebar">
        <div className="d-flex">
          <a href="#" className="brand-link" style={{ borderBottom: isOpenS ? "1px solid #4b545c" : "0px solid #4b545c" }}>
            <span className="brand-text font-weight-light">
              <img src={Logo} className="heigh30" alt={`...`} />
            </span>
          </a>
          {(isOpenS || isHovered) && (
            <div
              onClick={toggleSidebar}
              className="brand-link toggleBar"

            >
              <i className="bi bi-list text-light"></i>
            </div>
          )}
        </div>
        {/*{!isOpenS && (*/}
          {/*<div*/}
            {/*onClick={toggleSidebar}*/}
            {/*className=""*/}
          {/*>*/}
            {/*<i className="bi bi-list text-light" style={{ fontSize: "19px" }}></i>*/}
          {/*</div>*/}
        {/*)}*/}
        <nav className="mt-2"
         onMouseEnter={() => setIsHovered(true)} // Expand on hover
         onMouseLeave={() => setIsHovered(false)} // Collapse on hover exit
        >
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            {filterList?.map((item) => {
              const isParent = item.parent_id === "0";
              const isDropdownOpen = dropdownStates[item.menu_id] || false;

              const DataShow = filterList?.filter(
                (child) => child.parent_id === item.menu_id
              );

              return (
                <React.Fragment key={item.id}>
                  {isParent && (
                    <li
                      className={`nav-item has-treeview ${activeMenu === item.name ? "menu-open" : ""
                        }`}

                    // className={`nav-item  ${
                    //   isDropdownOpen ? "menu-is-opening menu-open" : ""
                    // }`}

                    >
                      {DataShow?.length > 0 ? (
                        <NavLink
                          to={item.url}
                          // className={`nav-link`}
                          onClick={() => handleMenuClick(item.name)}
                          style={{
                            marginLeft: "14px",
                            display: "flex",
                            marginTop: "6px",
                            marginBottom: "6px",
                          }}
                          
                        >
                          <i
                            data-bs-toggle="tooltip"
                            title={item.name}
                            data-bs-custom-class="tooltip-custom"
                            className={item.icon}
                            onClick={(e) => {
                              e.preventDefault();
                              toggleDropdown(item.menu_id);
                            }}
                          ></i>

                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              toggleDropdown(item.menu_id);
                            }}
                          >
                            {(isOpenS || isHovered) && <p className="ms-2 mb-0" >
                              {t(item.name)}
                              <i

                                style={{ right: "1rem", position: "absolute" }}
                                className={`left-chevron right bi ${isDropdownOpen
                                  ? "bi-chevron-left"
                                  : "bi-chevron-down"
                                  }`}
                              ></i>
                            </p>
                            }
                          </a>
                        </NavLink>
                      ) : (
                        <>
                          <NavLink
                            to={item.url}
                            className={`nav-link ${activeMenu === item.name ? "active" : ""
                              }`}
                            onClick={() => handleMenuClick(item.name)}
                            
                          >
                            <i
                              className={item.icon}
                              data-bs-toggle="tooltip"
                              title={item.name}
                              data-bs-custom-class="tooltip-custom"
                            ></i>

                            {(isOpenS || isHovered) && <p className="ms-2 mb-0">{t(item.name)}</p>}
                          </NavLink>
                        </>
                      )}

                      <ul
                        className={`nav nav-treeview ${isDropdownOpen ? "d-block" : "d-none"
                          }`}
                      >
                        {filterList
                          ?.filter((child) => child.parent_id === item.menu_id)
                          .map((child) => (
                            <li
                              key={child.id}
                              className={`nav-item  ${activeMenu === child.name ? "menu-open" : ""
                                }`}
                            >
                              <NavLink
                                to={child.url}
                                className={`nav-link ${activeMenu === child.name ? "active" : ""
                                  }`}
                                onClick={() => handleMenuClick(child.name)}

                              >
                                <i
                                  className={child.icon}
                                  data-bs-toggle="tooltip"
                                  title={child.name}
                                  data-bs-custom-class="tooltip-custom"
                                ></i>
                                {(isOpenS || isHovered) && <p className="ms-2 mb-0">{t(child.name)}</p>}
                              </NavLink>
                            </li>
                          ))}
                      </ul>
                    </li>
                  )}
                </React.Fragment>
              );
            })}

            {RoleData?.role_id === "1" && (
              <>
                <li
                  className={`nav-item ${isDropdownOpen ? "menu-is-opening menu-open" : ""
                    }`}
                >
                  <a
                    href="#"
                    className="nav-link"
                    onClick={toggleDropdowns}
                    style={{
                      display: "flex",
                      marginTop: "2px",
                      marginBottom: "6px",
                    }}
                  >
                    <i
                      className="bi bi-gear"
                      data-bs-toggle="tooltip"
                      title="General"
                    ></i>
                    {isOpenS && (
                      <p className={`ms-2 mb-0`}>
                        General{" "}
                        <i
                          className={`right bi ${isDropdownOpen
                            ? "bi-chevron-left"
                            : "right bi bi-chevron-down"
                            }`}
                        ></i>
                      </p>
                    )}
                  </a>
                  <ul
                    className={`nav nav-treeview ${isDropdownOpen ? "d-block" : "d-none"
                      }`}
                  >
                    <li
                      className={`nav-item has-treeview ${activeMenu === "faqAdmin" ? "menu-open" : ""
                        }`}
                    >
                      <NavLink
                        to="/faqs"
                        className={`nav-link ${activeMenu === "faqAdmin" ? "active" : ""
                          }`}
                        onClick={() => handleMenuClick("faqAdmin")}
                      >
                        <i
                          class="bi bi-question-lg"
                          data-bs-toggle="tooltip"
                          title="FAQ"
                        ></i>
                        {isOpenS && <p className="ms-2">{t("FAQ")}</p>}
                      </NavLink>
                    </li>

                    <li
                      className={`nav-item has-treeview ${activeMenu === "termscondition" ? "menu-open" : ""
                        }`}
                    >
                      <NavLink
                        to="/terms-condition-admin"
                        className={`nav-link ${activeMenu === "termscondition" ? "active" : ""
                          }`}
                        onClick={() => handleMenuClick("termscondition")}
                      >
                        <i
                          class="bi bi-check2-square"
                          data-bs-toggle="tooltip"
                          title="Terms Condition"
                        ></i>
                        {isOpenS && (
                          <p className="ms-2">{t("Terms Condition")}</p>
                        )}
                      </NavLink>
                    </li>

                    <li
                      className={`nav-item has-treeview ${activeMenu === "privacypolicy" ? "menu-open" : ""
                        }`}
                    >
                      <NavLink
                        to="/privacy-policy-admin"
                        className={`nav-link ${activeMenu === "privacypolicy" ? "active" : ""
                          }`}
                        onClick={() => handleMenuClick("privacypolicy")}
                      >
                        <i
                          class="bi bi-lock-fill"
                          data-bs-toggle="tooltip"
                          title="Privacy Policy"
                        ></i>{" "}
                        {isOpenS && (
                          <p className="ms-2">{t("Privacy Policy")}</p>
                        )}
                      </NavLink>
                    </li>
                  </ul>
                </li>

                {/* <li
                  className={`nav-item has-treeview ${
                    activeMenu === "privacyPolicy" ? "menu-open" : ""
                  }`}
                >
                  <NavLink
                    to="/privacy-policy-admin"
                    className={`nav-link ${
                      activeMenu === "privacyPolicy" ? "active" : ""
                    }`}
                    onClick={() => handleMenuClick("privacyPolicy")}
                  >
                    <i class="bi bi-check2-square"></i>
                    <p className="ms-2">{t("Terms Condition")}</p>
                  </NavLink>
                </li> */}
              </>
            )}

            {/* <li
              className={`nav-item has-treeview ${
                activeMenu === "fuelType" ? "menu-open" : ""
              }`}
            >
              <NavLink
                to="/fuel-type"
                className={`nav-link ${
                  activeMenu === "fuelType" ? "active" : ""
                }`}
                onClick={() => handleMenuClick("fuelType")}
              >
                <i className="bi bi-fuel-pump" />
                <p className="ms-2">Fuel</p>
              </NavLink>
            </li>
            <li
              className={`nav-item has-treeview ${
                activeMenu === "lerCatalog" ? "menu-open" : ""
              }`}
            >
              <NavLink
                to="/ler-catalog"
                className={`nav-link ${
                  activeMenu === "lerCatalog" ? "active" : ""
                }`}
                onClick={() => handleMenuClick("lerCatalog")}
              >
                <i className="bi bi-file-earmark-text" />
                <p className="ms-2">LER Catalog</p>
              </NavLink>
            </li>

            <li
              className={`nav-item has-treeview ${
                activeMenu === "partCatalog" ? "menu-open" : ""
              }`}
            >
              <NavLink
                to="/part-catalog"
                className={`nav-link ${
                  activeMenu === "partCatalog" ? "active" : ""
                }`}
                onClick={() => handleMenuClick("partCatalog")}
              >
                <i className="bi bi-fuel-pump" />
                <p className="ms-2">Part Catalog</p>
              </NavLink>
            </li>
            {companyId.company_id > 0 ? (
              ""
            ) : (
              <li
                className={`nav-item has-treeview ${
                  activeMenu === "subscription" ? "menu-open" : ""
                }`}
              >
                <NavLink
                  to="/subscription"
                  className={`nav-link ${
                    activeMenu === "subscription" ? "active" : ""
                  }`}
                  onClick={() => handleMenuClick("subscription")}
                >
                  <i class="bi bi-bookmark-fill"></i>
                  <p className="ms-2">SubScription</p>
                </NavLink>
              </li>
            )}

            <li
              className={`nav-item has-treeview ${
                activeMenu === "vehicleProcess" ? "menu-open" : ""
              }`}
            >
              <NavLink
                to="/vehicle-process"
                className={`nav-link ${
                  activeMenu === "vehicleProcess" ? "active" : ""
                }`}
                onClick={() => handleMenuClick("vehicleProcess")}
              >
                <i class="bi bi-car-front"></i>
                <p className="ms-2">Vehicle</p>
              </NavLink>
            </li>
            <li
              className={`nav-item has-treeview ${
                activeMenu === "partsInventory" ? "menu-open" : ""
              }`}
            >
              <NavLink
                to="/parts-inventory"
                className={`nav-link ${
                  activeMenu === "partsInventory" ? "active" : ""
                }`}
                onClick={() => handleMenuClick("partsInventory")}
              >
                <i className="bi bi-car-front"></i>
                <p className="ms-2">Parts Inventory</p>
              </NavLink>
            </li>

            <li
              className={`nav-item has-treeview ${
                activeMenu === "role" ? "menu-open" : ""
              }`}
            >
              <NavLink
                to="/Roles"
                className={`nav-link ${activeMenu === "role" ? "active" : ""}`}
                onClick={() => handleMenuClick("role")}
              >
                <i class="bi bi-person-check"></i>
                <p className="ms-2">Roles</p>
              </NavLink>
            </li>

            <li
              className={`nav-item ${
                isDropdownOpen ? "menu-is-opening menu-open" : ""
              }`}
            >
              <a href="#" className="nav-link" onClick={toggleDropdown}>
                <i className="bi bi-archive"></i>
                <p className={`ms-2`}>
                  Master Manager
                  <i
                    className={`right bi ${
                      isDropdownOpen ? "bi-chevron-left" : "bi-chevron-left"
                    }`}
                  ></i>
                </p>
              </a>
              <ul
                className={`nav nav-treeview ${
                  isDropdownOpen ? "d-block" : "d-none"
                }`}
              >
                <li
                  className={`nav-item has-treeview ${
                    activeMenu === "partType" ? "menu-open" : ""
                  }`}
                >
                  <NavLink
                    to="/part-type"
                    className={`nav-link ${
                      activeMenu === "partType" ? "active" : ""
                    }`}
                    onClick={() => handleMenuClick("partType")}
                  >
                    <i className="bi bi-gear-fill" />
                    <p className="ms-2">PartType</p>
                  </NavLink>
                </li>
                <li
                  className={`nav-item has-treeview ${
                    activeMenu === "partlocationType" ? "menu-open" : ""
                  }`}
                >
                  <NavLink
                    to="/part-location"
                    className={`nav-link ${
                      activeMenu === "partlocationType" ? "active" : ""
                    }`}
                    onClick={() => handleMenuClick("partlocationType")}
                  >
                    <i className="bi bi-geo-fill" />
                    <p className="ms-2">Part Location</p>
                  </NavLink>
                </li>

                <li
                  className={`nav-item has-treeview ${
                    activeMenu === "partgroupType" ? "menu-open" : ""
                  }`}
                >
                  <NavLink
                    to="/part-group"
                    className={`nav-link ${
                      activeMenu === "partgroupType" ? "active" : ""
                    }`}
                    onClick={() => handleMenuClick("partgroupType")}
                  >
                    <i className="bi bi-snow3" />
                    <p className="ms-2">Part Group</p>
                  </NavLink>
                </li>

                <li
                  className={`nav-item has-treeview ${
                    activeMenu === "partsidesType" ? "menu-open" : ""
                  }`}
                >
                  <NavLink
                    to="/part-sides"
                    className={`nav-link ${
                      activeMenu === "partsidesType" ? "active" : ""
                    }`}
                    onClick={() => handleMenuClick("partsidesType")}
                  >
                    <i className="bi bi-layout-split" />
                    <p className="ms-2">Part Sides</p>
                  </NavLink>
                </li>

                <li
                  className={`nav-item has-treeview ${
                    activeMenu === "vehicleCategory" ? "menu-open" : ""
                  }`}
                >
                  <NavLink
                    to="/vehicle-category"
                    className={`nav-link ${
                      activeMenu === "vehicleCategory" ? "active" : ""
                    }`}
                    onClick={() => handleMenuClick("vehicleCategory")}
                  >
                    <i class="bi bi-car-front"></i>
                    <p className="ms-2">Vehicle Category</p>
                  </NavLink>
                </li>

                <li
                  className={`nav-item has-treeview ${
                    activeMenu === "vehicleType" ? "menu-open" : ""
                  }`}
                >
                  <NavLink
                    to="/vehicle-type"
                    className={`nav-link ${
                      activeMenu === "vehicleType" ? "active" : ""
                    }`}
                    onClick={() => handleMenuClick("vehicleType")}
                  >
                    <i class="bi bi-car-front"></i>
                    <p className="ms-2">Vehicle Type</p>
                  </NavLink>
                </li>

                <li
                  className={`nav-item has-treeview ${
                    activeMenu === "vehicleModel" ? "menu-open" : ""
                  }`}
                >
                  <NavLink
                    to="/vehicle-model"
                    className={`nav-link ${
                      activeMenu === "vehicleModel" ? "active" : ""
                    }`}
                    onClick={() => handleMenuClick("vehicleModel")}
                  >
                    <i class="bi bi-car-front"></i>
                    <p className="ms-2">Vehicle Model</p>
                  </NavLink>
                </li>
              </ul>
            </li> */}
          </ul>
        </nav>

      </div>
    </aside>
  );
};

export default Sidebar;