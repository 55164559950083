import React, { useEffect, useRef, useState } from "react";
import {
  getCatalogList,
  actionPartInventoryTempStore,
  getVehicleList,
  getPartInventoryTempList,
  DestoryPartInventoryTemp,
  createPartsInventory,
  updatePartsInventory,
  actionPartInventoryImage,
  destoryPartInventoryImage,
  partVehicleProcessUpdateExpiryDate,
  partInvenoryVehicleSearch,
  partInvenoryPartETAIPrice,
  partInvenoryPartETAI,
} from "../../services/PartsInventory";
import {
  Button,
  FormGroup,
  Label,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Select from "react-select";
import ConfigDB from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import { getVehiclePartList } from "../../services/VehicleService";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Pagination from "react-js-pagination";
import { getAllViewShelfList } from "../../services/ShelfServices";
import {
  getCommonSearchList,
  getSettingsCompanyDetails,
} from "../../services/settingsServices";
import { getAllBrandSerachList } from "../../services/PartBrandServices";
import moment from "moment/moment";
import { getVehicleAllModalSerachList } from "../../services/VehicleModelService";
import { getVehicleEngineSerachList } from "../../services/VehicleEngineService";
import {
  actionTempStoreDismental,
  getDismentalList,
} from "../../services/DismentalServices";

const VehiclePartsAdd = ({ vehicleAddDetails, vehicleImageDetails }) => {
  const tableLengthList = ConfigDB?.data?.dataTableLength;
  const navigate = useNavigate();
  const LangData = localStorage.getItem("lng");
  const state = useLocation();

  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryTemp, setSearchQueryTemp] = useState("");
  const [VehicleModelAllList, setVehicleModelAllList] = useState([]);
  const [CatalogList, setCatalogList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [tempData, setTempData] = useState({});
  const [partsData, setPartsData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentStep, setCurrentStep] = useState(1);
  const [inputValue, setInputValue] = useState("");
  const [inputSelctedValue, setInputSelctedValue] = useState("");
  const [images, setImage] = useState([]);
  const [ShelfList, SetShelfList] = useState([]);
  const [enlargedIndexes, setEnlargedIndexes] = useState([]);
  const [vehiclePartsData, setVehiclePartsData] = useState({
    vehicle_model_id: "",
    vehicle_make: "",
    vehicle_year: "",
    vehicle_model: "",
    vehicle_process_end_date: "",
    vehicle_process_start_date: "",
    vehicle_model_ids: "",
    vehicle_make_id: "",
  });

  const [vehiclePartsDatasTemp, setVehiclePartsDatasTemp] = useState({
    vehicle_model_id: "",
  });
  const [selectedVehicleModel, setSelectedVehicleModel] = useState(null);
  const [detailsForImages, SetDetailsForImages] = useState([]);
  const [currentPartIndex, setCurrentPartIndex] = useState(0);
  const [showSkip, setShowSkip] = useState(false);
  const [previewUrl, SetPreviewUrl] = useState([]);
  const [showModalAbout, setShowModalAbout] = useState(false);
  const [ImageDetailsNot, setImageDetailsNot] = useState({});
  const [stateList, setStateList] = useState([]);
  const [conditionList, setConditionList] = useState([]);
  const [inventoryList, setInventoryList] = useState([]);
  const [modifyExpiry, setModifyExpiry] = useState(false);
  const [settingDetails, setSettingDetails] = useState("");
  const [totalRecordTemp, setTotalRecordsTemp] = useState(0);
  const [vehicleExceedData, setVehicleExceedData] = useState([]);
  const [vehiclePartDetailsModal, setVehiclePartDetailsModal] = useState(false);
  const [vehicleData, setVehicleData] = useState({
    date: "",
  });
  const [allBrandList, SetAllBrandList] = useState([]);
  const [btnLoader, setBtnLoader] = useState(false);
  const [btnLoading, setbtnLoading] = useState(false);
  const [btnClosed, setbtnClosed] = useState(false);
  const [btnNext, setbtnNext] = useState(false);
  const [btnSaveNext, setbtnSaveNext] = useState(false);
  const [btnImage, setbtnImage] = useState(false);
  const [btnImageSave, setImageSave] = useState(false);

  const [filteredSuggestionsModel, setFilteredSuggestionsModel] = useState([]);
  const [activeSuggestionIndexModel, setActiveSuggestionIndexModel] =
    useState(0);
  const [showSuggestionsModel, setShowSuggestionsModel] = useState(false);
  const [vehicleModelAuto, setVehicleModelAuto] = useState("");
  const [selectedModels, setSelectedModels] = useState([]);
  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  const [filteredSuggestionsEngine, setFilteredSuggestionsEngine] = useState(
    []
  );
  const [activeSuggestionIndexEngine, setActiveSuggestionIndexEngine] =
    useState(0);
  const [showSuggestionsEngine, setShowSuggestionsEngine] = useState(false);
  const [vehicleEngineAuto, setVehicleEngineAuto] = useState("");
  const [vehicleEngineId, setVehicleEngineId] = useState({
    vehicle_model_id: "",
    make_id: "",
    vehicle_model_name: "",
    vehicle_process_end_date: "",
    id: "",
    vehicle_in_inventory: "",
    variants_id: "",
    vehicle_make_name: "",
    registration_plate: "",
    registration: "",
  });

  const fileInputRef = useRef(null);

  const DataVehicle = Number(vehiclePartsData.vehicle_model_id);
  const [referenceList, setReferenceList] = useState([]);
  const [partETAIRerList, setPartETAIRerList] = useState("");
  const [partEtaiModify, setPartEtaiModify] = useState(false);
  const [partEtaiPriceModify, setPartEtaiPriceModify] = useState(false);
  const [partETAIPriceList, setPartETAIPriceList] = useState("");
  const [partETAIRefDetails, setPartETAIRefDetails] = useState([]);
  const [partETAIPriceDetail, setPartETAIPriceDetail] = useState([]);
  const [selectedReferenceCode, setSelectedReferenceCode] = useState(null);

  const [inputSelctedModalValue, setInputSelctedModalValue] = useState("");
  const [EngineData, setEngineData] = useState({
    engine_code: "",
    kw: "",
    hp: "",
  });

  const [vehicleEngineList, setvehicleEngineList] = useState([]);
  const [inputSelctedEngineValue, setInputSelctedEngineValue] = useState("");
  const [vehicleModalSelectedList, setVehicleModalSelectedList] = useState("");
  const [DismetalList, setDismetalList] = useState([]);
  const [inputSelctedDismetalValue, setInputSelctedDismetalValue] =
    useState("");
  const [dismentalData, setDismentalData] = useState({
    dismantle_id: "",
  });
  const [dismantleTempData, setDismantleTempData] = useState("");
  const [categoryMasterPart, setCategoryMasterPart] = useState({});
  const [modifyMasterPart, setModifyMasterPart] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  console.log("checkedItems", checkedItems);

  const handleCheck = (itemId) => {
    setCheckedItems((prevState) => {
      if (prevState.includes(itemId)) {
        return prevState.filter((id) => id !== itemId); // Uncheck
      } else {
        return [...prevState, itemId]; // Check
      }
    });
  };

  const handleTempDismetalAddClick = (e) => {
    e.preventDefault();

    if (vehicleModalSelectedList?.id) {
      let data = {
        dismantle_id: dismentalData?.dismantle_id,
        temp_id: dismantleTempData?.temp_id || tempData?.temp_id || "", // Use the first available temp_id or default to ""
        vehicle_id: vehicleModalSelectedList?.id,
      };

      actionTempStoreDismental(data)
        .then((response) => {
          // toast.success(response.data.message);
          setDismantleTempData(response.data.data);

          setDismentalData({
            dismantle_id: "",
          });

          setInputSelctedDismetalValue(""); // Clear input value if needed
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    } else {
      toast.error("Please select Vehicle");
    }
  };

  const handleSelectWithoutChangeDismetalList = (option) => {
    setDismentalData((prevData) => ({
      ...prevData,
      dismantle_id: option.value,
    }));
  };

  useEffect(function () {
    var obj = {
      search: "",
      limit: tableLengthList[0],
      page: 1,
    };

    getDismentalList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }
        setDismetalList(list);
      })
      .catch((err) => {});
  }, []);
  useEffect(function () {
    var obj = {
      search: "",
      limit: tableLengthList[0],
      page: 1,
    };

    getVehicleEngineSerachList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].motor_code,
            kw: response.data[i].kw,
            hp: response.data[i].hp,
          });
        }
        setvehicleEngineList(list);
      })
      .catch((err) => {});
  }, []);

  const handleInputSelectedChangeEngineList = (inputSelctedEngineValue) => {
    setInputSelctedEngineValue(inputSelctedEngineValue);

    if (inputSelctedEngineValue.length > 0) {
      const obj = {
        search: inputSelctedEngineValue,
        limit: tableLengthList[0],
        page: 1,
      };

      getVehicleEngineSerachList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].motor_code,
              kw: response.data[i].kw,
              hp: response.data[i].hp,
            });
          }
          setvehicleEngineList(list);
        })
        .catch((err) => {});
    }
  };

  const handleInputSelectedChangeDismetalList = (inputSelctedDismetalValue) => {
    setInputSelctedDismetalValue(inputSelctedDismetalValue);

    if (inputSelctedDismetalValue.length > 0) {
      const obj = {
        search: inputSelctedDismetalValue,
        limit: tableLengthList[0],
        page: 1,
      };

      getDismentalList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].name,
            });
          }
          setDismetalList(list);
        })
        .catch((err) => {});
    }
  };

  const handleSelectWithoutChangeEngineList = (option) => {
    setEngineData((prevData) => ({
      ...prevData,
      engine_code: option.value,
      kw: option.kw,
      hp: option.hp,
    }));
  };

  const handleRadioChange = (referenceCode) => {
    setSelectedReferenceCode(referenceCode);
  };

  const handleEtaiDetails = (event, etaiPartIds, index) => {
    event.preventDefault();
    setPartETAIRerList(etaiPartIds);
    setPartEtaiModify(true);
  };

  const handleEtaiPriceDetails = (event, etaiPartIds, index) => {
    event.preventDefault();
    setPartETAIPriceList(etaiPartIds);

    setPartEtaiPriceModify(true);
  };

  const handlePartEtaiClose = () => {
    setPartEtaiModify(false);
  };

  const handlePartEtaiPriceClose = () => {
    setPartEtaiPriceModify(false);
  };

  useEffect(() => {
    if (partETAIRerList) {
      const data = {
        etai_part_id: partETAIRerList,
      };

      partInvenoryPartETAI(data)
        .then((response) => {
          setPartETAIRefDetails(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [partETAIRerList]);

  useEffect(() => {
    if (partETAIPriceList) {
      const data = {
        etai_part_id: partETAIPriceList,
      };

      partInvenoryPartETAIPrice(data)
        .then((response) => {
          setPartETAIPriceDetail(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [partETAIPriceList]);

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "reference",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setReferenceList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (state?.state?.item) {
      setVehicleEngineId({
        vehicle_model_id: state?.state?.item?.vehicle_model_id,
        make_id: state?.state?.item?.make_id,
        vehicle_model_name: state?.state?.item?.vehicle_model,
        vehicle_process_end_date: state?.state?.item?.vehicle_process_end_date,
        id: state?.state?.item?.id,
        vehicle_in_inventory: state?.state?.item?.vehicle_in_inventory,
        variants_id: state?.state?.item?.variants_id,
        vehicle_make_name: state?.state?.vehicle_make_name,
        registration_plate: state?.state?.registration_plate,
        registration: state?.state?.registration,
      });
      setVehicleEngineAuto(state?.state?.item?.vehicle_model);
    }
  }, [state]);

  const selectedModel = VehicleModelAllList.find(
    (item) => item.value == DataVehicle
  );
  const excludeIds = vehicleExceedData?.map((x) =>
    parseInt(x.part_catelog, 10)
  );

  const handleChangeEngine = (e) => {
    const userInput = e.target.value;

    let data = {
      limit: params.limit,
      page: params.page,
      search: userInput,
      is_reload: true,
    };
    if (userInput.length > 0) {
      partInvenoryVehicleSearch(data)
        .then((response) => {
          setFilteredSuggestionsEngine(response.data.data);
        })
        .catch((err) => {});
    }

    setVehicleEngineAuto(userInput);
    setActiveSuggestionIndexEngine(0);
    setShowSuggestionsEngine(true);
  };

  const handleClickEngine = (suggestion) => {
    setVehicleEngineId({
      vehicle_model_id: suggestion?.vehicle_model_id,
      make_id: suggestion?.make_id,
      vehicle_model_name: suggestion?.vehicle_model_name,
      vehicle_process_end_date: suggestion?.vehicle_process_end_date,
      id: suggestion?.id,
      vehicle_in_inventory: suggestion?.vehicle_in_inventory,
      variants_id: suggestion?.variants_id,
      vehicle_make_name: suggestion?.vehicle_make_name,
      registration_plate: suggestion?.registration_plate,
      registration: suggestion?.registration,
    });
    setVehicleEngineAuto(suggestion.vehicle_model_name);
    setFilteredSuggestionsEngine([]);
    setShowSuggestionsEngine(false);
  };

  const handleKeyDownEngine = (e) => {
    if (e.key === "ArrowDown") {
      if (
        filteredSuggestionsEngine &&
        activeSuggestionIndexEngine < filteredSuggestionsEngine?.length - 1
      ) {
        setActiveSuggestionIndexEngine(activeSuggestionIndexEngine + 1);
      }
    } else if (e.key === "ArrowUp") {
      if (activeSuggestionIndexEngine > 0) {
        setActiveSuggestionIndexEngine(activeSuggestionIndexEngine - 1);
      }
    } else if (e.key === "Enter") {
      setVehicleEngineAuto(
        filteredSuggestionsEngine[activeSuggestionIndexEngine]
      );
      setShowSuggestionsEngine(false);
      setFilteredSuggestionsEngine([]);
    }
  };

  const filteredCatalogList = CatalogList?.data?.filter(
    (item) => !excludeIds?.includes(parseInt(item?.id, 10))
  );

  const handleChangeModel = (e) => {
    const userInput = e.target.value;

    let data = {
      limit: params.limit,
      page: params.page,
      search: userInput,
      is_reload: true,
      make_id: 0,
    };
    if (userInput.length > 0) {
      getVehicleAllModalSerachList(data)
        .then((response) => {
          setFilteredSuggestionsModel(response.data.data);
        })
        .catch((err) => {});
    }

    setVehicleModelAuto(userInput);
    setActiveSuggestionIndexModel(0);
    setShowSuggestionsModel(true);
  };

  const handleClickModel = (suggestion) => {
    const isAlreadySelected = selectedModels.some(
      (model) => model.id === suggestion.id
    );

    if (isAlreadySelected) {
      setSelectedModels(
        selectedModels.filter((model) => model.id !== suggestion.id)
      );
    } else {
      setSelectedModels([...selectedModels, suggestion]);
    }

    setVehicleModelAuto("");
    setFilteredSuggestionsModel([]);
    setShowSuggestionsModel(false);
  };

  const handleKeyDownModel = (e) => {
    if (e.key === "ArrowDown") {
      if (
        filteredSuggestionsModel &&
        activeSuggestionIndexModel < filteredSuggestionsModel?.length - 1
      ) {
        setActiveSuggestionIndexModel(activeSuggestionIndexModel + 1);
      }
    } else if (e.key === "ArrowUp") {
      if (activeSuggestionIndexModel > 0) {
        setActiveSuggestionIndexModel(activeSuggestionIndexModel - 1);
      }
    } else if (e.key === "Enter") {
      setVehicleModelAuto(filteredSuggestionsModel[activeSuggestionIndexModel]);
      setShowSuggestionsModel(false);
      setFilteredSuggestionsModel([]);
    }
  };

  const ExpiryModalOpen = () => {
    setModifyExpiry(true);
  };

  const ExpiryModalclose = () => {
    setModifyExpiry(false);
  };

  const handleSkip = () => {
    setCurrentPartIndex((prevIndex) => prevIndex + 1);
    setShowSkip(false);
  };

  const handleOpenSkip = () => {
    setShowSkip(true);
  };

  const handleCloseSkip = () => {
    setShowSkip(false);
  };

  const handleFinish = () => {
    // if (images?.length === 0) {
    //   navigate("/parts-inventory");
    // } else {

    // if (images?.length === 0) {
    //   const formData = new FormData();
    //   setbtnImage(true);
    //   setImageSave(true);
    //   formData.append("part_id", ImageDetailsNot?.id);
    //   images.forEach((file) => {
    //     formData.append("files", file);
    //   });

    //   actionPartInventoryImage(formData)
    //     .then((response) => {
    //       toast.success(response.data.message);
    //       setImage([]);
    //       SetPreviewUrl([]);
    //       setCurrentPartIndex((prevIndex) => prevIndex + 1);
    //     })
    //     .catch((err) => {
    //       setbtnImage(false);
    //       setImageSave(false);

    //       toast.dismiss();
    //       toast.error(Utils.getErrorMessage(err));
    //     });
    // } else {
    const formData = new FormData();
    formData.append("part_id", currentPart?.id);
    images.forEach((file) => {
      formData.append("files", file);
    });
    actionPartInventoryImage(formData)
      .then((response) => {
        toast.success(response.data.message);
        setImage([]);
        SetPreviewUrl([]);
        navigate("/parts-inventory");
      })
      .catch((err) => {
        setbtnImage(false);
        setImageSave(false);
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
    // }
  };

  const onvehiclePartDetailsOpen = (e, item) => {
    e.preventDefault();
    setVehiclePartDetailsModal(true);
  };

  const onvehiclePartDetailsClose = () => {
    setVehiclePartDetailsModal(false);
  };

  useEffect(() => {
    const obj = {
      search: "",
      limit: 10,
      page: 1,
      placeName: "",
    };

    getAllBrandSerachList(obj)
      .then((response) => {
        response = response.data;
        let list = [];

        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].descriptionPT,
          });
        }
        SetAllBrandList(list);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (selectedModel) {
      let data = {
        vehicle_id: selectedModel.value,
      };
      getVehiclePartList(data)
        .then((response) => {
          setVehicleExceedData(response.data.data);
        })
        .catch((err) => {
          toast.dismiss();

          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [selectedModel]);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const companyResponse = await getSettingsCompanyDetails();

        setSettingDetails(companyResponse.data.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchSettings();
  }, []);

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "state",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setStateList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "part_inventory",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setInventoryList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "condition",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setConditionList(list);
      })
      .catch((err) => {});
  }, []);

  const currentPart =
    vehicleImageDetails && vehicleImageDetails?.length > currentPartIndex
      ? vehicleImageDetails[currentPartIndex]
      : detailsForImages && detailsForImages?.length > currentPartIndex
      ? detailsForImages[currentPartIndex]
      : null;
  console.log("currentPart", currentPart);

  const hasNextPart = currentPartIndex < detailsForImages.length;

  const handleSubmits = (event) => {
    event.preventDefault();

    // if (images?.length === 0) {
    //   const formData = new FormData();
    //   formData.append("part_id", ImageDetailsNot?.id);
    //   images.forEach((file) => {
    //     formData.append("files", file);
    //   });

    //   actionPartInventoryImage(formData)
    //     .then((response) => {
    //       toast.success(response.data.message);
    //       setImage([]);
    //       SetPreviewUrl([]);
    //       setCurrentPartIndex((prevIndex) => prevIndex + 1);
    //     })
    //     .catch((err) => {
    //       toast.dismiss();

    //       toast.error(Utils.getErrorMessage(err));
    //     });
    // } else {
    if (currentPart?.id) {
      const formData = new FormData();
      formData.append("part_id", currentPart?.id);
      images.forEach((file) => {
        formData.append("files", file);
      });

      actionPartInventoryImage(formData)
        .then((response) => {
          toast.success(response.data.message);
          setImage([]);
          SetPreviewUrl([]);
          setCurrentPartIndex((prevIndex) => prevIndex + 1);
        })
        .catch((err) => {
          toast.dismiss();

          toast.error(Utils.getErrorMessage(err));
        });
    }

    // }
  };

  const handleAboutFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);

    if (selectedFiles.length > 0) {
      setImage((prevImages) => [...prevImages, ...selectedFiles]);

      const newPreviewUrls = selectedFiles.map((file) =>
        URL.createObjectURL(file)
      );
      SetPreviewUrl((prevUrls) => [...prevUrls, ...newPreviewUrls]);
    }
  };
  const isLastPart =
    (vehicleImageDetails &&
      currentPartIndex === vehicleImageDetails?.length - 1) ||
    (detailsForImages && currentPartIndex === detailsForImages?.length - 1);

  const handleRemoveFile = (indexToRemove, item) => {
    if (item) {
      const data = { id: item };
      destoryPartInventoryImage(data)
        .then((response) => {
          toast.success(response.data.message);
          // navigate("/parts-inventory");
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    } else {
      setImage((prevImages) =>
        prevImages.filter((_, index) => index !== indexToRemove)
      );
      toast.success("Image removed successfully.");
    }
  };

  const handleShowAbout = () => setShowModalAbout(true);
  const handleCloseAbout = () => setShowModalAbout(false);

  let errorsObj = {
    quantity: "",
    part_price: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  useEffect(() => {
    const isDuplicatePart = (part, newPart) => {
      return (
        part.part_code === newPart.part_code &&
        part.vehicle_make === newPart.vehicle_make &&
        part.vehicle_model === newPart.vehicle_model &&
        part.vehicle_year === newPart.vehicle_year
      );
    };

    if (vehicleAddDetails?.id) {
      const newPart = {
        catalog_id: vehicleAddDetails.parts_catalog_id,
        namePartPT: vehicleAddDetails.namePartPT,
        part_code: vehicleAddDetails?.part_code,
        part_name: vehicleAddDetails?.part_name,
        quantity: vehicleAddDetails?.quantity,
        part_price: vehicleAddDetails?.part_price,
        part_description: vehicleAddDetails?.part_description,
        inventory_status: vehicleAddDetails?.inventory_status,
        parts_associate: vehicleAddDetails?.parts_associate,
        vehicle_make: vehicleAddDetails.vehicle_make,
        vehicle_model: vehicleAddDetails.vehicle_model,
        vehicle_model_id: vehicleAddDetails.vehicle_parts_id,
        vehicle_year: vehicleAddDetails.vehicle_year,
        print_label: vehicleAddDetails?.print_label,
        state_id: vehicleAddDetails?.state_id,
        condition_id: vehicleAddDetails?.condition_id,
        part_cost_price: vehicleAddDetails?.part_cost_price,
        part_original_price: vehicleAddDetails?.part_original_price,
        is_custom_size: vehicleAddDetails?.is_custom_size,
        parts_weight: vehicleAddDetails?.parts_weight,
        parts_width: vehicleAddDetails?.parts_width,
        parts_height: vehicleAddDetails?.parts_height,
        parts_length: vehicleAddDetails?.parts_length,
        brand_id: vehicleAddDetails?.brand_id,
        addedData: vehicleAddDetails?.parts_reference,
        vat_included: vehicleAddDetails?.vat_included,
        reg_number_dismantler: vehicleAddDetails?.reg_number_dismantler,
        vehicle_model_name: vehicleAddDetails?.vehicle_model_name,
        vehicle_make_name: vehicleAddDetails?.vehicle_make_name,
      };
      setSelectedModels(vehicleAddDetails?.parts_associate);
      setPartsData((prevData) => {
        if (!prevData.some((part) => isDuplicatePart(part, newPart))) {
          return [...prevData, newPart];
        }
        return prevData;
      });
    } else if (selectedItems && selectedItems.length > 0) {
      const selectedParts = selectedItems?.map((item) => ({
        tempId: item.id,
        temp_id: item.temp_id,
        id: item.catalog_id,
        etai_part_id: item?.etai_part_id,
        namePartPT: item.namePartPT,
        part_name: item.namePartPT,
        quantity: "1",
        part_price: "0",
        state_id: settingDetails?.state_id ? settingDetails?.state_id : "",
        condition_id: settingDetails ? settingDetails?.condition_id : 1,
        part_original_price: "0",
        part_cost_price: "0",
        is_custom_size: "0",
        parts_weight: "0",
        parts_width: "0",
        parts_height: "0",
        parts_length: "0",
        inventory_status: "1",
        print_label: settingDetails?.print_label
          ? settingDetails?.print_label
          : "0",
        vat_included: settingDetails?.vat_included
          ? settingDetails?.vat_included
          : "0",
        part_description: "",
        vehicle_make: item.vehicle_make,
        vehicle_model: item.vehicle_model,
        vehicle_model_id: item.vehicle_model_id,
        vehicle_year: item.vehicle_model_id,
        brand_id: "0",
        reference_code: "",
        type_id: null,
        is_main: 1,
        condition_id_ref: "",
        addedData: [],
        vehicle_model_name: item.vehicle_model_name,
        vehicle_make_name: item?.vehicle_make_name,
        is_show_engine_record: item?.is_show_engine_record,

        engine_code: "",
        variants_id: item?.variants_id,
        cv: "",
        cc: "",
        kw: "",
        reg_number_dismantler: settingDetails?.reg_number_dismantler
          ? settingDetails?.reg_number_dismantler
          : "0",
      }));

      setPartsData(selectedParts);
    }
  }, [vehicleAddDetails, selectedItems]);

  const handleInputChangePartListRef = (e, pIndex) => {
    const { name, value } = e.target;
    setPartsData((prev) =>
      prev.map((part, index) =>
        index === pIndex ? { ...part, [name]: value } : part
      )
    );
  };

  const handleCheckboxChangeRef = (pIndex) => {
    setPartsData((prev) =>
      prev.map((part, index) =>
        index === pIndex
          ? { ...part, is_main: part.is_main === 0 ? 1 : 0 }
          : part
      )
    );
  };

  const handleAddPartRef = (pIndex) => {
    setPartsData((prev) =>
      prev?.map((part, index) => {
        if (index === pIndex) {
          const referenceCode = selectedReferenceCode || part?.reference_code;

          if (!referenceCode) {
            toast.error("Reference Code is required!");
            return part;
          }

          const isDuplicate = part?.addedData?.some(
            (data) => data?.reference_code === referenceCode
          );
          if (isDuplicate) {
            toast.error("Reference Code already added!");
            return part;
          }

          const selectedCondition = conditionList.find(
            (cond) => cond?.value === part?.condition_id_ref
          ) || { label: "Not Selected", value: "null" };

          const selectedType = conditionList.find(
            (cond) => cond?.value === part?.type_id
          ) || {
            label: "Not Selected",
            value: "null",
          };

          console.log(
            "Adding reference with condition_id_ref:",
            part?.condition_id_ref
          );

          return {
            ...part,
            addedData: [
              ...(part?.addedData || []), // Ensure `addedData` is always an array
              {
                reference_code: referenceCode,
                type_id: part?.type_id,
                is_main: part?.is_main,
                condition_id_ref: part?.condition_id_ref,
                selectedCondition,
                selectedType,
              },
            ],
            reference_code: "",
            type_id: null,
            condition_id_ref: null,
            is_main: 0,
          };
        }
        return part;
      })
    );
    setSelectedReferenceCode(null);
  };

  const handleUpdatePartRef = (pIndex, rowIndex, key, value) => {
    setPartsData((prev) =>
      prev.map((part, index) => {
        if (index !== pIndex) return part;

        const updatedAddedData = part?.addedData?.map((data, idx) => {
          if (key === "is_main") {
            return { ...data, is_main: idx === rowIndex ? value : 0 };
          }

          if (idx !== rowIndex) return data;
          return { ...data, [key]: value };
        });

        return {
          ...part,
          addedData: updatedAddedData || [], // Ensure `addedData` is always valid
        };
      })
    );
  };

  const handleDeletePartRef = (pIndex, rowIndex) => {
    setPartsData((prev) =>
      prev.map((part, index) =>
        index === pIndex
          ? {
              ...part,
              addedData:
                part.addedData?.filter((_, idx) => idx !== rowIndex) || [],
            }
          : part
      )
    );
  };

  const handleEditPartRef = (pIndex, rowIndex) => {
    setPartsData((prev) =>
      prev.map((part, index) =>
        index === pIndex
          ? {
              ...part,
              addedData:
                part.addedData?.map((data, idx) =>
                  idx === rowIndex
                    ? { ...data, isEditing: !data.isEditing }
                    : data
                ) || [],
            }
          : part
      )
    );

    toast.success("Successfully updated!");
  };

  const handleConditionChangeRef = (pIndex, selectedOption) => {
    setPartsData((prev) =>
      prev.map((part, index) =>
        index === pIndex
          ? { ...part, type_id: selectedOption?.value || null }
          : part
      )
    );
  };

  const handleConditionSChangeRefs = (pIndex, selectedOption) => {
    setPartsData((prev) =>
      prev.map((part, index) =>
        index === pIndex
          ? { ...part, condition_id_ref: selectedOption?.value || null }
          : part
      )
    );
  };

  useEffect(() => {
    const obj = {
      search: "",
      limit: 10,
      page: 1,
      park_id: 0,
    };

    getAllViewShelfList(obj)
      .then((response) => {
        response = response.data;
        let list = [];

        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
            bar_code: response.data[i].bar_code,
            place: response.data[i].place,
            from_iels: response.data[i].from_iels,
            to_iels: response.data[i].to_iels,
          });
        }
        SetShelfList(list);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleSelfIdChange = (index, selectedOption) => {
    const updatedParts = [...partsData];

    updatedParts[index] = {
      ...updatedParts[index],
      name: selectedOption.label,
      bar_code: selectedOption.bar_code,
      place: selectedOption.place,
      from_iels: selectedOption.from_iels,
      to_iels: selectedOption.to_iels,
    };

    setPartsData(updatedParts);
  };

  const handleInputChangePartList = (e, id) => {
    const { name, value, type, checked } = e.target;
    setPartsData((prevData) =>
      prevData.map((part) =>
        part?.id === id
          ? { ...part, [name]: type === "checkbox" ? (checked ? 1 : 0) : value }
          : part
      )
    );
  };

  const handleInputChangePartLists = (e, tempId) => {
    const { name, value } = e.target;
    const updatedParts = [...partsData];
    const partToUpdate = updatedParts.find((part) => part.tempId === tempId);

    if (partToUpdate) {
      partToUpdate[name] = value;
      setPartsData(updatedParts);

      if (name === "part_price") {
        const originalPrice = parseFloat(value) || 0;
        setPartCostPrice(tempId, originalPrice);
      }
    }
  };

  const handleMasterPartClose = () => {
    setModifyMasterPart(false);
  };

  const handleAddClick = (item) => {
    setCategoryMasterPart(item);

    if (item.is_master_part === "1") {
      setModifyMasterPart(true);
      return;
    } else {
      let partListItem = {
        vehicle_make: vehicleModalSelectedList?.make_id || "",
        vehicle_year: vehicleModalSelectedList?.vehicle_model_id || "",
        vehicle_model: vehicleModalSelectedList?.vehicle_model_id || "",
        vehicle_variant: vehicleModalSelectedList?.variants_id || "",
        catalog_id: item.id,
        master_part_id: categoryMasterPart?.is_master_part,
      };

      let data = {
        temp_id: tempData?.temp_id || dismantleTempData?.temp_id || "",
        vehicle_id: vehicleModalSelectedList?.id,
        part_list: [partListItem],
      };

      actionPartInventoryTempStore(data)
        .then((response) => {
          setTempData(response.data.data);
        })
        .catch((err) => {
          toast.dismiss();
          toast.error(Utils.getErrorMessage(err));
        });
    }
    if (modifyMasterPart === true) {
      submitData();
    }
  };

  const handleModalSubmit = (e) => {
    e.preventDefault();
    submitData();
  };

  const submitData = () => {
    const partListItems = checkedItems?.map((item) => {
      const referenceId = item.reference_id;
      const masterId = item.parts_catalog_id;
      return {
        vehicle_make: vehicleModalSelectedList?.make_id || "",
        vehicle_year: vehicleModalSelectedList?.vehicle_model_id || "",
        vehicle_model: vehicleModalSelectedList?.vehicle_model_id || "",
        vehicle_variant: vehicleModalSelectedList?.variants_id || "",
        catalog_id: referenceId,
        master_part_id: masterId,
      };
    });

    let payload = {
      temp_id: tempData?.temp_id || dismantleTempData?.temp_id || "",
      vehicle_id: vehicleModalSelectedList?.id,
      part_list: [
        ...partListItems,
        ...(categoryMasterPart?.is_master_part === "1"
          ? [
              {
                vehicle_make: vehicleModalSelectedList?.make_id || "",
                vehicle_year: vehicleModalSelectedList?.vehicle_model_id || "",
                vehicle_model: vehicleModalSelectedList?.vehicle_model_id || "",
                vehicle_variant: vehicleModalSelectedList?.variants_id || "",
                catalog_id: categoryMasterPart.id,
                master_part_id: categoryMasterPart?.is_master_part,
              },
            ]
          : []),
      ],
    };

    actionPartInventoryTempStore(payload)
      .then((response) => {
        setTempData(response.data.data);
        setModifyMasterPart(false);
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleDeleteClick = (item) => {
    setbtnClosed(true);
    let data = {
      id: item.tempId ? item.tempId : item?.id,
    };

    setTempData(item);

    DestoryPartInventoryTemp(data)
      .then((response) => {
        toast.success(response.data.message);

        const fetchData = async () => {
          try {
            const obj = {
              search: searchQueryTemp,
              limit: tableLengthList[0],
              page: 1,
              temp_id: item?.temp_id,
            };
            const response = await getPartInventoryTempList(obj);
            setSelectedItems(response.data.data);
          } catch (err) {
            console.error("Error fetching part inventory:", err);
          }
        };

        fetchData();
      })
      .catch((err) => {
        toast.dismiss();

        toast.error(Utils.getErrorMessage(err));
      })
      .finally(() => {
        // Reset button state to "close"
        setbtnClosed(false);
      });
  };

  useEffect(() => {
    if (selectedItems?.length === 0) {
      setCurrentStep(1);
    }
  }, [selectedItems]);

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = "";
    } else if (type === "pagination") {
      paramsObj.page = e;
    }

    setParams(paramsObj);
  };

  useEffect(function () {
    var obj = {
      text: "",
      limit: tableLengthList[0],
      page: 1,
    };

    partInvenoryVehicleSearch(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            // value: response.data[i].id,
            // label: response.data[i].vehicle_model,
            // vehicle_make: response.data[i].vehicle_make,
            // vehicle_year: response.data[i].vehicle_end_year,
            // vehicle_model: response.data[i].vehicle_model,
            // vehicle_exp_value: response.data[i].vehicle_exp_value,
            value: response.data[i]?.vehicle_model_name,
            label: `${response.data[i]?.vehicle_make_name || ""} | ${
              response.data[i]?.vehicle_model_name || ""
            } | ${response.data[i]?.registration_plate || ""} | ${
              response.data[i]?.registration || ""
            }`,
            vehicle_model_name: response.data[i]?.vehicle_model_name,

            vehicle_model_id: response.data[i]?.vehicle_model_id,
            make_id: response.data[i]?.make_id,
            vehicle_process_end_date:
              response.data[i]?.vehicle_process_end_date,
            vehicle_in_inventory: response.data[i]?.vehicle_in_inventory,
            variants_id: response.data[i]?.variants_id,
            vehicle_make_name: response.data[i]?.vehicle_make_name,
            registration_plate: response.data[i]?.registration_plate,
            registration: response.data[i]?.registration,
            id: response.data[i]?.id,
          });
        }
        setVehicleModelAllList(list);
      })
      .catch((err) => {});
  }, []);

  const handleInputChange = (inputValue) => {
    setInputValue(inputValue);

    if (inputValue.length > 2) {
      const obj = {
        search: inputValue,
        limit: tableLengthList[0],
        page: 1,
      };

      partInvenoryVehicleSearch(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i]?.vehicle_model_name,
              label: `${response.data[i]?.vehicle_make_name || ""} | ${
                response.data[i]?.vehicle_model_name || ""
              } | ${response.data[i]?.registration_plate || ""} | ${
                response.data[i]?.registration || ""
              }`,
              vehicle_model_name: response.data[i]?.vehicle_model_name,
              vehicle_model_id: response.data[i]?.vehicle_model_id,
              make_id: response.data[i]?.make_id,
              vehicle_process_end_date:
                response.data[i]?.vehicle_process_end_date,
              vehicle_in_inventory: response.data[i]?.vehicle_in_inventory,
              variants_id: response.data[i]?.variants_id,
              vehicle_make_name: response.data[i]?.vehicle_make_name,
              registration_plate: response.data[i]?.registration_plate,
              registration: response.data[i]?.registration,
              id: response.data[i]?.id,
            });
          }

          setVehicleModelAllList(list);
        })
        .catch((err) => {
          console.error("Error fetching vehicle model list", err);
        });
    }
  };

  useEffect(
    function () {
      let data = {
        limit: params.limit,
        page: params.page,
        search: searchQuery,
        is_reload: false,
      };
      getCatalogList(data)
        .then((response) => {
          response = response.data;

          setCatalogList(response);
          setTotalRecords(response.total);
        })
        .catch((err) => {});
    },
    [searchQuery, params]
  );

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchChangeTemp = (e) => {
    setSearchQueryTemp(e.target.value);
  };

  useEffect(() => {
    if (tempData?.temp_id || dismantleTempData?.temp_id) {
      const fetchData = async () => {
        try {
          const tempIdToSend = tempData?.temp_id || dismantleTempData?.temp_id;

          const obj = {
            search: searchQueryTemp,
            limit: tableLengthList[0],
            page: 1,
            temp_id: tempIdToSend,
          };

          const response = await getPartInventoryTempList(obj);
          setSelectedItems(response.data.data);
          setTotalRecords(response.data.total);
        } catch (err) {
          console.error("Error fetching part inventory:", err);
        }
      };

      fetchData();
    }
  }, [searchQueryTemp, tempData, dismantleTempData]);

  const createPayload = () => {
    return partsData.map((part) => ({
      vehicle_id: vehicleModalSelectedList?.id || 0,
      part_catelog: part?.id || "",
      part_name: part?.part_name,
      quantity: part?.quantity,
      part_price: part?.part_price,
      condition_id: part?.condition_id,
      part_cost_price: part?.part_cost_price,
      part_original_price: part?.part_original_price,
      state_id: part?.state_id,
      is_custom_size: part?.is_custom_size,
      parts_weight: part?.parts_weight || 0,
      parts_width: part?.parts_width || 0,
      parts_height: part?.parts_height || 0,
      parts_length: part?.parts_length || 0,
      print_label: part?.print_label ? part?.print_label : 0,
      vat_included: part?.vat_included ? part?.vat_included : 0,
      inventory_status: part.inventory_status ? part.inventory_status : 1,
      vehicle_make: part?.vehicle_make,
      vehicle_year: part?.vehicle_model,
      vehicle_model: part?.vehicle_model,
      sponsor_price: part?.sponsor_price,
      part_description: part?.part_description,
      parts_associate: [],
      brand_id: part.brand_id || 0,
      parts_reference: partsData?.flatMap((part) =>
        part?.addedData?.map((item) => ({
          reference_code: item?.reference_code,
          is_main: item?.is_main,
          type_id: item?.type_id,
          condition_id: item?.condition_id_ref,
        }))
      ),
      engine_code: part?.engine_code,
      variants_id: part?.variants_id,
      cv: part?.cv,
      cc: part?.cc,
      kw: part?.kw,
      reg_number_dismantler: part?.reg_number_dismantler,
    }));
  };

  const createPayloads = () => {
    const part = partsData[0];

    return {
      uuid: vehicleAddDetails?.uuid,
      id: vehicleAddDetails?.id,
      vehicle_id: vehicleAddDetails?.vehicle_id || 0,
      part_catelog: part?.id || vehicleAddDetails?.part_catelog,
      part_name: part?.part_name,
      quantity: part?.quantity,
      part_price: part?.part_price,
      condition_id: part?.condition_id,
      part_cost_price: part?.part_cost_price,
      part_original_price: part?.part_original_price,
      state_id: part?.state_id,
      is_custom_size: part?.is_custom_size,
      parts_weight: part?.parts_weight || 0,
      parts_width: part?.parts_width || 0,
      parts_height: part?.parts_height || 0,
      parts_length: part?.parts_length || 0,
      print_label: part?.print_label,
      vat_included: part?.vat_included,
      inventory_status: part.inventory_status ? part.inventory_status : 1,
      vehicle_make: part?.vehicle_make,
      vehicle_year: part?.vehicle_model,
      vehicle_model: part?.vehicle_model,
      part_description: part?.part_description,
      brand_id: part?.brand_id || 0,
      parts_associate:
        selectedModels?.map((model) => ({
          uuid: model?.uuid,
          vehicle_make: model.make_id,
          vehicle_year: model.model_id ? model?.model_id : model?.id,
          vehicle_model: model.model_id ? model?.model_id : model?.id,
        })) || [],
      parts_reference:
        part?.addedData?.map((item) => ({
          id: item?.id,
          reference_code: item?.reference_code,
          is_main: item?.is_main,
          type_id: item?.type_id,
          condition_id: item?.condition_id_ref,
        })) || [],
      part_original_price: part?.part_original_price,
      engine_code: part?.engine_code,
      variants_id: part?.variants_id,
      cv: part?.cv,
      cc: part?.cc,
      kw: part?.kw,
      reg_number_dismantler: part?.reg_number_dismantler,
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let error = false;
    let errorObj = { ...errorsObj };
    partsData.forEach((part) => {
      if (!part.quantity) {
        errorObj.quantity = `Part Quantity is required.`;
        error = true;
      }
    });

    setErrors(errorObj);
    if (error) return;

    const payloads = createPayload();
    console.log("payloads", payloads);
    const payloadsUpdated = createPayloads();
    setbtnLoading(true);
    setbtnSaveNext(true);
    if (vehicleAddDetails?.id) {
      updatePartsInventory(payloadsUpdated)
        .then((response) => {
          SetDetailsForImages(response.data.data);
          toast.success(response.data.message);
          // navigate("/parts-inventory");
          setCurrentStep(2);
          setbtnLoading(false);
          setbtnSaveNext(false);
        })
        .catch((err) => {
          setbtnLoading(false);
          toast.dismiss();
          setbtnSaveNext(false);
          toast.error(Utils.getErrorMessage(err));
        });
    } else {
      let data = {
        temp_id: tempData?.temp_id,
        part_list: payloads,
      };
      createPartsInventory(data)
        .then((response) => {
          SetDetailsForImages(response.data.data);
          toast.success(response.data.message);
          setCurrentStep(3);
          setbtnLoading(false);
          setbtnSaveNext(false);
        })
        .catch((err) => {
          setbtnLoading(false);
          setbtnSaveNext(false);
          toast.dismiss();

          toast.error(Utils.getErrorMessage(err));
        });
    }
  };

  const handleSubmitAsPrintLabel = (e) => {
    e.preventDefault();

    let error = false;
    let errorObj = { ...errorsObj };
    partsData.forEach((part) => {
      if (!part.quantity) {
        errorObj.quantity = `Quantity is required.`;
        error = true;
      }
    });

    setErrors(errorObj);
    if (error) return;

    const payloads = createPayload();
    const payloadsUpdated = createPayloads();

    setBtnLoader(true);
    if (vehicleAddDetails?.id) {
      updatePartsInventory(payloadsUpdated)
        .then((response) => {
          toast.success(response.data.message);
          // navigate("/parts-inventory");
          setCurrentStep(2);
          setBtnLoader(false);
        })
        .catch((err) => {
          toast.dismiss();
          setBtnLoader(false);
          toast.dismiss();
          toast.error(Utils.getErrorMessage(err));
        });
    } else {
      let data = {
        temp_id: tempData?.temp_id,
        is_print: 1,
        part_list: payloads,
      };
      createPartsInventory(data)
        .then((response) => {
          SetDetailsForImages(response.data.data);
          toast.success(response.data.message);
          // navigate("/parts-inventory");

          setCurrentStep(3);
          setBtnLoader(false);
        })
        .catch((err) => {
          toast.dismiss();

          toast.error(Utils.getErrorMessage(err));
          setBtnLoader(false);
        });
    }
  };

  const handleNextPart = () => {
    setCurrentStep(2);
    setImageDetailsNot(vehicleAddDetails);
  };

  const handleNextClick = (e) => {
    e.preventDefault();
    setbtnNext(true);
    const resetLoadingState = () => {
      setTimeout(() => {
        setbtnNext(false);
      }, 3000); // Adjust the timeout duration as needed
    };
    if (!tempData?.temp_id || selectedItems.length === 0) {
      toast.error("Please select a vehicle part.");
      resetLoadingState();
      return;
    } else if (vehicleModalSelectedList?.vehicle_process_end_date) {
      setCurrentStep(1);
      setModifyExpiry(true);
    }
  };
  const handleAddPart = (e, index) => {
    e.preventDefault();

    if (selectedVehicleModel) {
      let list = [...partsData];
      let partList = list[index]["parts_associate"];
      const isDuplicate = partList.some(
        (part) => part.value === selectedVehicleModel.value
      );

      let idx = partList.findIndex(
        (x) => x.value == selectedVehicleModel.value
      );
      if (idx === -1) {
        partList.push(selectedVehicleModel);
        list[index]["parts_associate"] = partList;
        setPartsData(list);
      } else {
        toast.error(selectedVehicleModel.label + ` is already added.`);
      }
    } else {
      console.warn("No vehicle model selected.");
    }
  };

  const handleSelectWithoutChange = (option) => {
    setSelectedVehicleModel(option);
  };

  const handleBackButtonClick = () => {
    if (currentStep === 2) {
      setCurrentStep(1);
    } else {
      navigate("/parts-inventory");
    }
  };
  const toggleEnlarge = (index) => {
    if (enlargedIndexes.includes(index)) {
      setEnlargedIndexes(enlargedIndexes.filter((i) => i !== index));
    } else {
      setEnlargedIndexes([...enlargedIndexes, index]);
    }
  };

  const setPartCostPrice = (id, price1) => {
    const discountPercentage = settingDetails?.discount_percentage / 100;

    const discountedPrice = price1 * (1 - discountPercentage);

    const updatedParts = [...partsData];
    const partToUpdate = updatedParts.find((part) => part?.id === id);

    if (partToUpdate) {
      partToUpdate.part_cost_price = discountedPrice;
      setPartsData(updatedParts);
    }
  };

  const handleInputChangePartPrice1 = (e, id) => {
    const { name, value } = e.target;
    const updatedParts = [...partsData];
    const partToUpdate = updatedParts.find((part) => part?.id === id);

    if (partToUpdate) {
      partToUpdate[name] = value;
      setPartsData(updatedParts);

      if (name === "part_price") {
        const originalPrice = parseFloat(value) || 0;
        setPartCostPrice(id, originalPrice);
      }
    }
  };
  const handleInventoryIdChange = (index, selectedOption) => {
    const updatedParts = [...partsData];

    updatedParts[index] = {
      ...updatedParts[index],
      inventory_status: selectedOption.value,
    };

    setPartsData(updatedParts);
  };

  const handleConditionChange = (index, selectedOption) => {
    const updatedParts = [...partsData];

    updatedParts[index] = {
      ...updatedParts[index],
      condition_id: selectedOption.value,
    };

    setPartsData(updatedParts);
  };

  const handleStateListChange = (index, selectedOption) => {
    const updatedParts = [...partsData];

    updatedParts[index] = {
      ...updatedParts[index],
      state_id: selectedOption.value,
    };

    setPartsData(updatedParts);
  };

  const handleCheckboxCustomChange = (index, e) => {
    const updatedParts = [...partsData];

    updatedParts[index] = {
      ...updatedParts[index],
      is_custom_size: e.target.checked ? "1" : "0", // Update the specific part at that index
    };

    setPartsData(updatedParts);
  };

  const handleCheckboxPrintChanges = (pIndex, event) => {
    const updatedParts = [...partsData]; // Create a copy of the parts data
    const partToUpdate = updatedParts[pIndex];

    partToUpdate.print_label = event.target.checked ? 1 : 0; // Update the print_label to 1 if checked, or 0 if unchecked

    setPartsData(updatedParts); // Update the state with the new list
  };

  const handleCheckboxVatChanges = (pIndex, event) => {
    const updatedParts = [...partsData]; // Create a copy of the parts data
    const partToUpdate = updatedParts[pIndex];

    partToUpdate.vat_included = event.target.checked ? 1 : 0; // Update the print_label to 1 if checked, or 0 if unchecked

    setPartsData(updatedParts); // Update the state with the new list
  };

  const handleBrandIdChange = (index, selectedOption) => {
    const updatedParts = [...partsData];

    updatedParts[index] = {
      ...updatedParts[index],
      brand_id: selectedOption.value,
    };

    setPartsData(updatedParts);
  };

  const vehicleDatesExpiry = moment(
    vehicleModalSelectedList?.vehicle_process_end_date
  ).format("YYYY-MM-DD");

  useEffect(() => {
    if (vehicleDatesExpiry) {
      setVehicleData({
        date: vehicleDatesExpiry,
      });
    }
  }, [vehicleDatesExpiry]);

  const ExpiryModalNextclose = () => {
    if (vehicleModalSelectedList?.vehicle_process_end_date) {
      let data = {
        id: vehicleModalSelectedList.id,
        date: vehicleModalSelectedList?.vehicle_process_end_date,
      };

      partVehicleProcessUpdateExpiryDate(data)
        .then((response) => {
          setVehicleExceedData(response.data.data);
          toast.success(response.data.message);

          setModifyExpiry(false);
          setCurrentStep(2);
        })
        .catch((err) => {
          toast.dismiss();

          toast.error(Utils.getErrorMessage(err));
        })
        .finally(() => {
          setbtnNext(false); // Reset loading state
        });
    } else {
      let data = {
        id: selectedModel.value,
        date: vehicleData.date,
      };

      if (
        vehicleData?.date < vehicleModalSelectedList?.vehicle_process_start_date
      ) {
        toast.error("Please select Correct date grater than your start date.");
        setbtnNext(false);
      }
      partVehicleProcessUpdateExpiryDate(data)
        .then((response) => {
          setVehicleExceedData(response.data.data);
          toast.success(response.data.message);

          setCurrentStep(2);
        })
        .catch((err) => {
          toast.dismiss();

          toast.error(Utils.getErrorMessage(err));
        })
        .finally(() => {
          setbtnNext(false); // Reset loading state
        });
    }
  };

  return (
    <div>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  {/* <a href="#">Home</a> */}
                </li>
                {/* <li className="breadcrumb-item active">Fuel</li> */}
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>

      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary card-primary-Vehicle">
            {vehicleAddDetails?.id ? (
              <>
                {currentStep === 1 && (
                  <>
                    <div className="card-body vehicleparts-table mt-3">
                      <div className="vehicleparts-table-inner">
                        <Form method={`post`} onSubmit={handleSubmit}>
                          <div className="row justify-content-start">
                            {partsData?.map((part, pIndex) => {
                              console.log("part", part);
                              return (
                                <React.Fragment key={pIndex}>
                                  <div className="col-lg-12" key={part?.id}>
                                    <div className="text-center cstm-scroll cstm-scroll-2">
                                      <Card.Header className="cardHeader">
                                        <div
                                          className="d-flex"
                                          data-bs-toggle="collapse"
                                          href={`#multiCollapseExample-${part?.tempId}`}
                                          role="button"
                                          aria-expanded="true"
                                          aria-controls={`multiCollapseExample-${part?.tempId}`}
                                        >
                                          <div className=""></div>
                                          <div className="AddPh6">
                                            <h6>
                                              {part?.part_name} |{" "}
                                              {vehicleAddDetails?.id} |{" "}
                                              {part?.vehicle_make_name} |{" "}
                                              {part?.vehicle_model_name}
                                            </h6>
                                          </div>
                                        </div>
                                      </Card.Header>
                                      <div className="row">
                                        <div className="col">
                                          <Card.Body
                                            className="cardBody card collapse multi-collapse show"
                                            id={`multiCollapseExample-${part.tempId}`}
                                          >
                                            <div className="row">
                                              <div className="col-lg-6">
                                                <div className="row">
                                                  <div className="col-lg-6 addPartField">
                                                    <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                      Quantity
                                                    </label>
                                                    <input
                                                      className="form-control input-search"
                                                      type="number"
                                                      name="quantity"
                                                      value={part?.quantity}
                                                      onChange={(e) =>
                                                        handleInputChangePartList(
                                                          e,
                                                          part?.id
                                                        )
                                                      }
                                                      placeholder="0"
                                                    />

                                                    {errors.quantity && (
                                                      <span className="input-error">
                                                        {errors.quantity}
                                                      </span>
                                                    )}
                                                  </div>
                                                  <div className="col-lg-6 addPartField">
                                                    <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                      Price 1
                                                    </label>
                                                    <input
                                                      className="form-control input-search"
                                                      type="number"
                                                      name="part_price"
                                                      value={part.part_price}
                                                      onChange={(e) => {
                                                        handleInputChangePartPrice1(
                                                          e,
                                                          part?.id
                                                        );
                                                      }}
                                                      placeholder="0"
                                                    />

                                                    {errors.part_price && (
                                                      <span className="input-error">
                                                        {errors.part_price}
                                                      </span>
                                                    )}
                                                  </div>
                                                  <div className="col-lg-6 addPartField">
                                                    <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                      Price 2 (
                                                      {
                                                        settingDetails?.discount_percentage
                                                      }
                                                      % discount)
                                                    </label>
                                                    <input
                                                      className="form-control input-search"
                                                      type="number"
                                                      name="part_original_price"
                                                      value={
                                                        part.part_original_price
                                                      }
                                                      onChange={(e) =>
                                                        handleInputChangePartList(
                                                          e,
                                                          part.id
                                                        )
                                                      }
                                                      placeholder="0"
                                                    />
                                                  </div>

                                                  <div className="col-lg-6 addPartField">
                                                    <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                      Part Cost Price
                                                    </label>
                                                    <input
                                                      className="form-control input-search"
                                                      type="number"
                                                      name="part_cost_price"
                                                      value={
                                                        part?.part_cost_price
                                                      }
                                                      onChange={(e) => {
                                                        handleInputChangePartList(
                                                          e,
                                                          part?.tempId
                                                        );
                                                      }}
                                                      placeholder="0"
                                                    />
                                                  </div>
                                                  <div className="col-lg-6">
                                                    <div className="addPartField">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        Inventory Status
                                                      </label>
                                                      <Select
                                                        className="my-Select"
                                                        options={inventoryList}
                                                        name="inventory_status"
                                                        value={inventoryList.find(
                                                          (option) =>
                                                            option.value ===
                                                            part.inventory_status
                                                        )}
                                                        onChange={(
                                                          selectedOption
                                                        ) =>
                                                          handleInventoryIdChange(
                                                            pIndex,
                                                            selectedOption
                                                          )
                                                        }
                                                        placeholder={
                                                          "Select Inventory Status"
                                                        }
                                                        isSearchable={true}
                                                      ></Select>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-6">
                                                    <div className="addPartField">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        Condition
                                                      </label>
                                                      <Select
                                                        className="my-Select"
                                                        options={conditionList}
                                                        value={
                                                          conditionList.find(
                                                            (option) =>
                                                              option.value ===
                                                                part.condition_id ||
                                                              option.value ===
                                                                settingDetails?.condition_id
                                                          ) || {
                                                            value: 1,
                                                            label: "OEM",
                                                          }
                                                        }
                                                        onChange={(
                                                          selectedOption
                                                        ) =>
                                                          handleConditionChange(
                                                            pIndex,
                                                            selectedOption
                                                          )
                                                        }
                                                        isSearchable
                                                        placeholder={
                                                          "Select Condition"
                                                        }
                                                      ></Select>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-6">
                                                    <div className="addPartField">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        Part Brand
                                                      </label>
                                                      <Select
                                                        className="my-Select"
                                                        options={allBrandList}
                                                        name="brand_id"
                                                        value={allBrandList.find(
                                                          (option) =>
                                                            option.value ===
                                                            part.brand_id
                                                        )}
                                                        onChange={(
                                                          selectedOption
                                                        ) =>
                                                          handleBrandIdChange(
                                                            pIndex,
                                                            selectedOption
                                                          )
                                                        }
                                                        placeholder={
                                                          "Select Part Brand"
                                                        }
                                                        isSearchable={true}
                                                      ></Select>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-6">
                                                    <div className="addPartField">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        State
                                                      </label>
                                                      <Select
                                                        className="my-Select"
                                                        options={stateList}
                                                        value={stateList.find(
                                                          (option) =>
                                                            option.value ===
                                                            settingDetails?.state_id
                                                              ? option.value ===
                                                                settingDetails?.state_id
                                                              : option.value ===
                                                                part.state_id
                                                        )}
                                                        onChange={(
                                                          selectedOption
                                                        ) =>
                                                          handleStateListChange(
                                                            pIndex,
                                                            selectedOption
                                                          )
                                                        }
                                                        isSearchable
                                                        placeholder={
                                                          "Select State"
                                                        }
                                                      ></Select>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-4 addPartField">
                                                    <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                      Custome Size
                                                    </label>

                                                    <div className="col-lg-12 addPartField">
                                                      <div className="form-check text-left">
                                                        <input
                                                          className="form-check-input"
                                                          type="checkbox"
                                                          checked={
                                                            part.is_custom_size ===
                                                            "1"
                                                          }
                                                          onChange={(e) =>
                                                            handleCheckboxCustomChange(
                                                              pIndex,
                                                              e
                                                            )
                                                          }
                                                        />
                                                        <label className="form-check-label">
                                                          Custome Size
                                                        </label>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-4 addPartField">
                                                    <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                      Print label
                                                    </label>
                                                    <div className="col-lg-12 addPartField">
                                                      <div className="form-check text-left">
                                                        <input
                                                          className="form-check-input"
                                                          type="checkbox"
                                                          checked={
                                                            part.print_label ===
                                                            1
                                                          }
                                                          onChange={(e) =>
                                                            handleCheckboxPrintChanges(
                                                              pIndex,
                                                              e
                                                            )
                                                          }
                                                        />
                                                        <label className="form-check-label">
                                                          Print label
                                                        </label>
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <div className="col-lg-4 addPartField">
                                                    <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                      VAT included{" "}
                                                    </label>
                                                    <div className="col-lg-12 addPartField">
                                                      <div className="form-check text-left">
                                                        <input
                                                          className="form-check-input"
                                                          type="checkbox"
                                                          checked={
                                                            part.vat_included ===
                                                            1
                                                          }
                                                          onChange={(e) =>
                                                            handleCheckboxVatChanges(
                                                              pIndex,
                                                              e
                                                            )
                                                          }
                                                        />
                                                        <label className="form-check-label">
                                                          VAT included{" "}
                                                        </label>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-lg-6">
                                                <div className="row">
                                                  <div className="col-lg-12">
                                                    <div className="addPartField">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        Part Brand
                                                      </label>
                                                      <Select
                                                        className="my-Select"
                                                        options={allBrandList}
                                                        name="brand_id"
                                                        value={allBrandList.find(
                                                          (option) =>
                                                            option.value ===
                                                            part.brand_id
                                                        )}
                                                        onChange={(
                                                          selectedOption
                                                        ) =>
                                                          handleBrandIdChange(
                                                            pIndex,
                                                            selectedOption
                                                          )
                                                        }
                                                        placeholder={
                                                          "Select Part Brand"
                                                        }
                                                        isSearchable={true}
                                                      ></Select>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-12">
                                                    <div className="addPartField">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        Notes
                                                      </label>
                                                      <textarea
                                                        className="form-control input-search"
                                                        type="text"
                                                        name="part_description"
                                                        value={
                                                          part.part_description
                                                        }
                                                        onChange={(e) =>
                                                          handleInputChangePartList(
                                                            e,
                                                            part.id
                                                          )
                                                        }
                                                        placeholder="Notes"
                                                        rows={3}
                                                      />
                                                    </div>
                                                  </div>
                                                  {vehicleAddDetails.vehicle_id ===
                                                    null && (
                                                    <>
                                                      <div className="col-lg-12">
                                                        <label class="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                          Vehicle Modal
                                                        </label>
                                                        <div className="selectDisplay">
                                                          <div className="input-div SelectV selectA">
                                                            <div className="col-xl-12">
                                                              <input
                                                                className="form-control mt-2 input-search"
                                                                type="text"
                                                                maxLength={50}
                                                                placeholder="Search Vehicle Model"
                                                                value={
                                                                  vehicleModelAuto
                                                                }
                                                                onChange={
                                                                  handleChangeModel
                                                                }
                                                                onKeyDown={
                                                                  handleKeyDownModel
                                                                }
                                                                onClick={(e) =>
                                                                  e.stopPropagation()
                                                                }
                                                              />
                                                              {showSuggestionsModel &&
                                                                vehicleModelAuto && (
                                                                  <ul className="suggestions">
                                                                    {filteredSuggestionsModel?.length >
                                                                    0 ? (
                                                                      filteredSuggestionsModel.map(
                                                                        (
                                                                          suggestion,
                                                                          index
                                                                        ) => (
                                                                          <li
                                                                            key={
                                                                              suggestion
                                                                            }
                                                                            className={
                                                                              index ===
                                                                              activeSuggestionIndexModel
                                                                                ? "active"
                                                                                : ""
                                                                            }
                                                                            onClick={() =>
                                                                              handleClickModel(
                                                                                suggestion
                                                                              )
                                                                            }
                                                                          >
                                                                            {
                                                                              suggestion.model_name
                                                                            }
                                                                            /
                                                                            {
                                                                              suggestion.initial_year
                                                                            }
                                                                          </li>
                                                                        )
                                                                      )
                                                                    ) : (
                                                                      <li>
                                                                        No
                                                                        suggestions
                                                                        available
                                                                      </li>
                                                                    )}
                                                                  </ul>
                                                                )}
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div
                                                          className="tableScroll vehicleThBg"
                                                          style={{
                                                            height: "0px",
                                                            border:
                                                              "1px solid #eee",
                                                            borderRadius: "0px",
                                                            width: "97%",
                                                            marginLeft: "8px",
                                                            marginTop: "5px",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              borderCollapse:
                                                                "separate",
                                                              borderSpacing:
                                                                "0 15px",
                                                              marginBottom:
                                                                "16px",
                                                            }}
                                                            className="striped bordered hover"
                                                          >
                                                            <div className="container">
                                                              <div className="row row-cols-2 row-cols-lg-3 g-2 g-lg-3 justify-content-start">
                                                                {selectedModels?.length >
                                                                  0 &&
                                                                  selectedModels?.map(
                                                                    (
                                                                      row,
                                                                      partIndex
                                                                    ) => (
                                                                      <React.Fragment
                                                                        key={
                                                                          partIndex
                                                                        }
                                                                      >
                                                                        <div className="d-flex">
                                                                          <div className="lisatingTable mb-1 col vehicleModall">
                                                                            {
                                                                              row.model_name
                                                                            }{" "}
                                                                            (
                                                                            {
                                                                              row.initial_year
                                                                            }
                                                                            )
                                                                          </div>
                                                                          <Button
                                                                            style={{
                                                                              borderRadius:
                                                                                "0px",
                                                                              margin:
                                                                                "7px",
                                                                              height:
                                                                                "30px",
                                                                              width:
                                                                                "30px",
                                                                              color:
                                                                                "#dd4b4b",
                                                                              backgroundColor:
                                                                                "#ffe9e9",
                                                                              display:
                                                                                "flex",
                                                                              justifyContent:
                                                                                "center",
                                                                              alignItems:
                                                                                "center",
                                                                              border:
                                                                                "transparent",
                                                                              marginTop:
                                                                                "12px",
                                                                            }}
                                                                            className=""
                                                                            onClick={() =>
                                                                              setSelectedModels(
                                                                                selectedModels.filter(
                                                                                  (
                                                                                    m
                                                                                  ) =>
                                                                                    m.id !==
                                                                                    row.id
                                                                                )
                                                                              )
                                                                            }
                                                                          >
                                                                            <i className="bi bi-trash3"></i>
                                                                          </Button>
                                                                        </div>
                                                                      </React.Fragment>
                                                                    )
                                                                  )}
                                                              </div>
                                                            </div>
                                                            <div></div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </div>
                                              {part.is_custom_size === "1" && (
                                                <>
                                                  <div className="col-lg-3 addPartField">
                                                    <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                      Weight (kg){" "}
                                                    </label>
                                                    <input
                                                      className="form-control input-search"
                                                      type="number"
                                                      name="parts_weight"
                                                      value={part.parts_weight}
                                                      onChange={(e) =>
                                                        handleInputChangePartList(
                                                          e,
                                                          part.id
                                                        )
                                                      }
                                                      placeholder="0"
                                                    />
                                                  </div>

                                                  <div className="col-lg-3 addPartField">
                                                    <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                      Width(cm){" "}
                                                    </label>
                                                    <input
                                                      className="form-control input-search"
                                                      type="number"
                                                      name="parts_width"
                                                      value={part.parts_width}
                                                      onChange={(e) =>
                                                        handleInputChangePartList(
                                                          e,
                                                          part.id
                                                        )
                                                      }
                                                      placeholder="0"
                                                    />

                                                    {errors.parts_width && (
                                                      <span className="input-error">
                                                        {errors.parts_width}
                                                      </span>
                                                    )}
                                                  </div>

                                                  <div className="col-lg-3 addPartField">
                                                    <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                      Height(cm){" "}
                                                    </label>
                                                    <input
                                                      className="form-control input-search"
                                                      type="number"
                                                      name="parts_height"
                                                      value={part.parts_height}
                                                      onChange={(e) =>
                                                        handleInputChangePartList(
                                                          e,
                                                          part.id
                                                        )
                                                      }
                                                      placeholder="0"
                                                    />
                                                  </div>

                                                  <div className="col-lg-3 addPartField">
                                                    <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                      Length(cm){" "}
                                                    </label>
                                                    <input
                                                      className="form-control input-search"
                                                      type="number"
                                                      name="parts_length"
                                                      value={part.parts_length}
                                                      onChange={(e) =>
                                                        handleInputChangePartList(
                                                          e,
                                                          part.id
                                                        )
                                                      }
                                                      placeholder="0"
                                                    />
                                                  </div>
                                                </>
                                              )}

                                              {part?.state_id === 2 && (
                                                <div className="col-lg-3 addPartField col-lg-3-mob">
                                                  <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                    Reg Number Dismantler
                                                  </label>
                                                  <input
                                                    className="form-control input-search"
                                                    type="text"
                                                    name="reg_number_dismantler"
                                                    value={
                                                      settingDetails?.reg_number_dismantler
                                                        ? settingDetails?.reg_number_dismantler
                                                        : part?.reg_number_dismantler
                                                    }
                                                    onChange={(e) =>
                                                      handleInputChangePartList(
                                                        e,
                                                        part?.tempId
                                                      )
                                                    }
                                                    placeholder="0"
                                                  />
                                                </div>
                                              )}
                                            </div>
                                            {/* <div className="row">
                                              <div className="col-lg-4 addPartField">
                                                <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                  Quantity
                                                </label>
                                                <input
                                                  className="form-control input-search"
                                                  type="number"
                                                  name="quantity"
                                                  value={part.quantity}
                                                  onChange={(e) =>
                                                    handleInputChangePartList(
                                                      e,
                                                      part.id
                                                    )
                                                  }
                                                  placeholder="0"
                                                />

                                                {errors.quantity && (
                                                  <span className="input-error">
                                                    {errors.quantity}
                                                  </span>
                                                )}
                                              </div>

                                              <div className="col-lg-4 addPartField">
                                                <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                  Price 1
                                                </label>
                                                <input
                                                  className="form-control input-search"
                                                  type="number"
                                                  name="part_price"
                                                  value={part.part_price}
                                                  onChange={(e) => {
                                                    handleInputChangePartPrice1(
                                                      e,
                                                      part.id
                                                    );
                                                  }}
                                                  placeholder="0"
                                                />

                                                {errors.part_price && (
                                                  <span className="input-error">
                                                    {errors.part_price}
                                                  </span>
                                                )}
                                              </div>
                                              <div className="col-lg-4 addPartField">
                                                <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                  Price 2 (
                                                  {
                                                    settingDetails?.discount_percentage
                                                  }
                                                  % discount)
                                                </label>
                                                <input
                                                  className="form-control input-search"
                                                  type="number"
                                                  name="part_cost_price"
                                                  value={part.part_cost_price}
                                                  onChange={(e) =>
                                                    handleInputChangePartList(
                                                      e,
                                                      part.id
                                                    )
                                                  }
                                                  placeholder="0"
                                                />
                                              </div>

                                          <div className="col-lg-4 addPartField">
                                            <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                              Inventory Status
                                            </label>
                                            <Select
                                              className="my-Select"
                                              options={inventoryList}
                                              name="inventory_status"
                                              value={
                                                inventoryList.find(
                                                  (item) =>
                                                    item.value ===
                                                    partsData[pIndex]
                                                      ?.inventory_status
                                                ) ||
                                                inventoryList.find(
                                                  (item) => item.value === 1
                                                )
                                              }
                                              onChange={(selectedOption) =>
                                                handleInventoryIdChange(
                                                  pIndex,
                                                  selectedOption
                                                )
                                              }
                                              placeholder={
                                                "Select Inventory Status"
                                              }
                                              isSearchable={true}
                                            ></Select>
                                          </div>

                                              <div className="col-lg-4 addPartField">
                                                <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                  Condition
                                                </label>
                                                <Select
                                                  className="my-Select"
                                                  options={conditionList}
                                                  value={conditionList.find(
                                                    (option) =>
                                                      option.value ===
                                                      part.condition_id ||
                                                      option.value ===
                                                      settingDetails?.condition_id
                                                  )}
                                                  onChange={(selectedOption) =>
                                                    handleConditionChange(
                                                      pIndex,
                                                      selectedOption
                                                    )
                                                  }
                                                  isSearchable
                                                  placeholder={"Select Condition"}
                                                ></Select>
                                              </div>

                                              <div className="col-lg-4 addPartField">
                                                <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                  State
                                                </label>
                                                <Select
                                                  className="my-Select"
                                                  options={stateList}
                                                  value={stateList.find((option) =>
                                                    option.value ===
                                                      settingDetails?.state_id
                                                      ? option.value ===
                                                      settingDetails?.state_id
                                                      : option.value ===
                                                      part.state_id
                                                  )}
                                                  onChange={(selectedOption) =>
                                                    handleStateListChange(
                                                      pIndex,
                                                      selectedOption
                                                    )
                                                  }
                                                  isSearchable
                                                  placeholder={"Select Condition"}
                                                ></Select>
                                              </div>

                                              <div className="col-lg-6 addPartField">
                                                <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                  Notes
                                                </label>
                                                <textarea
                                                  className="form-control input-search"
                                                  type="text"
                                                  name="part_description"
                                                  value={part.part_description}
                                                  onChange={(e) =>
                                                    handleInputChangePartList(
                                                      e,
                                                      part.id
                                                    )
                                                  }
                                                  placeholder="Notes"
                                                  rows={3}
                                                />
                                              </div>

                                              <div className="col-lg-6 addPartField">
                                                <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                  Part Brand
                                                </label>
                                                <Select
                                                  className="my-Select"
                                                  options={allBrandList}
                                                  name="brand_id"
                                                  value={allBrandList.find(
                                                    (option) =>
                                                      option.value === part.brand_id
                                                  )}
                                                  onChange={(selectedOption) =>
                                                    handleBrandIdChange(
                                                      pIndex,
                                                      selectedOption
                                                    )
                                                  }
                                                  placeholder={"Select Part Brand"}
                                                  isSearchable={true}
                                                ></Select>
                                              </div>

                                              <div className="col-lg-6 addPartField">
                                                <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                  Custome Size
                                                </label>

                                                <div className="col-lg-12 addPartField">
                                                  <div className="form-check text-left">
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      checked={
                                                        part.is_custom_size === "1"
                                                      }
                                                      onChange={(e) =>
                                                        handleCheckboxCustomChange(
                                                          pIndex,
                                                          e
                                                        )
                                                      }
                                                    />
                                                    <label className="form-check-label">
                                                      Custome Size
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-lg-6 addPartField">
                                                <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                  Print label
                                                </label>
                                                <div className="col-lg-12 addPartField">
                                                  <div className="form-check text-left">
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      checked={
                                                        part.print_label === 1
                                                      }
                                                      onChange={(e) =>
                                                        handleCheckboxPrintChanges(
                                                          pIndex,
                                                          e
                                                        )
                                                      }
                                                    />
                                                    <label className="form-check-label">
                                                      Print label
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>

                                              {part.is_custom_size === "1" && (
                                                <>
                                                  <div className="col-lg-3 addPartField">
                                                    <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                      Weight (kg){" "}
                                                    </label>
                                                    <input
                                                      className="form-control input-search"
                                                      type="number"
                                                      name="parts_weight"
                                                      value={part.parts_weight}
                                                      onChange={(e) =>
                                                        handleInputChangePartList(
                                                          e,
                                                          part.id
                                                        )
                                                      }
                                                      placeholder="0"
                                                    />
                                                  </div>

                                                  <div className="col-lg-3 addPartField">
                                                    <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                      Width(cm){" "}
                                                    </label>
                                                    <input
                                                      className="form-control input-search"
                                                      type="number"
                                                      name="parts_width"
                                                      value={part.parts_width}
                                                      onChange={(e) =>
                                                        handleInputChangePartList(
                                                          e,
                                                          part.id
                                                        )
                                                      }
                                                      placeholder="0"
                                                    />

                                                    {errors.parts_width && (
                                                      <span className="input-error">
                                                        {errors.parts_width}
                                                      </span>
                                                    )}
                                                  </div>

                                                  <div className="col-lg-3 addPartField">
                                                    <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                      Height(cm){" "}
                                                    </label>
                                                    <input
                                                      className="form-control input-search"
                                                      type="number"
                                                      name="parts_height"
                                                      value={part.parts_height}
                                                      onChange={(e) =>
                                                        handleInputChangePartList(
                                                          e,
                                                          part.id
                                                        )
                                                      }
                                                      placeholder="0"
                                                    />
                                                  </div>

                                                  <div className="col-lg-3 addPartField">
                                                    <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                      Length(cm){" "}
                                                    </label>
                                                    <input
                                                      className="form-control input-search"
                                                      type="number"
                                                      name="parts_length"
                                                      value={part.parts_length}
                                                      onChange={(e) =>
                                                        handleInputChangePartList(
                                                          e,
                                                          part.id
                                                        )
                                                      }
                                                      placeholder="0"
                                                    />
                                                  </div>
                                                </>
                                              )}
                                            </div> */}
                                            {/* {vehicleAddDetails.vehicle_id ===
                                              null && (
                                                <>
                                                  <div className="col-lg-12">
                                                    <label class="partsLabel col-form-label ml-2 label-my form-label">
                                                      Vehicle Modal
                                                    </label>
                                                    <div className="selectDisplay">
                                                      <div className="input-div SelectV selectA">
                                                        <div className="col-xl-12">
                                                          <input
                                                            className="form-control mt-2 input-search"
                                                            type="text"
                                                            maxLength={50}
                                                            placeholder="Search Vehicle Model"
                                                            value={vehicleModelAuto}
                                                            onChange={
                                                              handleChangeModel
                                                            }
                                                            onKeyDown={
                                                              handleKeyDownModel
                                                            }
                                                            onClick={(e) =>
                                                              e.stopPropagation()
                                                            }
                                                          />
                                                          {showSuggestionsModel &&
                                                            vehicleModelAuto && (
                                                              <ul className="suggestions">
                                                                {filteredSuggestionsModel?.length >
                                                                  0 ? (
                                                                  filteredSuggestionsModel.map(
                                                                    (
                                                                      suggestion,
                                                                      index
                                                                    ) => (
                                                                      <li
                                                                        key={
                                                                          suggestion
                                                                        }
                                                                        className={
                                                                          index ===
                                                                            activeSuggestionIndexModel
                                                                            ? "active"
                                                                            : ""
                                                                        }
                                                                        onClick={() =>
                                                                          handleClickModel(
                                                                            suggestion
                                                                          )
                                                                        }
                                                                      >
                                                                        {
                                                                          suggestion.model_name
                                                                        }
                                                                        /
                                                                        {
                                                                          suggestion.initial_year
                                                                        }
                                                                      </li>
                                                                    )
                                                                  )
                                                                ) : (
                                                                  <li>
                                                                    No suggestions
                                                                    available
                                                                  </li>
                                                                )}
                                                              </ul>
                                                            )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div
                                                      className="tableScroll vehicleThBg border-0"
                                                      style={{ height: "129px" }}
                                                    >
                                                      <div
                                                        style={{
                                                          borderCollapse: "separate",
                                                          borderSpacing: "0 15px",
                                                        }}
                                                        className="striped
                                                  bordered
                                                  hover"
                                                      >
                                                        <div className="container">
                                                          <div className="row row-cols-2 row-cols-lg-3 g-2 g-lg-3 justify-content-start">
                                                            {selectedModels?.length >
                                                              0 &&
                                                              selectedModels?.map(
                                                                (row, partIndex) => (
                                                                  <React.Fragment
                                                                    key={partIndex}
                                                                  >
                                                                    <div className="d-flex">
                                                                      <div className="lisatingTable mb-1 col vehicleModall">
                                                                        {
                                                                          row.model_name
                                                                        }{" "}
                                                                        (
                                                                        {
                                                                          row.initial_year
                                                                        }
                                                                        )
                                                                      </div>
                                                                      <Button
                                                                        style={{
                                                                          borderRadius:
                                                                            "0px",
                                                                          margin:
                                                                            "7px",
                                                                          height:
                                                                            "30px",
                                                                          width:
                                                                            "30px",
                                                                          color:
                                                                            "#dd4b4b",
                                                                          backgroundColor:
                                                                            "#ffe9e9",
                                                                          display:
                                                                            "flex",
                                                                          justifyContent:
                                                                            "center",
                                                                          alignItems:
                                                                            "center",
                                                                          border:
                                                                            "transparent",
                                                                          marginTop:
                                                                            "12px",
                                                                        }}
                                                                        className=""
                                                                        onClick={() =>
                                                                          setSelectedModels(
                                                                            selectedModels.filter(
                                                                              (m) =>
                                                                                m.id !==
                                                                                row.id
                                                                            )
                                                                          )
                                                                        }
                                                                      >
                                                                        <i className="bi bi-trash3"></i>
                                                                      </Button>
                                                                    </div>
                                                                  </React.Fragment>
                                                                )
                                                              )}
                                                          </div>
                                                        </div>
                                                        <div></div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              )} */}

                                            <div className="col-lg-12">
                                              <table className="table">
                                                {part?.addedData?.map(
                                                  (data, rowIndex) => {
                                                    return (
                                                      <>
                                                        <tr key={rowIndex}>
                                                          <td>
                                                            <div className="col-lg-12 addPartField">
                                                              <label className="partsLabel col-form-label ml-2">
                                                                Reference Code
                                                              </label>
                                                              <input
                                                                className="form-control input-search"
                                                                type="text"
                                                                name="reference_code"
                                                                value={
                                                                  data.reference_code
                                                                }
                                                                onChange={(e) =>
                                                                  handleUpdatePartRef(
                                                                    pIndex,
                                                                    rowIndex,
                                                                    "reference_code",
                                                                    e.target
                                                                      .value
                                                                  )
                                                                }
                                                                placeholder="Reference Code"
                                                              />
                                                            </div>
                                                          </td>

                                                          <td>
                                                            <div className="col-lg-12 addPartField">
                                                              <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                                Condition
                                                              </label>
                                                              <Select
                                                                className="my-Select"
                                                                options={
                                                                  conditionList
                                                                }
                                                                value={conditionList.find(
                                                                  (option) =>
                                                                    option.value ===
                                                                    Number(
                                                                      data.condition_id
                                                                    )
                                                                )}
                                                                onChange={(
                                                                  selectedOption
                                                                ) =>
                                                                  handleUpdatePartRef(
                                                                    pIndex,
                                                                    rowIndex,
                                                                    "condition_id",
                                                                    selectedOption?.value ||
                                                                      null
                                                                  )
                                                                }
                                                                isSearchable
                                                                placeholder="Select Condition"
                                                              />
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className="col-lg-12 addPartField">
                                                              <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                                Reference
                                                              </label>
                                                              <Select
                                                                className="my-Select"
                                                                options={
                                                                  referenceList
                                                                }
                                                                value={referenceList.find(
                                                                  (option) =>
                                                                    option.value ===
                                                                    Number(
                                                                      data.type_id
                                                                    )
                                                                )}
                                                                onChange={(
                                                                  selectedOption
                                                                ) =>
                                                                  handleUpdatePartRef(
                                                                    pIndex,
                                                                    rowIndex,
                                                                    "type_id",
                                                                    selectedOption?.value ||
                                                                      null
                                                                  )
                                                                }
                                                                isSearchable
                                                                placeholder="Select Type"
                                                              />
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className="col-lg-12 addPartField">
                                                              <label className="partsLabel col-form-label ml-2">
                                                                Is Main
                                                              </label>
                                                              <div className="form-check text-left">
                                                                <input
                                                                  className="form-check-input"
                                                                  type="checkbox"
                                                                  checked={
                                                                    data.is_main ===
                                                                    1
                                                                  }
                                                                  onChange={() =>
                                                                    handleUpdatePartRef(
                                                                      pIndex,
                                                                      rowIndex,
                                                                      "is_main",
                                                                      data.is_main ===
                                                                        0
                                                                        ? 1
                                                                        : 0
                                                                    )
                                                                  }
                                                                />
                                                              </div>
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className="col-lg-12 addPartField">
                                                              <label className="partsLabel col-form-label ml-2">
                                                                Action{" "}
                                                              </label>
                                                              <i
                                                                className="bi bi-pen-fill"
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                  marginRight:
                                                                    "10px",
                                                                }}
                                                                onClick={() =>
                                                                  handleEditPartRef(
                                                                    pIndex,
                                                                    rowIndex
                                                                  )
                                                                }
                                                              />
                                                              <i
                                                                class="bi bi-trash-fill"
                                                                onClick={() =>
                                                                  handleDeletePartRef(
                                                                    pIndex,
                                                                    rowIndex
                                                                  )
                                                                }
                                                              ></i>{" "}
                                                              {/* <button
                                                                  className="btn btn-danger"
                                                                  onClick={() =>
                                                                    handleDeletePartRef(
                                                                      pIndex,
                                                                      rowIndex
                                                                    )
                                                                  }
                                                                >
                                                                  Delete
                                                                </button> */}
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </table>
                                            </div>
                                          </Card.Body>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </React.Fragment>
                              );
                            })}
                          </div>
                          <div className="row mt-2">
                            <div className="col-sm-6">
                              <button
                                type="button"
                                className="back-btn bottomSpace"
                                onClick={() => navigate("/parts-inventory")}
                              >
                                <i class="bi bi-arrow-left"></i> Back
                              </button>
                            </div>

                            <div className="col-lg-6 btnTextAlign">
                              {btnSaveNext ? (
                                <button
                                  type="submit"
                                  disabled={true}
                                  className="btn btn-primary mr-2"
                                >
                                  Loading...
                                </button>
                              ) : (
                                <button
                                  type="submit"
                                  className="btn btn-primary mr-2"
                                >
                                  Save & Next
                                  <i class="bi bi-save ms-2"></i>
                                </button>
                              )}

                              <button
                                type="button"
                                className="btn btn-primary me-2"
                                onClick={handleNextPart}
                              >
                                Next
                                <i class="bi bi-arrow-right ms-2"></i>
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </>
                )}

                {currentStep === 2 && (
                  <>
                    <div className="card-body vehicleparts-table mt-3">
                      <div className="vehicleparts-table-inner">
                        <div className="row">
                          <>
                            <Form onSubmit={handleSubmits} className="row">
                              <React.Fragment>
                                <div className="col-lg-6">
                                  <Card className="text-center">
                                    <Card.Header className="cardHeader">
                                      <div className="d-flex">
                                        <div className="AddPh6">
                                          <h6>Part Information</h6>
                                        </div>
                                      </div>
                                    </Card.Header>
                                    <Card.Body
                                      className="cardBody"
                                      style={{ padding: "0" }}
                                    >
                                      <div class="container text-justify">
                                        <div
                                          class="row"
                                          style={{
                                            padding: "8px",
                                            borderBottom: "1px solid #eee",
                                          }}
                                        >
                                          <div class="col">Part Name</div>
                                          <div class="col">
                                            {currentPart?.part_name}
                                          </div>
                                        </div>
                                        <div
                                          class="row"
                                          style={{
                                            padding: "8px",
                                            borderBottom: "1px solid #eee",
                                          }}
                                        >
                                          <div class="col">
                                            Part Description
                                          </div>
                                          <div class="col">
                                            {currentPart?.part_description}
                                          </div>
                                        </div>

                                        <div
                                          class="row"
                                          style={{
                                            padding: "8px",
                                            borderBottom: "1px solid #eee",
                                          }}
                                        >
                                          <div class="col">Part Quantity</div>
                                          <div class="col">
                                            {currentPart?.quantity || "1"}
                                          </div>
                                        </div>
                                        <div
                                          class="row"
                                          style={{
                                            padding: "8px",
                                            borderBottom: "1px solid #eee",
                                          }}
                                        >
                                          <div class="col">Part Price</div>
                                          <div class="col">
                                            {currentPart?.part_price || 0}
                                          </div>
                                        </div>

                                        <div
                                          class="row"
                                          style={{
                                            padding: "8px",
                                            borderBottom: "1px solid #eee",
                                          }}
                                        >
                                          <div
                                            class="col"
                                            style={{ lineHeight: "18px" }}
                                          >
                                            Sponsor Price{" "}
                                          </div>
                                          <div class="col">
                                            {currentPart?.sponsor_price || 0}
                                          </div>
                                        </div>
                                      </div>
                                    </Card.Body>
                                  </Card>
                                </div>

                                <div className="col-lg-6">
                                  <Card className="text-center">
                                    <Card.Header className="cardHeader">
                                      <div className="d-flex">
                                        <div className="AddPh6">
                                          <h6>Upload Part Images</h6>
                                        </div>
                                      </div>
                                    </Card.Header>
                                    <Card.Body className="cardBody">
                                      <div className="row">
                                        <div className="col-lg-12 addPartField">
                                          <div className="col-lg-12 addPartField">
                                            <label className="partsLabel col-form-label ml-2 Label-my form-label form-label uploadImgs">
                                              Upload Images
                                              {/* {currentPart.part_name} */}
                                            </label>
                                            <div className="input-div">
                                              <div className="avatar-upload">
                                                <div className="avatar-edit">
                                                  <div className="input-div">
                                                    <input
                                                      ref={fileInputRef}
                                                      className="inputFile"
                                                      type="file"
                                                      id="imageuploadAbout"
                                                      onChange={
                                                        handleAboutFileChange
                                                      }
                                                      name="file"
                                                      accept=".jpg,.jpeg,.png"
                                                      multiple
                                                    />
                                                    {/* <i
                                                        style={{
                                                          position: "absolute",
                                                          top: "46px",
                                                          right: "12px",
                                                          fontSize: "20px",
                                                          color: "#81bb42",
                                                        }}
                                                        className="bi bi-eye"
                                                        onClick={
                                                          handleShowAbout
                                                        }
                                                      ></i> */}
                                                    <>
                                                      {vehicleImageDetails?.map(
                                                        (item, index) => {
                                                          return (
                                                            <div
                                                              key={index}
                                                              className="preview-item"
                                                            >
                                                              <img
                                                                src={
                                                                  item?.image_url
                                                                }
                                                                alt={`Preview ${index}`}
                                                                style={{
                                                                  width:
                                                                    "100px",
                                                                  margin: "5px",
                                                                }}
                                                              />
                                                              <div className="btn-Remove">
                                                                <i
                                                                  className="bi bi-x"
                                                                  style={{
                                                                    color:
                                                                      "red",
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  onClick={() =>
                                                                    handleRemoveFile(
                                                                      index,
                                                                      item.id
                                                                    )
                                                                  }
                                                                ></i>
                                                              </div>
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                    </>

                                                    {previewUrl.length > 0 &&
                                                    images.length > 0 ? (
                                                      <>
                                                        {images.map(
                                                          (
                                                            { file, id },
                                                            index
                                                          ) => (
                                                            <div className="partImages mt-2">
                                                              <div
                                                                key={id}
                                                                className="preview-item"
                                                              >
                                                                <div
                                                                  className="btn-Remove"
                                                                  style={{
                                                                    position:
                                                                      "absolute",
                                                                    width:
                                                                      "20px",
                                                                    height:
                                                                      "20px",
                                                                  }}
                                                                >
                                                                  <i
                                                                    className="bi bi-x"
                                                                    style={{
                                                                      color:
                                                                        "red",
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                    onClick={() =>
                                                                      handleRemoveFile(
                                                                        index
                                                                      )
                                                                    }
                                                                  ></i>
                                                                </div>
                                                                <img
                                                                  src={
                                                                    previewUrl[
                                                                      index
                                                                    ]
                                                                  }
                                                                  alt={`Preview ${index}`}
                                                                  style={{
                                                                    width:
                                                                      "100px",
                                                                    margin:
                                                                      "5px",
                                                                    height:
                                                                      "100px",
                                                                    border:
                                                                      "1px solid",
                                                                    borderRadius:
                                                                      "5px",
                                                                    maxWidth:
                                                                      "100px",
                                                                    maxHeight:
                                                                      "100px",
                                                                  }}
                                                                />
                                                              </div>
                                                            </div>
                                                          )
                                                        )}
                                                      </>
                                                    ) : null}
                                                  </div>
                                                  <label htmlFor="imageuploadAbout"></label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Card.Body>
                                  </Card>
                                </div>
                              </React.Fragment>
                              <div className="d-flex flex-row mt-3 finishBtn justify-content-between">
                                {/* {!isLastPart && (
                                  <Button
                                    className="me-2 mb-2 btn-primary"
                                    onClick={handleOpenSkip}
                                  >
                                    Skip & Next
                                  </Button>
                                )}

                                {!isLastPart && (
                                  <Button className="me-2 mb-2 btn-primary" type="submit">
                                    Save & Next
                                  </Button>
                                )} */}

                                {/* {isLastPart && ( */}
                                <div class="">
                                  <button
                                    type="button"
                                    class="back-btn bottomSpace"
                                    onClick={handleBackButtonClick}
                                  >
                                    <i class="bi bi-arrow-left"></i> Back
                                  </button>
                                </div>
                                <div>
                                  {btnImage ? (
                                    <Button
                                      className="me-2 mb-2 btn-primary"
                                      disabled={true}
                                    >
                                      Loading
                                    </Button>
                                  ) : (
                                    <Button
                                      className="me-2 mb-2 btn-Finish"
                                      onClick={handleFinish}
                                    >
                                      Save <i class="bi bi-save"></i>
                                    </Button>
                                  )}

                                  <Button
                                    className="me-2 mb-2 btn-Finish"
                                    onClick={() => navigate("/parts-inventory")}
                                  >
                                    Finish <i class="bi bi-check2"></i>
                                  </Button>
                                </div>
                                {/* )} */}
                                {/* {isLastPart && (
                                  <Button
                                    className="me-2 mb-2"
                                    onClick={() => navigate("/parts-inventory")}
                                  >
                                    Close
                                  </Button>
                                )} */}
                              </div>
                            </Form>
                          </>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                {currentStep === 1 && (
                  <>
                    <div className="d-flex">
                      <div className="mt-2">
                        <FormGroup>
                          <Label className="col-form-label ml-2">
                            {"Vehicle"}
                          </Label>
                        </FormGroup>
                      </div>
                      <div className="">
                        {vehicleModalSelectedList?.vehicle_in_inventory ===
                          true && (
                          <div className="mt-2 ms-3">
                            <Button
                              className="next-button"
                              type="button"
                              style={{ width: "134px" }}
                              onClick={onvehiclePartDetailsOpen}
                            >
                              Exceed Part
                              <i class="bi bi-tools"></i>
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-7">
                        <div className="card-body vehicleparts-table vehicleparts-table-h-100">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3  vehicleparts-input">
                                <div className="">
                                  <div className="">
                                    <input
                                      className="form-control input-search"
                                      type="text"
                                      value={searchQuery}
                                      onChange={handleSearchChange}
                                      maxLength={50}
                                      placeholder="Search"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="input-div">
                                <Select
                                  className="my-Select"
                                  options={VehicleModelAllList}
                                  value={
                                    VehicleModelAllList.find(
                                      (item) =>
                                        item.value ==
                                        vehicleModalSelectedList.vehicle_model_name
                                    ) || null
                                  }
                                  onChange={(selectedOption) => {
                                    setVehicleEngineId({
                                      ...vehicleModalSelectedList,
                                      vehicle_model_name: selectedOption
                                        ? selectedOption.value
                                        : "",
                                    });
                                    setVehicleModalSelectedList(selectedOption);
                                  }}
                                  isSearchable={true}
                                  onInputChange={handleInputChange}
                                  inputSelctedValue={inputValue}
                                  placeholder={"Select Vehicle"}
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div
                                className="col-md-6"
                                style={{ marginBottom: "10px" }}
                              >
                                <Select
                                  className="my-Select"
                                  options={DismetalList}
                                  value={
                                    DismetalList.find(
                                      (item) =>
                                        item.value ===
                                        dismentalData.dismantle_id
                                    ) || null
                                  }
                                  onChange={
                                    handleSelectWithoutChangeDismetalList
                                  }
                                  placeholder={"Select Dismental"}
                                  isSearchable={true}
                                  onInputChange={
                                    handleInputSelectedChangeDismetalList
                                  }
                                  inputSelctedValue={inputSelctedDismetalValue}
                                />{" "}
                              </div>

                              <div
                                className="col-md-6"
                                style={{ marginTop: "10px" }}
                              >
                                <div
                                  className="addBtn"
                                  style={{ cursor: "pointer" }}
                                  onClick={handleTempDismetalAddClick}
                                >
                                  <i className="bi bi-plus-lg"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="vehicleparts-table-inner vehicleThBg tableScroll">
                            <table className="rounded-table table table-striped table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th style={{ width: "10%" }}>Part Catalog</th>
                                  {vehicleModalSelectedList?.vehicle_model_id && (
                                    <th style={{ width: "10%" }}>Vehicle</th>
                                  )}
                                  <th style={{ width: "0%" }}>Action</th>
                                </tr>
                              </thead>

                              <tbody>
                                {(selectedModel?.value
                                  ? filteredCatalogList
                                  : CatalogList.data
                                )?.length > 0 ? (
                                  (selectedModel?.value
                                    ? filteredCatalogList
                                    : CatalogList.data
                                  ).map((item, index) => (
                                    <tr key={index}>
                                      <td>
                                        {LangData === "pt"
                                          ? item.namePartPT
                                          : LangData === "fr"
                                          ? item.namePartFR
                                          : LangData === "es"
                                          ? item.namePartES
                                          : LangData === "it"
                                          ? item.namePartIT
                                          : LangData === "de"
                                          ? item.namePartDE
                                          : item.namePartEN}{" "}
                                      </td>
                                      {vehicleModalSelectedList?.vehicle_model_id && (
                                        <td>
                                          {
                                            vehicleModalSelectedList?.vehicle_make_name
                                          }{" "}
                                          |{" "}
                                          {
                                            vehicleModalSelectedList?.vehicle_model_name
                                          }
                                          |{" "}
                                          {
                                            vehicleModalSelectedList?.registration_plate
                                          }{" "}
                                          |{" "}
                                          {
                                            vehicleModalSelectedList?.registration
                                          }
                                        </td>
                                      )}
                                      <td>
                                        <Button
                                          className="addBtn"
                                          onClick={() => handleAddClick(item)}
                                          disabled={
                                            !vehicleModalSelectedList?.vehicle_model_id
                                          }
                                        >
                                          <i className="bi bi-plus-lg"></i>
                                        </Button>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="100%">No results found</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-5">
                        <div className="card-body vehicleparts-table  vehicleparts-table-h-100">
                          <div className="mb-3  vehicleparts-input">
                            <input
                              className="form-control input-search"
                              type="text"
                              value={searchQueryTemp}
                              onChange={handleSearchChangeTemp}
                              maxLength={50}
                              placeholder="Search"
                            />
                          </div>
                          <div className="vehicleparts-table-inner vehicleThBg tableScroll">
                            <table className="rounded-table table table-striped table-bordered table-hover scrolldown">
                              <thead>
                                <tr>
                                  <th style={{ width: "24%" }}>Part Catalog</th>
                                  <th style={{ width: "10%" }}>
                                    Vehicle Model
                                  </th>

                                  <th style={{ width: "0%" }}>Action</th>
                                </tr>
                              </thead>

                              <tbody>
                                {selectedItems?.length > 0 ? (
                                  selectedItems?.map((item, index) => (
                                    <tr key={index}>
                                      <td>{item.namePartPT}</td>

                                      <td>{item?.vehicle_model_name}</td>

                                      <td>
                                        <Button
                                          className="btn-danger-delete"
                                          onClick={() =>
                                            handleDeleteClick(item)
                                          }
                                        >
                                          <i class="bi bi-trash3"></i>
                                        </Button>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="100%" className="text-center">
                                      No results found
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {tempData?.temp_id && (
                        <div className="col-lg-5">
                          <div className="fuelCF">
                            <div className="card-footer clearfix card-footer-part">
                              <div className="pagination pagination-sm m-0  paginationRight">
                                <Pagination
                                  activePage={params.page}
                                  itemsCountPerPage={parseInt(params.limit)}
                                  totalItemsCount={parseInt(totalRecords)}
                                  pageRangeDisplayed={5}
                                  itemClass={`page-item`}
                                  linkClass={`page-link`}
                                  onChange={(e) =>
                                    handleParams(e, "pagination")
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="row mt-2">
                      <div className="col">
                        <button
                          type="button"
                          className="back-btn"
                          onClick={handleBackButtonClick}
                        >
                          <i className="bi bi-arrow-left"></i> Back
                        </button>
                      </div>
                      <div className="d-flex justify-content-end col">
                        {btnNext ? (
                          <Button
                            className="btn-primary"
                            type="button"
                            disabled={true}
                          >
                            Loading...
                          </Button>
                        ) : (
                          <Button
                            className="next-button"
                            type="button"
                            onClick={(e) => handleNextClick(e)}
                            // disabled={
                            //   !vehiclePartsData?.vehicle_make &&
                            //   !tempData?.temp_id
                            // }
                          >
                            Next<i className="bi bi-arrow-right"></i>
                          </Button>
                        )}
                      </div>
                    </div>
                  </>
                )}

                {currentStep === 2 && (
                  <>
                    <div className="card-body vehicleparts-table mt-3">
                      <div className="vehicleparts-table-inner">
                        <Form method={`post`} onSubmit={handleSubmit}>
                          <div
                            className="row"
                            style={{ justifyContent: "flex-start" }}
                          >
                            {partsData?.map((part, pIndex) => {
                              return (
                                <React.Fragment key={pIndex}>
                                  <div className="col-lg-12" key={part?.id}>
                                    <div
                                      className="text-center cstm-scroll"
                                      style={{
                                        height: "674px",

                                        overflowY: "scroll",
                                        paddingBottom: "80px",
                                      }}
                                    >
                                      <Card.Header className="cardHeader partInfoHeader">
                                        <div
                                          className="partInfo"
                                          data-bs-toggle="collapse"
                                          href={`#multiCollapseExample-${part?.tempId}`}
                                          role="button"
                                          aria-expanded="true"
                                          aria-controls={`multiCollapseExample-${part?.tempId}`}
                                        >
                                          <div className="AddPh6">
                                            <h6>
                                              {part?.part_name} | {part?.id} |{" "}
                                              {part?.vehicle_make_name} |{" "}
                                              {part?.vehicle_model_name}
                                            </h6>
                                          </div>
                                          <div className="">
                                            {btnClosed ? (
                                              <button
                                                type="button"
                                                className="btnClose"
                                              >
                                                <i className="bi bi-arrow-repeat"></i>
                                              </button>
                                            ) : (
                                              <button
                                                type="button"
                                                className="btnClose"
                                                onClick={() =>
                                                  handleDeleteClick(part)
                                                }
                                              >
                                                <i className="bi bi-x-lg"></i>
                                              </button>
                                            )}
                                          </div>
                                        </div>
                                      </Card.Header>
                                      <div className="row">
                                        <div className="col " Reference Code>
                                          <Card.Body
                                            className="cardBody collapse multi-collapse show card"
                                            id={`multiCollapseExample-${part?.tempId}`}
                                          >
                                            <div className="row">
                                              <div className="col-lg-3 addPartField">
                                                <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                  Quantity
                                                </label>
                                                <input
                                                  className="form-control input-search"
                                                  type="number"
                                                  name="quantity"
                                                  value={part?.quantity}
                                                  onChange={(e) =>
                                                    handleInputChangePartLists(
                                                      e,
                                                      part?.tempId
                                                    )
                                                  }
                                                  placeholder="0"
                                                />

                                                {errors.quantity && (
                                                  <span className="input-error">
                                                    {errors.quantity}
                                                  </span>
                                                )}
                                              </div>

                                              <div className="col-lg-3 addPartField">
                                                <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                  Price 1
                                                </label>
                                                <input
                                                  className="form-control input-search"
                                                  type="number"
                                                  name="part_price"
                                                  value={part?.part_price}
                                                  onChange={(e) =>
                                                    handleInputChangePartLists(
                                                      e,
                                                      part?.tempId
                                                    )
                                                  }
                                                  placeholder="0"
                                                />

                                                {errors.part_price && (
                                                  <span className="input-error">
                                                    {errors.part_price}
                                                  </span>
                                                )}
                                              </div>
                                              <div className="col-lg-3 addPartField">
                                                <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                  Price 2 (
                                                  {
                                                    settingDetails?.discount_percentage
                                                  }
                                                  % discount)
                                                </label>
                                                <input
                                                  className="form-control input-search"
                                                  type="number"
                                                  name="part_original_price"
                                                  value={
                                                    part?.part_original_price
                                                  }
                                                  onChange={(e) =>
                                                    handleInputChangePartLists(
                                                      e,
                                                      part?.tempId
                                                    )
                                                  }
                                                  placeholder="0"
                                                />
                                              </div>

                                              <div className="col-lg-3 addPartField">
                                                <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                  Part Cost Price
                                                </label>
                                                <input
                                                  className="form-control input-search"
                                                  type="number"
                                                  name="part_cost_price"
                                                  value={part?.part_cost_price}
                                                  onChange={(e) =>
                                                    handleInputChangePartLists(
                                                      e,
                                                      part?.tempId
                                                    )
                                                  }
                                                  placeholder="0"
                                                />
                                              </div>

                                              <div className="col-lg-3 addPartField">
                                                <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                  Inventory Status
                                                </label>
                                                <Select
                                                  className="my-Select"
                                                  options={inventoryList}
                                                  name="inventory_status"
                                                  value={
                                                    inventoryList.find(
                                                      (item) =>
                                                        item.value ===
                                                        partsData[pIndex]
                                                          ?.inventory_status
                                                    ) ||
                                                    inventoryList.find(
                                                      (item) => item.value === 1
                                                    )
                                                  }
                                                  onChange={(selectedOption) =>
                                                    handleInventoryIdChange(
                                                      pIndex,
                                                      selectedOption
                                                    )
                                                  }
                                                  placeholder={
                                                    "Select Inventory Status"
                                                  }
                                                  isSearchable={true}
                                                ></Select>
                                              </div>

                                              <div className="col-lg-3 addPartField">
                                                <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                  Condition
                                                </label>
                                                <Select
                                                  className="my-Select"
                                                  options={conditionList}
                                                  value={
                                                    conditionList.find(
                                                      (item) =>
                                                        item.value ===
                                                        partsData[pIndex]
                                                          ?.condition_id
                                                    ) ||
                                                    conditionList.find(
                                                      (item) =>
                                                        item.value ===
                                                        settingDetails?.condition_id
                                                    ) ||
                                                    conditionList.find(
                                                      (item) => item.value === 1
                                                    )
                                                  }
                                                  onChange={(selectedOption) =>
                                                    handleConditionChange(
                                                      pIndex,
                                                      selectedOption
                                                    )
                                                  }
                                                  isSearchable
                                                  placeholder={
                                                    "Select Condition"
                                                  }
                                                ></Select>
                                              </div>

                                              <div className="col-lg-3 addPartField">
                                                <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                  State
                                                </label>
                                                <Select
                                                  className="my-Select"
                                                  options={stateList}
                                                  value={
                                                    stateList.find(
                                                      (item) =>
                                                        item.value ===
                                                        partsData[pIndex]
                                                          ?.state_id
                                                    ) ||
                                                    stateList.find(
                                                      (item) =>
                                                        item.value ===
                                                        settingDetails?.state_id
                                                    ) ||
                                                    stateList.find(
                                                      (item) => item.value === 1
                                                    )
                                                  }
                                                  onChange={(selectedOption) =>
                                                    handleStateListChange(
                                                      pIndex,
                                                      selectedOption
                                                    )
                                                  }
                                                  isSearchable
                                                  placeholder={
                                                    "Select Condition"
                                                  }
                                                ></Select>
                                              </div>
                                              <div className="col-lg-3 addPartField">
                                                <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                  Part Brand
                                                </label>
                                                <Select
                                                  className="my-Select"
                                                  options={allBrandList}
                                                  name="brand_id"
                                                  value={allBrandList.find(
                                                    (option) =>
                                                      option.value ===
                                                      part?.brand_id
                                                  )}
                                                  onChange={(selectedOption) =>
                                                    handleBrandIdChange(
                                                      pIndex,
                                                      selectedOption
                                                    )
                                                  }
                                                  placeholder={
                                                    "Select Part Brand"
                                                  }
                                                  isSearchable={true}
                                                ></Select>
                                              </div>

                                              <div className="col-lg-3 addPartField offset-lg-3">
                                                <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                  Notes
                                                </label>
                                                <textarea
                                                  className="form-control input-search"
                                                  type="text"
                                                  name="part_description"
                                                  value={part?.part_description}
                                                  onChange={(e) =>
                                                    handleInputChangePartList(
                                                      e,
                                                      part?.id
                                                    )
                                                  }
                                                  placeholder="Notes"
                                                  rows={3}
                                                />
                                              </div>

                                              <div className="col-lg-3 addPartField">
                                                <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                  Custome Size
                                                </label>

                                                <div className="col-lg-12 addPartField">
                                                  <div className="form-check text-left">
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      checked={
                                                        part?.is_custom_size ===
                                                        "1"
                                                      }
                                                      onChange={(e) =>
                                                        handleCheckboxCustomChange(
                                                          pIndex,
                                                          e
                                                        )
                                                      }
                                                    />
                                                    <label className="form-check-label">
                                                      Custome Size
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                              {part?.is_custom_size === "1" && (
                                                <>
                                                  <div className="col-lg-3 addPartField">
                                                    <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                      Weight (kg){" "}
                                                    </label>
                                                    <input
                                                      className="form-control input-search"
                                                      type="number"
                                                      name="parts_weight"
                                                      value={part?.parts_weight}
                                                      onChange={(e) =>
                                                        handleInputChangePartList(
                                                          e,
                                                          part?.id
                                                        )
                                                      }
                                                      placeholder="0"
                                                    />
                                                  </div>

                                                  <div className="col-lg-3 addPartField">
                                                    <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                      Width(cm){" "}
                                                    </label>
                                                    <input
                                                      className="form-control input-search"
                                                      type="number"
                                                      name="parts_width"
                                                      value={part?.parts_width}
                                                      onChange={(e) =>
                                                        handleInputChangePartList(
                                                          e,
                                                          part?.id
                                                        )
                                                      }
                                                      placeholder="0"
                                                    />

                                                    {errors.parts_width && (
                                                      <span className="input-error">
                                                        {errors.parts_width}
                                                      </span>
                                                    )}
                                                  </div>

                                                  <div className="col-lg-3 addPartField">
                                                    <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                      Height(cm){" "}
                                                    </label>
                                                    <input
                                                      className="form-control input-search"
                                                      type="number"
                                                      name="parts_height"
                                                      value={part?.parts_height}
                                                      onChange={(e) =>
                                                        handleInputChangePartList(
                                                          e,
                                                          part?.id
                                                        )
                                                      }
                                                      placeholder="0"
                                                    />
                                                  </div>

                                                  <div className="col-lg-3 addPartField">
                                                    <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                      Length(cm){" "}
                                                    </label>
                                                    <input
                                                      className="form-control input-search"
                                                      type="number"
                                                      name="parts_length"
                                                      value={part?.parts_length}
                                                      onChange={(e) =>
                                                        handleInputChangePartList(
                                                          e,
                                                          part?.id
                                                        )
                                                      }
                                                      placeholder="0"
                                                    />
                                                  </div>
                                                </>
                                              )}
                                              <div className="col-lg-3 addPartField">
                                                <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                  Print label
                                                </label>
                                                <div className="col-lg-12 addPartField">
                                                  <div className="form-check text-left">
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      checked={
                                                        part?.print_label === 1
                                                      }
                                                      onChange={(e) =>
                                                        handleCheckboxPrintChanges(
                                                          pIndex,
                                                          e
                                                        )
                                                      }
                                                    />
                                                    <label className="form-check-label">
                                                      Print label
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="col-lg-3 addPartField">
                                                <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                  VAT included{" "}
                                                </label>
                                                <div className="col-lg-12 addPartField">
                                                  <div className="form-check text-left">
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      checked={
                                                        part?.vat_included === 1
                                                      }
                                                      onChange={(e) =>
                                                        handleCheckboxVatChanges(
                                                          pIndex,
                                                          e
                                                        )
                                                      }
                                                    />
                                                    <label className="form-check-label">
                                                      VAT included{" "}
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>

                                              {part?.state_id === 2 && (
                                                <div className="col-lg-3 addPartField col-lg-3-mob">
                                                  <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                    Reg Number Dismantler
                                                  </label>
                                                  <input
                                                    className="form-control input-search"
                                                    type="text"
                                                    name="reg_number_dismantler"
                                                    value={
                                                      settingDetails?.reg_number_dismantler
                                                        ? settingDetails?.reg_number_dismantler
                                                        : part?.reg_number_dismantler
                                                    }
                                                    onChange={(e) =>
                                                      handleInputChangePartList(
                                                        e,
                                                        part?.tempId
                                                      )
                                                    }
                                                    placeholder="0"
                                                  />
                                                </div>
                                              )}

                                              <div
                                                className="col-lg-12"
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "end",
                                                  marginBottom: "10px",
                                                }}
                                              >
                                                {part?.etai_part_id && (
                                                  <>
                                                    <button
                                                      style={{
                                                        marginTop: "8px",
                                                        marginRight: "10px",
                                                      }}
                                                      type="button"
                                                      className="btn btn-primary"
                                                      onClick={(e) =>
                                                        handleEtaiDetails(
                                                          e,
                                                          part?.etai_part_id,
                                                          pIndex
                                                        )
                                                      }
                                                    >
                                                      View Reference
                                                    </button>
                                                  </>
                                                )}
                                              </div>
                                              <div className="col-lg-3 addPartField"></div>

                                              <div className="col-lg-3 addPartField"></div>

                                              {part?.is_show_engine_record ===
                                                true && (
                                                <>
                                                  <div className="col-lg-3 addPartField">
                                                    <div className="col-md-12">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        Engine Code{" "}
                                                      </label>
                                                      <Select
                                                        className="my-Select"
                                                        options={
                                                          vehicleEngineList
                                                        }
                                                        value={vehicleEngineList.find(
                                                          (item) =>
                                                            item.value ===
                                                            EngineData?.vehicle_variant
                                                        )}
                                                        onChange={
                                                          handleSelectWithoutChangeEngineList
                                                        }
                                                        placeholder={
                                                          "Select Engine"
                                                        }
                                                        isSearchable={true}
                                                        onInputChange={
                                                          handleInputSelectedChangeEngineList
                                                        }
                                                        inputSelctedValue={
                                                          inputSelctedEngineValue
                                                        }
                                                      />
                                                    </div>
                                                  </div>

                                                  <div className="col-lg-3 addPartField">
                                                    <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                      Cv{" "}
                                                    </label>
                                                    <input
                                                      className="form-control input-search"
                                                      type="text"
                                                      name="cv"
                                                      value={part?.cv}
                                                      onChange={(e) => {
                                                        handleInputChangePartList(
                                                          e,
                                                          part?.tempId
                                                        );
                                                      }}
                                                      placeholder="0"
                                                    />
                                                  </div>

                                                  <div className="col-lg-3 addPartField">
                                                    <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                      CC
                                                    </label>
                                                    <input
                                                      className="form-control input-search"
                                                      type="text"
                                                      name="cc"
                                                      value={part?.cc}
                                                      onChange={(e) => {
                                                        handleInputChangePartList(
                                                          e,
                                                          part?.tempId
                                                        );
                                                      }}
                                                      placeholder="0"
                                                    />
                                                  </div>

                                                  <div className="col-lg-3 addPartField">
                                                    <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                      KW
                                                    </label>
                                                    <input
                                                      className="form-control input-search"
                                                      type="text"
                                                      name="kw"
                                                      value={part?.kw}
                                                      onChange={(e) => {
                                                        handleInputChangePartList(
                                                          e,
                                                          part?.tempId
                                                        );
                                                      }}
                                                      placeholder="0"
                                                    />
                                                  </div>
                                                </>
                                              )}

                                              <div
                                                className="col-lg-12"
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "end",
                                                  marginBottom: "10px",
                                                }}
                                              >
                                                {part?.etai_part_id && (
                                                  <>
                                                    <button
                                                      style={{
                                                        marginTop: "8px",
                                                        marginRight: "10px",
                                                      }}
                                                      type="button"
                                                      className="btn btn-primary"
                                                      onClick={(e) =>
                                                        handleEtaiDetails(
                                                          e,
                                                          part?.etai_part_id,
                                                          pIndex
                                                        )
                                                      }
                                                    >
                                                      View Reference
                                                    </button>

                                                    <button
                                                      style={{
                                                        marginTop: "8px",
                                                      }}
                                                      type="button"
                                                      className="btn btn-primary"
                                                      onClick={(e) =>
                                                        handleEtaiPriceDetails(
                                                          e,
                                                          part?.etai_part_id,
                                                          pIndex
                                                        )
                                                      }
                                                    >
                                                      View Price
                                                    </button>
                                                  </>
                                                )}
                                              </div>
                                              <div className="col-lg-12">
                                                <table className="table">
                                                  <thead>
                                                    <tr>
                                                      <th scope="col">
                                                        <div className="col-lg-12 addPartField">
                                                          <label className="partsLabel col-form-label ml-2">
                                                            Reference Code
                                                          </label>
                                                          <input
                                                            className="form-control"
                                                            type="text"
                                                            name="reference_code"
                                                            value={
                                                              selectedReferenceCode
                                                                ? selectedReferenceCode
                                                                : part?.reference_code
                                                            }
                                                            onChange={(e) =>
                                                              handleInputChangePartListRef(
                                                                e,
                                                                pIndex
                                                              )
                                                            }
                                                            placeholder="Reference Code"
                                                          />
                                                        </div>
                                                      </th>

                                                      <th scope="col">
                                                        <div className="col-lg-12 addPartField">
                                                          <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                            Condition
                                                          </label>
                                                          <Select
                                                            className="my-Select"
                                                            options={
                                                              conditionList
                                                            }
                                                            value={
                                                              conditionList.find(
                                                                (option) =>
                                                                  option.value ===
                                                                    part?.condition_id_ref ||
                                                                  option.label ===
                                                                    part?.selectedCondition
                                                              ) || null
                                                            }
                                                            onChange={(
                                                              selectedOption
                                                            ) =>
                                                              handleConditionSChangeRefs(
                                                                pIndex,
                                                                selectedOption
                                                              )
                                                            }
                                                            isSearchable
                                                            placeholder={
                                                              "Select Condition"
                                                            }
                                                          ></Select>
                                                        </div>
                                                      </th>
                                                      <th scope="col">
                                                        <div className="col-lg-12 addPartField">
                                                          <label className="partsLabel col-form-label ml-2">
                                                            Reference Type
                                                          </label>
                                                          <Select
                                                            className="my-Select"
                                                            options={
                                                              referenceList
                                                            }
                                                            value={
                                                              referenceList.find(
                                                                (option) =>
                                                                  option.value ===
                                                                    part?.type_id ||
                                                                  option.label ===
                                                                    part?.selectedType
                                                              ) || null
                                                            }
                                                            onChange={(
                                                              selectedOption
                                                            ) =>
                                                              handleConditionChangeRef(
                                                                pIndex,
                                                                selectedOption
                                                              )
                                                            }
                                                            isSearchable
                                                            placeholder="Select Type"
                                                          />
                                                        </div>
                                                      </th>
                                                      <th scope="col"></th>
                                                      <th>
                                                        <button
                                                          style={{
                                                            marginTop: "8px",
                                                          }}
                                                          type="button"
                                                          className="btn btn-primary"
                                                          onClick={() =>
                                                            handleAddPartRef(
                                                              pIndex
                                                            )
                                                          }
                                                        >
                                                          Add Reference{" "}
                                                        </button>
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {part?.addedData?.map(
                                                      (data, rowIndex) => {
                                                        return (
                                                          <>
                                                            <tr key={rowIndex}>
                                                              <td>
                                                                {/* <input
                                                                  className="form-control"
                                                                  type="text"
                                                                  value={
                                                                    data.reference_code
                                                                  }
                                                                  readOnly
                                                                /> */}

                                                                <div className="col-lg-12 addPartField">
                                                                  <label className="partsLabel col-form-label ml-2">
                                                                    Reference
                                                                    Code
                                                                  </label>
                                                                  <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="reference_code"
                                                                    value={
                                                                      data?.reference_code
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleUpdatePartRef(
                                                                        pIndex,
                                                                        rowIndex,
                                                                        "reference_code",
                                                                        e.target
                                                                          .value
                                                                      )
                                                                    }
                                                                    placeholder="Reference Code"
                                                                  />
                                                                </div>
                                                              </td>

                                                              <td>
                                                                <label className="partsLabel col-form-label ml-2">
                                                                  Condition
                                                                </label>
                                                                <Select
                                                                  className="my-Select"
                                                                  options={
                                                                    conditionList
                                                                  }
                                                                  value={conditionList.find(
                                                                    (option) =>
                                                                      option.value ===
                                                                      data?.condition_id_ref
                                                                  )}
                                                                  onChange={(
                                                                    selectedOption
                                                                  ) =>
                                                                    handleUpdatePartRef(
                                                                      pIndex,
                                                                      rowIndex,
                                                                      "condition_id_ref",
                                                                      selectedOption?.value ||
                                                                        null
                                                                    )
                                                                  }
                                                                  isSearchable
                                                                  placeholder="Select Condition"
                                                                />
                                                              </td>
                                                              <td>
                                                                <label className="partsLabel col-form-label ml-2">
                                                                  Reference Type
                                                                </label>
                                                                <Select
                                                                  className="my-Select"
                                                                  options={
                                                                    referenceList
                                                                  }
                                                                  value={referenceList.find(
                                                                    (option) =>
                                                                      option.value ===
                                                                      data?.type_id
                                                                  )}
                                                                  onChange={(
                                                                    selectedOption
                                                                  ) =>
                                                                    handleUpdatePartRef(
                                                                      pIndex,
                                                                      rowIndex,
                                                                      "type_id",
                                                                      selectedOption?.value ||
                                                                        null
                                                                    )
                                                                  }
                                                                  isSearchable
                                                                  placeholder="Select Type"
                                                                />
                                                              </td>
                                                              <td>
                                                                <div className="form-check text-left">
                                                                  <label className="partsLabel col-form-label ml-2">
                                                                    Is Main
                                                                  </label>
                                                                  <input
                                                                    style={{
                                                                      marginLeft:
                                                                        "20px",
                                                                    }}
                                                                    className="form-check-input"
                                                                    type="radio" // Changed from checkbox to radio
                                                                    name={`isMainRadioGroup-${pIndex}-${rowIndex}`} // Unique name for each radio button group (to handle multiple groups)
                                                                    checked={
                                                                      data?.is_main ===
                                                                      1
                                                                    }
                                                                    onChange={() =>
                                                                      handleUpdatePartRef(
                                                                        pIndex,
                                                                        rowIndex,
                                                                        "is_main",
                                                                        1
                                                                      )
                                                                    }
                                                                  />
                                                                </div>
                                                              </td>
                                                              <td>
                                                                <label className="partsLabel col-form-label ml-2">
                                                                  Action{" "}
                                                                </label>
                                                                <i
                                                                  className="bi bi-pen-fill"
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                    marginRight:
                                                                      "10px",
                                                                  }}
                                                                  onClick={() =>
                                                                    handleEditPartRef(
                                                                      pIndex,
                                                                      rowIndex
                                                                    )
                                                                  }
                                                                />
                                                                <i
                                                                  class="bi bi-trash-fill"
                                                                  onClick={() =>
                                                                    handleDeletePartRef(
                                                                      pIndex,
                                                                      rowIndex
                                                                    )
                                                                  }
                                                                ></i>{" "}
                                                                {/* <button
                                                                  className="btn btn-danger"
                                                                  onClick={() =>
                                                                    handleDeletePartRef(
                                                                      pIndex,
                                                                      rowIndex
                                                                    )
                                                                  }
                                                                >
                                                                  Delete
                                                                </button> */}
                                                              </td>
                                                            </tr>
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </Card.Body>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </React.Fragment>
                              );
                            })}
                          </div>
                          <div className="row">
                            <div class="col-lg-6">
                              <button
                                type="button"
                                class="next-button btn btn-secondary"
                                onClick={handleBackButtonClick}
                              >
                                <i class="bi bi-arrow-left"></i> Back
                              </button>
                            </div>

                            <div class="col-lg-6 saveasLBtns">
                              {btnLoader === true ? (
                                <button
                                  type="button"
                                  disabled={true}
                                  className="btn btn-primary"
                                >
                                  Loading...
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={handleSubmitAsPrintLabel}
                                >
                                  Save As Label <i className="bi bi-save" />
                                </button>
                              )}

                              <div>
                                {btnLoading === true ? (
                                  <button
                                    type="button"
                                    disabled={true}
                                    className="btn btn-primary"
                                  >
                                    Loading...
                                  </button>
                                ) : (
                                  <button
                                    type="submit"
                                    class="btn btn-primary addPArtsSave"
                                  >
                                    Save <i className="bi bi-save ms-2"></i>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </Form>
                        {/* <div className="card-footer clearfix card-footer-part">
                          <div className="pagination pagination-sm m-0 float-right">
                            <Pagination
                              activePage={params.page}
                              itemsCountPerPage={parseInt(params.limit)}
                              totalItemsCount={parseInt(totalRecords)}
                              pageRangeDisplayed={5}
                              itemClass={`page-item`}
                              linkClass={`page-link`}
                              onChange={(e) => handleParams(e, "pagination")}
                            />
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </>
                )}

                {currentStep === 3 && (
                  <>
                    <div className="card-body vehicleparts-table mt-3">
                      <div className="vehicleparts-table-inner">
                        <div className="row">
                          <>
                            <Form onSubmit={handleSubmits} className="row">
                              <React.Fragment>
                                <div className="col-lg-6">
                                  <Card className="text-center">
                                    <Card.Header className="cardHeader">
                                      <div className="d-flex">
                                        <div className="AddPh6">
                                          <h6>Part Information</h6>
                                        </div>
                                      </div>
                                    </Card.Header>
                                    <Card.Body
                                      className="cardBody"
                                      style={{ padding: "0" }}
                                    >
                                      <div class="container text-justify">
                                        <div class="row pt-2 pb-2 border-bottom">
                                          <div class="col">Part Name</div>
                                          <div class="col">
                                            {currentPart?.part_name}
                                          </div>
                                        </div>
                                        <div class="row pt-2 pb-2 border-bottom">
                                          <div class="col">
                                            Part Description
                                          </div>
                                          <div class="col">
                                            {currentPart?.part_description}
                                          </div>
                                        </div>

                                        <div class="row pt-2 pb-2 border-bottom">
                                          <div class="col">Part Quantity</div>
                                          <div class="col">
                                            {currentPart?.quantity}
                                          </div>
                                        </div>
                                        <div class="row pt-2 pb-2 border-bottom">
                                          <div class="col">Part Price</div>
                                          <div class="col">
                                            {currentPart?.part_price}
                                          </div>
                                        </div>
                                      </div>
                                    </Card.Body>
                                  </Card>
                                </div>

                                <div className="col-lg-6">
                                  <Card className="text-center">
                                    <Card.Header className="cardHeader">
                                      <div className="d-flex">
                                        <div className="AddPh6">
                                          <h6>Upload Part Image</h6>
                                        </div>
                                      </div>
                                    </Card.Header>
                                    <Card.Body className="cardBody">
                                      <div className="row">
                                        <div className="col-lg-12 addPartField">
                                          <div className="col-lg-12 addPartField">
                                            <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                              Upload Images
                                              {/* {currentPart.part_name} */}
                                            </label>
                                            <div className="input-div">
                                              <div className="avatar-upload">
                                                <div className="avatar-edit">
                                                  <div className="input-div">
                                                    <input
                                                      ref={fileInputRef}
                                                      className="inputFile"
                                                      type="file"
                                                      id="imageuploadAbout"
                                                      onChange={
                                                        handleAboutFileChange
                                                      }
                                                      name="file"
                                                      accept=".jpg,.jpeg,.png"
                                                      multiple
                                                    />
                                                    <div className="partImages mt-2">
                                                      {images?.map(
                                                        (
                                                          { file, id },
                                                          index
                                                        ) => (
                                                          <div
                                                            key={id}
                                                            className="preview-item"
                                                          >
                                                            <div
                                                              className="btn-Remove"
                                                              style={{
                                                                position:
                                                                  "absolute",
                                                                width: "20px",
                                                                height: "20px",
                                                              }}
                                                            >
                                                              <i
                                                                className="bi bi-x"
                                                                style={{
                                                                  color: "red",
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                onClick={() =>
                                                                  handleRemoveFile(
                                                                    index
                                                                  )
                                                                }
                                                              ></i>
                                                            </div>
                                                            <img
                                                              onClick={() =>
                                                                toggleEnlarge(
                                                                  index
                                                                )
                                                              }
                                                              className={`image ${
                                                                enlargedIndexes.includes(
                                                                  index
                                                                )
                                                                  ? "enlarged"
                                                                  : ""
                                                              }`}
                                                              src={
                                                                previewUrl[
                                                                  index
                                                                ]
                                                              }
                                                              alt={`Preview ${index}`}
                                                              style={{
                                                                width: "100px",
                                                                margin: "5px",
                                                                height: "100px",
                                                                border:
                                                                  "1px solid",
                                                                borderRadius:
                                                                  "5px",
                                                                maxWidth:
                                                                  "100px",
                                                                maxHeight:
                                                                  "100px",
                                                              }}
                                                            />
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                  </div>
                                                  <label htmlFor="imageuploadAbout"></label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Card.Body>
                                  </Card>
                                </div>
                              </React.Fragment>
                              <div className="addPartsBtns flex-row mt-3 justify-content-end">
                                {!isLastPart && (
                                  <Button
                                    className="me-2 mb-2 btn-primary"
                                    onClick={handleOpenSkip}
                                  >
                                    Skip & Next
                                  </Button>
                                )}

                                {!isLastPart && (
                                  <Button
                                    className="me-2 mb-2 btn-primary"
                                    type="submit"
                                  >
                                    Save & Next
                                  </Button>
                                )}

                                {isLastPart && (
                                  <>
                                    {btnImageSave ? (
                                      <Button
                                        className="mb-2 btn-primary"
                                        disabled={true}
                                      >
                                        Loading...
                                      </Button>
                                    ) : (
                                      <Button
                                        className="mb-2 next-button"
                                        onClick={handleFinish}
                                        style={{ marginRight: "10px" }}
                                      >
                                        Save <i class="bi bi-save ms-2"></i>
                                      </Button>
                                    )}

                                    <Button
                                      className="mb-2 next-button"
                                      onClick={() =>
                                        navigate("/parts-inventory")
                                      }
                                    >
                                      Finish <i class="bi bi-check2"></i>
                                    </Button>
                                  </>
                                )}
                              </div>
                            </Form>
                          </>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>

        <Modal isOpen={modifyMasterPart} size="lg">
          <ModalHeader>
            {categoryMasterPart ? categoryMasterPart?.label : ""}
          </ModalHeader>

          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-12">
                  <FormGroup>
                    {categoryMasterPart?.part_list?.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <input
                            type="checkbox"
                            checked={checkedItems.includes(item)}
                            onChange={() => handleCheck(item)}
                          />
                        </td>
                        <td>
                          <div className="input-div SelectV">
                            <div className="col-md-12">{item?.namePartPT}</div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </FormGroup>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-block btn-secondary"
              onClick={handleMasterPartClose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-block btn-primary"
              style={{ width: "100px", marginTop: "10px" }}
              onClick={handleModalSubmit}
            >
              Submit
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={showModalAbout}>
          <ModalHeader>"Part Image"</ModalHeader>
          <ModalBody>
            <div className="preview-container">
              {images.map(({ file, id }, index) => (
                <div key={id} className="preview-item">
                  <img
                    src={previewUrl[index]}
                    alt={`Preview ${index}`}
                    style={{ width: "100px", margin: "5px" }}
                  />
                  <div className="btn-Remove">
                    <i
                      className="bi bi-x"
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={() => handleRemoveFile(index)}
                    ></i>
                  </div>
                </div>
              ))}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button variant="secondary" onClick={handleCloseAbout}>
              Close
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={showSkip}>
          <ModalHeader></ModalHeader>
          <ModalBody>
            <div className="row">
              Are you sure you want to skip this part? You won't be able to
              upload images for this part. Are you sure you want to skip this
              part? You won't be able to upload images for this part.
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-block btn-primary"
              onClick={handleCloseSkip}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-x-lg"></i>
            </button>

            <button
              type="button"
              className="btn btn-block btn-primary"
              onClick={handleSkip}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Yes <i class="bi bi-x-lg"></i>
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={vehiclePartDetailsModal}>
          <ModalHeader>{`Part Details`}</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="vehicleThBg table-responsive">
                  <table className="rounded-table table table-striped table-bordered table-hover">
                    <thead>
                      <tr>
                        <th style={{ width: "10%" }}>Part Name</th>

                        <th style={{ width: "10%" }}>Part quantity</th>

                        <th style={{ width: "0%" }}>Part Price</th>
                        <th style={{ width: "0%" }}>Part Original Price</th>
                        <th style={{ width: "0%" }}>Part Code</th>
                      </tr>
                    </thead>

                    <tbody>
                      {vehicleExceedData?.length > 0 ? (
                        vehicleExceedData?.map((item, index) => (
                          <tr key={index}>
                            <td>{item.part_name}</td>
                            <td>{item.quantity}</td>
                            <td>{item.part_price}</td>
                            <td>{item.part_original_price}</td>
                            <td>{item.part_code}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="100%">No results found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-block btn-primary"
              onClick={onvehiclePartDetailsClose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-x-lg"></i>
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modifyExpiry}>
          <ModalHeader>{`Vehicle Expiry Date`}</ModalHeader>

          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-12">
                  <FormGroup>
                    <Label className="col-form-label">{"Expiry Date"}</Label>
                    <div className="col-xl-12">
                      <div className="input-div">
                        <input
                          type="date"
                          id="date"
                          className="form-control"
                          value={vehicleData?.date}
                          onChange={(e) => {
                            setVehicleData({
                              ...vehicleData,
                              date: e.target.value,
                            });
                          }}
                          placeholder="Expiry Date"
                        />
                      </div>
                    </div>
                  </FormGroup>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {/* <button
              type="button"
              className="btn btn-block btn-primary"
              onClick={ExpiryModalclose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i className="bi bi-x-lg"></i>
            </button> */}

            <button
              type="button"
              className="btn btn-block btn-primary"
              onClick={ExpiryModalNextclose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Submit <i class="bi bi-check2"></i>
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={partEtaiModify}>
          <ModalHeader>{`Reference Code`}</ModalHeader>

          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-12">
                  <FormGroup>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Reference Code</th>
                          <th>Product Label</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {partETAIRefDetails?.map((item, index) => (
                          <tr key={index}>
                            <td>{item?.reference_code}</td>
                            <td>{item?.product_label}</td>
                            <td>
                              <input
                                type="radio"
                                className="form-check-input"
                                name="radio-select" // Group all radio buttons with the same name
                                checked={
                                  selectedReferenceCode === item?.reference_code
                                } // Check if the current item is selected
                                onChange={() =>
                                  handleRadioChange(item?.reference_code)
                                } // Set the selected reference code when changed
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </FormGroup>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-block btn-primary"
              onClick={handlePartEtaiClose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Submit <i class="bi bi-check2"></i>
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={partEtaiPriceModify}>
          <ModalHeader>{`Price`}</ModalHeader>

          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-12">
                  <FormGroup>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Price</th>
                          <th>Product Label</th>
                        </tr>
                      </thead>
                      <tbody>
                        {partETAIPriceDetail &&
                        partETAIPriceDetail.length > 0 ? (
                          partETAIPriceDetail.map((item, index) => (
                            <tr key={index}>
                              <td>{item?.source}</td>
                              <td>{item?.label}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="2" className="text-center">
                              No record found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </FormGroup>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-block btn-primary"
              onClick={handlePartEtaiPriceClose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-check2"></i>
            </button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default VehiclePartsAdd;
