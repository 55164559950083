import axiosInstance from "./AxiosInstance";

export function getLerCatalogList(data) {
  return axiosInstance.post(`/ler-catalog/list`, data);
}

export function getExportLerCatalogList(data) {
  return axiosInstance.post(`/ler-catalog/export-list`, data);
}
export function createLerCatalog(data) {
  return axiosInstance.post(`/ler-catalog/create`, data);
}

export function updateLerCatalog(data) {
  return axiosInstance.post(`/ler-catalog/update`, data);
}

export function importLerCatalog(data) {
  return axiosInstance.post(`/ler-catalog/import`, data);
}

export function deleteLerCatalog(data) {
  return axiosInstance.post(`/ler-catalog/delete`, data);
}

export function getPartCatalogList(data) {
  return axiosInstance.post(`/parts-catalog/list`, data);
}
export function getExportPartCatalogList(data) {
  return axiosInstance.post(`/parts-catalog/export-list`, data);
}
export function createPartCatalog(data) {
  return axiosInstance.post(`/parts-catalog/create`, data);
}

export function importPartCatalog(data) {
  return axiosInstance.post(`/parts-catalog/import`, data);
}

export function deletePartCatalog(data) {
  return axiosInstance.post(`/parts-catalog/delete`, data);
}

export function updatePartCatalog(data) {
  return axiosInstance.post(`/parts-catalog/update`, data);
}

export function getEtaiSearchList(data) {
  return axiosInstance.post(`/etai/search-part`, data);
}

export function actionPartAssignEtai(data) {
  return axiosInstance.post(`/parts-catalog/assign-etai-part`, data);
}

