import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";

import {
  createPartType,
  deletePartType,
  getPartType,
  importPartType,
  updatePartType,
  getExportPartType,
} from "../../services/PartTypeService";
import { toast } from "react-toastify";
import Utils from "../../utils";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import * as XLSX from "xlsx";
import { useLocation } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';


const PartType = () => {
  const location = useLocation();
  const RolePermission = Utils.getRecordForCurrentPath(location);
  const tableLengthList = ConfigDB.data.dataTableLength;
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const companyId = JSON.parse(data);

  const LangData = localStorage.getItem("lng");

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    company_id: companyId.company_id || 0,
  });
  const [totalRecords, setTotalRecords] = useState(0);

  const [partGroupList, setPartGroupList] = useState([]);

  const [delModal, setDelModal] = useState(false);
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [delpart, setDelPart] = useState("");
  const [modifyModal, setModifyModal] = useState(false);
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [partGroupData, setPartGroupData] = useState({
    id: "",
    descriptionPT: "",
    descriptionES: "",
    descriptionFR: "",
    descriptionIT: "",
    descriptionDE: "",
    descriptionUK: "",
  });

  const [modifyImportModal, setModifyImportModal] = useState(false);
  const [file, setFile] = useState(null);
  let errorsObj = {
    descriptionPT: "",
    descriptionES: "",
    descriptionFR: "",
    descriptionIT: "",
    descriptionDE: "",
    descriptionUK: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [isLoading, setIsLoading] = useState(false);


  let errorsObjImport = {
    file: "",
  };
  const [errorsImport, setErrorsImport] = useState(errorsObjImport);

  const handleImportModal = () => {
    setModifyImportModal(true);
  };

  const handleImportCloseModal = () => {
    setModifyImportModal(false);
    setErrorsImport("");
    setFile(null);
  };

  const handleAddModal = () => {
    setModifyModal(true);
    setPartGroupData("");
  };

  const handleCloseModal = () => {
    setModifyModal(false);
    setPartGroupData("");
    setErrors("");
  };

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  const fetchPartLocation = () => {
    let data = { ...params };
    getPartType(data)
      .then((response) => {
        setPartGroupList(response.data.data);
        setTotalRecords(response.data.total);
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  };
  const fetchExportPartLocation = () => {
    let data = { ...params };
    getExportPartType(data)
      .then((response) => {
        const worksheet = XLSX.utils.json_to_sheet(response.data.data);
        const csv = XLSX.utils.sheet_to_csv(worksheet);

        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "PartType.csv");
        link.style.visibility = "hidden";

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(
    function () {
      let data = { ...params };

      fetchPartLocation(data);
    },
    [params]
  );

  const onDeleteModalClose = () => {
    setDelModal(false);
  };

  const onEditPartType = (e, item) => {
    setModifyModal(true);
    setPartGroupData({
      id: item?.id,
      descriptionPT: item?.descriptionPT,
      descriptionES: item?.descriptionES,
      descriptionFR: item?.descriptionFR,
      descriptionIT: item?.descriptionIT,
      descriptionDE: item?.descriptionDE,
      descriptionUK: item?.descriptionUK,
    });
  };

  const onDeleteModalOpen = (e, item) => {
    e.preventDefault();
    setDelPart(item.id);
    setDelModal(true);
  };

  const onDestroyPartType = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { id: delpart };
    deletePartType(data)
      .then((response) => {
        let data = { ...params };

        fetchPartLocation(data);
        toast.success(response.data.message);
        setDelPart("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handlePartGroupForm = (e) => {
    e.preventDefault();
    let error = false;
    let errorObj = { ...errorsObj };
    if (!partGroupData.descriptionPT) {
      errorObj.descriptionPT = `Description PT required.`;
      error = true;
    }

    if (!partGroupData.descriptionES) {
      errorObj.descriptionES = `Description ES required.`;
      error = true;
    }
    if (!partGroupData.descriptionFR) {
      errorObj.descriptionFR = `Description FR required.`;
      error = true;
    }
    if (!partGroupData.descriptionIT) {
      errorObj.descriptionIT = `Description IT required.`;
      error = true;
    }
    if (!partGroupData.descriptionDE) {
      errorObj.descriptionDE = `Description DE required.`;
      error = true;
    }
    if (!partGroupData.descriptionUK) {
      errorObj.descriptionUK = `Description UK required.`;
      error = true;
    }

    setErrors(errorObj);
    if (error) return;

    setIsModifyButtonLoad(true);
    let data = {
      descriptionPT: partGroupData.descriptionPT,
      descriptionES: partGroupData.descriptionES,
      descriptionFR: partGroupData.descriptionFR,
      descriptionIT: partGroupData.descriptionIT,
      descriptionDE: partGroupData.descriptionDE,
      descriptionUK: partGroupData.descriptionUK,
    };

    let updateData = {
      id: partGroupData.id,
      descriptionPT: partGroupData.descriptionPT,
      descriptionES: partGroupData.descriptionES,
      descriptionFR: partGroupData.descriptionFR,
      descriptionIT: partGroupData.descriptionIT,
      descriptionDE: partGroupData.descriptionDE,
      descriptionUK: partGroupData.descriptionUK,
    };

    if (partGroupData?.id) {
      updatePartType(updateData)
        .then((response) => {
          toast.dismiss();
          toast.success(response.data.message);
          let data = { ...params };
          fetchPartLocation(data);
          setModifyModal(false);
          setIsModifyButtonLoad(false);
        })
        .catch((err) => {
          toast.dismiss();
          toast.error(Utils.getErrorMessage(err));
          setIsModifyButtonLoad(false);
        });
    } else {
      createPartType(data)
        .then((response) => {
          toast.dismiss();
          toast.success(response.data.message);
          let data = { ...params };
          fetchPartLocation(data);
          setModifyModal(false);
          setIsModifyButtonLoad(false);
        })
        .catch((err) => {
          toast.dismiss();
          toast.error(Utils.getErrorMessage(err));
          setIsModifyButtonLoad(false);
        });
    }
  };

  const handleImportGroup = (e) => {
    e.preventDefault();

    let error = false;
    let errorObjImport = { ...errorsObjImport };
    if (!file) {
      errorObjImport.file = `File is required.`;
      error = true;
    }

    setErrorsImport(errorObjImport);
    if (error) return;

    setIsModifyButtonLoad(true);
    const formData = new FormData();
    formData.append("files", file);

    importPartType(formData)
      .then((response) => {
        toast.dismiss();
        toast.success(response.data.message);
        let data = { ...params };
        fetchPartLocation(data);
        setModifyImportModal(false);
        setIsModifyButtonLoad(false);
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
        setIsModifyButtonLoad(false);
      });
  };
  const handleExport = () => {
    let data = { ...params };
    fetchExportPartLocation(data);
  };
  return (
    <div>
      {isLoading ? (
        // <div className="text-center pageLoader">
        //   <div className="spinner-border" role="status">
        //     <span className="visually-hidden">Loading...</span>
        //   </div>
        // </div>
        <div className="skeleton-loader p-3 d-none">
          <div className="card">
            <div className="card-header">
              <table className="table rounded-table table-striped table-hover">
                <thead>
                  <tr>
                    <th
                      style={{ width: 10, borderTop: "0", borderBottom: "0" }}
                    >
                      <div className="skeleton"></div>
                    </th>
                    <th style={{ borderTop: "0", borderBottom: "0" }}>
                      <div className="skeleton"></div>
                    </th>
                    <th style={{ borderTop: "0", borderBottom: "0" }}></th>
                    <th
                      class="d-flex"
                      style={{ borderTop: "0", borderBottom: "0" }}
                    >
                      <div class="skeleton me-1"></div>
                      <div class="skeleton me-1"></div>
                      <div class="skeleton"></div>
                    </th>
                    <th
                      style={{ width: 10, borderTop: "0", borderBottom: "0" }}
                    >
                      <div className="skeleton"></div>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <div className="card-body">
              <div className="vehicleThBg table-responsive">
                <table className="table table-bordered rounded-table table table-striped table-hover">
                  <thead>
                    <tr>
                      <th style={{ backgroundColor: "#c0c0c0" }}>
                        <div className="skeleton"></div>
                      </th>
                      <th style={{ backgroundColor: "#c0c0c0" }}>
                        <div className="skeleton"></div>
                      </th>
                      <th style={{ backgroundColor: "#c0c0c0" }}>
                        <div className="skeleton"></div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {partGroupList?.map((_, index) => (
                      <tr key={index}>
                        <td>
                          <div className="skeleton"></div>
                        </td>
                        <td>
                          <div className="skeleton"></div>
                        </td>
                        <td>
                          <div className="skeleton"></div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card-footer">
              <div className="skeleton col-lg-3 float-right"></div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  {/* <h1 className="page-title">Part Type</h1> */}
                </div>
                <div className="col-sm-6"></div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card fuelCard container-custom-height">
                    <div className="card-header">
                      <div className="card-title m-0 float-left" style={{width: "50%"}}>
                        <input
                          type="text"
                          className="form-control"
                          value={params.search}
                          onChange={(e) => handleParams(e, "search")}
                          placeholder="Search"
                        ></input>
                      </div>
                      <div className="card-title m-0 float-right d-flex">
                        {RolePermission?.findIndex(
                          (e) => e.permission_name === "import_part_type"
                        ) > -1 && (
                          <button
                            type="button"
                            className="btn  btn-icon-text mr-2"
                            onClick={handleImportModal}
                          >
                            <i className="bi bi-file-earmark-excel-fill"></i>
                            <span className="ms-2">Import</span>
                          </button>
                        )}
                        {RolePermission?.findIndex(
                          (e) => e.permission_name === "export_part_type"
                        ) > -1 && (
                          <button
                            type="button"
                            className="btn  btn-icon-text mr-2"
                            onClick={handleExport}
                          >
                            <i className="bi bi-file-earmark-excel-fill"></i>
                            <span className="ms-2">Export</span>
                          </button>
                        )}
                        {RolePermission?.findIndex(
                          (e) => e.permission_name === "add_part_type"
                        ) > -1 && (
                          <button
                            type="button"
                            className="btn  btn-icon-text mr-2"
                            onClick={handleAddModal}
                          >
                            <i className="bi bi-plus-lg"></i>
                            <span className="ms-2">Add</span>
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="card-body fuelCardB">
                      <div
                        className="vehicleThBg table-responsive table-responsive-vehicle">
                        <table className="table table-bordered rounded-table table-striped table-hover">
                          <thead>
                            <tr>
                              <th style={{ width: "1%" }}>#</th>
                              <th className="col-form-label ml-2 Label-my form-label form-label" style={{ width: "10%" }}>
                                {LangData === "pt"
                                  ? "Part Type Description"
                                  : LangData === "fr"
                                  ? "Part Type Description"
                                  : LangData === "es"
                                  ? "Part Type Description"
                                  : LangData === "it"
                                  ? "Part Type Description"
                                  : LangData === "de"
                                  ? "Part Type Description"
                                  : "Part Type Description"}
                              </th>

                              <th
                                className="col-form-label ml-2 Label-my form-label form-label"
                                style={{ textAlign: "center", width: "0%" }}
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {partGroupList?.map((item, index) => (
                              <tr key={item.id}>
                                {" "}
                                <td>{++index}</td>
                                <td>
                                  {LangData === "pt"
                                    ? item.descriptionPT
                                    : LangData === "fr"
                                    ? item.descriptionFR
                                    : LangData === "es"
                                    ? item.descriptionES
                                    : LangData === "it"
                                    ? item.descriptionIT
                                    : LangData === "de"
                                    ? item.descriptionDE
                                    : item.descriptionUK}
                                </td>
                                {/*<td className="d-flex">*/}
                                  {/*<Dropdown className="iconDropdown">*/}
                                    {/*<Dropdown.Toggle variant="success" id="dropdown-basic" className="iconToggle">*/}
                                      {/*<i className="bi bi-three-dots-vertical"></i>*/}
                                    {/*</Dropdown.Toggle>*/}

                                    {/*<Dropdown.Menu>*/}
                                      {/*<Dropdown.Item href="#/action-1" className="d-flex">*/}
                                        {/*{item.company_id > 0 ||*/}
                                      {/*(companyId.role_id === "1" &&*/}
                                          {/*RolePermission?.findIndex(*/}
                                              {/*(e) =>*/}
                                                  {/*e.permission_name ===*/}
                                                  {/*"modify_part_type"*/}
                                          {/*) > -1 && (*/}
                                              {/*<a*/}
                                                  {/*className="edit-btn"*/}
                                                  {/*href="#"*/}
                                                  {/*onClick={(e) =>*/}
                                                      {/*onEditPartType(e, item)*/}
                                                  {/*}*/}
                                              {/*>*/}
                                                {/*<i className="bi bi-pencil"></i>*/}
                                              {/*</a>*/}
                                          {/*))}<span>Edit</span>*/}
                                      {/*</Dropdown.Item>*/}
                                      {/*<Dropdown.Item href="#/action-2" className="d-flex">*/}
                                        {/*{(item.company_id > 0 ||*/}
                                            {/*companyId.role_id === "1") &&*/}
                                        {/*RolePermission?.findIndex(*/}
                                            {/*(e) =>*/}
                                                {/*e.permission_name === "delete_part_type"*/}
                                        {/*) > -1 && (*/}
                                            {/*<div className="trash-btn">*/}
                                              {/*<a*/}
                                                  {/*className="delete-btn"*/}
                                                  {/*href="#"*/}
                                                  {/*onClick={(e) =>*/}
                                                      {/*onDeleteModalOpen(e, item)*/}
                                                  {/*}*/}
                                              {/*>*/}
                                                {/*<i className="bi bi-trash3"></i>*/}
                                              {/*</a>*/}
                                            {/*</div>*/}
                                        {/*)}<span>Delete</span>*/}
                                      {/*</Dropdown.Item>*/}
                                    {/*</Dropdown.Menu>*/}
                                  {/*</Dropdown>*/}


                                {/*</td>*/}
                                <td className="d-flex">
                                  <Dropdown className="iconDropdown">
                                    <Dropdown.Toggle variant="success" id="dropdown-basic" className="iconToggle">
                                      <i className="bi bi-three-dots-vertical"></i>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item href="#/action-1" className="d-flex" onClick={(e) =>
                                          onEditPartType(e, item)
                                      }>
                                        {item.company_id > 0 ||
                                        (companyId.role_id === "1" &&
                                            RolePermission?.findIndex(
                                                (e) =>
                                                    e.permission_name ===
                                                    "modify_part_type"
                                            ) > -1 && (
                                                <a
                                                    className=""
                                                    href="#"

                                                >
                                                  <i className="bi bi-pencil"></i>
                                                </a>
                                            ))}
                                        <div
                                            className="edit-btn"
                                            href="#"

                                        >
                                          <i className="bi bi-pencil"></i>
                                        </div>
                                            <span>Edit</span>
                                      </Dropdown.Item>
                                      <Dropdown.Item href="#/action-2" className="d-flex" onClick={(e) =>
                                          onDeleteModalOpen(e, item)
                                      }>

                                        {(item.company_id > 0 ||
                                            companyId.role_id === "1") &&
                                        RolePermission?.findIndex(
                                            (e) =>
                                                e.permission_name === "delete_part_type"
                                        ) > -1 && (
                                            <div className="">
                                              <a
                                                  className=""
                                                  href="#"

                                              >

                                              </a>
                                            </div>
                                        )}
                                        <div className="trash-btn">
                                          <div
                                              className="delete-btn"
                                          >
                                            <i className="bi bi-trash3"></i>
                                          </div>
                                        </div>
                                        <span>Delete</span>
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>


                                </td>
                              </tr>
                            ))}
                            {partGroupList && partGroupList.length === 0 && (
                              <tr>
                                <td colSpan={4} className="text-center">
                                  Record Not Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* /.card-body */}
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer card-footer-part clearfix">
              <div className="pagination pagination-sm m-0 float-right">
                <Pagination
                  activePage={params.page}
                  itemsCountPerPage={parseInt(params.limit)}
                  totalItemsCount={parseInt(totalRecords)}
                  pageRangeDisplayed={5}
                  itemClass={`page-item`}
                  linkClass={`page-link`}
                  onChange={(e) => handleParams(e, "pagination")}
                />
              </div>
            </div>
          </section>
        </div>
      )}
      <Modal isOpen={delModal}>
        <ModalHeader>{`Delete Part Type`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this part Type?`}</p>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            disabled={isDelButtonLoad}
            onClick={onDeleteModalClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close <i class="bi bi-x-lg"></i>
          </button>

          {!isDelButtonLoad && (
            <button
              type="button"
              className="btn btn-block bg-red"
              style={{ width: "100px" }}
              onClick={onDestroyPartType}
            >
              Delete <i class="bi bi-trash3"></i>
            </button>
          )}
          {isDelButtonLoad && (
            <button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
            </button>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modifyModal}>
        <ModalHeader>Part Type</ModalHeader>
        <Form method={`post`} onSubmit={handlePartGroupForm}>
          <ModalBody>
            <div className="row">
              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Description PT"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={partGroupData.descriptionPT}
                        onChange={(e) => {
                          setPartGroupData({
                            ...partGroupData,
                            descriptionPT: e.target.value,
                          });
                        }}
                        name={`descriptionPT`}
                        maxLength={50}
                        placeholder="Description PT"
                      />
                    </div>
                    {errors.descriptionPT && (
                      <span className="input-error">
                        {errors.descriptionPT}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Description ES"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={partGroupData.descriptionES}
                        onChange={(e) => {
                          setPartGroupData({
                            ...partGroupData,
                            descriptionES: e.target.value,
                          });
                        }}
                        name={`descriptionES`}
                        maxLength={50}
                        placeholder="Description ES"
                      />
                    </div>
                    {errors.descriptionES && (
                      <span className="input-error">
                        {errors.descriptionES}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Description FR"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={partGroupData.descriptionFR}
                        onChange={(e) => {
                          setPartGroupData({
                            ...partGroupData,
                            descriptionFR: e.target.value,
                          });
                        }}
                        name={`descriptionFR`}
                        maxLength={50}
                        placeholder="Description FR"
                      />
                    </div>
                    {errors.descriptionFR && (
                      <span className="input-error">
                        {errors.descriptionFR}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Description IT"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={partGroupData.descriptionIT}
                        onChange={(e) => {
                          setPartGroupData({
                            ...partGroupData,
                            descriptionIT: e.target.value,
                          });
                        }}
                        name={`descriptionIT`}
                        maxLength={50}
                        placeholder="Description IT"
                      />
                    </div>
                    {errors.descriptionIT && (
                      <span className="input-error">
                        {errors.descriptionIT}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Description DE"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={partGroupData.descriptionDE}
                        onChange={(e) => {
                          setPartGroupData({
                            ...partGroupData,
                            descriptionDE: e.target.value,
                          });
                        }}
                        name={`descriptionDE`}
                        maxLength={50}
                        placeholder="Description DE"
                      />
                    </div>
                    {errors.descriptionDE && (
                      <span className="input-error">
                        {errors.descriptionDE}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Description UK"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={partGroupData.descriptionUK}
                        onChange={(e) => {
                          setPartGroupData({
                            ...partGroupData,
                            descriptionUK: e.target.value,
                          });
                        }}
                        name={`descriptionUK`}
                        maxLength={40}
                        placeholder="Description UK"
                      />
                    </div>
                    {errors.descriptionUK && (
                      <span className="input-error">
                        {errors.descriptionUK}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              class="btn btn-block btn-primary"
              disabled={isModifyButtonLoad}
              onClick={handleCloseModal}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-x-lg"></i>
            </button>
            {!isModifyButtonLoad && (
              <button
                type="submit"
                class="btn btn-block btn-primary"
                style={{ width: "100px", marginTop: "10px" }}
              >
                Submit <i class="bi bi-check2"></i>
              </button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={modifyImportModal}>
        <Form method={`post`} onSubmit={handleImportGroup}>
          <ModalHeader>{`Import Part Type`}</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <FormGroup>
                  <Label className="col-form-label">Select File</Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="file"
                        onChange={(e) => setFile(e.target.files[0])}
                        name="file"
                        accept=".csv,.xlsx"
                      />
                    </div>
                    {errorsImport.file && (
                      <span className="input-error">{errorsImport.file}</span>
                    )}
                  </div>
                </FormGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              class="btn btn-block btn-secondary"
              disabled={isModifyButtonLoad}
              onClick={handleImportCloseModal}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close
            </button>
            {!isModifyButtonLoad && (
              <button
                type="submit"
                class="btn btn-block btn-primary"
                style={{ width: "100px", marginTop: "10px" }}
              >
                Import
              </button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default PartType;
