import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import { toast } from "react-toastify";
import Utils from "../../utils";
import { useNavigate, useLocation } from "react-router-dom";
import {
  actionDelDepollutionOpration,
  actionUpdateDepollutionOpration,
  getDepollutionOprationList,
} from "../../services/DepollutionService";
import Dropdown from "react-bootstrap/Dropdown";
import { getCommonSearchList } from "../../services/settingsServices";

const DepollutionOpration = () => {
  const location = useLocation();
  const RolePermission = Utils.getRecordForCurrentPath(location);
  const tableLengthList = ConfigDB.data.dataTableLength;
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const companyId = JSON.parse(data);
  const navigate = useNavigate();
  const [delModal, setDelModal] = useState(false);
  const [delPartId, setDelPartId] = useState("");
  const [catalog, setCatalog] = useState("");
  const [model, setModel] = useState("");
  const [year, SetYear] = useState("");
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [inventoryStatus, setInventoryStatus] = useState(0);
  const [modifyModal, setModifyModal] = useState(false);
  const [depollutionData, setdepollutionData] = useState({
    uuid: "",
    ler_code_id: "",
    wastege_value: "",
    description: "",
    vehicle_make: "",
    vehicle_model: "",
    vehicle_start_year: "",
    vehicle_year: "",
    vehicle_id: "",
    vehicle_make1: "",
    vehicle_model1: "",
    vehicle_year1: "",
    ler_code: "",
  });
  const [lerCode, setLerCode] = useState("");

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    company_id: companyId.company_id || 0,
    depollution_type: 0,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [depollutionList, setDepollutionList] = useState([]);
  const [partColumnList, setPartColumnList] = useState([]);
  const [depollutionTypeStatus, setdepollutionTypeStatus] = useState(0);

  const handleAddModal = (item) => {
    setModifyModal(true);
    setdepollutionData({
      uuid: item?.uuid,
      ler_code_id: item?.ler_code_id,
      wastege_value: item?.wastege_value,
      description: item?.description,
      vehicle_make: item?.vehicle_make,
      vehicle_model: item?.vehicle_model,
      vehicle_start_year: item?.vehicle_start_year,
      vehicle_year: item?.vehicle_year,
      vehicle_id: item?.vehicle_id,
      vehicle_make1: item?.vehicle_make1,
      vehicle_model1: item?.vehicle_model1,
      vehicle_year1: item?.vehicle_year1,
      ler_code: item?.ler_code,
    });
  };

  const handleCloseModal = () => {
    setModifyModal(false);
  };

  const handleParams = (e, type) => {
    let paramsObj = { ...params };

    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    } else if (type === "depollution_type") {
      paramsObj.depollution_type = parseInt(e.target.value);
    }

    setParams(paramsObj);
  };

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "depollution_filter",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setPartColumnList(list);
      })
      .catch((err) => {});
  }, []);

  const fetchPartInventory = () => {
    let data = { ...params };
    getDepollutionOprationList(data)
      .then((response) => {
        setDepollutionList(response.data.data);
        setTotalRecords(response.data.total);
      })
      .catch((err) => {
        toast.dismiss();

        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(
    function () {
      let data = { ...params };
      fetchPartInventory(data);
    },
    [params]
  );

  const handlePartsInventory = () => {
    navigate("/add-vehicle-depollution-operation");
  };

  const onDeleteModalOpen = (e, item) => {
    e.preventDefault();
    setDelPartId(item.uuid);
    setDelModal(true);
  };
  const onDeleteModalClose = () => {
    setDelModal(false);
  };
  const onDestroyPartInventory = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: delPartId };
    actionDelDepollutionOpration(data)
      .then((response) => {
        let data = { ...params };
        fetchPartInventory(data);
        toast.success(response.data.message);
        setDelPartId("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  };
  const handleEditDepollution = (e) => {
    e.preventDefault();

    let data = {
      uuid: depollutionData?.uuid,
      vehicle_id: depollutionData?.vehicle_id,
      ler_code_id: depollutionData?.ler_code_id,
      wastege_value: depollutionData?.wastege_value,
      description: depollutionData?.description,
    };

    actionUpdateDepollutionOpration(data)
      .then((response) => {
        toast.success(response.data.message);
        setModifyModal(false);
        let data = { ...params };
        fetchPartInventory(data);
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                {/* <h1>Depollution Opration</h1> */}
              </div>
              <div className="col-sm-6"></div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card fuelCard container-custom-height">
                  <div className="card-header">
                    <div
                      className="card-title m-0 float-left fuelCT"
                      style={{ width: "50%" }}
                    >
                      <div className="row">
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control"
                            value={params.search}
                            onChange={(e) => handleParams(e, "search")}
                            placeholder="Search"
                          ></input>
                        </div>

                        <div className="col-md-6">
                          <select
                            className="form-select"
                            type="select"
                            value={params.depollution_type}
                            onChange={(e) => {
                              handleParams(e, "depollution_type");
                            }}
                          >
                            <option>All</option>
                            {partColumnList?.map((item) => (
                              <option key={item.value} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="card-title m-0 float-right fuelCT">
                      <button
                        type="button"
                        className="btn-icon-text mr-2 mr-2"
                        onClick={handlePartsInventory}
                      >
                        <i className="bi bi-plus-lg"></i>
                        <span className="ms-2">Add For Depollution</span>
                      </button>
                    </div>
                  </div>
                  <div className="card-body fuelCardB">
                    <div className="vehicleThBg table-responsive table-responsive-vehicle">
                      <table className="table table-bordered rounded-table table table-striped table-hover">
                        <thead>
                          <tr>
                            <th className="Label-my">LER Code </th>
                            <th className="Label-my">Wastage Value</th>
                            <th className="Label-my">Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          {depollutionList?.map((item) => {
                            return (
                              <>
                                <tr key={item.id}>
                                  <td>{item.ler_code}</td>
                                  <td>{item.wastege_value}</td>
                                  <td>{item.type}</td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                        {depollutionList && depollutionList?.length === 0 && (
                          <tr>
                            <td
                              colSpan={7}
                              className={`text-center`}
                            >{`Record Not Found`}</td>
                          </tr>
                        )}
                      </table>
                    </div>
                  </div>
                  {/* /.card-body */}
                </div>
              </div>
            </div>
          </div>
          <div className="fuelCF">
            <div className="pagination pagination-sm m-0 float-right card-footer-part">
              <Pagination
                activePage={params.page}
                itemsCountPerPage={parseInt(params.limit)}
                totalItemsCount={parseInt(totalRecords)}
                pageRangeDisplayed={5}
                itemClass={`page-item`}
                linkClass={`page-link`}
                onChange={(e) => handleParams(e, "pagination")}
              />
            </div>
          </div>
        </section>
      </div>

      <Modal isOpen={delModal}>
        <ModalHeader>{`Delete Inventory Part`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this inventory part?`}</p>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            disabled={isDelButtonLoad}
            onClick={onDeleteModalClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close <i class="bi bi-x-lg"></i>
          </button>

          {!isDelButtonLoad && (
            <button
              type="button"
              className="btn btn-block bg-red"
              style={{ width: "100px" }}
              onClick={onDestroyPartInventory}
            >
              Delete <i class="bi bi-trash3"></i>
            </button>
          )}
          {isDelButtonLoad && (
            <button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
            </button>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modifyModal}>
        <ModalHeader>Depollution Opration</ModalHeader>
        <Form method={`post`} onSubmit={handleEditDepollution}>
          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                {depollutionData?.vehicle_id === 0 ? (
                  <>
                    <ul style={{ listStyleType: "square" }}>
                      <li>Vehicle Make : {depollutionData?.vehicle_make}</li>
                      <li>Vehicle Model : {depollutionData?.vehicle_model}</li>
                      <li>Vehicle Year : {depollutionData?.vehicle_year}</li>
                      <li>LER Code : {depollutionData?.ler_code}</li>
                    </ul>
                  </>
                ) : (
                  <>
                    <ul style={{ listStyleType: "square" }}>
                      <li>
                        Vehicle Start Year :{" "}
                        {depollutionData?.vehicle_start_year}
                      </li>
                      <li>Vehicle Make : {depollutionData?.vehicle_make1}</li>
                      <li>Vehicle Model : {depollutionData?.vehicle_model1}</li>
                      <li>LER Code : {depollutionData?.ler_code}</li>
                    </ul>
                  </>
                )}
              </div>
              <div className="col-md-12">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my form-label form-label">
                    {"wastage Value"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search form-control"
                        type="number"
                        value={depollutionData.wastege_value}
                        onChange={(e) => {
                          setdepollutionData({
                            ...depollutionData,
                            wastege_value: e.target.value,
                          });
                        }}
                        name={`wastege_value`}
                        maxLength={50}
                        placeholder="Wastege Value"
                      />
                    </div>
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-12">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my form-label form-label">
                    {"Description"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <textarea
                        className="form-control input-search form-control"
                        type="text"
                        value={depollutionData.description}
                        onChange={(e) => {
                          setdepollutionData({
                            ...depollutionData,
                            description: e.target.value,
                          });
                        }}
                        name={`description`}
                        maxLength={50}
                        placeholder="Description"
                        rows={3}
                      />
                    </div>
                  </div>
                </FormGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              class="btn btn-block btn-primary"
              onClick={handleCloseModal}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-x-lg"></i>
            </button>
            <button
              type="submit"
              class="btn btn-block btn-primary"
              style={{ width: "100px", marginTop: "10px" }}
            >
              Submit <i class="bi bi-check2"></i>
            </button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default DepollutionOpration;
