import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createPartGroup,
  deletePartgroup,
  getPartgroupList,
  importPartGroup,
  updatePartGroup,
  getExportPartgroupList,
} from "../../services/PartTypeService";
import { toast } from "react-toastify";
import Utils from "../../utils";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import * as XLSX from "xlsx";
import Select from "react-select";
import {
  WareHouseEntitySerach,
  createWareHouseEntity,
  deleteWareHouseEntity,
  getWareHouseEntityList,
} from "../../services/WareHouseServices";
import {
  createLabelBrothers,
  createLabelZebras,
  CreateRePrint,
  getLabelList,
} from "../../services/LabelServices";
import { getSettingsCompanyDetails } from "../../services/settingsServices";

const PrintedList = [
  { id: 0, value: "Not Printed" },
  { id: 1, value: "Printed" },
];

const Labels = () => {
  const tableLengthList = ConfigDB.data.dataTableLength;
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const location = useLocation();
  const RolePermission = Utils.getRecordForCurrentPath(location);

  const navigate = useNavigate();

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    print_status: "0",
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [PollPrinting, setPollPrinting] = useState([]);

  const [printLabel, setPrintLabel] = useState(false);
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [ReprintLabel, setRePrintLabel] = useState(false);

  const [delpart, setDelPart] = useState("");

  const [entityList, setEntityList] = useState([]);
  const [settingCompanyDetails, setSettingCompanyDetails] = useState("");

  const [printLabelData, setprintLabelData] = useState("");
  const [printReLabelData, setprintReLabelData] = useState([]);
  const [addedLabelData, setAddedLabelData] = useState([]);
  const [totalRecordsRe, setTotalRecordsRe] = useState(0);

  const [searchQuery, setSearchQuery] = useState("");

  const onPrintLableModalClose = () => {
    setPrintLabel(false);
  };

  const onRePrintLable = () => {
    setRePrintLabel(true);
  };

  const onRePrintLableClose = () => {
    setRePrintLabel(false);
  };

  const handlePopupPageChange = (page) => {
    setParams((prev) => ({ ...prev, page }));
  };

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    } else if (type === "print_status") {
      paramsObj.print_status = e.target.value;
    }
    setParams(paramsObj);
  };

  useEffect(function () {
    WareHouseEntitySerach()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].entity_name,
          });
        }

        setEntityList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const companyResponse = await getSettingsCompanyDetails();
        setSettingCompanyDetails(companyResponse.data.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchSettings();
  }, []);

  const fetchWareHouse = () => {
    let data = { ...params };
    getLabelList(data)
      .then((response) => {
        setPollPrinting(response.data.data);
        setTotalRecords(response.data.total);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(
    function () {
      let data = { ...params };

      fetchWareHouse(data);
    },
    [params]
  );

  useEffect(() => {
    if (ReprintLabel === true) {
      let data = {
        limit: tableLengthList[0],
        page: 1,
        search: searchQuery,
        is_reload: false,
        print_status: "1",
      };
      getLabelList(data)
        .then((response) => {
          setprintReLabelData(response.data.data);
          setTotalRecordsRe(response.data.total);
        })
        .catch((err) => {
          toast.dismiss();
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [ReprintLabel, searchQuery]);

  const handleAdd = (label) => {
    setprintReLabelData((prev) => prev.filter((item) => item.id !== label.id));
    setAddedLabelData((prev) => [...prev, label]);
  };

  const handleDelete = (label) => {
    setAddedLabelData((prev) => prev.filter((item) => item.id !== label.id));
    setprintReLabelData((prev) => [...prev, label]);
  };

  const onDeleteModalClose = () => {
    setPrintLabel(false);
  };

  const onEditPrintLabel = (e, item) => {
    setPrintLabel(true);
    setprintLabelData(item);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      id: [printLabelData.parts_id],
      column_ids: printLabelData.column_ids,
      uuid: printLabelData.uuid,
    };

    if (settingCompanyDetails.print_label === 1) {
      createLabelZebras(payload)
        .then((response) => {
          toast.success(response.data.message);
          setPrintLabel(false);
          let data = { ...params };

          fetchWareHouse(data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    } else {
      console.log("payload", payload);
      createLabelBrothers(payload)
        .then((response) => {
          toast.success(response.data.message);
          setPrintLabel(false);

          let data = { ...params };

          fetchWareHouse(data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  };

  const handleReprintSubmit = (e) => {
    e.preventDefault();

    const labelIdsArray = addedLabelData.map((item) => item.id);

    const payload = {
      ids: labelIdsArray,
    };

    CreateRePrint(payload)
      .then((response) => {
        toast.success(response.data.message);
        setRePrintLabel(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                {/* <h1 className="page-title">Part Group</h1> */}
              </div>
              <div className="col-sm-6"></div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card fuelCard container-custom-height">
                  <div className="card-header">
                    <div className="card-title m-0 float-left">
                      <div className="row">
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control"
                            value={params.search}
                            onChange={(e) => handleParams(e, "search")}
                            placeholder="Search"
                          ></input>
                        </div>
                        <div className="col-md-6">
                          <select
                            className="form-select"
                            type="select"
                            value={params.print_status}
                            onChange={(e) => handleParams(e, "print_status")}
                          >
                            {PrintedList?.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.value}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="card-title m-0 float-right d-flex">
                      <button
                        type="button"
                        className="btn btn-icon-text mr-2"
                        onClick={onRePrintLable}
                      >
                        <i className="bi bi-plus-lg"></i>
                        <span className="ms-2">Re Print</span>
                      </button>

                      <button
                        type="button"
                        className="btn btn-icon-text mr-2"
                        onClick={() => navigate("/add-label")}
                      >
                        <i className="bi bi-plus-lg"></i>
                        <span className="ms-2">Print</span>
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div
                      className="vehicleThBg table-responsive table-responsive-vehicle">
                      <table className="table table-bordered rounded-table table table-striped table-hover">
                        <thead>
                          <tr>
                            <th style={{ width: "1%" }}>#</th>
                            <th
                              className="col-form-label ml-2 Label-my form-label form-label"
                              style={{ width: "10%" }}
                            >
                              Column Name
                            </th>
                            <th
                              className="col-form-label ml-2 Label-my form-label form-label"
                              style={{ width: "10%" }}
                            >
                              Part
                            </th>
                            <th
                              className="col-form-label ml-2 Label-my form-label form-label"
                              style={{ width: "10%" }}
                            >
                              Status
                            </th>
                            {params.print_status === "0" && (
                              <th
                                className="col-form-label ml-2 Label-my form-label form-label"
                                style={{ textAlign: "center", width: "0%" }}
                              >
                                Action
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {PollPrinting?.map((item, index) => {
                            return (
                              <>
                                <tr>
                                  <td>{++index}</td>
                                  <td>{item?.column_names}</td>
                                  <td>{item?.part_name}</td>
                                  <td>
                                    <span
                                      style={{
                                        backgroundColor:
                                          params.print_status === "0"
                                            ? "orange"
                                            : "green",
                                        color: "white",
                                        padding: "5px 10px",
                                        borderRadius: "20px",
                                        fontWeight: "bold",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {params.print_status === "0"
                                        ? "Not Printed"
                                        : "Printed"}
                                    </span>
                                  </td>
                                  {params.print_status === "0" && (
                                    <td className="d-flex">
                                      <a
                                        className="edit-btn"
                                        href="#"
                                        onClick={(e) =>
                                          onEditPrintLabel(e, item)
                                        }
                                      >
                                        <i class="bi bi-printer"></i>
                                      </a>
                                    </td>
                                  )}
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                        {PollPrinting && PollPrinting?.length === 0 && (
                          <tr>
                            <td
                              colSpan={7}
                              className={`text-center`}
                            >{`Record Not Found`}</td>
                          </tr>
                        )}
                      </table>
                    </div>
                  </div>
                  {/* /.card-body */}
                </div>
              </div>
            </div>
          </div>
          <div className="fuelCF">
            <div className="card-footer clearfix card-footer-part ">
              <div className="pagination pagination-sm m-0 float-right">
                <Pagination
                  activePage={params.page}
                  itemsCountPerPage={parseInt(params.limit)}
                  totalItemsCount={parseInt(totalRecords)}
                  pageRangeDisplayed={5}
                  itemClass={`page-item`}
                  linkClass={`page-link`}
                  onChange={(e) => handleParams(e, "pagination")}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
      <Modal isOpen={printLabel}>
        <ModalHeader>{`Print Label`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to Print this Label?`}</p>
        </ModalBody>
        <ModalFooter>
          {/* <button
            type="button"
            class="btn btn-block btn-primary"
            disabled={isDelButtonLoad}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close
          </button> */}

          <Button variant="secondary" onClick={onPrintLableModalClose}>
            Close
          </Button>

          <button
            type="button"
            className="btn btn-block btn-primary"
            style={{ width: "100px" }}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={ReprintLabel} size="xl">
        <Form method={`post`} onSubmit={handleReprintSubmit}>
          <ModalHeader>{`Re Print Label`}</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3 ">
                    <Label className="col-form-label ml-2 Label-my form-label form-label">
                      {"Search"}
                    </Label>
                    <input
                      type="text"
                      className="form-control"
                      value={searchQuery} // Controlled input bound to state
                      onChange={(e) => setSearchQuery(e.target.value)}
                      placeholder="Search"
                    />
                  </div>
                  <div className="vehicleThBg tableScroll table-scroll-modal">
                    <table className="rounded-table table table-striped table-bordered table-hover">
                      <thead>
                        <tr>
                          <th>Label Name</th>
                          <th>Part Name</th>

                          <th>Action</th>
                        </tr>
                      </thead>
                      {ReprintLabel === true && (
                        <tbody>
                          {printReLabelData?.map((label, index) => (
                            <tr key={index}>
                              <td>{label.column_names}</td>
                              <td>{label.part_name}</td>

                              <td>
                                <button
                                  type="button"
                                  className="btn btn-success btn-sm"
                                  onClick={() => handleAdd(label)}
                                >
                                  Add
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>

                <div className="col-md-6" style={{ marginTop: "97px" }}>
                  <div className="col-xl-12">
                    <div className="input-div"></div>
                  </div>
                  <div className="vehicleThBg tableScroll table-scroll-modal">
                    <table className="rounded-table table table-striped table-bordered table-hover">
                      <thead>
                        <tr>
                          <th>Label Name</th>
                          <th>Part Name</th>

                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {addedLabelData.map((label, index) => (
                          <tr key={index}>
                            <td>{label.column_names}</td>
                            <td>{label.part_name}</td>

                            <td>
                              <div className="trash-btn">
                                <a
                                  className="delete-btn"
                                  href="#"
                                  onClick={() => handleDelete(label)}
                                >
                                  <i className="bi bi-trash3"></i>
                                </a>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>

          <div className="card-footer clearfix" style={{ display: "flex" }}>
            <div className="pagination pagination-sm m-0 float-right card-footer-part">
              <Pagination
                activePage={params.page}
                itemsCountPerPage={parseInt(params.limit)}
                totalItemsCount={parseInt(totalRecordsRe)}
                pageRangeDisplayed={5}
                itemClass={`page-item`}
                linkClass={`page-link`}
                onChange={(page) => handlePopupPageChange(page)} // Handle pagination here
              />
            </div>
          </div>
          <ModalFooter>
            <Button variant="secondary" onClick={onRePrintLableClose}>
              Close
            </Button>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default Labels;
