import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";

import {
  createPartSlides,
  deletePartsides,
  getPartsidesList,
  importPartSlide,
  updatePartGroup,
  getExportPartsidesList,
} from "../../services/PartTypeService";
import { toast } from "react-toastify";
import Utils from "../../utils";

import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Select from "react-select";

import { useLocation, useNavigate } from "react-router-dom";
import {
  actionAddPark,
  actionDelPark,
  actionUpdatePark,
  getParkList,
} from "../../services/ParkServices";
import {
  actionCityList,
  actionCountryList,
  actionStateList,
} from "../../services/CommonService";
import { getVehicleList } from "../../services/PartsInventory";
import {
  deleteCertificate,
  geCertificateList,
} from "../../services/CertificateServices";
import moment from "moment";

const Certificate = () => {
  const location = useLocation();
  const RolePermission = Utils.getRecordForCurrentPath(location);

  const tableLengthList = ConfigDB.data.dataTableLength;
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const companyId = JSON.parse(data);
  const navigate = useNavigate();

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    company_id: companyId.company_id || 0,
  });
  const [totalRecords, setTotalRecords] = useState(0);

  const [certificateList, setCertificateList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [delModal, setDelModal] = useState(false);
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [delpart, setDelPart] = useState("");
  const [modifyModal, setModifyModal] = useState(false);
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [parkData, setParkData] = useState({
    uuid: "",
    vehicle_id: "",
    country_id: "",
    state_id: "",
    city_id: "",
    place: "",
    name: "",
    description: "",
  });

  const [VehicleModelList, setVehicleModelList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const handleAddModal = () => {
    setModifyModal(true);
  };

  const handleCloseModal = () => {
    setModifyModal(false);
    setParkData("");
  };

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  const fetchDataPark = () => {
    let data = { ...params };
    geCertificateList(data)
      .then((response) => {
        setCertificateList(response.data.data);
        setTotalRecords(response.data.total);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(
    function () {
      let data = { ...params };

      fetchDataPark(data);
    },
    [params]
  );

  useEffect(function () {
    actionCountryList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }
        setCountryList(list);
      })
      .catch((err) => {});
  }, []);

  const onDeleteModalClose = () => {
    setDelModal(false);
  };

  const onEditPark = (e, item) => {
    setModifyModal(true);
    setParkData({
      uuid: item?.uuid,
      vehicle_id: item?.vehicle_id,
      country_id: item?.country_id,
      state_id: item?.state_id,
      city_id: item?.city_id,
      place: item?.place,
      name: item?.name,
      description: item?.description,
    });
  };

  const onDeleteModalOpen = (e, item) => {
    e.preventDefault();
    setDelPart(item.uuid);
    setDelModal(true);
  };

  const onDestroyPartType = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: delpart };
    deleteCertificate(data)
      .then((response) => {
        let data = { ...params };
        fetchDataPark(data);
        toast.success(response.data.message);
        setDelPart("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                {/* <h1 className="page-title">Part Side</h1> */}
              </div>
              <div className="col-sm-6"></div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card fuelCard container-custom-height">
                  <div className="">
                    <div className="">
                      <div className="card-header">
                        <div className="card-title m-0 float-left" style={{width: "50%"}}>
                          <input
                            type="text"
                            className="form-control"
                            value={params.search}
                            onChange={(e) => handleParams(e, "search")}
                            placeholder="Search"
                          ></input>
                        </div>
                        <div className="card-title m-0 float-right d-flex">
                          {" "}
                          {RolePermission?.findIndex(
                            (e) => e.permission_name === "add_park"
                          ) > -1 && (
                            <button
                              type="button"
                              className="btn  btn-icon-text mr-2"
                              onClick={handleAddModal}
                            >
                              <i className="bi bi-plus-lg"></i>
                              <span className="ms-2">Add</span>
                            </button>
                          )}{" "}
                        </div>
                      </div>
                      <div className="card-body">
                        <div
                          className="vehicleThBg table-responsive table-responsive-vehicle"
                          
                        >
                          <table className="table table-bordered rounded-table table table-striped table-hover">
                            <thead>
                              <tr>
                                <th style={{ width: "1%" }}>#</th>
                                <th className="col-form-label ml-2 Label-my form-label form-label">
                                  Name
                                </th>

                                <th className="col-form-label ml-2 Label-my form-label form-label">
                                  Vat Number
                                </th>

                                <th className="col-form-label ml-2 Label-my form-label form-label">
                                  Certificate Date
                                </th>

                                <th className="col-form-label ml-2 Label-my form-label form-label">
                                  Registration Plate
                                </th>
                                <th
                                  className="col-form-label ml-2 Label-my form-label form-label"
                                  style={{ width: "0%" }}
                                >
                                  {" "}
                                  Action{" "}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {" "}
                              {certificateList
                                ?.filter(
                                  (item) => item.certificate_sync === null
                                )
                                .map((item, index) => {
                                  return (
                                    <>
                                      <tr>
                                        <td>{++index}</td>
                                        <td>{item.name}</td>
                                        <td>{item.vat_Number}</td>
                                        <td>
                                          {item.certificate_date === null
                                            ? "-"
                                            : moment(
                                                item.certificate_date
                                              ).format("DD/MM/YYYY")}
                                        </td>
                                        <td>{item.registration_plate}</td>
                                        <td className="d-flex">
                                          {" "}
                                          <a
                                            className="edit-btn"
                                            href="#"
                                            onClick={() => {
                                              localStorage.removeItem(
                                                "ownerList"
                                              );
                                              localStorage.removeItem(
                                                "vehicleId"
                                              );

                                              navigate("/vehicle", {
                                                state: item,
                                              });
                                            }}
                                          >
                                            {" "}
                                            <i class="bi bi-car-front-fill"></i>
                                          </a>
                                          <div className="trash-btn">
                                            <a
                                              className="delete-btn"
                                              href="#"
                                              onClick={(e) =>
                                                onDeleteModalOpen(e, item)
                                              }
                                            >
                                              {" "}
                                              <i className="bi bi-trash3"></i>
                                            </a>
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}{" "}
                            </tbody>{" "}
                            {certificateList &&
                              certificateList?.length === 0 && (
                                <tr>
                                  <td
                                    colSpan={7}
                                    className={`text-center`}
                                  >{`Record Not Found`}</td>
                                </tr>
                              )}
                          </table>
                        </div>
                      </div>{" "}
                      {/* /.card-body */}{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fuelCF">
            <div className="card-footer card-footer-part clearfix">
              <div className="pagination pagination-sm m-0 float-right">
                <Pagination
                  activePage={params.page}
                  itemsCountPerPage={parseInt(params.limit)}
                  totalItemsCount={parseInt(totalRecords)}
                  pageRangeDisplayed={5}
                  itemClass={`page-item`}
                  linkClass={`page-link`}
                  onChange={(e) => handleParams(e, "pagination")}
                />
              </div>
            </div>
          </div>
        </section>

        <Modal isOpen={delModal}>
          <ModalHeader>{`Delete Certificate`}</ModalHeader>
          <ModalBody>
            <p
              className={`mb-0`}
            >{`Are you sure you want to delete this certificate?`}</p>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              class="btn btn-block btn-primary"
              disabled={isDelButtonLoad}
              onClick={onDeleteModalClose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close
            </button>

            {!isDelButtonLoad && (
              <button
                type="button"
                className="btn btn-block bg-red"
                style={{ width: "100px" }}
                onClick={onDestroyPartType}
              >
                Delete
              </button>
            )}
            {isDelButtonLoad && (
              <button
                color="secondary"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
              </button>
            )}
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default Certificate;
