import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import UserImg from "../../asset/images/user-img.png";
import LanguageSwitcher from "./LanguageSwitcher";
import ConfigDB from "../../config";
import { useTranslation } from "react-i18next";

const Header = ({ isOpenS, setIsOpens }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenLan, setIsOpenLan] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const { t } = useTranslation();

  let title;
  if (location.pathname === "/dashboard") {
    title = t("dashboard");
  } else if (location.pathname === "/vehicle-process") {
    title = "Vehicle";
  } else if (location.pathname === "/vehicle-process") {
    title = "Vehicle";
  } else if (location.pathname === "/parts-inventory") {
    title = "Parts Inventory";
  } else if (location.pathname === "/ler-catalog") {
    title = "LER Catalog";
  } else if (location.pathname === "/depollution-operation") {
    title = "Depollution Opration";
  } else if (location.pathname === "/User") {
    title = "User";
  } else if (location.pathname === "/Roles") {
    title = "Roles";
  } else if (location.pathname === "/fuel-type") {
    title = "Fuel";
  } else if (location.pathname === "/part-type") {
    title = "Part Type";
  } else if (location.pathname === "/part-location") {
    title = "Part Location";
  } else if (location.pathname === "/part-group") {
    title = "Part Group";
  } else if (location.pathname === "/part-sides") {
    title = "Part Sides";
  } else if (location.pathname === "/vehicle-category") {
    title = "Vehicle Category";
  } else if (location.pathname === "/vehicle-type") {
    title = "Vehicle Type";
  } else if (location.pathname === "/vehicle-model") {
    title = "Vehicle Model";
  } else if (location.pathname === "/faqs") {
    title = "FAQ";
  } else if (location.pathname === "/terms-condition-admin") {
    title = "Terms & Condition";
  } else if (location.pathname === "/privacy-policy-admin") {
    title = "Privacy & Policy";
  } else if (location.pathname === "/part-catalog") {
    title = "Part Catalog";
  } else if (location.pathname === "/park") {
    title = "Park";
  } else if (location.pathname === "/shelf") {
    title = "Shelf";
  } else if (location.pathname === "/certificate") {
    title = "Certificate";
  } else if (location.pathname === "/warehouse-entity-type") {
    title = "Warehouse Entity Type";
  } else if (location.pathname === "/warehouse") {
    title = "Warehouse";
  } else if (location.pathname === "/pool-printing") {
    title = "Pool Printing";
  } else if (location.pathname === "/vehicle-make") {
    title = "Vehicle Make";
  } else if (location.pathname === "/vehicle-engine") {
    title = "Vehicle Engine";
  } else if (location.pathname === "/vehicle-variants") {
    title = "Vehicle Variants";
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdownLan = () => {
    setIsOpenLan(!isOpenLan);
  };

  const handleLogout = () => {
    localStorage.removeItem("@secure.@data");
    navigate("/");
  };
  const toggleSidebar = () => {
    console.log("Toggle Sidebar Clicked");
    setIsOpens(!isOpenS);
  };
  return (
    <div className="wrapper-dashboard">
      <nav
        className="main-header navbar navbar-expand navbar-white navbar-light"
        style={{ marginLeft: isOpenS ? "250px" : "60px" }}
      >
        {isOpenS && (
          <div
            style={{ padding: "3px 5px", fontSize: "25px" }}
            onClick={toggleSidebar}
            className="nav-link d-lg-none"
          >
            <i className="bi bi-list text-dark"></i>
          </div>
        )}
        <h5>{title}</h5>
        <ul className="navbar-nav ml-auto ">
          <li className="nav-item dropdown hoverDropdown" style={{ marginRight: "8px" }}>
            <LanguageSwitcher />
          </li>
          <li className="nav-item dropdown hoverDropdown" style={{ position: "relative" }}>
            <div className="profile">
              <div className="dropdown">
                <img
                  src={UserImg}
                  alt="user"
                  className="img-thumbnail"
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                onClick={toggleDropdown}
                />
                <ul
                  className={`dropdown-menu dropdown-menu-md dropdown-menu-right ${
                    isOpen ? "show" : ""
                  }`}
                  style={{ display: isOpen ? "block" : "none" }}
                  // className="dropdown-menu dropdown-menu-md dropdown-menu-right"
                  // style={{
                  //   // display: "none",
                  //   position: "absolute",
                  //   top: "100%",
                  //   right: "0",
                  // }}
                >
                  <li>
                    <a className="dropdown-item" href="/profile">
                      Profile
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/settings">
                      Settings
                    </a>
                  </li>

                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={handleLogout}
                    >
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Header;
