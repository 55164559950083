import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import polution from "../../asset/images/pollution-icon.svg";
import stock from "../../asset/images/stock.svg";
import car from "../../asset/images/car.svg";

const Services = ({ settingDetails }) => {
  const Services = [
    {
      heading: "Our Service",
      text: "We are ready to help you to move forward and grow your business, with us your problem will be solved",
    },
  ];
  const NavLink = {
    Depollution: "Depollution",
    Stock: "Stock",
    vehicles: "Dismantle vehicles",
  };
  return (
    <div id="our-service" className="our-service">
      <Container>
        <Row>
          <Col xl="7">
            <div className="service-inner-block">
              <div className="icon-box bg-black">
                <h4>
                  <a href="/">{NavLink.Depollution}</a>{" "}
                </h4>
                <div className="icon">
                  <img src={polution} alt="pollution" />
                </div>
              </div>

              <div className="icon-box bg-light-green icon-margin">
                <h4>
                  <a href="/">{NavLink.Stock}</a>
                </h4>
                <div className="icon">
                  <img src={stock} alt="stock" />
                </div>
              </div>

              <div className="icon-box bg-lights">
                <h4>
                  <a href="/" className="text-dark">
                    {NavLink.vehicles}
                  </a>
                </h4>
                <div className="icon">
                  <img src={car} alt="car" />
                </div>
              </div>
            </div>
          </Col>
          <Col xl="5">
            {Services?.map((item, index) => (
              <div className="about-content" key={index}>
                <h3>{item.heading}</h3>
                {settingDetails?.our_services_description ? (
                  <p>{settingDetails?.our_services_description}</p>
                ) : (
                  <p>{item?.text}</p>
                )}
              </div>
            ))}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Services;
