import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../asset/images/logo.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import validator from "validator";
import {
  login,
  signUp,
  forgotPassword,
  resetPasswordToken,
  resetPassword,
  getSubscriptionList,
  SubscribeCompany,
  validateOTP,
} from "../services/AuthServices";
import Utils from "../utils";
import { authActions } from "../store";
import { toast } from "react-toastify";
import {
  actionCityList,
  actionCountryList,
  actionStateList,
} from "../services/CommonService";
import Signup from "./auth/Signup";
import MbwayIcon from "../asset/images/MbwayIcon.svg";
import MultiBancoIcon from "../asset/images/Multibanco.png";
import {
  CreatePaymentMBpay,
  CreatePaymentMultibanco,
  getPaymentWebHook,
} from "../services/PaymentServices";
import ConfigDB from "../config";
import axios from "axios";

const preventRefresh = (e) => {
  e.preventDefault();
};

const Header = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");

  const [zipCode, setZipCode] = useState("");
  let errorsObj = { email: "", password: "", subscription: "" };
  let resetPassErrorsObj = { res_password: "", confirm_password: "" };
  let signUpErrorsObj = {
    name: "",
    email: "",
    password: "",
    address: "",
    address_proof: "",
    company_registration_number: "",
    state: "",
    country: "",
    zipCode: "",
    city: "",
  };
  let forgotErrorsObj = { email: "" };
  let forgotOTPErrorsObj = { otp: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [signUpErrors, setSignUpErrors] = useState(signUpErrorsObj);
  const [forgotPassErrors, setForgotPassErrors] = useState(forgotErrorsObj);
  const [forgotPassOTPErrors, setForgotPassOTPErrors] =
    useState(forgotOTPErrorsObj);
  const [resetPassErrors, setResetPassErrors] = useState(resetPassErrorsObj);
  const [name, setName] = useState("");
  const [userUUID, setUserUUID] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [tokenOTP, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [res_password, setResPassword] = useState("");
  const [confirm_password, setConfPassword] = useState("");
  const [address, setAddress] = useState("");
  const [address_proof, setAddressProof] = useState("");
  const [company_registration_number, setCompanyRegistrationNumber] =
    useState("");
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isForgotLoading, setForgotLoading] = useState(false);
  const [isForgotOTPLoading, setForgotOTPLoading] = useState(false);
  const [isResetLoading, setResetLoading] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [signUpModal, setSignUpModal] = useState(false);
  const [forgotPassModal, setForgotPassModal] = useState(false);
  const [forgotPassotpModal, setForgotPassOTPModal] = useState(false);
  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const [resetPassModal, setResetPassModal] = useState(false);
  const [isSignUpButtonLoad, setIsSignUpButtonLoad] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [SubscriptionList, setSubscriptionList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [isStateLoad, setIsStateLoad] = useState(false);
  const [isCityLoad, setIsCityoad] = useState(false);
  const [purchaseModal, setPurchaseModal] = useState(false);
  const [purchaseDetailsModal, setPurchaseDetailsModal] = useState(false);
  const [purchaseData, setPurchaseData] = useState({
    amount: "",
    mobileNumber: "",
    email: "",
    description: "",
    type_payment: "mb_way",
  });
  const [purchaseDetailsData, setPurchaseDetailsData] = useState("");

  const [purchaseMessageModal, setPurchaseMessageModal] = useState(false);
  const [amountDetails, setAmountDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const [purchaseMultiBancoModal, setPurchaseMultiBancoModal] = useState(false);
  const [multiBancoDetails, setMultiBancoDetails] = useState("");
  const [selectedMethod, setSelectedMethod] = useState(null);
  const API_URL = ConfigDB.data.api_url;

  const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const handlePurchaseDetailsClose = () => {
    setPurchaseModal(true);
    setPurchaseDetailsModal(false);
  };

  const handlePurchaseClose = () => {
    setPurchaseModal(false);
  };

  const handlePurchaseSubmit = (method) => {
    setSelectedMethod(method);

    setPurchaseModal(false);
    setPurchaseDetailsModal(true);
  };

  const handlePurchaseMUltibancoSubmit = (e, method) => {
    e.preventDefault();
    setSelectedMethod(method);

    setPurchaseModal(false);
    setLoading(true);

    const amount = isChecked
      ? amountDetails?.yearly_price
      : amountDetails?.monthly_price;

    let data = {
      amount: amount,
    };

    CreatePaymentMultibanco(data)
      .then((response) => {
        toast.dismiss();
        toast.success(response.data.message);
        setMultiBancoDetails(response.data.data);
        setLoading(false);
        setPurchaseMultiBancoModal(true);
      })

      .catch((err) => {
        setLoading(false);
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handlePurchaseMessageClose = () => {
    setPurchaseMessageModal(false);
    navigate("/dashboard");
  };

  const handlePurchaseMultiBancoMessageClose = () => {
    setPurchaseMultiBancoModal(false);
  };

  function LoadList() {
    getSubscriptionList()
      .then((response) => {
        setSubscriptionList(response.data.data);
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  }
  useEffect(() => {
    if (token) {
      var obj = {
        token: token,
      };
      resetPasswordToken(obj)
        .then((response) => {
          setResetPassModal(true);
        })
        .catch((error) => {
          toast.error(Utils.getErrorMessage(error));
        })
        .finally(() => {});
    }
  }, [token]);

  useEffect(function () {
    actionCountryList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }
        setCountryList(list);
      })
      .catch((err) => {});
  }, []);

  const onUpdateCountry = (e) => {
    let countryId = e.target.value;
    setCountry(countryId);
    setState("");
    setIsStateLoad(true);

    actionStateList({ country_id: countryId })
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }
        setStateList(list);
        setIsStateLoad(false);
      })
      .catch((err) => {
        setIsStateLoad(false);
      });
  };

  const onUpdateState = (e) => {
    let stateId = e.target.value;
    setState(stateId);
    setCity("");
    setIsCityoad(true);

    actionCityList({ state_id: stateId })
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }
        setCityList(list);
        setIsCityoad(false);
      })
      .catch((err) => {
        setIsCityoad(false);
      });
  };

  const valueChangedHandler = function () {
    setIsChecked(!isChecked);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setAddressProof(selectedFile);

    if (selectedFile) {
      setSelectedFileName(selectedFile.name);
    } else {
      setSelectedFileName("");
    }
  };

  const validateImage = (file) => {
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    if (!allowedTypes.includes(file.type)) {
      return false;
    }

    if (file.size > 5 * 1024 * 1024) {
      return false;
    }

    return true;
  };

  const onLoginModalClose = (e) => {
    e.preventDefault();
    setLoginModal(false);
  };

  const onLoginModal = (e) => {
    e.preventDefault();
    setSignUpModal(false);
    setLoginModal(true);
  };

  const onLogin = (e) => {
    e.preventDefault();

    let errorObj = { ...errorsObj };
    let error = false;

    if (!email) {
      errorObj.email = "Email address is required";
      error = true;
    } else if (!validator.isEmail(email)) {
      errorObj.email = "Please enter valid email address";
      error = true;
    }

    if (!password) {
      errorObj.password = "Password is required";
      error = true;
    }

    setErrors(errorObj);
    Utils.focusError();

    if (error) return;

    let obj = {
      email: email,
      password: password,
    };
    setIsLoading(true);
    login(obj)
      .then((response) => {
        let userData = response?.data?.data;
        if (userData) {
          setUserUUID(userData.id);

          if (isEmpty(userData.subscription_data) && userData.role_id != 1) {
            setLoginModal(false);
            setSubscriptionModal(true);
            LoadList();
          } else {
            const todayDate = new Date();

            if (
              userData.role_id != 1 &&
              userData.subscription_data.subscribed_ends < todayDate
            ) {
              setLoginModal(false);
              setSubscriptionModal(true);
              LoadList();

              errorObj.subscription =
                "Your subscription is expired pleased renew it.";
              error = true;
              setErrors(errorObj);
              if (error) return;
            } else {
              Utils.setDetail(userData);
              dispatch(authActions.login(userData));
              navigate("/dashboard");
              // setPurchaseModal(true);
              setLoginModal(false);
            }
          }
        }
      })
      .catch((error) => {
        toast.error(Utils.getErrorMessage(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSignUpModal = (e) => {
    e.preventDefault();
    setLoginModal(false);
    setSignUpModal(true);
  };

  const onSignUpModalClose = (e) => {
    e.preventDefault();
    setSignUpModal(false);
  };

  const onForgotPassOTPModal = (e) => {
    e.preventDefault();
    setForgotPassModal(false);
    setForgotPassOTPModal(true);
  };

  const onForgotPassOTPModalClose = (e) => {
    e.preventDefault();
    setForgotPassOTPModal(false);
  };

  const onForgotPasswordOTP = (e) => {
    e.preventDefault();
    let errorObj = { ...forgotOTPErrorsObj };
    let error = false;

    if (!otp) {
      errorObj.otp = "OTP is required";
      error = true;
    }

    setForgotPassOTPErrors(errorObj);
    if (error) return;

    let obj = {
      otp: otp,
      email: email,
    };
    setForgotOTPLoading(true);
    validateOTP(obj)
      .then((response) => {
        toast.success(response.data.message);
        setEmail("");
        setOtp("");
        let token = response.data.token;
        if (token) {
          var obj = {
            token: token,
          };
          setToken(token);
          resetPasswordToken(obj)
            .then((response) => {
              setResetPassModal(true);
              setForgotPassOTPModal(false);
            })
            .catch((error) => {
              toast.error(Utils.getErrorMessage(error));
            })
            .finally(() => {});
        }
      })
      .catch((error) => {
        toast.error(Utils.getErrorMessage(error));
      })
      .finally(() => {
        setForgotOTPLoading(false);
      });
  };

  const onForgotPassModal = (e) => {
    e.preventDefault();
    setLoginModal(false);
    setForgotPassModal(true);
  };

  const onForgotPassModalClose = (e) => {
    e.preventDefault();
    setForgotPassModal(false);
  };

  const onResetPassModalClose = (e) => {
    e.preventDefault();
    setResetPassModal(false);
  };

  const onForgotPassword = (e) => {
    e.preventDefault();
    let errorObj = { ...forgotErrorsObj };
    let error = false;

    if (!email) {
      errorObj.email = "Email address is required";
      error = true;
    } else if (!validator.isEmail(email)) {
      errorObj.email = "Please enter valid email address";
      error = true;
    }

    setForgotPassErrors(errorObj);
    if (error) return;

    let obj = {
      email: email,
    };
    setForgotLoading(true);
    forgotPassword(obj)
      .then((response) => {
        toast.success(response.data.message);
        setOtp("");
        setForgotPassModal(false);
        setForgotPassOTPModal(true);
      })
      .catch((error) => {
        toast.error(Utils.getErrorMessage(error));
      })
      .finally(() => {
        setForgotLoading(false);
      });
  };

  const onResetPassword = (e) => {
    e.preventDefault();
    let errorObj = { ...resetPassErrorsObj };
    let error = false;

    if (!res_password) {
      errorObj.res_password = "Password is required";
      error = true;
    }
    if (!confirm_password) {
      errorObj.confirm_password = "Confirm password is required";
      error = true;
    }
    if (res_password != confirm_password) {
      errorObj.confirm_password = "Password doesn't match";
      error = true;
    }

    setResetPassErrors(errorObj);
    if (error) return;

    let obj = {
      token: tokenOTP,
      password: res_password,
      confirm_password: confirm_password,
    };
    setResetLoading(true);
    resetPassword(obj)
      .then((response) => {
        toast.success(response.data.message);
        setResetPassModal(false);
        setLoginModal(true);
      })
      .catch((error) => {
        toast.error(Utils.getErrorMessage(error));
      })
      .finally(() => {
        setResetLoading(false);
      });
  };

  const onSubscriptionModalClose = (e) => {
    e.preventDefault();
    setSubscriptionModal(false);
  };

  const onSubscription = (item) => {
    setAmountDetails(item);
    let obj = {
      user_id: userUUID,
      plan_type: isChecked ? 2 : 1,
      uuid: item.uuid,
    };
    SubscribeCompany(obj)
      .then((response) => {
        let userData = response?.data?.data;
        if (userData) {
          Utils.setDetail(userData);
          dispatch(authActions.login(userData));
          setSubscriptionModal(false);
          if (item.is_free === 1) {
            navigate("/dashboard");
          } else {
            setPurchaseModal(true);
          }
        }
      })
      .catch((error) => {
        toast.error(Utils.getErrorMessage(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSubmitMBPayPayment = (e) => {
    e.preventDefault();

    // Start the loading state
    setLoading(true); // Set loading to true

    const amount = isChecked
      ? amountDetails?.yearly_price
      : amountDetails?.monthly_price;

    let data = {
      amount: amount,
      mobileNumber: purchaseData?.mobileNumber,
      email: purchaseData?.email,
      type_payment: "mb_way",
    };

    CreatePaymentMBpay(data)
      .then((response) => {
        // End loading after response is received
        setLoading(false); // Set loading to false

        if (response.data.data.Message === "Declined") {
          toast.success(response.data.message);
        } else {
          setPurchaseDetailsData(response.data.data);
          toast.success(response.data.message);

          setPurchaseDetailsModal(false);
          if (response.data.data.Message === "Success") {
            const transactionId = response.data.data.RequestId;

            const requestId = transactionId;
            const webHookData = {
              paid_date: new Date().toISOString(),
            };

            axios({
              method: "GET",
              url: `${API_URL}payment-webhook?request_id=${requestId}`,
              data: webHookData,
              headers: {
                "Access-Control-Allow-Origin": "*",
                Accept: "*/*",
                "Content-Type": "application/json",
              },
            })
              .then((webhookResponse) => {
                setPurchaseMessageModal(true);
              })
              .catch((err) => {
                toast.error("Error processing webhook: " + err.message);
              });
          }
        }
      })
      .catch((err) => {
        // End loading even on error
        setLoading(false); // Set loading to false
        toast.dismiss();

        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <>
      <div>
        <Navbar
          collapseOnSelect
          expand="lg"
          className="customeNavbar"
          fixed="top"
        >
          <Container>
            <Navbar.Brand href="#home">
              <img alt={`...`} className="heigh30" src={logo} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mx-lg-auto margi-top">
                <Nav.Link href="/">Home</Nav.Link>
                <Nav.Link href="#About">About</Nav.Link>
                <Nav.Link href="#our-service">Service</Nav.Link>
              </Nav>
              <Nav>
                <div className="d-flex align-items-lg-center mt-3 mt-lg-0">
                  <a href="#contactUS" className=" btn-contact">
                    {" "}
                    Contact
                    <i className="bi bi-arrow-right-short icon-arrow" />
                  </a>
                  <button
                    type="button"
                    className="login-btn"
                    onClick={(e) => {
                      onLoginModal(e);
                    }}
                  >
                    <span className="text">Login</span>
                    <span className="icon">
                      <i className="bi bi-arrow-right-short" />
                    </span>
                  </button>
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

      <Modal isOpen={loginModal}>
        {/*<ModalHeader>{`Login`}</ModalHeader>*/}
        <ModalBody>
          <button type="button" className="close" onClick={onLoginModalClose}>
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="form">
            <div className="heading">LOGIN</div>
            <form method={`post`} onSubmit={onLogin}>
              <div className="padding-bottom--24">
                <label htmlFor="email" className="label-my">
                  E-Mail
                </label>
                <input
                  className="form-control input-my"
                  type="text"
                  id="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {errors.email && (
                  <span className="input-error">{errors.email}</span>
                )}
              </div>
              <div className="padding-bottom--24">
                <label htmlFor="password" className="label-my">
                  Password
                </label>
                <input
                  className="form-control input-my"
                  type="password"
                  id="password"
                  placeholder="Enter you password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {errors.password && (
                  <span className="input-error">{errors.password}</span>
                )}
                <span className="float-end">
                  <Link
                    to={"#"}
                    onClick={(e) => {
                      onForgotPassModal(e);
                    }}
                  >
                    {" "}
                    Forgot Password{" "}
                  </Link>
                </span>
              </div>
              <div className="d-flex justify-content-center mt-3">
                <button
                  className="login-btn"
                  type="submit"
                  disabled={isLoading}
                >
                  <span className="text">
                    {isLoading ? "Logging in..." : "Login"}
                  </span>
                  <span className="icon">
                    <i className="bi bi-arrow-right-short"></i>
                  </span>
                </button>
              </div>
            </form>
            <div className="d-flex justify-content-center mt-3">
              <p>
                {" "}
                Don't have an account ?{" "}
                <Link
                  class="signUp-a"
                  to={"#"}
                  onClick={(e) => {
                    onSignUpModal(e);
                  }}
                >
                  {" "}
                  Sign Up{" "}
                </Link>
              </p>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={forgotPassModal}>
        {/*<ModalHeader>{`forgot password`}</ModalHeader>*/}
        <ModalBody>
          <button
            type="button"
            className="close"
            onClick={onForgotPassModalClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="form">
            <div className="heading">FORGOT PASSWORD</div>
            <form method={`post`} onSubmit={onForgotPassword}>
              <div className="padding-bottom--24">
                <label htmlFor="email" className="label-my">
                  E-Mail
                </label>
                <input
                  className="form-control input-my"
                  type="text"
                  id="email"
                  placeholder="Enter your registered email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {forgotPassErrors.email && (
                  <span className="input-error">{forgotPassErrors.email}</span>
                )}
              </div>
              <div className="d-flex justify-content-center">
                <button
                  className="login-btn"
                  type="submit"
                  disabled={isForgotLoading}
                >
                  <span className="text">
                    {isForgotLoading ? "Logging in..." : "Submit"}
                  </span>
                  <span className="icon">
                    <i className="bi bi-arrow-right-short"></i>
                  </span>
                </button>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={forgotPassotpModal}>
        {/*<ModalHeader>{`forgot password`}</ModalHeader>*/}
        <ModalBody>
          <button
            type="button"
            className="close"
            onClick={onForgotPassOTPModalClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="form">
            <div className="heading">FORGOT PASSWORD OTP</div>
            <form method={`post`} onSubmit={onForgotPasswordOTP}>
              <div className="padding-bottom--24">
                <label htmlFor="otp" className="label-my">
                  OTP
                </label>
                <input
                  className="form-control input-my"
                  type="number"
                  id="otp"
                  placeholder="Enter your otp here"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
                {forgotPassOTPErrors.otp && (
                  <span className="input-error">{forgotPassOTPErrors.otp}</span>
                )}
              </div>
              <div className="d-flex justify-content-center">
                <button
                  className="login-btn"
                  type="submit"
                  disabled={isForgotOTPLoading}
                >
                  <span className="text">
                    {isForgotOTPLoading ? "Logging in..." : "Submit"}
                  </span>
                  <span className="icon">
                    <i className="bi bi-arrow-right-short"></i>
                  </span>
                </button>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={resetPassModal} fade={true}>
        <ModalBody>
          <button
            type="button"
            className="close"
            onClick={onResetPassModalClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="form">
            <div className="heading">RESET PASSWORD</div>
            <form method={`post`} onSubmit={onResetPassword}>
              <div className="padding-bottom--24">
                <label htmlFor="password" className="label-my">
                  Password
                </label>
                <input
                  className="form-control input-my"
                  type="password"
                  id="password"
                  placeholder="Enter your new password"
                  onChange={(e) => setResPassword(e.target.value)}
                />
                {resetPassErrors.res_password && (
                  <span className="input-error">
                    {resetPassErrors.res_password}
                  </span>
                )}
              </div>
              <div className="padding-bottom--24">
                <label htmlFor="confirm_password" className="label-my">
                  Confirm Password
                </label>
                <input
                  className="form-control input-my"
                  type="password"
                  id="confirm_password"
                  placeholder="Enter your confirm password"
                  onChange={(e) => setConfPassword(e.target.value)}
                />
                {resetPassErrors.confirm_password && (
                  <span className="input-error">
                    {resetPassErrors.confirm_password}
                  </span>
                )}
              </div>
              <div className="d-flex justify-content-center">
                <button
                  className="login-btn"
                  type="submit"
                  disabled={isResetLoading}
                >
                  <span className="text">
                    {isResetLoading ? "Logging in..." : "Submit"}
                  </span>
                  <span className="icon">
                    <i className="bi bi-arrow-right-short" />
                  </span>
                </button>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={subscriptionModal} className="modal-dialog modal-xl">
        <ModalBody>
          <button
            type="button"
            className="close"
            onClick={onSubscriptionModalClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="form">
            <div className="heading"></div>
            <form method={`post`}>
              <div className="heading mb-4">
                Subscription
                <div
                  className={`bootstrap-switch ${
                    isChecked ? "bootstrap-switch-on" : "bootstrap-switch-off"
                  }`}
                  style={{ marginLeft: "10px" }}
                >
                  <div
                    className="bootstrap-switch-container"
                    onClick={(e) => valueChangedHandler()}
                  >
                    <span
                      className={`bootstrap-switch-handle-on ${
                        isChecked ? "bootstrap-switch-primary" : ""
                      }`}
                    >
                      Yearly
                    </span>
                    <span className="bootstrap-switch-label">&nbsp;</span>
                    <span
                      className={`bootstrap-switch-handle-off ${
                        !isChecked ? "bootstrap-switch-default" : ""
                      }`}
                    >
                      Monthly
                    </span>
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={(e) => setIsChecked(e.target.value)}
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
              </div>

              <div className="padding-bottom--24">
                <div className="container">
                  <div className="card-deck mb-3  card-deck-custome">
                    {SubscriptionList?.map((item, index) => {
                      return (
                        <>
                          <div className="card mb-4 box-shadow">
                            <div className="card-header">
                              <h4 className="my-0 font-weight-normal">
                                {item.name}
                              </h4>
                            </div>
                            <div className="card-body">
                              <h5 className="pricing-card-title">
                                <b>
                                  $
                                  {isChecked
                                    ? item.yearly_price + "/Year"
                                    : item.monthly_price + "/Month"}
                                </b>{" "}
                              </h5>
                              <div className="list-unstyled mt-3 mb-4">
                                {item.subscription_desc}
                              </div>
                            </div>
                            <div className="card-footer">
                              <div className="d-flex justify-content-center">
                                <button
                                  className="login-btn"
                                  type="button"
                                  onClick={(e) => onSubscription(item)}
                                >
                                  <span className="text">
                                    {item.is_free ? "Free" : "Purchase"}
                                  </span>
                                  <span className="icon">
                                    <i className="bi bi-currency-dollar" />
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>

      <Signup
        signUpModal={signUpModal}
        setSignUpModal={setSignUpModal}
        setLoginModal={setLoginModal}
        subscriptionModal={subscriptionModal}
        setSubscriptionModal={setSubscriptionModal}
        setSubscriptionList={setSubscriptionList}
        SubscriptionList={SubscriptionList}
        userUUID={userUUID}
        setUserUUID={setUserUUID}
      />

      <Modal isOpen={purchaseModal}>
        <ModalHeader>Payment Method</ModalHeader>
        <ModalBody>
          <div
            class="card"
            style={{ borderRadius: "8px", marginBottom: "0px" }}
          >
            <div
              className={`card-body ${
                selectedMethod === "MBWAY" ? "selected" : ""
              }`}
              style={{ display: "flex", cursor: "pointer" }}
              onClick={() => handlePurchaseSubmit("MBWAY")}
            >
              <img src={MbwayIcon} alt="" style={{ width: "85px" }} />
              <h4 style={{ fontSize: "21px", marginTop: "10px" }}>MBWAY</h4>
            </div>
            <div
              className={`card-body ${
                selectedMethod === "MultiBanco" ? "selected" : ""
              }`}
              style={{
                display: "flex",
                cursor: "pointer",
                marginLeft: "-13px",
              }}
              onClick={(e) => handlePurchaseMUltibancoSubmit(e, "MultiBanco")}
            >
              <img src={MultiBancoIcon} alt="" style={{ width: "85px" }} />
              <h4 style={{ fontSize: "21px", marginTop: "10px" }}>
                MultiBanco
              </h4>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-secondary"
            onClick={handlePurchaseClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close <i class="bi bi-x-lg"></i>
          </button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={purchaseDetailsModal}>
        <div className="spinner-border" role="status" style={{position: "absolute", left: "50%" , top:"46%" , opacity:"0.2", zIndex: "0"}}>
          <span className="visually-hidden">Loading...</span>
        </div>
        <ModalHeader style={{padding: "0px" , zIndex: "999"}} className="mbWay">
          <img src={MbwayIcon} alt="" style={{ width: "85px" }} />
          MBWAY
        </ModalHeader>
        <ModalBody style={{padding: "7px"}}>
        <Form method={`post`} onSubmit={handleSubmitMBPayPayment}>
          {loading ? (
              <span style={{display: "flex", justifyContent: "center"}}>Authorize the payment in MBWAY APP</span>
          ) : (
            <>
              <ModalBody>
                <div className="row">
                  <div className="col-md-12">
                    <FormGroup>
                      <Label className="col-form-label ml-2 Label-my">
                        {"Mobile Number"}
                      </Label>
                      <div className="col-xl-12">
                        <div className="input-div">
                          <input
                            className="form-control input-search"
                            type="text"
                            value={purchaseData.mobileNumber}
                            onChange={(e) => {
                              setPurchaseData({
                                ...purchaseData,
                                mobileNumber: e.target.value,
                              });
                            }}
                            name={`mobileNumber`}
                            maxLength={9}
                            placeholder="Mobile Number"
                          />
                        </div>
                      </div>
                    </FormGroup>
                  </div>

                  <div className="col-md-12">
                    <FormGroup>
                      <Label className="col-form-label ml-2 Label-my">
                        {"Email"}
                      </Label>
                      <div className="col-xl-12">
                        <div className="input-div">
                          <input
                            className="form-control input-search"
                            type="text"
                            value={purchaseData.email}
                            onChange={(e) => {
                              setPurchaseData({
                                ...purchaseData,
                                email: e.target.value,
                              });
                            }}
                            name={`email`}
                            maxLength={100}
                            placeholder="Email"
                          />
                        </div>
                      </div>
                    </FormGroup>
                  </div>

                  {/* <div className="col-md-12">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"description"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <textarea
                        className="form-control input-search"
                        type="text"
                        value={purchaseData.description}
                        onChange={(e) => {
                          setPurchaseData({
                            ...purchaseData,
                            description: e.target.value,
                          });
                        }}
                        name={`description`}
                        maxLength={100}
                        placeholder="description"
                        maxrow={3}
                      />
                    </div>
                  </div>
                </FormGroup>
              </div> */}
                </div>
              </ModalBody>
              <ModalFooter>
                <button
                  type="button"
                  class="btn btn-block btn-secondary"
                  onClick={handlePurchaseDetailsClose}
                  style={{ width: "100px", marginTop: "10px" }}
                >
                  Close <i class="bi bi-x-lg"></i>
                </button>
                <button
                  type="submit"
                  class="btn btn-block btn-primary"
                  style={{ width: "100px", marginTop: "10px" }}
                >
                  Submit <i class="bi bi-check2"></i>
                </button>
              </ModalFooter>
            </>
          )}
        </Form>
        </ModalBody>
      </Modal>

      <Modal isOpen={purchaseMessageModal}>
        <ModalHeader className="d-flex justify-content-center">
          Payment Successful!!
        </ModalHeader>
        <ModalBody>
          <div
            class="card"
            style={{
              boxShadow: "none",
              marginBottom: "0px",
              textAlign: "center",
            }}
          >
            <div class="card-body">
              <div className="checked">
                <i className="bi bi-check-lg"></i>
              </div>
              <h5 style={{ fontSize: "11px", marginTop: "12px" }}>
                Your trascation Id: {purchaseDetailsData?.RequestId}
              </h5>
              <p style={{ fontSize: "14px", color: "#000", marginTop: "12px" }}>
                Payment was made successfully
              </p>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="border-0" style={{ marginTop: "-18px" }}>
          <button
            type="button"
            class="btn btn-block btn-payment"
            onClick={handlePurchaseMessageClose}
            style={{ width: "auto", margin: "auto", padding: "5px 12px" }}
          >
            Ok <i class="bi bi-x-lg"></i>
          </button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={purchaseMultiBancoModal}>
        <ModalHeader className="d-flex justify-content-center">
          <img src={MultiBancoIcon} alt="" style={{ width: "66px" }} />
          Payment via Multibanco or Homebanking
        </ModalHeader>
        <ModalBody>
          <div
            class="card"
            style={{
              boxShadow: "none",
              marginBottom: "0px",
              textAlign: "center",
            }}
          >
            <div class="card-body">
              <div className="checked">
              </div>
              <h5 style={{ fontSize: "21px", marginTop: "12px" }}>
                Entity: {multiBancoDetails?.entity}
              </h5>
              <h5 style={{ fontSize: "21px", marginTop: "12px" }}>
                Reference: {multiBancoDetails?.reference}
              </h5>

              <h5 style={{ fontSize: "21px", marginTop: "12px" }}>
                Amount: {multiBancoDetails?.amount} €
              </h5>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="border-0" style={{ marginTop: "-18px" }}>
          <button
            type="button"
            class="btn btn-block btn-payment"
            onClick={handlePurchaseMultiBancoMessageClose}
            style={{ width: "auto", margin: "auto", padding: "5px 12px" }}
          >
            Ok <i class="bi bi-x-lg"></i>
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Header;
