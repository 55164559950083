import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { toast } from "react-toastify";
import Utils from "../../utils";
import {
  actionAddRole,
  actionDelRole,
  actionUpdateRole,
  getRoleList,
} from "../../services/RoleServices";
import { useLocation, useNavigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';

const Roles = () => {

  const location = useLocation();
  const RolePermission = Utils.getRecordForCurrentPath(location);
  const tableLengthList = ConfigDB.data.dataTableLength;
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const companyId = JSON.parse(data);
  const navigate = useNavigate();

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    company_id: companyId.company_id || 0,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [roleList, setRoleList] = useState([]);
  const [delModal, setDelModal] = useState(false);
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [delpart, setDelPart] = useState("");
  const [modifyModal, setModifyModal] = useState(false);
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [roleData, setRoleData] = useState({
    uuid: "",
    name: "",
  });

  let errorsObj = {
    ler_code: "",
    item_weight: "",
    item_weight_unit: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const handleCloseModal = () => {
    setModifyModal(false);
  };

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  const featchRole = () => {
    let data = { ...params };
    getRoleList(data)
      .then((response) => {
        setRoleList(response.data.data);
        setTotalRecords(response.data.total);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(
    function () {
      let data = { ...params };

      featchRole(data);
    },
    [params]
  );

  const onDeleteModalClose = () => {
    setDelModal(false);
  };

  const onDeleteModalOpen = (e, item) => {
    e.preventDefault();
    setDelPart(item.uuid);
    setDelModal(true);
  };

  const onDestroyPartType = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: delpart };
    actionDelRole(data)
      .then((response) => {
        featchRole();
        toast.success(response.data.message);
        setDelPart("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleRoleForm = (e) => {
    e.preventDefault();
    let error = false;
    let errorObj = { ...errorsObj };
    if (!roleData.name) {
      errorObj.name = `Role is  required.`;
      error = true;
    }

    setErrors(errorObj);
    Utils.focusError();

    if (error) return;

    setIsModifyButtonLoad(true);
    let data = {
      name: roleData.name,
      is_super_role: 0,
    };

    let updatedData = {
      uuid: roleData.uuid,
      name: roleData.name,
      is_super_role: 0,
    };

    actionAddRole(data)
      .then((response) => {
        toast.success(response.data.message);
        let datas = { ...params };

        featchRole(datas);
        setModifyModal(false);
        setIsModifyButtonLoad(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        setIsModifyButtonLoad(false);
      });
    if (roleData?.uuid) {
      actionUpdateRole(updatedData)
        .then((response) => {
          toast.success(response.data.message);
          let datas = { ...params };

          featchRole(datas);
          setModifyModal(false);
          setIsModifyButtonLoad(false);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
          setIsModifyButtonLoad(false);
        });
    }
  };
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div>
      {isLoading ? (
        // <div className="text-center pageLoader">
        //   <div className="spinner-border" role="status">
        //     <span className="visually-hidden">Loading...</span>
        //   </div>
        // </div>
        <div className="skeleton-loader p-3 d-none">
          <div className="card">
            <div className="card-header">
              <table className="table rounded-table table-striped table-hover">
                <thead>
                  <tr>
                    <th style={{ width: 10, borderTop: "0", borderBottom: "0" }}><div className="skeleton" ></div></th>
                    <th style={{ borderTop: "0", borderBottom: "0" }}><div className="skeleton"></div></th>
                    <th style={{ borderTop: "0", borderBottom: "0" }}></th>
                    <th class="d-flex" style={{ borderTop: "0", borderBottom: "0" }}><div class="skeleton me-1"></div>
                      <div class="skeleton me-1"></div>
                      <div class="skeleton"></div>
                    </th>
                    <th style={{ width: 10, borderTop: "0", borderBottom: "0" }}><div className="skeleton"></div></th>
                  </tr>
                </thead>
              </table>
            </div>
            <div className="card-body">
              <div className="vehicleThBg table-responsive">
                <table className="table table-bordered rounded-table table table-striped table-hover">
                  <thead>
                    <tr>
                      <th style={{ backgroundColor: "#c0c0c0" }}><div className="skeleton"></div></th>
                      <th style={{ backgroundColor: "#c0c0c0" }}><div className="skeleton"></div></th>
                      <th style={{ backgroundColor: "#c0c0c0" }}><div className="skeleton"></div></th>
                    </tr>
                  </thead>
                  <tbody>
                    {roleList?.map((_, index) => (
                      <tr key={index}>
                        <td><div className="skeleton"></div></td>
                        <td><div className="skeleton"></div></td>
                        <td><div className="skeleton"></div></td>

                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card-footer">
              <div className="skeleton col-lg-3 float-right"></div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  {/* <h1>Roles</h1> */}
                </div>
                <div className="col-sm-6">

                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card fuelCard">
                    <div className="card-header">
                      <div className="card-title m-0 float-left" style={{width: "50%"}}>
                        <input
                          type="text"
                          className="form-control"
                          value={params.search}
                          onChange={(e) => handleParams(e, "search")}
                          placeholder="Search"
                        ></input>
                      </div>
                      <div className="card-title m-0 float-right d-flex">
                        {RolePermission?.findIndex((e) => e.permission_name === "add_role") > -1 && (
                          <button
                            type="button"
                            className="btn  btn-icon-text mr-2"
                            onClick={() => navigate("/add-role-permssion")}
                          >
                            <i className="bi bi-plus-lg"></i>
                            <span className="ms-2">Add</span>
                          </button>
                        )}

                      </div>
                    </div>
                    <div className="card-body">
                      <div className="vehicleThBg" style={{height: "calc(76vh - 50px)"}}>
                        <table className="table table-bordered rounded-table table table-striped table-hover">
                          <thead>
                            <tr>
                              <th style={{ width: 10 }}>#</th>
                              <th className="col-form-label ml-2 Label-my form-label form-label">
                                Name
                              </th>

                              <th
                                className="col-form-label ml-2 Label-my form-label form-label"
                                style={{ width: 10 }}
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {roleList?.map((item, index) => {
                              return (
                                <>
                                  <tr key={item.id}>
                                    <td>{++index}</td>
                                    <td>{item.name}</td>
                                    <td className="d-flex">
                                      <Dropdown className="iconDropdown">
                                        <Dropdown.Toggle variant="success" id="dropdown-basic" className="iconToggle">
                                          <i className="bi bi-three-dots-vertical"></i>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item href="#/action-1" className="d-flex">
                                            {RolePermission?.findIndex((e) => e.permission_name === "modify_role") > -1 && (
                                                <a
                                                    className="edit-btn"
                                                    href="#"
                                                    onClick={(e) => {
                                                      navigate(
                                                          `/add-role-permssion/${item.uuid}`
                                                      );
                                                    }}
                                                >
                                                  <i className="bi bi-pencil"></i>
                                                </a>
                                            )}<span>Edit</span>
                                          </Dropdown.Item>
                                          <Dropdown.Item href="#/action-2" className="d-flex">
                                            {RolePermission?.findIndex((e) => e.permission_name === "delete_role") > -1 && (
                                                <div
                                                    className="trash-btn"
                                                    onClick={(e) =>
                                                        onDeleteModalOpen(e, item)
                                                    }
                                                >
                                                  <a className="delete-btn" href="#">
                                                    <i className="bi bi-trash3"></i>
                                                  </a>
                                                </div>
                                            )}<span>Delete</span>
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>




                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                          {roleList && roleList?.length === 0 && (
                            <tr>
                              <td
                                colSpan={7}
                                className={`text-center`}
                              >{`Record Not Found`}</td>
                            </tr>
                          )}
                        </table>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="fuelCF">
              <div className="card-footer clearfix card-footer-part">
                <div className="pagination pagination-sm m-0 float-right">
                  <Pagination
                    activePage={params.page}
                    itemsCountPerPage={parseInt(params.limit)}
                    totalItemsCount={parseInt(totalRecords)}
                    pageRangeDisplayed={5}
                    itemClass={`page-item`}
                    linkClass={`page-link`}
                    onChange={(e) => handleParams(e, "pagination")}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
      <Modal isOpen={delModal}>
        <ModalHeader>{`Delete Role`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this role?`}</p>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            disabled={isDelButtonLoad}
            onClick={onDeleteModalClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close <i class="bi bi-x-lg"></i>
          </button>

          {!isDelButtonLoad && (
            <button
              type="button"
              className="btn btn-block bg-red"
              style={{ width: "100px" }}
              onClick={onDestroyPartType}
            >
              Delete <i class="bi bi-trash3"></i>
            </button>
          )}
          {isDelButtonLoad && (
            <button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
            </button>
          )}
        </ModalFooter>
      </Modal>
      <Modal isOpen={modifyModal}>
        <ModalHeader>Role</ModalHeader>
        <Form method={`post`} onSubmit={handleRoleForm}>
          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Role Name"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={roleData.name}
                        onChange={(e) => {
                          setRoleData({
                            ...roleData,
                            name: e.target.value,
                          });
                        }}
                        name={`name`}
                        maxLength={50}
                        placeholder="Role Name"
                      />
                    </div>
                    {errors.name && (
                      <span className="input-error">{errors.name}</span>
                    )}
                  </div>
                </FormGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              class="btn btn-block btn-secondary"
              disabled={isModifyButtonLoad}
              onClick={handleCloseModal}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-x-lg"></i>
            </button>
            {!isModifyButtonLoad && (
              <button
                type="submit"
                class="btn btn-block btn-primary"
                style={{ width: "100px", marginTop: "10px" }}
              >
                Submit <i class="bi bi-check2"></i>
              </button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default Roles;
