import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Utils from "../../utils";
import {
  getpublicPartList,
  getVehiclePartList,
} from "../../services/VehicleService";

const PartDetailsPublic = () => {
  const state = useLocation();

  console.log("state", state?.state);

  const { uuid } = useParams(); // Get the uuid from URL

  const [vehiclePartDetails, setVehiclePartDetails] = useState([]);
  console.log("aaaaa", vehiclePartDetails);

  useEffect(() => {
    if (uuid) {
      let data = {
        uuid: uuid,
      };

      getpublicPartList(data)
        .then((response) => {
          setVehiclePartDetails(response.data.data);
          //   setTotalRecords(response.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [uuid]);

  return (
    <div>
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">{/* <h1>Vehicle</h1> */}</div>
              <div className="col-sm-6"></div>
            </div>
          </div>
        </section>
        <section className="content">
          <>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card fuelCard">
                    <div className="cardHeader card-header card-header-custom">
                      <p>Part Details</p>
                    </div>
                    <div className="card-body card-body-custom">
                      <div className="row justify-content-start">
                        <div className="col-sm-3">
                          <div className="invoice-col">
                            <b> Part Name:</b>{" "}
                            <address>{vehiclePartDetails?.namePartPT}</address>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="invoice-col">
                            <b> Part Code:</b>{" "}
                            <address>{vehiclePartDetails?.part_code}</address>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="invoice-col">
                            <b> Description:</b>{" "}
                            <address>
                              {vehiclePartDetails?.part_description}
                            </address>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="invoice-col">
                            <b> Original Price:</b>{" "}
                            <address>
                              {vehiclePartDetails?.part_original_price}
                            </address>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="invoice-col">
                            <b> Cost Price:</b>{" "}
                            <address>
                              {vehiclePartDetails?.part_cost_price}
                            </address>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="invoice-col">
                            <b> Part Price:</b>{" "}
                            <address>{vehiclePartDetails?.part_price}</address>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="invoice-col">
                            <b>Brand Name:</b>{" "}
                            <address>{vehiclePartDetails.brand_name}</address>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="invoice-col">
                            <b>Condition Name:</b>{" "}
                            <address>
                              {vehiclePartDetails?.condition_name}
                            </address>
                          </div>
                        </div>

                        <div className="col-sm-3">
                          <div className="invoice-col">
                            <b>Inventory Status Name:</b>{" "}
                            <address>
                              {vehiclePartDetails?.inventory_status_name}
                            </address>
                          </div>
                        </div>

                        <div className="col-sm-3">
                          <div className="invoice-col">
                            <b>Height:</b>{" "}
                            <address>
                              {vehiclePartDetails?.parts_height}
                            </address>
                          </div>
                        </div>

                        <div className="col-sm-3">
                          <div className="invoice-col">
                            <b>Length:</b>{" "}
                            <address>
                              {vehiclePartDetails?.parts_length}
                            </address>
                          </div>
                        </div>

                        <div className="col-sm-3">
                          <div className="invoice-col">
                            <b>Width:</b>{" "}
                            <address>{vehiclePartDetails?.parts_width}</address>
                          </div>
                        </div>

                        <div className="col-sm-3">
                          <div className="invoice-col">
                            <b>Weight:</b>{" "}
                            <address>
                              {vehiclePartDetails?.parts_weight}
                            </address>
                          </div>
                        </div>

                        <div className="col-sm-3">
                          <div className="invoice-col">
                            <b>Print Label:</b>{" "}
                            <address>{vehiclePartDetails?.print_label}</address>
                          </div>
                        </div>

                        <div className="col-sm-3">
                          <div className="invoice-col">
                            <b>Modal Name:</b>{" "}
                            <address>
                              {vehiclePartDetails?.vehicle_model_name}
                            </address>
                          </div>
                        </div>

                        <div className="col-sm-3">
                          <div className="invoice-col">
                            <b>Year:</b>{" "}
                            <address>
                              {vehiclePartDetails?.initial_year}
                            </address>
                          </div>
                        </div>

                        <div className="col-sm-3">
                          <div className="invoice-col">
                            <b>Make Name:</b>{" "}
                            <address>
                              {vehiclePartDetails?.vehicle_make_name}
                            </address>
                          </div>
                        </div>
                      </div>
                    </div>
                    {vehiclePartDetails?.part_qty_list?.length > 0 && (
                      <>
                        <div className="card-custom-heading">
                          <p>Part Quntity List</p>
                        </div>
                        <div className="card-body card-body-custom">
                          <div className="vehicleThBg table-responsive">
                            <table className="table table-bordered rounded-table table table-striped table-hover">
                              <thead>
                                <tr>
                                  <th className="col-form-label ml-2 Label-my form-label form-label">
                                    Rating
                                  </th>
                                  <th className="col-form-label ml-2 Label-my form-label form-label">
                                    Location Name
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {vehiclePartDetails?.part_qty_list?.map(
                                  (vehiclePartDetailss) => {
                                    return (
                                      <>
                                        <tr>
                                          <td>
                                            {vehiclePartDetailss?.part_rating}
                                          </td>
                                          <td>
                                            {vehiclePartDetailss?.location_name}
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    )}

                    {vehiclePartDetails?.parts_reference?.length > 0 && (
                      <>
                        <div className="card-custom-heading">
                          <p>Part Reference List</p>
                        </div>
                        <div className="card-body card-body-custom">
                          <div className="vehicleThBg table-responsive">
                            <table className="table table-bordered rounded-table table table-striped table-hover">
                              <thead>
                                <tr>
                                  <th className="col-form-label ml-2 Label-my form-label form-label">
                                    Condition Name
                                  </th>
                                  <th className="col-form-label ml-2 Label-my form-label form-label">
                                    Type Name
                                  </th>

                                  <th className="col-form-label ml-2 Label-my form-label form-label">
                                    Is Main
                                  </th>

                                  <th className="col-form-label ml-2 Label-my form-label form-label">
                                    Reference Code{" "}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {vehiclePartDetails?.parts_reference?.map(
                                  (vehiclePartDetailss) => {
                                    return (
                                      <>
                                        <tr>
                                          <td>
                                            {
                                              vehiclePartDetailss?.condition_name
                                            }
                                          </td>
                                          <td>
                                            {vehiclePartDetailss?.type_name}
                                          </td>
                                          <td>
                                            {vehiclePartDetailss?.is_main}
                                          </td>
                                          <td>
                                            {
                                              vehiclePartDetailss?.reference_code
                                            }
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    )}

                    {vehiclePartDetails?.parts_associate?.length > 0 && (
                      <>
                        <div className="card-custom-heading">
                          <p>Part Associate List</p>
                        </div>
                        <div className="card-body card-body-custom">
                          <div className="vehicleThBg table-responsive">
                            <table className="table table-bordered rounded-table table table-striped table-hover">
                              <thead>
                                <tr>
                                  <th className="col-form-label ml-2 Label-my form-label form-label">
                                    Name
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {vehiclePartDetails?.parts_associate?.map(
                                  (vehiclePartDetailss) => {
                                    return (
                                      <>
                                        <tr>
                                          <td>
                                            {
                                              vehiclePartDetailss?.vehicle_model_name
                                            }
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    )}

                    {vehiclePartDetails?.image_list?.length > 0 && (
                      <>
                        <div className="card-custom-heading">
                          <p>Part images</p>
                        </div>
                        <div className="card-body card-body-custom">
                          <div className="row justify-content-start">
                            {vehiclePartDetails?.image_list?.map((image) => {
                              return (
                                <>
                                  <div className="col-sm-3">
                                    <div className="column-img">
                                      <img src={image?.image_url} alt="" />
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        </section>
      </div>
    </div>
  );
};

export default PartDetailsPublic;
