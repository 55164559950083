import axiosInstance from "./AxiosInstance";

export function createPartsBrand(data) {
  return axiosInstance.post(`/parts-brand/create`, data);
}
export function updatePartsBrand(data) {
  return axiosInstance.post(`/parts-brand/update`, data);
}

export function getPartBrandList(data) {
  return axiosInstance.post(`/parts-brand/list`, data);
}

export function DestoryPartBrand(data) {
  return axiosInstance.post(`/parts-brand/delete`, data);
}

export function importPartBrand(data) {
  return axiosInstance.post(`/parts-brand/import`, data);
}

export function exportPartBrandList(data) {
  return axiosInstance.post(`/parts-brand/export-list`, data);
}

export function getAllBrandSerachList(data) {
  return axiosInstance.post(`/brand-search-list`, data);
}
