import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Utils from "../../utils";
import { getVehiclePartList } from "../../services/VehicleService";

const PartdetailsInventory = () => {
  const state = useLocation();
  const navigate = useNavigate();

  const [vehiclePartDetails, setVehiclePartDetails] = useState([]);

  console.log("state", state);

  useEffect(() => {
    if (state?.state?.id) {
      let data = {
        vehicle_id: 0,
        part_id: state?.state?.id,
      };

      getVehiclePartList(data)
        .then((response) => {
          setVehiclePartDetails(response.data.data);
          //   setTotalRecords(response.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [state?.state?.id]);

  return (
    <div>
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">{/* <h1>Vehicle</h1> */}</div>
              <div className="col-sm-6"></div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card fuelCard">
              <div className="cardHeader card-header card-header-custom">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <p>Part Details</p>
                  </div>
                  <div className="col-sm-6">
                    <div className="d-flex  justify-content-end">
                      <button
                        type="button"
                        className="next-button btn btn-secondary"
                        onClick={() => navigate("/parts-inventory")}
                      >
                        <i className="bi bi-arrow-left"></i> Back
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          {vehiclePartDetails?.parts?.length > 0 ? (
            vehiclePartDetails?.parts?.map((item) => {
              const publicPageUrl = `${window.location.origin}/part-details/${item.uuid}`;
              const mailto = `mailto:recipient@example.com?subject=Part Details Link&body=Here is the link to the part details:%0D%0A${publicPageUrl}`;
              const whatsappUrl = `https://wa.me/?text=Here is the link to the part details:%20${encodeURIComponent(
                publicPageUrl
              )}`;

              return (
                <>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card fuelCard">
                          <div className="cardHeader card-header card-header-custom">
                            <div className="row">
                              <div className="col-md-4">
                                <p>Part Details</p>
                              </div>
                              <div className="col-md-8">
                                <div className="float-end">
                                  <a href={mailto} className="btn btn-email">
                                    <i className="bi bi-envelope"></i>
                                    {/* <span className="ms-2">Send Part Details via Email</span> */}
                                  </a>

                                  <a
                                    href={whatsappUrl}
                                    className="btn btn-success btn-whats"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <i className="bi bi-whatsapp"></i>
                                    {/* <span className="ms-2">Share via WhatsApp</span> */}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card-body card-body-custom">
                            <div className="row justify-content-start">
                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b> Part Name:</b>{" "}
                                  <address>{item?.namePartPT}</address>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b> Part Code:</b>{" "}
                                  <address>{item?.part_code}</address>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b> Description:</b>{" "}
                                  <address>{item?.part_description}</address>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b> Original Price:</b>{" "}
                                  <address>{item?.part_original_price}</address>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b> Cost Price:</b>{" "}
                                  <address>{item?.part_cost_price}</address>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b> Part Price:</b>{" "}
                                  <address>{item?.part_price}</address>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b>Brand Name:</b>{" "}
                                  <address>{item.brand_name}</address>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b>Condition Name:</b>{" "}
                                  <address>{item?.condition_name}</address>
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b>Inventory Status Name:</b>{" "}
                                  <address>
                                    {item?.inventory_status_name}
                                  </address>
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b>Height:</b>{" "}
                                  <address>{item?.parts_height}</address>
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b>Length:</b>{" "}
                                  <address>{item?.parts_length}</address>
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b>Width:</b>{" "}
                                  <address>{item?.parts_width}</address>
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b>Weight:</b>{" "}
                                  <address>{item?.parts_weight}</address>
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b>Print Label:</b>{" "}
                                  <address>{item?.print_label}</address>
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b>Modal Name:</b>{" "}
                                  <address>{item?.vehicle_model_name}</address>
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b>Year:</b>{" "}
                                  <address>{item?.initial_year}</address>
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b>Make Name:</b>{" "}
                                  <address>{item?.vehicle_make_name}</address>
                                </div>
                              </div>
                            </div>
                          </div>
                          {item?.part_qty_list.length > 0 && (
                            <>
                              <div className="card-custom-heading">
                                <p>Part Quntity List</p>
                              </div>
                              <div className="card-body card-body-custom">
                                <div className="vehicleThBg table-responsive">
                                  <table className="table table-bordered rounded-table table table-striped table-hover">
                                    <thead>
                                      <tr>
                                        <th className="col-form-label ml-2 Label-my form-label form-label">
                                          Rating
                                        </th>
                                        <th className="col-form-label ml-2 Label-my form-label form-label">
                                          Location Name
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {item?.part_qty_list?.map((items) => {
                                        return (
                                          <>
                                            <tr>
                                              <td>{items?.part_rating}</td>
                                              <td>{items?.location_name}</td>
                                            </tr>
                                          </>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </>
                          )}

                          {item?.parts_reference?.length > 0 && (
                            <>
                              <div className="card-custom-heading">
                                <p>Part Reference List</p>
                              </div>
                              <div className="card-body card-body-custom">
                                <div className="vehicleThBg table-responsive">
                                  <table className="table table-bordered rounded-table table table-striped table-hover">
                                    <thead>
                                      <tr>
                                        <th className="col-form-label ml-2 Label-my form-label form-label">
                                          Condition Name
                                        </th>
                                        <th className="col-form-label ml-2 Label-my form-label form-label">
                                          Type Name
                                        </th>

                                        <th className="col-form-label ml-2 Label-my form-label form-label">
                                          Is Main
                                        </th>

                                        <th className="col-form-label ml-2 Label-my form-label form-label">
                                          Reference Code{" "}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {item?.parts_reference?.map((items) => {
                                        return (
                                          <>
                                            <tr>
                                              <td>{items?.condition_name}</td>
                                              <td>{items?.type_name}</td>
                                              <td>{items?.is_main}</td>
                                              <td>{items?.reference_code}</td>
                                            </tr>
                                          </>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </>
                          )}

                          {item?.parts_associate?.length > 0 && (
                            <>
                              <div className="card-custom-heading">
                                <p>Part Associate List</p>
                              </div>
                              <div className="card-body card-body-custom">
                                <div className="vehicleThBg table-responsive">
                                  <table className="table table-bordered rounded-table table table-striped table-hover">
                                    <thead>
                                      <tr>
                                        <th className="col-form-label ml-2 Label-my form-label form-label">
                                          Name
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {item?.parts_associate?.map((items) => {
                                        return (
                                          <>
                                            <tr>
                                              <td>
                                                {items?.vehicle_model_name}
                                              </td>
                                            </tr>
                                          </>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </>
                          )}

                          {item?.image_list?.length > 0 && (
                            <>
                              <div className="card-custom-heading">
                                <p>Part images</p>
                              </div>
                              <div className="card-body card-body-custom">
                                <div className="row justify-content-start">
                                  {item?.image_list?.map((image) => {
                                    return (
                                      <>
                                        <div className="col-sm-3">
                                          <div className="column-img">
                                            <img
                                              src={image?.image_url}
                                              alt=""
                                            />
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <p>No details Found</p>
          )}
        </section>
      </div>
    </div>
  );
};

export default PartdetailsInventory;
