import axiosInstance from "./AxiosInstance";

export function actionAddPark(data) {
  return axiosInstance.post(`/park/create`, data);
}

export function actionUpdatePark(data) {
  return axiosInstance.post(`/park/update`, data);
}

export function getParkList(data) {
  return axiosInstance.post(`/park/list`, data);
}

export function actionDelPark(data) {
  return axiosInstance.post(`/park/delete`, data);
}

export function getViewAllParkList(data) {
  return axiosInstance.post(`/park/view-list`, data);
}

export function actionPartCatalogList(data) {
  return axiosInstance.post(`/parts-catalog/percentage`, data);
}

