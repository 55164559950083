import axiosInstance from "./AxiosInstance";

export function getVehicleVariantList(data) {
  return axiosInstance.post(`/vehicle-variants/list`, data);
}

export function deleteVehicleVariant(data) {
  return axiosInstance.post(`/vehicle-variants/delete`, data);
}

export function createVehicleVariant(data) {
  return axiosInstance.post(`/vehicle-variants/create`, data);
}

export function updateVehicleVariant(data) {
  return axiosInstance.post(`/vehicle-variants/update`, data);
}

export function importVehicleVariant(data) {
  return axiosInstance.post(`/vehicle-variants/import`, data);
}

export function exportVehicleVariant(data) {
  return axiosInstance.post(`/vehicle-variants/export-list`, data);
}

export function importVehicleVariantEngineAssign(data) {
  return axiosInstance.post(`/vehicle-variants/engine-import`, data);
}

export function getVehicleSerachVariantList(data) {
  return axiosInstance.post(`/vehicle-variants/search-list`, data);
}


