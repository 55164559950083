import React, { useEffect, useState } from "react";
import { VehiclePartList } from "../../services/PartsInventory";
import { Button, Form, FormGroup, Label } from "reactstrap";
import {
  getCommonSearchList,
  getSettingsCompanyDetails,
} from "../../services/settingsServices";
import Select from "react-select";
import {
  createLabelBrothers,
  createLabelZebras,
} from "../../services/LabelServices";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useNavigate } from "react-router-dom";

const AddLabel = () => {
  const navigate = useNavigate();

  const [vehiclePartDetails, setVehiclePartDetails] = useState([]);
  const [addedParts, setAddedParts] = useState([]);
  const [settingCompanyDetails, setSettingCompanyDetails] = useState("");
  const [partColumnList, setPartColumnList] = useState([]);
  const [labelData, setLabelData] = useState({
    id: "",
    column_ids: "",
  });
  const [btnLoader, setBtnLoader] = useState(false);

  const handleAddClick = (id, name) => {
    const isAdded = addedParts.some((part) => part.id === id);

    if (isAdded) {
      setAddedParts((prev) => prev.filter((part) => part.id !== id));
    } else {
      setAddedParts((prev) => [...prev, { id, name }]);
    }
  };

  const filteredPartAssignList = vehiclePartDetails.filter(
    (item) => !addedParts.some((addedPart) => addedPart.id === item.id)
  );

  useEffect(() => {
    if (settingCompanyDetails) {
      setLabelData({
        column_ids: settingCompanyDetails?.part_column_ids,
      });
    }
  }, [settingCompanyDetails]);

  useEffect(function () {
    VehiclePartList()
      .then((response) => {
        setVehiclePartDetails(response.data.data);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "part_column",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setPartColumnList(list);
      })
      .catch((err) => {});
  }, []);

  const handleSelectCoulmnData = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value).join(",")
      : "";

    setLabelData({
      ...labelData,
      column_ids: selectedValues,
    });
  };

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const companyResponse = await getSettingsCompanyDetails();
        setSettingCompanyDetails(companyResponse.data.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchSettings();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    setBtnLoader(true);
    const vehiclePartID = addedParts.map((item) => item.id);

    const payload = {
      id: vehiclePartID,
      column_ids: labelData.column_ids,
    };

    if (settingCompanyDetails?.print_label === 1) {
      createLabelZebras(payload)
        .then((response) => {
          toast.success(response.data.message);
          navigate("/label");
        })
        .catch((err) => {
          setBtnLoader(false);
          toast.error(Utils.getErrorMessage(err));
        });
    } else {
      createLabelBrothers(payload)
        .then((response) => {
          toast.success(response.data.message);
          navigate("/label");
        })
        .catch((err) => {
          setBtnLoader(false);
          toast.error(Utils.getErrorMessage(err));
        });
    }
  };

  //   const handleSettingCompanyForm = (e) => {
  //     e.preventDefault();

  //     const isZebra = settingCompanyDetails?.label_machine === 1;

  //     if (selectedRadio === "1") {
  //       const data = {
  //         id: vehicleData?.id,
  //         part_prices: selectedPriceRadio,
  //       };

  //       if (isZebra) {
  //         createLabel(createLabelZebra, data);
  //       } else {
  //         createLabel(createLabelBrother, data);
  //       }
  //     } else if (selectedRadio === "2") {
  //       // For the second scenario (selectedRadio === "2")
  //       const data = {
  //         partIds: vehicleData?.id,
  //         shelf_id: vehicleData?.shelf_id, // This shelf_id seems to be static based on your logic
  //       };

  //       if (isZebra) {
  //         createLabel(createIELSLabelZebra, data);
  //       } else {
  //         createLabel(createIELSLabelBrother, data);
  //       }
  //     }
  //   };

  return (
    <div>
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Add Label</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/label">Label</a>
                  </li>
                  <li className="breadcrumb-item active">Add Label</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <Form method={`post`} onSubmit={handleSubmit}>
                    <div className="col-md-12">
                      <FormGroup>
                        <Label className="col-form-label ml-2 Label-my form-label form-label">
                          {"Select Part Column"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <Select
                                options={partColumnList}
                                value={partColumnList.filter((option) =>
                                    (labelData.column_ids || "")
                                        .split(",")
                                        .includes(String(option.value))
                                )}
                                onChange={handleSelectCoulmnData}
                                isSearchable={true}
                                isClearable={true}
                                isMulti
                                placeholder={"Select Part"}
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="vehicleparts-table-inner vehicleThBg tableScroll table-scroll-modal">
                        <table className="rounded-table table table-striped table-bordered table-hover">
                          <thead>
                            <tr>
                              <th style={{ width: "10%" }}>Vehicle Part</th>

                              <th style={{ width: "0%" }}>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {filteredPartAssignList?.length > 0 ? (
                              filteredPartAssignList?.map((item, index) => (
                                <tr key={index}>
                                  <td>{item.part_name}</td>

                                  <td>
                                    <Button
                                      className="addBtn"
                                      onClick={() =>
                                        handleAddClick(item.id, item.part_name)
                                      }
                                      style={{
                                        backgroundColor: addedParts.some(
                                          (part) => part.id === item.id
                                        )
                                          ? "red"
                                          : "green",
                                        color: "white",
                                      }}
                                    >
                                      <i className="bi bi-plus-lg"></i>{" "}
                                    </Button>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="100%">No results found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="vehicleparts-table-inner vehicleThBg tableScroll table-scroll-modal">
                          <table className="rounded-table table table-striped table-bordered table-hover">
                            <thead>
                            <tr>
                              <th style={{ width: "10%" }}>Vehicle Part</th>

                              <th style={{ width: "0%" }}>Action</th>
                            </tr>
                            </thead>

                            <tbody>
                            {addedParts?.length > 0 ? (
                                addedParts?.map((item, index) => (
                                    <tr key={index}>
                                      <td>{item.name}</td>

                                      <td>
                                        <div className="trash-btn">
                                          <a
                                              className="delete-btn"
                                              href="#"
                                              onClick={() =>
                                                  handleAddClick(
                                                      item.id,
                                                      item.namePartPT
                                                  )
                                              }
                                          >
                                            <i className="bi bi-trash3"></i>
                                          </a>
                                        </div>
                                        {/* </div> */}
                                      </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                  <td colSpan="100%">No results found</td>
                                </tr>
                            )}
                            </tbody>
                          </table>
                        </div>

                      </div>


                    </div>

                    <div className="row">
                      <div className="col"></div>
                      <div className="col">
                        <div
                          className="d-flex justify-content-end"
                          style={{ padding: "10px" }}
                        >
                          {btnLoader === true ? (
                              <button type="submit" className="btn btn-primary" disabled={true}>
                                Loading...
                              </button>
                              ) : (
                              <button type="submit" className="btn btn-primary">
                                Submit <i className="bi bi-check2"></i>
                              </button>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="card-footer"></div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AddLabel;
