import axiosInstance from "./AxiosInstance";

export function actionAddDismental(data) {
  return axiosInstance.post(`/dismantle/create`, data);
}

export function actionUpdateDismental(data) {
  return axiosInstance.post(`/dismantle/update`, data);
}

export function getDismentalList(data) {
  return axiosInstance.post(`/dismantle/list`, data);
}

export function actionDelDismental(data) {
  return axiosInstance.post(`/dismantle/delete`, data);
}

export function actionDetailsDismental(data) {
  return axiosInstance.post(`/dismantle/get-details`, data);
}

export function actionTempStoreDismental(data) {
  return axiosInstance.post(`/dismantle/temp-store`, data);
}
