import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import * as XLSX from "xlsx";
import {
  createVehicleMake,
  deleteVehicleMake,
  exportVehicleMake,
  getAllVehicleSerachType,
  getVehicleMakeList,
  importVehicleMake,
  updateVehicleMake,
} from "../../services/VehicleMakeService";

const VehicleMake = () => {
  const tableLengthList = ConfigDB.data.dataTableLength;
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const companyId = JSON.parse(data);

  const location = useLocation();
  const RolePermission = Utils.getRecordForCurrentPath(location);

  const LangData = localStorage.getItem("lng");

  const [totalRecords, setTotalRecords] = useState(0);
  const [typeList, setTypeList] = useState([]);
  const [delModal, setDelModal] = useState(false);
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [delType, setDelType] = useState("");
  const [modifyModal, setModifyModal] = useState(false);
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [vehicleMakeData, setVehicleMakeData] = useState({
    id: "",
    description: "",
    vehicle_type_id: "",
    descriptionPT: "",
    descriptionES: "",
    descriptionFR: "",
    descriptionIT: "",
    descriptionDE: "",
    descriptionEN: "",
    made_axles: "",
    made_engines: "",
    made_transmissions: "",
  });

  let errorsObj = {
    description: "",
    vehicle_type_id: "",
    descriptionPT: "",
    descriptionES: "",
    descriptionFR: "",
    descriptionIT: "",
    descriptionDE: "",
    descriptionEN: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [modifyImportModal, setModifyImportModal] = useState(false);
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [vehicleTypeList, setVehicleTypeList] = useState([]);
  const [catalog, setCatalog] = useState("");
  const [vehicleTypeId, setVehicleTypeId] = useState("");
  const [viewModal, setViewModal] = useState(false);

  let errorsObjImport = {
    file: "",
  };
  const [errorsImport, setErrorsImport] = useState(errorsObjImport);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    company_id: companyId.company_id || 0,
  });

  const onCloseViewFuelType = () => {
    setViewModal(false);
    setVehicleMakeData({
      id: "",
      description: "",
      vehicle_type_id: "",
      descriptionPT: "",
      descriptionES: "",
      descriptionFR: "",
      descriptionIT: "",
      descriptionDE: "",
      descriptionEN: "",
      made_axles: "",
      made_engines: "",
      made_transmissions: "",
    });
  };

  const onViewFuelType = (e, item) => {
    setViewModal(true);
    setVehicleMakeData({
      id: item?.id,
      description: item?.description,
      descriptionPT: item?.descriptionPT,
      descriptionES: item?.descriptionES,
      descriptionFR: item?.descriptionFR,
      descriptionIT: item?.descriptionIT,
      descriptionDE: item?.descriptionDE,
      descriptionEN: item?.descriptionEN,
      vehicle_type_id: item?.vehicle_type,
      made_axles: item?.made_axles,
      made_engines: item?.made_engines,
      made_transmissions: item?.made_transmissions,
    });
  };

  const handleImportModal = () => {
    setModifyImportModal(true);
  };

  const handleImportCloseModal = () => {
    setModifyImportModal(false);
    setErrorsImport("");
    setFile(null);
  };

  // useEffect(function () {
  //   let data = { ...params };
  //   getAllVehicleSerachType(data)
  //     .then((response) => {
  //       response = response.data;
  //       let list = [];
  //       for (let i = 0; i < response.data.length; i++) {
  //         list.push({
  //           value: response.data[i].id,
  //           label: response.data[i].type_name,
  //         });
  //       }
  //       setVehicleTypeList(list);
  //     })
  //     .catch((err) => {});
  // }, []);

  const handleChange = (e) => {
    const userInput = e.target.value;

    let data = {
      limit: params.limit,
      page: params.page,
      search: userInput,
      is_reload: true,
    };
    getAllVehicleSerachType(data)
      .then((response) => {
        setFilteredSuggestions(response.data.data);
      })
      .catch((err) => {});

    setCatalog(userInput);
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);
  };

  const handleClick = (suggestion) => {
    setVehicleTypeId(suggestion?.id);
    setCatalog(suggestion.vehicle_type_name);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      if (
        filteredSuggestions &&
        activeSuggestionIndex < filteredSuggestions?.length - 1
      ) {
        setActiveSuggestionIndex(activeSuggestionIndex + 1);
      }
    } else if (e.key === "ArrowUp") {
      if (activeSuggestionIndex > 0) {
        setActiveSuggestionIndex(activeSuggestionIndex - 1);
      }
    } else if (e.key === "Enter") {
      setCatalog(filteredSuggestions[activeSuggestionIndex]);
      setShowSuggestions(false);
      setFilteredSuggestions([]);
    }
  };

  const handleAddModal = () => {
    setModifyModal(true);
  };

  const handleCloseModal = () => {
    setModifyModal(false);
    setVehicleMakeData("");
    setVehicleTypeId("");
    setCatalog("");
    setErrors("");
  };

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    paramsObj.search = catalog;

    setParams(paramsObj);
  };

  const fetchType = () => {
    let data = { ...params };
    getVehicleMakeList(data)
      .then((response) => {
        setTypeList(response.data.data);
        setTotalRecords(response.data.total);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(
    function () {
      let data = { ...params };

      fetchType(data);
    },
    [params]
  );

  const onDeleteModalClose = () => {
    setDelModal(false);
  };
  const onEditVehicleType = (e, item) => {
    setModifyModal(true);
    setVehicleMakeData({
      id: item?.id,
      description: item?.description,
      descriptionPT: item?.descriptionPT,
      descriptionES: item?.descriptionES,
      descriptionFR: item?.descriptionFR,
      descriptionIT: item?.descriptionIT,
      descriptionDE: item?.descriptionDE,
      descriptionEN: item?.descriptionEN,
      vehicle_type_id: item?.vehicle_type_id,
    });
    setVehicleTypeId(item?.vehicle_type_id);
    setCatalog(item?.vehicle_type);
  };

  const onDeleteModalOpen = (e, item) => {
    e.preventDefault();
    setDelType(item.id);
    setDelModal(true);
  };

  const onDestroyType = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { id: delType };
    deleteVehicleMake(data)
      .then((response) => {
        let data = { ...params };

        fetchType(data);
        toast.success(response.data.message);
        setDelType("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleTypeForm = (e) => {
    e.preventDefault();
    let error = false;
    let errorObj = { ...errorsObj };
    if (!vehicleMakeData.description) {
      errorObj.description = `Description is required.`;
      error = true;
    }

    // if (!vehicleMakeData.vehicle_type_id) {
    //   errorObj.vehicle_type_id = `Vehicle type is required.`;
    //   error = true;
    // }

    if (!vehicleMakeData.descriptionPT) {
      errorObj.descriptionPT = `Description PT is required.`;
      error = true;
    }
    if (!vehicleMakeData.descriptionES) {
      errorObj.descriptionES = `Description ES is required.`;
      error = true;
    }
    if (!vehicleMakeData.descriptionFR) {
      errorObj.descriptionFR = `Description FR is required.`;
      error = true;
    }
    if (!vehicleMakeData.descriptionIT) {
      errorObj.descriptionIT = `Description IT is required.`;
      error = true;
    }
    if (!vehicleMakeData.descriptionDE) {
      errorObj.descriptionDE = `Description DE is required.`;
      error = true;
    }
    if (!vehicleMakeData.descriptionEN) {
      errorObj.descriptionEN = `Description EN is required.`;
      error = true;
    }

    setErrors(errorObj);
    Utils.focusError();

    if (error) return;

    setIsModifyButtonLoad(true);

    let data = {
      description: vehicleMakeData.description,
      vehicle_type_id: vehicleTypeId,
      descriptionPT: vehicleMakeData.descriptionPT,
      descriptionES: vehicleMakeData.descriptionES,
      descriptionFR: vehicleMakeData.descriptionFR,
      descriptionIT: vehicleMakeData.descriptionIT,
      descriptionDE: vehicleMakeData.descriptionDE,
      descriptionEN: vehicleMakeData.descriptionEN,
    };

    let Updatedata = {
      id: vehicleMakeData?.id,
      description: vehicleMakeData.description,
      vehicle_type_id: vehicleTypeId,
      descriptionPT: vehicleMakeData.descriptionPT,
      descriptionES: vehicleMakeData.descriptionES,
      descriptionFR: vehicleMakeData.descriptionFR,
      descriptionIT: vehicleMakeData.descriptionIT,
      descriptionDE: vehicleMakeData.descriptionDE,
      descriptionEN: vehicleMakeData.descriptionEN,
    };

    if (vehicleMakeData?.id) {
      updateVehicleMake(Updatedata)
        .then((response) => {
          toast.success(response.data.message);
          let data = { ...params };
          fetchType(data);
          setModifyModal(false);
          setIsModifyButtonLoad(false);
          setVehicleTypeId("");
          setCatalog("");
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
          setIsModifyButtonLoad(false);
        });
    } else {
      createVehicleMake(data)
        .then((response) => {
          toast.success(response.data.message);
          let data = { ...params };
          fetchType(data);
          setModifyModal(false);
          setIsModifyButtonLoad(false);
          setVehicleTypeId("");
          setCatalog("");
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
          setIsModifyButtonLoad(false);
        });
    }
  };

  const handleImportGroup = (e) => {
    e.preventDefault();

    let error = false;
    let errorObjImport = { ...errorsObjImport };
    if (!file) {
      errorObjImport.file = `File is required.`;
      error = true;
    }

    setErrorsImport(errorObjImport);
    if (error) return;

    setIsModifyButtonLoad(true);
    const formData = new FormData();
    formData.append("files", file);

    importVehicleMake(formData)
      .then((response) => {
        toast.success(response.data.message);
        let data = { ...params };
        fetchType(data);
        setModifyImportModal(false);
        setIsModifyButtonLoad(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        setIsModifyButtonLoad(false);
      });
  };

  const fetchExportVehicleType = () => {
    let data = { ...params };
    exportVehicleMake(data)
      .then((response) => {
        const worksheet = XLSX.utils.json_to_sheet(response.data.data);
        const csv = XLSX.utils.sheet_to_csv(worksheet);

        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "VehicleMake.csv");
        link.style.visibility = "hidden";

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleExport = () => {
    let data = { ...params };
    fetchExportVehicleType(data);
  };

  const handleSelectChange = (selectedOption) => {
    setVehicleMakeData({
      ...vehicleMakeData,
      vehicle_type_id: selectedOption ? selectedOption.value : "",
    });
  };

  return (
    <div>
      {isLoading ? (
        <></>
      ) : (
        <div>
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  {/* <h1 className="page-title">Vehicle Type</h1> */}
                </div>

                <div className="col-sm-6"></div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card fuelCard container-custom-height">
                    <div className="card-header">
                      <div
                        className="card-title m-0 float-left"
                        style={{ width: "50%" }}
                      >
                        <input
                          type="text"
                          className="form-control input-search"
                          value={params.search}
                          onChange={(e) => handleParams(e, "search")}
                          placeholder="Search"
                        ></input>
                      </div>

                      <div className="card-title m-0 float-right d-flex">
                        {RolePermission?.findIndex(
                          (e) => e.permission_name === "import_vehicle_make"
                        ) > -1 && (
                          <button
                            type="button"
                            className="btn  btn-icon-text mr-2"
                            onClick={handleImportModal}
                          >
                            <i className="bi bi-file-earmark-excel-fill"></i>
                            <span className="ms-2">Import</span>
                          </button>
                        )}
                        {RolePermission?.findIndex(
                          (e) => e.permission_name === "export_vehicle_make"
                        ) > -1 && (
                          <button
                            type="button"
                            className="btn  btn-icon-text mr-2"
                            onClick={handleExport}
                          >
                            <i className="bi bi-file-earmark-excel-fill"></i>
                            <span className="ms-2">Export</span>
                          </button>
                        )}
                        {RolePermission?.findIndex(
                          (e) => e.permission_name === "add_vehicle_make"
                        ) > -1 && (
                          <button
                            type="button"
                            className="btn  btn-icon-text mr-2"
                            onClick={handleAddModal}
                          >
                            <i className="bi bi-plus-lg"></i>
                            <span className="ms-2">Add</span>
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="card-body fuelCardB">
                      <div className="vehicleThBg table-responsive table-responsive-vehicle">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th
                                className="col-form-label ml-2 Label-my form-label form-label"
                                style={{ width: "1%" }}
                              >
                                #
                              </th>
                              <th
                                className="col-form-label ml-2 Label-my form-label form-label"
                                style={{ width: "10%" }}
                              >
                                Vehicle Make Name
                              </th>

                              <th
                                className="col-form-label ml-2 Label-my form-label form-label"
                                style={{ width: "10%" }}
                              >
                                {LangData === "pt"
                                  ? "Vehicle Make Description(PT)"
                                  : LangData === "fr"
                                  ? "Vehicle Make Description(FR)"
                                  : LangData === "es"
                                  ? "Vehicle Make Description(ES)"
                                  : LangData === "it"
                                  ? "Vehicle Make Description(IT)"
                                  : LangData === "de"
                                  ? "Vehicle Make Description(DE)"
                                  : "Vehicle Make Description(EN)"}
                              </th>

                              <th
                                className="col-form-label ml-2 Label-my form-label form-label"
                                style={{ width: "0%" }}
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {typeList?.map((item, index) => {
                              return (
                                <>
                                  <tr>
                                    <td>{++index}</td>
                                    <td>{item.description}</td>
                                    <td>
                                      {LangData === "pt"
                                        ? item.descriptionPT
                                        : LangData === "fr"
                                        ? item.descriptionFR
                                        : LangData === "es"
                                        ? item.descriptionES
                                        : LangData === "it"
                                        ? item.descriptionIT
                                        : LangData === "de"
                                        ? item.descriptionDE
                                        : item.descriptionEN}
                                    </td>

                                    <td className="d-flex">
                                      <Dropdown className="iconDropdown">
                                        <Dropdown.Toggle
                                          variant="success"
                                          id="dropdown-basic"
                                          className="iconToggle"
                                        >
                                          <i className="bi bi-three-dots-vertical"></i>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          {item?.is_import_record === 1 ? (
                                            <>
                                              <Dropdown.Item
                                                href="#/action-1"
                                                className="d-flex "
                                                onClick={(e) =>
                                                  onViewFuelType(e, item)
                                                }
                                              >
                                                <div className="edit-btn">
                                                  <i class="bi bi-eye-fill"></i>
                                                </div>
                                                <span>View</span>
                                              </Dropdown.Item>
                                            </>
                                          ) : (
                                            <>
                                              <Dropdown.Item
                                                href="#/action-1"
                                                className="d-flex"
                                                onClick={(e) =>
                                                  onEditVehicleType(e, item)
                                                }
                                              >
                                                {(item.company_id > 0 ||
                                                  companyId.role_id == 1) &&
                                                  RolePermission?.findIndex(
                                                    (e) =>
                                                      e.permission_name ===
                                                      "modify_vehicle_make"
                                                  ) > -1 && (
                                                    <a className=""></a>
                                                  )}
                                                <div className="edit-btn">
                                                  <i className="bi bi-pencil"></i>
                                                </div>
                                                <span>Edit</span>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                href="#/action-2"
                                                className="d-flex"
                                                onClick={(e) =>
                                                  onDeleteModalOpen(e, item)
                                                }
                                              >
                                                {(item.company_id > 0 ||
                                                  companyId.role_id == 1) &&
                                                  RolePermission?.findIndex(
                                                    (e) =>
                                                      e.permission_name ===
                                                      "delete_vehicle_type"
                                                  ) > -1 && (
                                                    <div className="">
                                                      <a className=""></a>
                                                    </div>
                                                  )}
                                                <div className="trash-btn">
                                                  <a className="delete-btn">
                                                    <i className="bi bi-trash3"></i>
                                                  </a>
                                                </div>
                                                <span>Delete</span>
                                              </Dropdown.Item>
                                            </>
                                          )}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                          {typeList && typeList?.length === 0 && (
                            <tr>
                              <td
                                colSpan={7}
                                className={`text-center`}
                              >{`Record Not Found`}</td>
                            </tr>
                          )}
                        </table>
                      </div>
                    </div>
                    {/* /.card-body */}
                  </div>
                </div>
              </div>
            </div>
            <div className="fuelCF">
              <div className="card-footer card-footer-part clearfix card-footer">
                <div className="pagination pagination-sm m-0 float-right">
                  <Pagination
                    activePage={params.page}
                    itemsCountPerPage={parseInt(params.limit)}
                    totalItemsCount={parseInt(totalRecords)}
                    pageRangeDisplayed={5}
                    itemClass={`page-item`}
                    linkClass={`page-link`}
                    onChange={(e) => handleParams(e, "pagination")}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
      <Modal isOpen={delModal}>
        <ModalHeader>{`Delete Vehicle Make`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this vehicle make?`}</p>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            disabled={isDelButtonLoad}
            onClick={onDeleteModalClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close <i class="bi bi-x-lg"></i>
          </button>

          {!isDelButtonLoad && (
            <button
              type="button"
              className="btn btn-block bg-red"
              style={{ width: "100px" }}
              onClick={onDestroyType}
            >
              Delete <i class="bi bi-trash3"></i>
            </button>
          )}
          {isDelButtonLoad && (
            <button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
            </button>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modifyModal}>
        <ModalHeader>Vehicle Make</ModalHeader>
        <Form method={`post`} onSubmit={handleTypeForm}>
          <ModalBody>
            <div className="row">
              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Description"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={vehicleMakeData.description}
                        onChange={(e) => {
                          setVehicleMakeData({
                            ...vehicleMakeData,
                            description: e.target.value,
                          });
                        }}
                        name={`description`}
                        maxLength={50}
                        placeholder="Description"
                      />
                    </div>
                    {errors.description && (
                      <span className="input-error">{errors.description}</span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Description PT"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={vehicleMakeData.descriptionPT}
                        onChange={(e) => {
                          setVehicleMakeData({
                            ...vehicleMakeData,
                            descriptionPT: e.target.value,
                          });
                        }}
                        name={`descriptionPT`}
                        maxLength={50}
                        placeholder="Description PT"
                      />
                    </div>
                    {errors.descriptionPT && (
                      <span className="input-error">
                        {errors.descriptionPT}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Description ES"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={vehicleMakeData.descriptionES}
                        onChange={(e) => {
                          setVehicleMakeData({
                            ...vehicleMakeData,
                            descriptionES: e.target.value,
                          });
                        }}
                        name={`descriptionES`}
                        maxLength={50}
                        placeholder="Description ES"
                      />
                    </div>
                    {errors.descriptionES && (
                      <span className="input-error">
                        {errors.descriptionES}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Description FR"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={vehicleMakeData.descriptionFR}
                        onChange={(e) => {
                          setVehicleMakeData({
                            ...vehicleMakeData,
                            descriptionFR: e.target.value,
                          });
                        }}
                        name={`descriptionFR`}
                        maxLength={50}
                        placeholder="Description FR"
                      />
                    </div>
                    {errors.descriptionFR && (
                      <span className="input-error">
                        {errors.descriptionFR}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Description IT"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={vehicleMakeData.descriptionIT}
                        onChange={(e) => {
                          setVehicleMakeData({
                            ...vehicleMakeData,
                            descriptionIT: e.target.value,
                          });
                        }}
                        name={`descriptionIT`}
                        maxLength={50}
                        placeholder="Description IT"
                      />
                    </div>
                    {errors.descriptionIT && (
                      <span className="input-error">
                        {errors.descriptionIT}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Description DE"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={vehicleMakeData.descriptionDE}
                        onChange={(e) => {
                          setVehicleMakeData({
                            ...vehicleMakeData,
                            descriptionDE: e.target.value,
                          });
                        }}
                        name={`descriptionDE`}
                        maxLength={50}
                        placeholder="Description DE"
                      />
                    </div>
                    {errors.descriptionDE && (
                      <span className="input-error">
                        {errors.descriptionDE}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Description EN"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={vehicleMakeData.descriptionEN}
                        onChange={(e) => {
                          setVehicleMakeData({
                            ...vehicleMakeData,
                            descriptionEN: e.target.value,
                          });
                        }}
                        name={`descriptionEN`}
                        maxLength={40}
                        placeholder="Description EN"
                      />
                    </div>
                    {errors.descriptionEN && (
                      <span className="input-error">
                        {errors.descriptionEN}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my form-label form-label">
                    {"Select Vehicle Type"}
                  </Label>
                  <div className="col-xl-12">
                    <input
                      className="form-control mt-2 input-search"
                      type="text"
                      maxLength={50}
                      placeholder="Search..."
                      value={catalog}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      onClick={(e) => e.stopPropagation()}
                    />
                    {showSuggestions && catalog && (
                      <ul className="suggestions">
                        {filteredSuggestions?.length > 0 ? (
                          filteredSuggestions.map((suggestion, index) => (
                            <li
                              key={suggestion}
                              className={
                                index === activeSuggestionIndex ? "active" : ""
                              }
                              onClick={() => handleClick(suggestion)}
                            >
                              {suggestion.vehicle_type_name}
                            </li>
                          ))
                        ) : (
                          <li>No suggestions available</li>
                        )}
                      </ul>
                    )}
                  </div>
                </FormGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              class="btn btn-block btn-primary"
              disabled={isModifyButtonLoad}
              onClick={handleCloseModal}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-x-lg"></i>
            </button>
            {!isModifyButtonLoad && (
              <button
                type="submit"
                class="btn btn-block btn-primary"
                style={{ width: "100px", marginTop: "10px" }}
              >
                Submit <i class="bi bi-check2"></i>
              </button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={modifyImportModal}>
        <Form method={`post`} onSubmit={handleImportGroup}>
          <ModalHeader>{`Import Vehicle Make`}</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <FormGroup>
                  <Label className="col-form-label">Select File</Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="file"
                        onChange={(e) => setFile(e.target.files[0])}
                        name="file"
                        accept=".csv,.xlsx"
                      />
                    </div>
                    {errorsImport.file && (
                      <span className="input-error">{errorsImport.file}</span>
                    )}
                  </div>
                </FormGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              class="btn btn-block btn-secondary"
              disabled={isModifyButtonLoad}
              onClick={handleImportCloseModal}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close
            </button>
            {!isModifyButtonLoad && (
              <button
                type="submit"
                class="btn btn-block btn-primary"
                style={{ width: "100px", marginTop: "10px" }}
              >
                Import
              </button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={viewModal} size="lg">
          <div className="border m-3 p-3">
        <ModalHeader className="p-0 pb-2">Vehicle Make Details</ModalHeader>

        <ModalBody>
         
          <table className="fuelTable">
              <tbody className="row">
              <tr className="col-md-6">
                  <td>Description</td>
                  <td>:</td>
                  <td>{vehicleMakeData?.description}</td>
              </tr>
              <tr className="col-md-6">
                  <td>DescriptionPT</td>
                  <td>:</td>
                  <td>{vehicleMakeData?.descriptionPT}</td>
              </tr>
              <tr className="col-md-6">
                  <td>DescriptionES</td>
                  <td>:</td>
                  <td>{vehicleMakeData?.descriptionES}</td>
              </tr>
              <tr className="col-md-6">
                  <td>DescriptionFR</td>
                  <td>:</td>
                  <td>{vehicleMakeData?.descriptionFR}</td>
              </tr>
              <tr className="col-md-6">
                  <td>DescriptionIT</td>
                  <td>:</td>
                  <td>{vehicleMakeData?.descriptionIT}</td>
              </tr>
              <tr className="col-md-6">
                  <td>DescriptionDE</td>
                  <td>:</td>
                  <td>{vehicleMakeData?.descriptionDE}</td>
              </tr>
              <tr className="col-md-6">
                  <td>DescriptionEN</td>
                  <td>:</td>
                  <td>{vehicleMakeData?.descriptionEN}</td>
              </tr>
              <tr className="col-md-6">
                  <td>Vehicle Type Name</td>
                  <td>:</td>
                  <td>{vehicleMakeData?.vehicle_type_id}</td>
              </tr>
              <tr className="col-md-6">
                  <td>Made Axles</td>
                  <td>:</td>
                  <td>{vehicleMakeData?.made_axles}</td>
              </tr>
              <tr className="col-md-6">
                  <td>Made Engine</td>
                  <td>:</td>
                  <td>{vehicleMakeData?.made_engines}</td>
              </tr>
              <tr className="col-md-6">
                  <td>Made Transmissions</td>
                  <td>:</td>
                  <td>{vehicleMakeData?.made_transmissions}</td>
              </tr>
              <tr className="col-md-6">
              </tr>
              </tbody>
          </table>
        </ModalBody>
        <ModalFooter className="p-0">
          <button
            type="button"
            class="btn btn-block btn-primary"
            disabled={isModifyButtonLoad}
            onClick={onCloseViewFuelType}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close <i class="bi bi-x-lg"></i>
          </button>

          {isModifyButtonLoad && (
            <Button
              color="success"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
            </Button>
          )}
        </ModalFooter>
          </div>
      </Modal>
    </div>
  );
};

export default VehicleMake;
