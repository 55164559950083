import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useLocation } from "react-router-dom";
import {
  createVehicleModel,
  deleteVehicleModel,
  exportVehiclemodel,
  getVehicleAllMakeList,
  getVehicleModelList,
  importVehiclemodel,
  updateVehicleModel,
} from "../../services/VehicleModelService";
import Select from "react-select";
import { getAllVehicleSerachType } from "../../services/VehicleMakeService";
import Dropdown from "react-bootstrap/Dropdown";
import * as XLSX from "xlsx";

const VehicleModel = () => {
  const tableLengthList = ConfigDB.data.dataTableLength;
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const companyId = JSON.parse(data);

  const location = useLocation();
  const RolePermission = Utils.getRecordForCurrentPath(location);
  const LangData = localStorage.getItem("lng");

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    company_id: companyId.company_id || 0,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [vehicleModelList, setVehicleModelList] = useState([]);
  const [delModal, setDelModal] = useState(false);
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [delVehicleModel, setDelVehicleModel] = useState("");
  const [modifyModal, setModifyModal] = useState(false);
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [vehicleModelData, setVehicleModelData] = useState({
    id: "",
    vehicle_type_id: "",
    vehicle_start_year: "",
    vehicle_end_year: "",
    make_id: "",
    description: "",
    descriptionPT: "",
    descriptionES: "",
    descriptionFR: "",
    descriptionIT: "",
    descriptionDE: "",
    descriptionEN: "",
    month_initial: "",
    month_final: "",
  });

  let errorsObj = {
    vehicle_type_id: "",
    vehicle_start_year: "",
    vehicle_end_year: "",
    make_id: "",
    description: "",
    descriptionPT: "",
    descriptionES: "",
    descriptionFR: "",
    descriptionIT: "",
    descriptionDE: "",
    descriptionEN: "",
    month_initial: "",
    month_final: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const [modifyImportModal, setModifyImportModal] = useState(false);
  let errorsObjImport = {
    file: "",
  };
  const [errorsImport, setErrorsImport] = useState(errorsObjImport);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);

  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [catalog, setCatalog] = useState("");
  const [vehicleTypeId, setVehicleTypeId] = useState("");

  const [filteredSuggestionsMake, setFilteredSuggestionsMake] = useState([]);
  const [activeSuggestionIndexMake, setActiveSuggestionIndexMake] = useState(0);
  const [showSuggestionsMake, setShowSuggestionsMake] = useState(false);
  const [catalogMake, setCatalogMake] = useState("");
  const [vehicleMakeId, setVehicleMakeId] = useState("");

  const onCloseViewVehicleModel = () => {
    setViewModal(false);
    setVehicleModelData({
      uuid: "",

      description: "",
      descriptionPT: "",
      descriptionES: "",
      descriptionFR: "",
      descriptionIT: "",
      descriptionDE: "",
      descriptionEN: "",
    });
  };

  const onViewEngineVehicleModel = (e, item) => {
    setViewModal(true);
    setVehicleModelData({
      uuid: item?.uuid,
      description: item?.description,
      descriptionPT: item?.descriptionPT,
      descriptionES: item?.descriptionES,
      descriptionFR: item?.descriptionFR,
      descriptionIT: item?.descriptionIT,
      descriptionDE: item?.descriptionDE,
      descriptionEN: item?.descriptionEN,
    });
  };

  const handleChange = (e) => {
    const userInput = e.target.value;

    let data = {
      limit: params.limit,
      page: params.page,
      search: userInput,
      is_reload: true,
    };
    getAllVehicleSerachType(data)
      .then((response) => {
        setFilteredSuggestions(response.data.data);
      })
      .catch((err) => {});

    setCatalog(userInput);
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);
  };

  const handleClick = (suggestion) => {
    setVehicleTypeId(suggestion?.id);
    setCatalog(suggestion.vehicle_type_name);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      if (
        filteredSuggestions &&
        activeSuggestionIndex < filteredSuggestions?.length - 1
      ) {
        setActiveSuggestionIndex(activeSuggestionIndex + 1);
      }
    } else if (e.key === "ArrowUp") {
      if (activeSuggestionIndex > 0) {
        setActiveSuggestionIndex(activeSuggestionIndex - 1);
      }
    } else if (e.key === "Enter") {
      setCatalog(filteredSuggestions[activeSuggestionIndex]);
      setShowSuggestions(false);
      setFilteredSuggestions([]);
    }
  };

  const handleChangeMake = (e) => {
    const userInput = e.target.value;

    let data = {
      limit: params.limit,
      page: params.page,
      search: userInput,
      is_reload: true,
    };
    getVehicleAllMakeList(data)
      .then((response) => {
        setFilteredSuggestionsMake(response.data.data);
      })
      .catch((err) => {});

    setCatalogMake(userInput);
    setActiveSuggestionIndexMake(0);
    setShowSuggestionsMake(true);
  };

  const handleClickMake = (suggestion) => {
    setVehicleMakeId(suggestion?.id);
    setCatalogMake(suggestion.make_name);
    setFilteredSuggestionsMake([]);
    setShowSuggestionsMake(false);
  };

  const handleKeyDownMake = (e) => {
    if (e.key === "ArrowDown") {
      if (
        filteredSuggestionsMake &&
        activeSuggestionIndexMake < filteredSuggestionsMake?.length - 1
      ) {
        setActiveSuggestionIndex(activeSuggestionIndexMake + 1);
      }
    } else if (e.key === "ArrowUp") {
      if (activeSuggestionIndexMake > 0) {
        setActiveSuggestionIndex(activeSuggestionIndexMake - 1);
      }
    } else if (e.key === "Enter") {
      setCatalogMake(filteredSuggestionsMake[activeSuggestionIndexMake]);
      setShowSuggestionsMake(false);
      setFilteredSuggestionsMake([]);
    }
  };

  const handleImportModal = () => {
    setModifyImportModal(true);
  };

  const handleImportCloseModal = () => {
    setModifyImportModal(false);
    setErrorsImport("");
    setFile(null);
  };

  const handleAddModal = () => {
    setModifyModal(true);
  };

  const handleCloseModal = () => {
    setModifyModal(false);
    setVehicleModelData("");
    setErrors("");
  };

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  const fetchVehicleModel = () => {
    let data = { ...params };
    getVehicleModelList(data)
      .then((response) => {
        setVehicleModelList(response.data.data);
        setTotalRecords(response.data.total);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(
    function () {
      let data = { ...params };

      fetchVehicleModel(data);
    },
    [params]
  );

  const onDeleteModalClose = () => {
    setDelModal(false);
  };

  const onEditVehicleModel = (e, item) => {
    setModifyModal(true);
    setVehicleModelData({
      id: item?.id,
      vehicle_type_id: item?.vehicle_type_id,
      vehicle_start_year: item?.initial_year,
      vehicle_end_year: item?.year_final,
      make_id: item?.make_id,
      description: item?.description,
      descriptionPT: item?.descriptionPT,
      descriptionES: item?.descriptionES,
      descriptionFR: item?.descriptionFR,
      descriptionIT: item?.descriptionIT,
      descriptionDE: item?.descriptionDE,
      descriptionEN: item?.descriptionEN,
      month_initial: item?.month_initial,
      month_final: item?.month_final,
    });
    setVehicleMakeId(item?.make_id);
    setVehicleTypeId(item?.vehicle_type_id);
    setCatalog(item?.vehicle_type);
    setCatalogMake(item?.vehicle_make);
  };

  const onDeleteModalOpen = (e, item) => {
    e.preventDefault();
    setDelVehicleModel(item.id);
    setDelModal(true);
  };

  const onDestroyVehicleForm = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { id: delVehicleModel };
    deleteVehicleModel(data)
      .then((response) => {
        let data = { ...params };

        fetchVehicleModel(data);
        toast.success(response.data.message);
        setDelVehicleModel("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleVehicleModelForm = (e) => {
    e.preventDefault();
    let error = false;
    let errorObj = { ...errorsObj };
    // if (!vehicleModelData.vehicle_type_id) {
    //   errorObj.vehicle_type_id = `Vehicle type is required.`;
    //   error = true;
    // }

    // if (!vehicleModelData.make_id) {
    //   errorObj.make_id = `Vehicle make is required.`;
    //   error = true;
    // }
    if (!vehicleModelData.description) {
      errorObj.description = `Vehicle model is required.`;
      error = true;
    }

    if (!vehicleModelData.month_initial) {
      errorObj.month_initial = `Month initial is required.`;
      error = true;
    }

    if (!vehicleModelData.month_final) {
      errorObj.month_final = `Month final is required.`;
      error = true;
    }

    if (
      !vehicleModelData.vehicle_start_year ||
      isNaN(vehicleModelData.vehicle_start_year)
    ) {
      errorObj.vehicle_start_year =
        "Vehicle start year is required and must be a number.";
      error = true;
    }

    if (
      !vehicleModelData.vehicle_end_year ||
      isNaN(vehicleModelData.vehicle_end_year)
    ) {
      errorObj.vehicle_end_year =
        "Vehicle end year is required and must be a number.";
      error = true;
    }

    if (!vehicleModelData.descriptionPT) {
      errorObj.descriptionPT = `Description PT is required.`;
      error = true;
    }
    if (!vehicleModelData.descriptionES) {
      errorObj.descriptionES = `Description ES is required.`;
      error = true;
    }
    if (!vehicleModelData.descriptionFR) {
      errorObj.descriptionFR = `Description FR is required.`;
      error = true;
    }
    if (!vehicleModelData.descriptionIT) {
      errorObj.descriptionIT = `Description IT is required.`;
      error = true;
    }
    if (!vehicleModelData.descriptionDE) {
      errorObj.descriptionDE = `Description DE is required.`;
      error = true;
    }
    if (!vehicleModelData.descriptionEN) {
      errorObj.descriptionEN = `Description EN is required.`;
      error = true;
    }



    setErrors(errorObj);
    Utils.focusError();

    if (error) return;

    setIsModifyButtonLoad(true);

    let data = {
      vehicle_type_id: vehicleTypeId,
      vehicle_start_year: vehicleModelData.vehicle_start_year,
      vehicle_end_year: vehicleModelData.vehicle_end_year,
      make_id: vehicleMakeId,
      description: vehicleModelData.description,
      descriptionPT: vehicleModelData.descriptionPT,
      descriptionES: vehicleModelData.descriptionES,
      descriptionFR: vehicleModelData.descriptionFR,
      descriptionIT: vehicleModelData.descriptionIT,
      descriptionDE: vehicleModelData.descriptionDE,
      descriptionEN: vehicleModelData.descriptionEN,
      month_final: vehicleModelData.month_final,
      month_initial: vehicleModelData.month_initial,
    };

    let Updatedata = {
      id: vehicleModelData?.id,
      vehicle_type_id: vehicleTypeId,
      vehicle_start_year: vehicleModelData.vehicle_start_year,
      vehicle_end_year: vehicleModelData.vehicle_end_year,
      make_id: vehicleMakeId,
      description: vehicleModelData.description,
      descriptionPT: vehicleModelData.descriptionPT,
      descriptionES: vehicleModelData.descriptionES,
      descriptionFR: vehicleModelData.descriptionFR,
      descriptionIT: vehicleModelData.descriptionIT,
      descriptionDE: vehicleModelData.descriptionDE,
      descriptionEN: vehicleModelData.descriptionEN,
      month_final: vehicleModelData.month_final,
      month_initial: vehicleModelData.month_initial,
    };

    if (vehicleModelData?.id) {
      updateVehicleModel(Updatedata)
        .then((response) => {
          toast.success(response.data.message);
          let data = { ...params };
          fetchVehicleModel(data);
          setModifyModal(false);
          setIsModifyButtonLoad(false);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
          setIsModifyButtonLoad(false);
        });
    } else {
      createVehicleModel(data)
        .then((response) => {
          toast.success(response.data.message);
          let data = { ...params };
          fetchVehicleModel(data);
          setModifyModal(false);
          setIsModifyButtonLoad(false);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
          setIsModifyButtonLoad(false);
        });
    }
  };

  const handleImportGroup = (e) => {
    e.preventDefault();

    let error = false;
    let errorObjImport = { ...errorsObjImport };
    if (!file) {
      errorObjImport.file = `File is required.`;
      error = true;
    }

    setErrorsImport(errorObjImport);
    if (error) return;

    setIsModifyButtonLoad(true);
    const formData = new FormData();
    formData.append("files", file);

    importVehiclemodel(formData)
      .then((response) => {
        toast.success(response.data.message);
        let data = { ...params };
        fetchVehicleModel(data);
        setModifyImportModal(false);
        setIsModifyButtonLoad(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        setIsModifyButtonLoad(false);
      });
  };

  const fetchExportVehicleType = () => {
    let data = { ...params };
    exportVehiclemodel(data)
      .then((response) => {
        const worksheet = XLSX.utils.json_to_sheet(response.data.data);
        const csv = XLSX.utils.sheet_to_csv(worksheet);

        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "VehicleMake.csv");
        link.style.visibility = "hidden";

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleExport = () => {
    let data = { ...params };
    fetchExportVehicleType(data);
  };
  return (
    <div>
      {isLoading ? (
        // <div className="text-center pageLoader">
        //   <div className="spinner-border" role="status">
        //     <span className="visually-hidden">Loading...</span>
        //   </div>
        // </div>
        <div className="skeleton-loader p-3 d-none">
          <div className="card">
            <div className="card-header">
              <table className="table rounded-table table-striped table-hover">
                <thead>
                  <tr>
                    <th
                      style={{ width: 10, borderTop: "0", borderBottom: "0" }}
                    >
                      <div className="skeleton"></div>
                    </th>
                    <th style={{ borderTop: "0", borderBottom: "0" }}>
                      <div className="skeleton"></div>
                    </th>
                    <th style={{ borderTop: "0", borderBottom: "0" }}></th>
                    <th
                      class="d-flex"
                      style={{ borderTop: "0", borderBottom: "0" }}
                    >
                      <div class="skeleton me-1"></div>
                      <div class="skeleton me-1"></div>
                      <div class="skeleton"></div>
                    </th>
                    <th
                      style={{ width: 10, borderTop: "0", borderBottom: "0" }}
                    >
                      <div className="skeleton"></div>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <div className="card-body">
              <div className="vehicleThBg table-responsive">
                <table className="table table-bordered rounded-table table table-striped table-hover">
                  <thead>
                    <tr>
                      <th style={{ backgroundColor: "#c0c0c0" }}>
                        <div className="skeleton"></div>
                      </th>
                      <th style={{ backgroundColor: "#c0c0c0" }}>
                        <div className="skeleton"></div>
                      </th>
                      <th style={{ backgroundColor: "#c0c0c0" }}>
                        <div className="skeleton"></div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {vehicleModelList?.map((_, index) => (
                      <tr key={index}>
                        <td>
                          <div className="skeleton"></div>
                        </td>
                        <td>
                          <div className="skeleton"></div>
                        </td>
                        <td>
                          <div className="skeleton"></div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card-footer">
              <div className="skeleton col-lg-3 float-right"></div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  {/* <h1 className="page-title">Vehicle Model</h1> */}
                </div>

                <div className="col-sm-6"></div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card fuelCard container-custom-height">
                    <div className="card-header">
                      <div
                        className="card-title m-0 float-left"
                        style={{ width: "50%" }}
                      >
                        <input
                          type="text"
                          className="form-control input-search"
                          value={params.search}
                          onChange={(e) => handleParams(e, "search")}
                          placeholder="Search"
                        ></input>
                      </div>

                      <div className="card-title m-0 float-right d-flex">
                        {RolePermission?.findIndex(
                          (e) => e.permission_name === "import_vehicle_model"
                        ) > -1 && (
                          <button
                            type="button"
                            className="btn  btn-icon-text mr-2"
                            onClick={handleImportModal}
                          >
                            <i className="bi bi-file-earmark-excel-fill"></i>
                            <span className="ms-2">Import</span>
                          </button>
                        )}
                        {RolePermission?.findIndex(
                          (e) => e.permission_name === "export_vehicle_model"
                        ) > -1 && (
                          <button
                            type="button"
                            className="btn  btn-icon-text mr-2"
                            onClick={handleExport}
                          >
                            <i className="bi bi-file-earmark-excel-fill"></i>
                            <span className="ms-2">Export</span>
                          </button>
                        )}
                        {RolePermission?.findIndex(
                          (e) => e.permission_name === "add_vehicle_model"
                        ) > -1 && (
                          <button
                            type="button"
                            className="btn  btn-icon-text mr-2"
                            onClick={handleAddModal}
                          >
                            <i className="bi bi-plus-lg"></i>
                            <span className="ms-2">Add</span>
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="card-body fuelCardB">
                      <div className="vehicleThBg table-responsive table-responsive-vehicle">
                        <table className="table table-bordered rounded-table table table-striped table-hover">
                          <thead>
                            <tr>
                              <th
                                className="col-form-label ml-2 Label-my form-label form-label"
                                style={{ width: "1%" }}
                              >
                                #
                              </th>
                              <th
                                className="col-form-label ml-2 Label-my form-label form-label"
                                style={{ width: "10%" }}
                              >
                                Vehicle Model Name
                              </th>
                              <th
                                className="col-form-label ml-2 Label-my form-label form-label"
                                style={{ width: "10%" }}
                              >
                                Vehicle Start Year
                              </th>
                              <th
                                className="col-form-label ml-2 Label-my form-label form-label"
                                style={{ width: "10%" }}
                              >
                                Vehicle End Year
                              </th>

                              <th
                                className="col-form-label ml-2 Label-my form-label form-label"
                                style={{ width: "10%" }}
                              >
                                {LangData === "pt"
                                  ? "Vehicle Model Description(PT)"
                                  : LangData === "fr"
                                  ? "Vehicle Model Description(FR)"
                                  : LangData === "es"
                                  ? "Vehicle Model Description(ES)"
                                  : LangData === "it"
                                  ? "Vehicle Model Description(IT)"
                                  : LangData === "de"
                                  ? "Vehicle Model Description(DE)"
                                  : "Vehicle Model Description(EN)"}
                              </th>

                              <th
                                className="col-form-label ml-2 Label-my form-label form-label"
                                style={{ width: "0%" }}
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {vehicleModelList?.map((item, index) => {
                              return (
                                <>
                                  <tr>
                                    <td>{++index}</td>
                                    <td>{item.description}</td>
                                    <td>{item.initial_year}</td>
                                    <td>{item.year_final}</td>
                                    <td>
                                      {LangData === "pt"
                                        ? item.descriptionPT
                                        : LangData === "fr"
                                        ? item.descriptionFR
                                        : LangData === "es"
                                        ? item.descriptionES
                                        : LangData === "it"
                                        ? item.descriptionIT
                                        : LangData === "de"
                                        ? item.descriptionDE
                                        : item.descriptionEN}
                                    </td>
                                    <td className="d-flex">
                                      <Dropdown className="iconDropdown">
                                        <Dropdown.Toggle
                                          variant="success"
                                          id="dropdown-basic"
                                          className="iconToggle"
                                        >
                                          <i className="bi bi-three-dots-vertical"></i>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          {item?.is_import_record === 1 ? (
                                            <>
                                              <Dropdown.Item
                                                href="#/action-1"
                                                className="d-flex "
                                                onClick={(e) =>
                                                  onViewEngineVehicleModel(
                                                    e,
                                                    item
                                                  )
                                                }
                                              >
                                                <div className="edit-btn">
                                                  <i class="bi bi-eye-fill"></i>
                                                </div>
                                                <span>View</span>
                                              </Dropdown.Item>
                                            </>
                                          ) : (
                                            <>
                                              <Dropdown.Item
                                                href="#/action-1"
                                                className="d-flex"
                                                onClick={(e) =>
                                                  onEditVehicleModel(e, item)
                                                }
                                              >
                                                {(item.company_id > 0 ||
                                                  companyId.role_id == 1) &&
                                                  RolePermission?.findIndex(
                                                    (e) =>
                                                      e.permission_name ===
                                                      "modify_vehicle_model"
                                                  ) > -1 && (
                                                    <a className=""></a>
                                                  )}
                                                <div className="edit-btn">
                                                  <i className="bi bi-pencil"></i>
                                                </div>
                                                <span>Edit</span>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                href="#/action-2"
                                                className="d-flex"
                                                onClick={(e) =>
                                                  onDeleteModalOpen(e, item)
                                                }
                                              >
                                                {(item.company_id > 0 ||
                                                  companyId.role_id == 1) &&
                                                  RolePermission?.findIndex(
                                                    (e) =>
                                                      e.permission_name ===
                                                      "delete_vehicle_model"
                                                  ) > -1 && (
                                                    <div className="">
                                                      <a className=""></a>
                                                    </div>
                                                  )}
                                                <div className="trash-btn">
                                                  <a className="delete-btn">
                                                    <i className="bi bi-trash3"></i>
                                                  </a>
                                                </div>
                                                <span>Delete</span>
                                              </Dropdown.Item>
                                            </>
                                          )}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                          {vehicleModelList &&
                            vehicleModelList?.length === 0 && (
                              <tr>
                                <td
                                  colSpan={7}
                                  className={`text-center`}
                                >{`Record Not Found`}</td>
                              </tr>
                            )}
                        </table>
                      </div>
                    </div>
                    {/* /.card-body */}
                  </div>
                </div>
              </div>
            </div>
            <div className="fuelCF">
              <div className="card-footer card-footer-part clearfix">
                <div className="pagination pagination-sm m-0 float-right">
                  <Pagination
                    activePage={params.page}
                    itemsCountPerPage={parseInt(params.limit)}
                    totalItemsCount={parseInt(totalRecords)}
                    pageRangeDisplayed={5}
                    itemClass={`page-item`}
                    linkClass={`page-link`}
                    onChange={(e) => handleParams(e, "pagination")}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
      <Modal isOpen={delModal}>
        <ModalHeader>{`Delete Vehicle Model`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this vehicle model?`}</p>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            disabled={isDelButtonLoad}
            onClick={onDeleteModalClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close <i class="bi bi-x-lg"></i>
          </button>

          {!isDelButtonLoad && (
            <button
              type="button"
              className="btn btn-block bg-red"
              style={{ width: "100px" }}
              onClick={onDestroyVehicleForm}
            >
              Delete <i class="bi bi-trash3"></i>
            </button>
          )}
          {isDelButtonLoad && (
            <button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
            </button>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modifyModal} size="lg">
        <ModalHeader>Vehicle Model</ModalHeader>
        <Form method={`post`} onSubmit={handleVehicleModelForm}>
          <ModalBody>
            <div className="row justify-content-start align-items-center">
              <div className="col-md-4">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my form-label form-label">
                    {"Select Vehicle Type"}
                  </Label>
                  <div className="col-xl-12">
                    <input
                      className="form-control mt-2 input-search"
                      type="text"
                      maxLength={50}
                      placeholder="Search..."
                      value={catalog}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      onClick={(e) => e.stopPropagation()}
                    />
                    {showSuggestions && catalog && (
                      <ul className="suggestions">
                        {filteredSuggestions?.length > 0 ? (
                          filteredSuggestions.map((suggestion, index) => (
                            <li
                              key={suggestion}
                              className={
                                index === activeSuggestionIndex ? "active" : ""
                              }
                              onClick={() => handleClick(suggestion)}
                            >
                              {suggestion.vehicle_type_name}
                            </li>
                          ))
                        ) : (
                          <li>No suggestions available</li>
                        )}
                      </ul>
                    )}
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Vehicle Start Year"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="number"
                        value={vehicleModelData.vehicle_start_year}
                        onChange={(e) => {
                          setVehicleModelData({
                            ...vehicleModelData,
                            vehicle_start_year: e.target.value,
                          });
                        }}
                        name={`vehicle_start_year`}
                        maxLength={4}
                        placeholder="Vehicle Start Year"
                      />
                    </div>
                    {errors.vehicle_start_year && (
                      <span className="input-error">
                        {errors.vehicle_start_year}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-4">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Vehicle End Year"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="number"
                        value={vehicleModelData.vehicle_end_year}
                        onChange={(e) => {
                          setVehicleModelData({
                            ...vehicleModelData,
                            vehicle_end_year: e.target.value,
                          });
                        }}
                        name={`vehicle_end_year`}
                        maxLength={4}
                        placeholder="Vehicle End Year"
                      />
                    </div>
                    {errors.vehicle_end_year && (
                      <span className="input-error">
                        {errors.vehicle_end_year}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-4">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my form-label form-label">
                    {"Select Vehicle Make"}
                  </Label>
                  <div className="col-xl-12">
                    <input
                      className="form-control mt-2 input-search"
                      type="text"
                      maxLength={50}
                      placeholder="Search..."
                      value={catalogMake}
                      onChange={handleChangeMake}
                      onKeyDown={handleKeyDownMake}
                      onClick={(e) => e.stopPropagation()}
                    />
                    {showSuggestionsMake && catalogMake && (
                      <ul className="suggestions">
                        {filteredSuggestionsMake?.length > 0 ? (
                          filteredSuggestionsMake.map((suggestion, index) => (
                            <li
                              key={suggestion}
                              className={
                                index === activeSuggestionIndexMake
                                  ? "active"
                                  : ""
                              }
                              onClick={() => handleClickMake(suggestion)}
                            >
                              {suggestion.make_name}
                            </li>
                          ))
                        ) : (
                          <li>No suggestions available</li>
                        )}
                      </ul>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-4">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Description"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={vehicleModelData.description}
                        onChange={(e) => {
                          setVehicleModelData({
                            ...vehicleModelData,
                            description: e.target.value,
                          });
                        }}
                        name={`description`}
                        maxLength={30}
                        placeholder="Description"
                      />
                    </div>
                    {errors.description && (
                      <span className="input-error">{errors.description}</span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-4">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Description PT"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={vehicleModelData.descriptionPT}
                        onChange={(e) => {
                          setVehicleModelData({
                            ...vehicleModelData,
                            descriptionPT: e.target.value,
                          });
                        }}
                        name={`descriptionPT`}
                        maxLength={50}
                        placeholder="Description PT"
                      />
                    </div>
                    {errors.descriptionPT && (
                      <span className="input-error">
                        {errors.descriptionPT}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-4">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Description ES"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={vehicleModelData.descriptionES}
                        onChange={(e) => {
                          setVehicleModelData({
                            ...vehicleModelData,
                            descriptionES: e.target.value,
                          });
                        }}
                        name={`descriptionES`}
                        maxLength={50}
                        placeholder="Description ES"
                      />
                    </div>
                    {errors.descriptionES && (
                      <span className="input-error">
                        {errors.descriptionES}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-4">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Description FR"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={vehicleModelData.descriptionFR}
                        onChange={(e) => {
                          setVehicleModelData({
                            ...vehicleModelData,
                            descriptionFR: e.target.value,
                          });
                        }}
                        name={`descriptionFR`}
                        maxLength={50}
                        placeholder="Description FR"
                      />
                    </div>
                    {errors.descriptionFR && (
                      <span className="input-error">
                        {errors.descriptionFR}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-4">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Description IT"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={vehicleModelData.descriptionIT}
                        onChange={(e) => {
                          setVehicleModelData({
                            ...vehicleModelData,
                            descriptionIT: e.target.value,
                          });
                        }}
                        name={`descriptionIT`}
                        maxLength={50}
                        placeholder="Description IT"
                      />
                    </div>
                    {errors.descriptionIT && (
                      <span className="input-error">
                        {errors.descriptionIT}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-4">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Description DE"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={vehicleModelData.descriptionDE}
                        onChange={(e) => {
                          setVehicleModelData({
                            ...vehicleModelData,
                            descriptionDE: e.target.value,
                          });
                        }}
                        name={`descriptionDE`}
                        maxLength={50}
                        placeholder="Description DE"
                      />
                    </div>
                    {errors.descriptionDE && (
                      <span className="input-error">
                        {errors.descriptionDE}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-4">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Description EN"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={vehicleModelData.descriptionEN}
                        onChange={(e) => {
                          setVehicleModelData({
                            ...vehicleModelData,
                            descriptionEN: e.target.value,
                          });
                        }}
                        name={`descriptionEN`}
                        maxLength={40}
                        placeholder="Description EN"
                      />
                    </div>
                    {errors.descriptionEN && (
                      <span className="input-error">
                        {errors.descriptionEN}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-4">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Vehicle Month Initial"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="number"
                        value={vehicleModelData.month_initial}
                        onChange={(e) => {
                          setVehicleModelData({
                            ...vehicleModelData,
                            month_initial: e.target.value,
                          });
                        }}
                        name={`month_initial`}
                        maxLength={2}
                        placeholder="Vehicle Month Initial"
                      />
                    </div>
                    {errors.month_initial && (
                      <span className="input-error">
                        {errors.month_initial}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-4">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Vehicle Month Final"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="number"
                        value={vehicleModelData.month_final}
                        onChange={(e) => {
                          setVehicleModelData({
                            ...vehicleModelData,
                            month_final: e.target.value,
                          });
                        }}
                        name={`month_final`}
                        maxLength={2}
                        placeholder="Vehicle Month Final"
                      />
                    </div>
                    {errors.month_final && (
                      <span className="input-error">{errors.month_final}</span>
                    )}
                  </div>
                </FormGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              class="btn btn-block btn-primary"
              disabled={isModifyButtonLoad}
              onClick={handleCloseModal}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-x-lg"></i>
            </button>
            {!isModifyButtonLoad && (
              <button
                type="submit"
                class="btn btn-block btn-primary"
                style={{ width: "100px", marginTop: "10px" }}
              >
                Submit <i class="bi bi-check2"></i>
              </button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={modifyImportModal}>
        <Form method={`post`} onSubmit={handleImportGroup}>
          <ModalHeader>{`Import Vehicle Model`}</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <FormGroup>
                  <Label className="col-form-label">Select File</Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="file"
                        onChange={(e) => setFile(e.target.files[0])}
                        name="file"
                        accept=".csv,.xlsx"
                      />
                    </div>
                    {errorsImport.file && (
                      <span className="input-error">{errorsImport.file}</span>
                    )}
                  </div>
                </FormGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              class="btn btn-block btn-secondary"
              disabled={isModifyButtonLoad}
              onClick={handleImportCloseModal}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close
            </button>
            {!isModifyButtonLoad && (
              <button
                type="submit"
                class="btn btn-block btn-primary"
                style={{ width: "100px", marginTop: "10px" }}
              >
                Import
              </button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={viewModal} size="lg">
        <div className="border m-3 p-3">
          <ModalHeader className="p-0 pb-2">Vehicle Type Details</ModalHeader>

          <ModalBody>
            {/*<div className="row">*/}
              {/*<div className="col-md-6">*/}
                {/*Description : {vehicleModelData?.description}*/}
              {/*</div>*/}
              {/*<div className="col-md-6">*/}
                {/*DescriptionPT : {vehicleModelData?.descriptionPT}*/}
              {/*</div>*/}
              {/*<div className="col-md-6">*/}
                {/*DescriptionES : {vehicleModelData?.descriptionES}*/}
              {/*</div>*/}
              {/*<div className="col-md-6">*/}
                {/*DescriptionFR : {vehicleModelData?.descriptionFR}*/}
              {/*</div>*/}
              {/*<div className="col-md-6">*/}
                {/*DescriptionIT : {vehicleModelData?.descriptionIT}*/}
              {/*</div>*/}
              {/*<div className="col-md-6">*/}
                {/*DescriptionDE : {vehicleModelData?.descriptionDE}*/}
              {/*</div>*/}
              {/*<div className="col-md-6">*/}
                {/*DescriptionEN : {vehicleModelData?.descriptionEN}*/}
              {/*</div>*/}
              {/*<div className="col-md-6"></div>*/}
            {/*</div>*/}
            <table className="fuelTable">
              <tbody className="row">
              <tr className="col-md-6">
                <td>Description</td>
                <td>:</td>
                <td>{vehicleModelData?.description}</td>
              </tr>
              <tr className="col-md-6">
                <td>DescriptionPT</td>
                <td>:</td>
                <td>{vehicleModelData?.descriptionPT}</td>
              </tr>
              <tr className="col-md-6">
                <td>DescriptionES</td>
                <td>:</td>
                <td>{vehicleModelData?.descriptionES}</td>
              </tr>
              <tr className="col-md-6">
                <td>DescriptionFR</td>
                <td>:</td>
                <td>{vehicleModelData?.descriptionFR}</td>
              </tr>
              <tr className="col-md-6">
                <td>DescriptionIT</td>
                <td>:</td>
                <td>{vehicleModelData?.descriptionDE}</td>
              </tr>
              <tr className="col-md-6">
                <td>DescriptionEN</td>
                <td>:</td>
                <td>{vehicleModelData?.descriptionEN}</td>
              </tr>
              </tbody>
            </table>
          </ModalBody>
          <ModalFooter className="p-0">
            <button
                type="button"
                class="btn btn-block btn-primary"
                disabled={isModifyButtonLoad}
                onClick={onCloseViewVehicleModel}
                style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-x-lg"></i>
            </button>

            {isModifyButtonLoad && (
                <Button
                    color="success"
                    type={`button`}
                    disabled={true}
                    className={`btn-square`}
                >
                  <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
                </Button>
            )}
          </ModalFooter>
        </div>
      </Modal>
    </div>
  );
};

export default VehicleModel;
