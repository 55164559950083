import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "../../view/partials/Header";
import Sidebar from "../../view/partials/Sidebar";
import "../../asset/style/dashboard.css";
import isHovered from "../../view/partials/Sidebar"


const DashboardLayout = () => {
  const [isOpenS, setIsOpens] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  console.log("isHovered",isHovered)

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsOpens(false);
    } else {
      setIsOpens(true);
    }
  };
  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div className="sidebar-mini layout-fixed">
        <Header setIsOpens={setIsOpens} isOpenS={isOpenS}/>
        <div className="app-main">
          <div className="app-sidebar">
            <Sidebar setIsOpens={setIsOpens} isOpenS={isOpenS} setIsHovered={setIsHovered} isHovered={isHovered}/>
          </div>
          <div className="content-wrapper" style={{
          // marginLeft: isOpenS ? "250px" : "60px" || isHovered === true ? "60px" : "250px", 
          marginLeft: isHovered ? "250px" : isOpenS ? "250px" : "60px",

          transition: "margin-left 0.3s ease",
        }}>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;