import React, { useState, useEffect, useRef } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import { toast } from "react-toastify";
import Utils from "../../utils";
import {
  getCatalogListAuto,
  getPartsInventoryList,
  deletePartInventory,
  VehiclePartQRDetails,
  getCatalogList,
  deletePartInventoryHard,
  getpartInventoryQuentityDetails,
  getpartQuantityHistoryList,
  getpartQuantityList,
  actionPartStatusUpdate,
  partInvenoryAllPartPdf,
} from "../../services/PartsInventory";
import { useNavigate, useLocation } from "react-router-dom";
import Barcode from "react-barcode";
import { getCommonSearchList } from "../../services/settingsServices";
import DesmeImage from "../../asset/images/dismantle_icon.svg";
import {
  getVehicleProcessDetails,
  getVehicleSentToWastage,
} from "../../services/VehicleService";
import Dropdown from "react-bootstrap/Dropdown";
import moment from "moment/moment";
import Select from "react-select";
import { getLerCodeList } from "../../services/DepollutionService";

const InvoiceDeleteData = [
  { id: 0, value: "Select Record" },
  { id: 1, value: "Delete record" },
  { id: 2, value: "Invoice record" },
];

const PartsInventory = () => {
  const location = useLocation();
  const RolePermission = Utils.getRecordForCurrentPath(location);
  const tableLengthList = ConfigDB.data.dataTableLength;
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const companyId = JSON.parse(data);
  const navigate = useNavigate();
  const [delModal, setDelModal] = useState(false);
  const [delPartId, setDelPartId] = useState("");
  const [catalog, setCatalog] = useState("");
  const [search, setSearch] = useState("");
  const [model, setModel] = useState("");
  const [year, SetYear] = useState("");
  const [partName, setPartName] = useState("");
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [inventoryStatus, setInventoryStatus] = useState("");
  const [recordStatus, setRecordStatus] = useState(0);

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    company_id: companyId.company_id || 0,
    vehicle_model: "",
    vehicle_year: "",
    catalog_name: "",
    part_name: "",
    inventory_status: 0,
    record_show_type: 0,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [partsInventoryList, setPartsInventoryList] = useState([]);
  const [qrDetails, setQrDetails] = useState(false);

  const [QrPartDetailsList, setQrPartDetailsList] = useState({});

  const [DelPartQRId, setDelPartQRId] = useState("");
  const [scannedBarcode, setScannedBarcode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [inventoryList, setInventoryList] = useState([]);
  const [vehiclePartDetails, setVehiclePartDetails] = useState([]);
  const [vehiclePartDetailsId, setVehiclePartDetailsId] = useState("");
  const [vehiclePartDetailsModal, setVehiclePartDetailsModal] = useState(false);
  const [partDetails, setPartDetails] = useState([]);
  const [show, setShow] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [partHistoryQtyModal, setPartHistoryQtyModal] = useState(false);
  const [partHistoryQtyDetails, setPartHistoryQtyDetails] = useState([]);
  const [partHistoryQtyId, setpartHistoryQtyId] = useState("");
  const [allPartQtyList, setAllPartQtyList] = useState([]);
  const [partQtyStatusId, setpartQtyStatusId] = useState(0);

  const [partStatusChangeModal, setPartStatusChangeModal] = useState(false);
  const [inventoryPartStatusChange, setInventoryPartStatusChange] =
    useState("");

  const [selectedItems, setSelectedItems] = useState([]);
  const [addedParts, setAddedParts] = useState([]);

  const [LerCatalogList, setLerCatalogList] = useState([]);
  const [vehiclePartsData, setVehiclePartsData] = useState({
    ler_code_id: [],
    unit_value: [],
  });
  const [inputValueLer, setInputValueLer] = useState("");

  const sumWastageValue = vehiclePartsData.unit_value.reduce((acc, curr) => {
    const num = Number(curr);
    return !isNaN(num) ? acc + num : acc;
  }, 0);

  const handleSelectLERChange = (selectedOptions) => {
    setVehiclePartsData({
      ...vehiclePartsData,
      ler_code_id: selectedOptions
        ? selectedOptions.map((option) => option.value)
        : [],
      unit_value: selectedOptions
        ? selectedOptions.map((option) => option.unit_value)
        : [],
    });
  };

  const handleAddClick = (
    id,
    namePartPT,
    vehicle_model,
    vehicle_year,
    inventory_status_name
  ) => {
    const isAdded = addedParts.some((part) => part.id === id);

    if (isAdded) {
      setAddedParts((prev) => prev.filter((part) => part.id !== id));
    } else {
      setAddedParts((prev) => [
        ...prev,
        { id, namePartPT, vehicle_model, vehicle_year, inventory_status_name },
      ]);
    }
  };

  const filteredPartAssignList = partsInventoryList.filter(
    (item) => !addedParts.some((addedPart) => addedPart.id === item.id)
  );

  const handleInputChangeLER = (inputValueLer) => {
    setInputValueLer(inputValueLer);

    if (inputValueLer.length > 2) {
      const obj = {
        search: inputValueLer,
        limit: tableLengthList[0],
        page: 1,
      };

      getLerCodeList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].ler_code,
            });
          }
          setLerCatalogList(list);
        })
        .catch((err) => {});
    } else {
      setLerCatalogList([]);
      const obj = {
        search: "",
        limit: tableLengthList[0],
        page: 1,
      };

      getLerCodeList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].ler_code,
            });
          }
          setLerCatalogList(list);
        })
        .catch((err) => {});
    }
  };

  useEffect(function () {
    var obj = {
      search: "",
      limit: tableLengthList[0],
      page: 1,
    };
    getLerCodeList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].ler_code,
            unit_value: response.data[i].unit_value,
          });
        }
        setLerCatalogList(list);
      })
      .catch((err) => {});
  }, []);

  const handleCheckboxChange = (id) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((itemId) => itemId !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedItems(partsInventoryList.map((item) => item.id));
    } else {
      setSelectedItems([]);
    }
  };

  const handleShowOpen = () => {
    setShow(true);
  };

  const handleShowClose = () => {
    setShow(false);
  };

  const filteredParts = partDetails.filter((item) => item.location_ids !== "");

  const generateRandomString = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 4; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const onvehiclePartDetailsOpen = (e, item) => {
    e.preventDefault();
    setVehiclePartDetailsId(item.vehicle_id);
    setVehiclePartDetailsModal(true);
  };

  const onvehiclePartDetailsClose = () => {
    setVehiclePartDetailsModal(false);
  };

  const ontpartHistoryQtyDetailsOpen = (e, item) => {
    e.preventDefault();
    setpartHistoryQtyId(item.id);
    setPartHistoryQtyModal(true);
  };

  const onpartHistoryQtyDetailsClose = () => {
    setPartHistoryQtyModal(false);
  };

  const onPartStatusChangeOpen = () => {
    setPartStatusChangeModal(true);
  };

  const onPartStatusChangeClose = () => {
    setPartStatusChangeModal(false);
  };

  useEffect(() => {
    if (vehiclePartDetailsId) {
      let data = {
        vehicle_id: vehiclePartDetailsId,
        data_type: "vehicle",
      };

      getVehicleProcessDetails(data)
        .then((response) => {
          setVehiclePartDetails(response.data.data);
          setTotalRecords(response.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [vehiclePartDetailsId]);

  useEffect(() => {
    if (partHistoryQtyId) {
      const obj = {
        part_id: partHistoryQtyId,
      };

      getpartQuantityList(obj)
        .then((response) => {
          response = response.data;
          let list = [];

          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].qty,
            });
          }
          setAllPartQtyList(list);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [partHistoryQtyId]);

  useEffect(() => {
    if (partHistoryQtyId) {
      let data = {
        part_id: partHistoryQtyId,
        id: parseInt(partQtyStatusId) ? parseInt(partQtyStatusId) : parseInt(0),
      };

      getpartQuantityHistoryList(data)
        .then((response) => {
          setPartHistoryQtyDetails(response.data.data);
          setTotalRecords(response.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [partHistoryQtyId, partQtyStatusId]);

  const onQrDetailsOpen = (e, item) => {
    e.preventDefault();
    setDelPartQRId(item.id);
    setQrDetails(true);
  };

  const handleQrDetailsClose = () => {
    setQrDetails(false);
  };

  useEffect(() => {
    if (DelPartQRId) {
      let data = {
        part_id: DelPartQRId,
      };
      getpartInventoryQuentityDetails(data)
        .then((response) => {
          setPartDetails(response.data.data);
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  }, [DelPartQRId]);

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "part_inventory",
    };
    getCommonSearchList(data)
      .then((response) => {
        setInventoryList(response.data.data);
      })
      .catch((err) => {});
  }, []);

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    paramsObj.vehicle_model = model;
    paramsObj.vehicle_year = year;
    paramsObj.catalog_name = catalog;
    paramsObj.search = search;
    paramsObj.part_name = partName;

    paramsObj.inventory_status = parseInt(inventoryStatus);
    paramsObj.record_show_type = parseInt(recordStatus);

    setShow(false);

    setParams(paramsObj);
  };

  const ResetModal = () => {
    setParams({
      limit: tableLengthList[0],
      page: 1,
      search: "",
      is_reload: false,
      company_id: companyId.company_id || 0,
      vehicle_model: "",
      vehicle_year: "",
      catalog_name: "",
      part_name: "",
      inventory_status: 0,
      record_show_type: 0,
    });
    setModel("");
    setCatalog("");
    SetYear("");
    setInventoryStatus("");
    setRecordStatus(0);
    setShow(false);
  };

  const fetchPartInventory = () => {
    let data = { ...params };
    getPartsInventoryList(data)
      .then((response) => {
        setPartsInventoryList(response.data.data);
        setTotalRecords(response.data.total);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(
    function () {
      let data = { ...params };
      fetchPartInventory(data);
    },
    [params]
  );

  const handlePartsInventory = () => {
    navigate("/parts-inventory/AddParts");
  };

  const onDeleteModalOpen = (e, item) => {
    e.preventDefault();
    setDelPartId(item);
    setDelModal(true);
  };

  const onDeleteModalClose = () => {
    setDelModal(false);
  };
  const onDestroyPartInventory = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { id: delPartId.id };

    if (params.record_show_type === 1) {
      let UpdateData = {
        record_show_type: 1,
        part_id: delPartId,
      };

      deletePartInventoryHard(UpdateData)
        .then((response) => {
          let data = { ...params };
          fetchPartInventory(data);
          toast.success(response.data.message);
          setDelPartId("");
          setDelModal(false);
          setIsDelButtonLoad(false);
        })
        .catch((err) => {
          setIsDelButtonLoad(false);
          toast.error(Utils.getErrorMessage(err));
        });
    } else {
      deletePartInventory(data)
        .then((response) => {
          let data = { ...params };
          fetchPartInventory(data);
          toast.success(response.data.message);
          setDelPartId("");
          setDelModal(false);
          setIsDelButtonLoad(false);
        })
        .catch((err) => {
          setIsDelButtonLoad(false);
          toast.error(Utils.getErrorMessage(err));
        });
    }
  };

  const handleBarcodeScan = (barcode) => {
    setScannedBarcode(barcode);
    fetchPartDetails(barcode);
    setQrDetails(true); // Open modal after barcode scan
  };

  const fetchPartDetails = (barcode) => {
    const partDetails = QrPartDetailsList.find(
      (item) => item.bar_code === barcode || item.bar_code_parts === barcode
    );
    if (partDetails) {
      setQrPartDetailsList(partDetails);
    } else {
      console.log("Part not found");
    }
  };

  const handleChange = (e) => {
    const userInput = e.target.value;

    let data = {
      limit: params.limit,
      page: params.page,
      search: userInput,
      is_reload: true,
    };
    getCatalogListAuto(data)
      .then((response) => {
        setFilteredSuggestions(response.data.data);
      })
      .catch((err) => {});

    setCatalog(userInput);
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);
  };

  const handleClick = (suggestion) => {
    setCatalog(suggestion.partName);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      if (
        filteredSuggestions &&
        activeSuggestionIndex < filteredSuggestions?.length - 1
      ) {
        setActiveSuggestionIndex(activeSuggestionIndex + 1);
      }
    } else if (e.key === "ArrowUp") {
      if (activeSuggestionIndex > 0) {
        setActiveSuggestionIndex(activeSuggestionIndex - 1);
      }
    } else if (e.key === "Enter") {
      setCatalog(filteredSuggestions[activeSuggestionIndex]);
      setShowSuggestions(false);
      setFilteredSuggestions([]);
    }
  };
  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const partsCatalogId = addedParts?.map((item) => item.id);

    const payload = {
      part_ids: partsCatalogId,
      inventory_status: inventoryPartStatusChange,
      ler_code_id:
        inventoryPartStatusChange == 3 ? vehiclePartsData.ler_code_id : "",
      wastege_value: inventoryPartStatusChange == 3 ? sumWastageValue : "",
      description: "",
    };

    actionPartStatusUpdate(payload)
      .then((response) => {
        toast.success(response.data.message);
        setPartStatusChangeModal(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      })
      .finally(() => {
        // Reset button state to "close"
        setLoading(false);
      });
  };

  const handleSubmitDeleteWaste = (e) => {
    e.preventDefault();

    if (delPartId?.ler_code_id == null) {
      toast.error(
        "This part does not have any assign LER code please go to LER part and add the LER code"
      );
      setDelModal(false);
    } else {
      const payload = {
        part_ids: [delPartId?.id],
        inventory_status: 3,
        ler_code_id: [delPartId?.ler_code_id],
        wastege_value: delPartId?.unit_value,
        description: "",
      };

      actionPartStatusUpdate(payload)
        .then((response) => {
          toast.success(response.data.message);
          setDelModal(false);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  };

  const handleSubmitPdfAll = (e) => {
    e.preventDefault();
    partInvenoryAllPartPdf()
      .then((response) => {
        if (
          response.data &&
          response.data.record &&
          response.data.record.url &&
          response.status === 200
        ) {
          window.open(response.data.record.url, "_blank");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">{/* <h1>Parts Inventory</h1> */}</div>
              <div className="col-sm-6"></div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card fuelCard">
                  <div className="card-header">
                    <div
                      className="card-title m-0 float-left fuelCT"
                      style={{ width: "40%" }}
                    >
                      <input
                        className="form-control"
                        type="text"
                        value={params.search}
                        onChange={(e) => handleParams(e, "search")}
                        maxLength={50}
                        placeholder="Search references like Code,Price,Notes etc."
                      />
                    </div>

                    {show && (
                      <div
                        className="offcanvas-backdrop show"
                        onClick={(e) => e.stopPropagation()}
                      ></div>
                    )}

                    <div class="card-title m-0 float-right fuelCT rightBtn">
                      <button
                        type="button"
                        className="btn-icon-text mr-2"
                        onClick={handleSubmitPdfAll}
                      >
                        <i className="bi bi-plus-lg"></i>
                        <span className="ms-2">Export PDF</span>
                      </button>
                      <button
                        type="button"
                        className="btn-icon-text mr-2 mr-2"
                        onClick={onPartStatusChangeOpen}
                      >
                        <i class="bi bi-tools"></i>
                        <span className="ms-2">Part Status</span>
                      </button>

                      {RolePermission?.findIndex(
                        (e) => e.permission_name === "add_part_inventory"
                      ) > -1 && (
                        <button
                          type="button"
                          className="btn-icon-text mr-2 mr-2"
                          onClick={handlePartsInventory}
                        >
                          <i className="bi bi-plus-lg"></i>
                          <span className="ms-2">Add For Vehicle</span>
                        </button>
                      )}
                      {RolePermission?.findIndex(
                        (e) => e.permission_name === "add_part_inventory"
                      ) > -1 && (
                        <button
                          type="button"
                          className="btn-icon-text mr-2"
                          onClick={() => navigate("/parts-inventory-addparts")}
                        >
                          <i className="bi bi-plus-lg"></i>
                          <span className="ms-2">Add For Parts</span>
                        </button>
                      )}
                      <button
                        type="button"
                        className="btn-icon-text mr-2 mr-2"
                        onClick={handleShowOpen}
                      >
                        <i className="bi bi-funnel"></i>Filter
                      </button>
                    </div>
                  </div>
                  <div className="card-body fuelCardB">
                    <div className="vehicleThBg table-responsive vehicle-responsive">
                      <table className="table table-bordered rounded-table table table-striped table-hover">
                        <thead>
                          <tr>
                            <th className="Label-my" style={{ width: "18%" }}>
                              Catalog
                            </th>
                            <th className="Label-my">Model</th>
                            <th className="Label-my">Make</th>
                            <th className="Label-my" style={{ width: "20%" }}>
                              Inventory Status
                            </th>
                            <th className="Label-my" style={{ width: "0%" }}>
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {partsInventoryList?.map((item) => {
                            return (
                              <>
                                <tr key={item.id}>
                                  <td>{item.namePartPT}</td>
                                  <td>{item.vehicle_model_name}</td>
                                  <td>{item.vehicle_make_name}</td>
                                  <td>{item.inventory_status_name}</td>
                                  <td className="d-flex">
                                    <Dropdown className="iconDropdown">
                                      <Dropdown.Toggle
                                        variant="success"
                                        id="dropdown-basic"
                                        className="iconToggle"
                                      >
                                        <i className="bi bi-three-dots-vertical"></i>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          href="#/action-1"
                                          className="d-flex"
                                          onClick={() => {
                                            navigate(
                                              `/parts-inventory/AddParts/${item.id}`
                                            );
                                          }}
                                        >
                                          {RolePermission?.findIndex(
                                            (e) =>
                                              e.permission_name ===
                                              "modify_part_inventory"
                                          ) > -1 && (
                                            <a className="edit-btn" href="#">
                                              <i className="bi bi-pencil"></i>
                                            </a>
                                          )}
                                          <span>Edit</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#/action-2"
                                          className="d-flex"
                                        >
                                          {RolePermission?.findIndex(
                                            (e) =>
                                              e.permission_name ===
                                              "delete_part_inventory"
                                          ) > -1 && (
                                            <>
                                              {params.record_show_type === 1 ? (
                                                <>
                                                  <div className="trash-btn">
                                                    <a
                                                      className="delete-btn"
                                                      href="#"
                                                      onClick={(e) =>
                                                        onDeleteModalOpen(
                                                          e,
                                                          item
                                                        )
                                                      }
                                                    >
                                                      <i className="bi bi-arrow-clockwise"></i>
                                                    </a>
                                                  </div>
                                                </>
                                              ) : (
                                                <div className="trash-btn">
                                                  <a
                                                    className="delete-btn"
                                                    href="#"
                                                    onClick={(e) =>
                                                      onDeleteModalOpen(e, item)
                                                    }
                                                  >
                                                    <i className="bi bi-trash3"></i>
                                                  </a>
                                                </div>
                                              )}
                                            </>
                                          )}
                                          {params.record_show_type === 1 ? (
                                            <span
                                              onClick={(e) =>
                                                onDeleteModalOpen(e, item)
                                              }
                                            >
                                              Restore
                                            </span>
                                          ) : (
                                            <span
                                              onClick={(e) =>
                                                onDeleteModalOpen(e, item)
                                              }
                                            >
                                              Delete
                                            </span>
                                          )}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#/action-3"
                                          className="d-flex"
                                          onClick={() => {
                                            navigate(
                                              `/part/details/inven/${item.id}`
                                            );
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: "30px",
                                              height: "30px",
                                              borderRadius: "50px",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            <a className="edit-btn" href="#">
                                              <i className="bi bi-eye-fill"></i>
                                            </a>
                                          </div>
                                          <span>View</span>
                                        </Dropdown.Item>
                                        {item?.vehicle_id && (
                                          <Dropdown.Item
                                            className="d-flex"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              if (item) {
                                                navigate(
                                                  `/vehicle/details/part/${item.uuid}`,
                                                  {
                                                    state: item,
                                                  }
                                                );
                                              }
                                            }}
                                          >
                                            <button
                                              className="edit-btn"
                                              onClick={(e) =>
                                                e.preventDefault()
                                              }
                                            >
                                              <i className="bi bi-pencil"></i>
                                            </button>
                                            <span>Vehicle Details</span>
                                          </Dropdown.Item>
                                        )}

                                        <Dropdown.Item
                                          href="#/action-5"
                                          className="d-flex"
                                          onClick={(e) => {
                                            onQrDetailsOpen(e, item);
                                          }}
                                        >
                                          <a className="edit-btn" href="#">
                                            <i className="bi bi-qr-code-scan"></i>
                                          </a>
                                          <span>QR</span>
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                          className="d-flex"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            if (item) {
                                              navigate(
                                                `/part/details/inven/${item.uuid}`,
                                                {
                                                  state: item,
                                                }
                                              );
                                            }
                                          }}
                                        >
                                          <button
                                            className="edit-btn"
                                            onClick={(e) => e.preventDefault()}
                                          >
                                            <img
                                              src={DesmeImage}
                                              style={{ width: "66%" }}
                                            />
                                          </button>
                                          <span>Part Details</span>
                                        </Dropdown.Item>

                                        {/*<Dropdown.Item*/}
                                        {/*href="#/action-4"*/}
                                        {/*className="d-flex"*/}
                                        {/*onClick={(e) => {*/}
                                        {/*ontpartHistoryQtyDetailsOpen(*/}
                                        {/*e,*/}
                                        {/*item*/}
                                        {/*);*/}
                                        {/*}}*/}
                                        {/*>*/}
                                        {/*<a className="edit-btn" href="#">*/}
                                        {/*<i class="bi bi-tools"></i>*/}
                                        {/*</a>*/}

                                        {/*<span>Part Quntity History</span>*/}
                                        {/*</Dropdown.Item>*/}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                        {partsInventoryList &&
                          partsInventoryList?.length === 0 && (
                            <tr>
                              <td
                                colSpan={7}
                                className={`text-center`}
                              >{`Record Not Found`}</td>
                            </tr>
                          )}
                      </table>
                    </div>
                  </div>
                  {/* /.card-body */}
                </div>
              </div>
            </div>
          </div>
          <div className="fuelCF">
            <div className="pagination pagination-sm m-0 float-right card-footer-part">
              <Pagination
                activePage={params.page}
                itemsCountPerPage={parseInt(params.limit)}
                totalItemsCount={parseInt(totalRecords)}
                pageRangeDisplayed={5}
                itemClass={`page-item`}
                linkClass={`page-link`}
                onChange={(e) => handleParams(e, "pagination")}
              />
            </div>
          </div>
        </section>
      </div>
      <Modal isOpen={delModal}>
        <ModalHeader>{`Delete Inventory Part`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to restore this inventory part?`}</p>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            disabled={isDelButtonLoad}
            onClick={onDeleteModalClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close <i class="bi bi-x-lg"></i>
          </button>

          {!isDelButtonLoad && params.record_show_type === 1 ? (
            <>
              <button
                type="button"
                className="btn btn-block bg-red"
                style={{ width: "100px" }}
                onClick={onDestroyPartInventory}
              >
                Restore <i class="bi bi-arrow-repeat"></i>
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-block bg-red"
                style={{ width: "100px" }}
                onClick={onDestroyPartInventory}
              >
                Delete <i class="bi bi-trash3"></i>
              </button>
              {delPartId.inventory_status !== 3 && (
                <button
                  type="button"
                  class="btn btn-block btn-primary"
                  style={{ width: "148px" }}
                  onClick={handleSubmitDeleteWaste}
                >
                  Send To Waste <i class="bi bi-arrow-repeat"></i>
                </button>
              )}
            </>
          )}

          {isDelButtonLoad && (
            <button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
            </button>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={vehiclePartDetailsModal} size="lg">
        <ModalHeader>{`Vehicle Details`}</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <div className="vehicleThBg table-responsive">
                <table className="rounded-table table table-striped table-bordered table-hover">
                  <thead>
                    <tr>
                      <th>Registration</th>
                      <th>BookLet Model</th>
                      <th>external Ref</th>
                      <th>Model</th>
                      <th>Model Name</th>
                      <th>Year</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>{vehiclePartDetails.registration}</td>
                      <td>{vehiclePartDetails.vehicle_booklet_model}</td>
                      <td>{vehiclePartDetails.vehicle_external_ref}</td>
                      <td>{vehiclePartDetails.vehicle_model}</td>
                      <td>{vehiclePartDetails.vehicle_model_name}</td>
                      <td>{vehiclePartDetails.vehicle_year}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-block btn-primary"
            onClick={onvehiclePartDetailsClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close <i class="bi bi-x-lg"></i>
          </button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={qrDetails}>
        <ModalHeader>{`QR Details`}</ModalHeader>
        <ModalBody>
          <div className="row">
            {filteredParts?.length > 0 ? (
              filteredParts.map((item) => {
                console.log("item", item);
                return (
                  <>
                    <div className="col-md-6">
                      <Label>Part Code</Label>
                      <div className="svg-barshelf">
                        <Barcode value={item?.bar_code_parts} />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Label>Location aaa</Label>
                      <div className="svg-barpart">
                        <Barcode
                          value={`${item?.id} - ${generateRandomString()}`}
                        />
                      </div>
                    </div>
                  </>
                );
              })
            ) : (
              <div>No QR found</div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-block btn-primary"
            disabled={isDelButtonLoad}
            onClick={handleQrDetailsClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close <i class="bi bi-x-lg"></i>
          </button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={partHistoryQtyModal} size="lg">
        <ModalHeader>{`Part Quantity History Details`}</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="">
              <select
                className="form-select mt-2 input-search"
                type="select"
                value={partQtyStatusId}
                onChange={(e) => {
                  setpartQtyStatusId(e.target.value);
                }}
                style={{ width: "100%" }}
              >
                <option value="0">All</option>

                {allPartQtyList?.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
              <div className="vehicleThBg table-responsive mt-2">
                <table className="rounded-table table table-striped table-bordered table-hover">
                  <thead>
                    <tr>
                      <th>User</th>
                      <th>Location</th>
                      <th>Date</th>
                    </tr>
                  </thead>

                  <tbody>
                    {partHistoryQtyDetails?.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td>{item.user_name}</td>
                          <td>{item.location_names || "-"}</td>
                          <td>
                            {moment(item.last_created_at).format("DD/MM/YYYY")}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-block btn-primary"
            onClick={onpartHistoryQtyDetailsClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close <i className="bi bi-x-lg"></i>
          </button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={partStatusChangeModal} size="xl">
        <ModalHeader>{`Part Status Change`}</ModalHeader>

        <Form method={`post`} onSubmit={handleSubmit}>
          <ModalBody>
            <div className="row">
              <div className="col-lg-6">
                <Label
                  className="col-form-label ml-2 Label-my form-label form-label"
                  style={{ paddingLeft: "13px" }}
                >
                  {"Part Status"}
                </Label>
                <select
                  className="form-select  input-search"
                  type="select"
                  value={inventoryPartStatusChange}
                  onChange={(e) => {
                    setInventoryPartStatusChange(e.target.value);
                  }}
                  style={{
                    width: "95%",
                    marginLeft: "19px",
                    marginBottom: "13px",
                  }}
                >
                  {inventoryList?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg-6">
                {inventoryPartStatusChange == 3 && (
                  <>
                    <div className="row">
                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Select LER Catalog"}
                          </Label>
                          <div className="">
                            <div className="input-div">
                              <Select
                                className="my-Select"
                                options={LerCatalogList}
                                value={LerCatalogList.find(
                                  (item) =>
                                    item.value == vehiclePartsData.ler_code_id
                                )}
                                onChange={handleSelectLERChange}
                                placeholder={"Select LER catalog"}
                                isSearchable={true}
                                onInputChange={handleInputChangeLER}
                                inputValue={inputValueLer}
                                isClearable={true}
                                isMulti
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-md-6" style={{ marginTop: "44px" }}>
                        {vehiclePartsData.ler_code_id.length > 0 && (
                          <div className="">
                            <p className="wastage">
                              wastage Value : {sumWastageValue}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="row">
              <div className="row">
                <div className="col-md-6">
                  <div className="vehicleThBg table-responsive tableScroll table-scroll-modal">
                    <table className="rounded-table table table-striped table-bordered table-hover">
                      <thead>
                        <tr>
                          <th style={{}}>Catalog</th>
                          <th style={{}}>Model</th>
                          <th style={{}}>Year</th>
                          <th style={{}}>Inventory Status</th>
                          <th style={{}}>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {filteredPartAssignList?.length > 0 ? (
                          filteredPartAssignList?.map((item, index) => (
                            <tr key={index}>
                              <td>{item?.namePartPT}</td>
                              <td>{item.vehicle_model}</td>
                              <td>{item.vehicle_year}</td>
                              <td>{item.inventory_status_name}</td>

                              <td>
                                <Button
                                  className="addBtn"
                                  onClick={() =>
                                    handleAddClick(
                                      item.id,
                                      item.namePartPT,
                                      item.vehicle_model,
                                      item.vehicle_year,
                                      item.inventory_status_name
                                    )
                                  }
                                  style={{
                                    backgroundColor: addedParts.some(
                                      (part) => part.id === item.id
                                    )
                                      ? "red"
                                      : "green",
                                    color: "white",
                                    cursor: "pointer",
                                  }}
                                >
                                  <i className="bi bi-plus-lg"></i>{" "}
                                </Button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className="scrollBrdr">
                            <td colSpan="100%">No results found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="card-footer clearfix card-footer-part">
                    <div className="pagination pagination-sm m-0  paginationRight">
                      <Pagination
                        activePage={params.page}
                        itemsCountPerPage={parseInt(params.limit)}
                        totalItemsCount={parseInt(totalRecords)}
                        pageRangeDisplayed={5}
                        itemClass={`page-item`}
                        linkClass={`page-link`}
                        onChange={(e) => handleParams(e, "pagination")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="vehicleThBg table-responsive tableScroll table-scroll-modal">
                    <table className="rounded-table table table-striped table-bordered table-hover">
                      <thead>
                        <tr>
                          <th style={{ width: "10%" }}>Catalog</th>
                          <th style={{ width: "10%" }}>Model</th>
                          <th style={{ width: "10%" }}>Year</th>
                          <th style={{ width: "10%" }}>Inventory Status</th>
                          <th style={{ width: "0%" }}>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {addedParts?.length > 0 ? (
                          addedParts?.map((item, index) => (
                            <tr key={index}>
                              <td>{item?.namePartPT}</td>
                              <td>{item.vehicle_model}</td>
                              <td>{item.vehicle_year}</td>
                              <td>{item.inventory_status_name}</td>

                              <td className="">
                                <a
                                  className="btn-danger-delete"
                                  href="#"
                                  onClick={() =>
                                    handleAddClick(item.id, item.registration)
                                  }
                                >
                                  <i className="bi bi-trash3"></i>
                                </a>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className="scrollBrdr">
                            <td colSpan="100%">No results found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              class="btn btn-block btn-primary"
              onClick={onPartStatusChangeClose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-x-lg"></i>
            </button>
            {loading ? (
              <button
                type="submit"
                className="btn btn-block btn-primary"
                style={{ width: "100px", marginTop: "10px" }}
              >
                loading...
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-block btn-primary"
                style={{ width: "100px", marginTop: "10px" }}
              >
                Submit <i className="bi bi-check2"></i>
              </button>
            )}
          </ModalFooter>
        </Form>
      </Modal>

      <div
        className={`offcanvas offcanvas-end ${show ? "show" : ""}`}
        tabIndex="-1"
        id="staticBackdrop"
        aria-labelledby="staticBackdropLabel"
        style={{ visibility: show ? "visible" : "hidden" }}
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="staticBackdropLabel">
            Filter
          </h5>

          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={handleShowClose}
          ></button>
        </div>

        <div className="offcanvas-body">
          <div>
            <div href="#/action-2">
              <div className="Label-my">
                Restore Data
                <select
                  className="form-select mt-2 input-search"
                  type="select"
                  value={recordStatus}
                  onChange={(e) => {
                    setRecordStatus(e.target.value);
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  {InvoiceDeleteData?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <div className="Label-my">
                Catalog
                <div className="autocomplete">
                  <input
                    className="form-control mt-2 input-search"
                    type="text"
                    maxLength={50}
                    placeholder="Search..."
                    value={catalog}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onClick={(e) => e.stopPropagation()}
                  />
                  {showSuggestions && catalog && (
                    <ul className="suggestions">
                      {filteredSuggestions?.length > 0 ? (
                        filteredSuggestions.map((suggestion, index) => (
                          <li
                            key={suggestion}
                            className={
                              index === activeSuggestionIndex ? "active" : ""
                            }
                            onClick={() => handleClick(suggestion)}
                          >
                            {suggestion.partName}
                          </li>
                        ))
                      ) : (
                        <li>No suggestions available</li>
                      )}
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <div>
              <div className="Label-my">
                Model
                <input
                  className="form-control mt-2 input-search"
                  type="text"
                  value={model}
                  onChange={(e) => {
                    setModel(e.target.value);
                  }}
                  maxLength={50}
                  placeholder="Model"
                  onClick={(e) => e.stopPropagation()}
                />
              </div>
            </div>
            <div>
              <div className="Label-my">
                Year
                <input
                  className="form-control mt-2 input-search"
                  type="number"
                  value={year}
                  onChange={(e) => {
                    SetYear(e.target.value);
                  }}
                  maxLength={50}
                  placeholder="year"
                  onClick={(e) => e.stopPropagation()}
                />
              </div>
            </div>
            <div>
              <div
                className="Label-my"
                style={{
                  whiteSpace: "nowrap",
                  paddingTop: "0px",
                }}
              >
                Inventory Status
                <select
                  className="form-select mt-2 input-search"
                  type="select"
                  value={inventoryStatus}
                  onChange={(e) => {
                    setInventoryStatus(e.target.value);
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <option>All</option>

                  {inventoryList?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-around">
            <button
              style={{ marginTop: "9px", display: "flex" }}
              type="button"
              className="btn  btn-primary btn-filter"
              onClick={(e) => handleParams(e, "search")}
            >
              <span className="ms-1">Search</span>
            </button>
            <button
              style={{ marginTop: "9px", display: "flex" }}
              type="button"
              className="btn  btn-primary ms-1 btn-filter"
              onClick={ResetModal}
            >
              <span className="ms-1 ">Reset</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartsInventory;
