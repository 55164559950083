import React from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Col from "react-bootstrap/Col";
import googleImG from "../../src/asset/images/google-play.png";
import appImG from "../../src/asset/images/app-store.png";

const Footer = () => {
  return (
    
    <footer className="footer">
      <Container>
        <div className="row">
          <Col xl="6">
            <h3>Download The App</h3>
          </Col>
          <Col xl="6">
            <div className="button-group store-buttons store-black">
              <Link to={"/"}>
                <img className="img-fluid" src={googleImG} alt="" />
              </Link>

              <Link to={"/"}>
                <img className="img-fluid" src={appImG} alt="" />
              </Link>
            </div>
          </Col>
        </div>
        <div className="footercoopyright">
          <div className="row">
            <div className="col-xl-6">
              <div className="copyright">
                Copyright <span> © 2024</span> telepecas. All Rights Reserved
              </div>
            </div>
            <div className="col-xl-6">
              <ul className="terms-naves">
                <li>
                  <Link to={"/terms-condition"}>Terms and Conditions</Link>
                </li>
                <li>
                  <Link to={"/privacy-policy"}>Privacy Policy</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </footer>
    
     
  );
};

export default Footer;
