import React, { useEffect, useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useNavigate } from "react-router-dom";
import {
  createVehicleImage,
  deleteVehicleImage,
  updateVehicleImageProcessSort,
  updateVehicleImageSort,
  // updateVehicleImageSequence,
} from "../../services/VehicleService"; // Add update API
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

const VehicleImage = ({ setActiveTab, vehicleProcessList, activeTab }) => {
  const [modifyImportModal, setModifyImportModal] = useState(false);
  const [files, setFiles] = useState([]);

  const navigate = useNavigate();
  const vehicleId = localStorage.getItem("vehicleId");

  const handleSaveOrder = () => {
    const validFiles = files.filter((file) => {
      return !String(file.id).startsWith("temp-");
    });

    const simplifiedFiles = validFiles.map((file, index) => ({
      id: file.id,
      sort_order: index + 1,
    }));

    const data = {
      sort_list: simplifiedFiles,
    };

    updateVehicleImageProcessSort(data)
      .then((response) => {
        toast.success(response.data.message);
        // setActiveTab("vehicle_extra_info");
        setModifyImportModal(false);
      })
      .catch((err) => toast.error(Utils.getErrorMessage(err)));
  };

  useEffect(() => {
    if (
      Array.isArray(vehicleProcessList?.vehicle_image) &&
      activeTab === "vehicle_images"
    ) {
      const initialFiles = vehicleProcessList.vehicle_image.map(
        (document, index) => ({
          url: document.vehicle_image,
          id: document.id,
          sequence: document.sort_order,
        })
      );

      setFiles(initialFiles);
    }
  }, [vehicleProcessList, activeTab]);

  const handleImportModal = () => setModifyImportModal(true);
  const handleImportCloseModal = () => setModifyImportModal(false);

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files).map((file, index) => ({
      url: URL.createObjectURL(file),
      file,
      id: `temp-${Date.now()}-${index}`,
      sequence: files.length + index + 1,
    }));

    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setModifyImportModal(false);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedFiles = Array.from(files);
    const [movedFile] = reorderedFiles.splice(result.source.index, 1);
    reorderedFiles.splice(result.destination.index, 0, movedFile);

    // Update sequence numbers
    const updatedFiles = reorderedFiles.map((file, index) => ({
      ...file,
      sequence: index + 1,
    }));

    setFiles(updatedFiles);
  };

  const handleRemoveFile = (fileToRemove) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));

    if (fileToRemove?.id) {
      setFiles((prevFiles) =>
        prevFiles.filter((file) => file.id !== fileToRemove.id)
      );

      const data = { id: fileToRemove.id };

      deleteVehicleImage(data)
        .then((response) => {
          toast.success(response.data.message);
          setActiveTab("vehicle_images");
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  };

  const handleVehicleImageForm = (e) => {
    e.preventDefault();

    const newFiles = files.filter((file) => file.file);

    if (newFiles.length > 0) {
      const formData = new FormData();
      newFiles.forEach((file, index) => {
        formData.append(`files[${index}]`, file.file);
        formData.append(`sort_order[${index}]`, file.sequence);
      });
      formData.append("vehicle_id", vehicleId);

      createVehicleImage(formData)
        .then((response) => {
          toast.success(response.data.message);
          setActiveTab("vehicle_extra_info");
        })
        .catch((err) => toast.error(Utils.getErrorMessage(err)));
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          {vehicleProcessList?.vehicle_image?.length > 0 ? (
            <>
              <div className="card card-primary">
                <Form method="post" onSubmit={handleVehicleImageForm}>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12 ms-3">
                        <button
                          type="button"
                          onClick={() =>
                            document.getElementById("file-input").click()
                          } // Trigger file input click
                          className={`btn btn-primary mb-2 ${
                            files.length === 0 ? "import-button-empty" : ""
                          }`}
                        >
                          <i class="bi bi-cloud-arrow-up"></i> Upload Multiple
                          Images
                        </button>
                        <input
                          id="file-input"
                          className="form-control"
                          type="file"
                          onChange={handleFileChange}
                          name="file"
                          accept=".jpg,.png"
                          multiple
                          style={{ display: "none" }} // Hide the input
                        />
                        <div className="file-preview file-preview-custom">
                          <div className="file-list">
                            {files.length === 0 ? (
                              <div className="file-item large-image">
                                <div
                                  className="preview-images-size"
                                  style={{ height: "254px" }}
                                >
                                  <div
                                    className="card"
                                    style={{ width: "340px" }}
                                  >
                                    <div className="card-body">
                                      <div className="">
                                        {/* <i
                                      className="bi bi-x"
                                      style={{
                                        color: "red",
                                        display: "flex",
                                        justifyContent: "end",
                                      }}
                                    ></i> */}
                                        <div className="btn-removeImg">
                                          <div className="btn-Remove">
                                            <i
                                              className="bi bi-x"
                                              style={{
                                                color: "red",
                                                display: "flex",
                                                justifyContent: "end",
                                              }}
                                            ></i>
                                          </div>
                                        </div>
                                        <p className="noImg">
                                          No image Selected
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              files?.map((file, index) => (
                                <div
                                  key={index}
                                  className={`file-item ${
                                    index === 0 ? "large-image" : "small-image"
                                  }`}
                                >
                                  <img
                                    src={file.url}
                                    alt="preview"
                                    className={`preview-images ${
                                      index === 0
                                        ? "large-images"
                                        : "small-images"
                                    }`}
                                  />
                                  <div
                                    className={`preview-images-size ${
                                      index === 0
                                        ? "large-images-size"
                                        : "small-images-size"
                                    }`}
                                    style={{
                                      position: "absolute",
                                      height: "auto",
                                      marginTop: "0",
                                      top: "5",
                                      zIndex: "9",
                                    }}
                                  >
                                    <div className="btn-Remove">
                                      <i
                                        className="bi bi-x"
                                        style={{ color: "red" }}
                                        onClick={() => handleRemoveFile(file)}
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                              ))
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12"></div>
                    </div>
                    <div className="buttonsB">
                      <div className="col">
                        <button
                          type="button"
                          className="back-btn"
                          onClick={() => setActiveTab("vehicle")}
                        >
                          <i class="bi bi-arrow-left"></i> Back
                        </button>
                      </div>
                      <div className="col">
                        <div className="btnNxtFns">
                          <button
                            type="button"
                            className="btn btn-primary nxt-btn"
                            onClick={handleImportModal}
                          >
                            Sort By Order <i class="bi bi-arrow-right"></i>
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary nxt-btn"
                          >
                            Save <i class="bi bi-arrow-right"></i>
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary nxt-btn"
                            onClick={() => setActiveTab("vehicle_extra_info")}
                          >
                            Next <i class="bi bi-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>{" "}
              </div>{" "}
            </>
          ) : (
            <>
              <div className="card card-primary">
                <Form method="post" onSubmit={handleVehicleImageForm}>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <button
                          type="button"
                          onClick={() =>
                            document.getElementById("file-input").click()
                          }
                          className={`btn btn-primary mb-2 ${
                            files.length === 0 ? "import-button-empty" : ""
                          }`}
                        >
                          <i className="bi bi-cloud-arrow-up"></i> Upload
                          Multiple Images
                        </button>
                        <input
                          id="file-input"
                          className="form-control"
                          type="file"
                          onChange={handleFileChange}
                          name="file"
                          accept=".jpg,.png"
                          multiple
                          style={{ display: "none" }}
                        />
                      </div>
                    </div>

                    <DragDropContext onDragEnd={handleDragEnd}>
                      <Droppable droppableId="fileList" direction="horizontal">
                        {(provided) => (
                          <div
                            className="file-list"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                              display: "flex",
                              gap: "10px",
                              overflowX: "auto",
                            }}
                          >
                            {files.map((file, index) => (
                              <Draggable
                                key={file.id || file.url}
                                draggableId={file.id || file.url}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    className="file-item"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      ...provided.draggableProps.style,
                                      border: "1px solid #ddd",
                                      padding: "5px",
                                      borderRadius: "5px",
                                      position: "relative",
                                    }}
                                  >
                                    <img
                                      src={file.url}
                                      alt="preview"
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        objectFit: "cover",
                                      }}
                                    />
                                    <i
                                      className="bi bi-x"
                                      style={{
                                        position: "absolute",
                                        top: "5px",
                                        right: "5px",
                                        cursor: "pointer",
                                        color: "red",
                                      }}
                                      onClick={() => handleRemoveFile(file)}
                                    ></i>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>

                    <div className="buttonsB">
                      <button
                        type="button"
                        className="back-btn"
                        onClick={() => setActiveTab("vehicle")}
                      >
                        <i className="bi bi-arrow-left"></i> Back
                      </button>
                      <button type="submit" className="btn btn-primary nxt-btn">
                        Finish <i className="bi bi-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </>
          )}
        </div>
      </div>

      <Modal isOpen={modifyImportModal} toggle={handleImportCloseModal}>
        <Form method="post">
          <ModalHeader toggle={handleImportCloseModal}>
            Sort By Order
          </ModalHeader>
          <ModalBody>
            <DragDropContext
              onDragEnd={(result) => {
                if (!result.destination) return;

                const reorderedFiles = Array.from(files);
                const [movedFile] = reorderedFiles.splice(
                  result.source.index,
                  1
                );
                reorderedFiles.splice(result.destination.index, 0, movedFile);

                const updatedFiles = reorderedFiles.map((file, index) => ({
                  ...file,
                  sequence: index + 1,
                }));

                setFiles(updatedFiles);
                console.log(
                  updatedFiles.map(({ id, sequence }) => ({ id, sequence }))
                ); // Simplified output
              }}
            >
              <Droppable droppableId="fileList" direction="vertical">
                {(provided) => (
                  <div
                    className="file-list"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    {files.map((file, index) => (
                      <Draggable
                        key={file.id.toString()}
                        draggableId={file.id.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            className="file-item"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              border: "1px solid #ddd",
                              padding: "10px",
                              borderRadius: "5px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={file.url}
                              alt="Preview"
                              style={{
                                width: "50px",
                                height: "50px",
                                objectFit: "cover",
                                borderRadius: "5px",
                              }}
                            />{" "}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </ModalBody>

          <ModalFooter>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleImportCloseModal}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                handleSaveOrder();
                handleImportCloseModal();
              }}
            >
              Save Order
            </button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default VehicleImage;
