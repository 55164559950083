import axiosInstance from "./AxiosInstance";

export function getWareHouseEntityList(data) {
  return axiosInstance.post(`/warehouse-entity/list`, data);
}

export function deleteWareHouseEntity(data) {
  return axiosInstance.post(`/warehouse-entity/delete`, data);
}

export function createWareHouseEntity(data) {
  return axiosInstance.post(`/warehouse-entity/create`, data);
}

export function updateWareHouseEntity(data) {
  return axiosInstance.post(`/warehouse-entity/update`, data);
}

export function WareHouseEntitySerach(data) {
  return axiosInstance.post(`/warehouse-entity/search-list`, data);
}

export function getWareHouseList(data) {
  return axiosInstance.post(`/warehouse/list`, data);
}

export function deleteWareHouse(data) {
  return axiosInstance.post(`/warehouse/delete`, data);
}

export function createWareHouse(data) {
  return axiosInstance.post(`/warehouse/create`, data);
}

export function updateWareHouse(data) {
  return axiosInstance.post(`/warehouse/update`, data);
}

export function WareHouseSerachList(data) {
  return axiosInstance.post(`/warehouse/search-list`, data);
}
