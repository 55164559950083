import axiosInstance from "./AxiosInstance";

export function CreatePaymentMBpay(data) {
  return axiosInstance.post(`/payment`, data);
}

export function getPaymentWebHooks(requestId, data) {
  const url = `/payment-webhook?request_id=${requestId}`;

  return axiosInstance.post(url, data);
}


export function CreatePaymentMultibanco(data) {
  return axiosInstance.post(`/payment-mb-offline`, data);
}
