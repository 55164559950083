import axiosInstance from "./AxiosInstance";

export function getVehicleMakeList(data) {
  return axiosInstance.post(`/vehicle-make/list`, data);
}

export function deleteVehicleMake(data) {
  return axiosInstance.post(`/vehicle-make/delete`, data);
}

export function createVehicleMake(data) {
  return axiosInstance.post(`/vehicle-make/create`, data);
}

export function updateVehicleMake(data) {
  return axiosInstance.post(`/vehicle-make/update`, data);
}

export function importVehicleMake(data) {
  return axiosInstance.post(`/vehicle-make/import`, data);
}

export function exportVehicleMake(data) {
  return axiosInstance.post(`/vehicle-make/export-list`, data);
}

export function getAllVehicleSerachType(data) {
  return axiosInstance.post(`/vehicle-type/search-list`, data);
}
