import React, { useEffect, useRef, useState } from "react";
import {
  getCatalogList,
  actionPartInventoryTempStore,
  getVehicleList,
  getPartInventoryTempList,
  DestoryPartInventoryTemp,
  createPartsInventory,
  updatePartsInventory,
  actionPartInventoryImage,
  partInvenoryPartETAI,
  partInvenoryPartETAIPrice,
} from "../../services/PartsInventory";
import Select from "react-select";
import ConfigDB from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getVehicleModelAllList,
  getvehicleMakeAllList,
  getvehicleYearList,
} from "../../services/VehicleService";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Pagination from "react-js-pagination";
import { getAllViewShelfList } from "../../services/ShelfServices";
import {
  getCommonSearchList,
  getSettingsCompanyDetails,
} from "../../services/settingsServices";

import { getAllBrandSerachList } from "../../services/PartBrandServices";
import { getVehicleAllModalSerachList } from "../../services/VehicleModelService";

import {
  Modal,
  Button,
  FormGroup,
  Label,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { getVehicleSerachVariantList } from "../../services/VehicleVariantsService";
import { getVehicleEngineSerachList } from "../../services/VehicleEngineService";
import {
  actionTempStoreDismental,
  getDismentalList,
} from "../../services/DismentalServices";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

const AddForParts = ({ vehicleAddDetails }) => {
  const tableLengthList = ConfigDB?.data?.dataTableLength;
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryTemp, setSearchQueryTemp] = useState("");
  const [VehicleModelList, setVehicleModelList] = useState([]);
  const [VehicleModelAllList, setVehicleModelAllList] = useState([]);
  const [CatalogList, setCatalogList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [tempData, setTempData] = useState({});
  const [partsData, setPartsData] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [inputSelctedValue, setInputSelctedValue] = useState("");
  const [categoryPartList, setCategoryPartList] = useState([]);
  const [vehicleYearPartList, setvehicleYearPartList] = useState([]);
  const [inputSelctedYearValue, setInputSelctedYearValue] = useState("");
  const [vehicleMakePartList, setvehicleMakePartList] = useState([]);
  const [inputSelctedMakeValue, setInputSelctedMakeValue] = useState("");
  const [vehicleModalPartList, setvehicleModalPartList] = useState([]);
  const [inputSelctedModalValue, setInputSelctedModalValue] = useState("");
  const [vehicleVariantPartList, setvehicleVariantPartList] = useState([]);
  const [inputSelctedVariantValue, setInputSelctedVariantValue] = useState("");
  const [vehicleEngineList, setvehicleEngineList] = useState([]);
  const [inputSelctedEngineValue, setInputSelctedEngineValue] = useState("");
  const [DismetalList, setDismetalList] = useState([]);
  const [inputSelctedDismetalValue, setInputSelctedDismetalValue] =
    useState("");
  const [dismentalData, setDismentalData] = useState({
    dismantle_id: "",
  });
  const [dismantleTempData, setDismantleTempData] = useState("");
  const [categoryPartsData, setCategoryPartsData] = useState({
    catalog_id: "",
    vehicle_year: "",
    vehicle_make: "",
    vehicle_model: "",
    vehicle_variant: "",
    is_master_id: "",
  });
  const [categoryMasterPart, setCategoryMasterPart] = useState({});

  const [modifyMasterPart, setModifyMasterPart] = useState(false);

  const [EngineData, setEngineData] = useState({
    engine_code: "",
    kw: "",
    hp: "",
  });

  const [selectedVehicleModel, setSelectedVehicleModel] = useState({});
  const [selectedInventoryStatus, setselectedInventoryStatus] = useState({});
  const [vehicleYearMasterPartList, setvehicleYearMasterPartList] = useState(
    []
  );
  // const [inputSelctedYearValue, setInputSelctedYearValue] = useState("");
  // const [vehicleMakePartList, setvehicleMakePartList] = useState([]);
  // const [inputSelctedMakeValue, setInputSelctedMakeValue] = useState("");
  // const [vehicleModalPartList, setvehicleModalPartList] = useState([]);
  // const [inputSelctedModalValue, setInputSelctedModalValue] = useState("");
  // const [vehicleVariantPartList, setvehicleVariantPartList] = useState([]);
  // const [inputSelctedVariantValue, setInputSelctedVariantValue] = useState("");
  const [vehiclePartsData, setVehiclePartsData] = useState({
    vehicle_model_id: "",
    vehicle_make: "",
    vehicle_year: "",
    vehicle_model: "",
    vehicle_variants: "",
  });

  const [selectedValues, setSelectedValues] = useState({});

  let errorsObj = {
    part_code: "",
    part_name: "",
    quantity: "",
    part_price: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  let errorsObjs = {
    catalog_id: "",
    vehicle_make: "",
    vehicle_year: "",
    vehicle_model: "",
  };
  const [errorss, setErrorss] = useState(errorsObjs);
  const [images, setImage] = useState([]);
  const [previewUrl, SetPreviewUrl] = useState([]);
  const [showModalAbout, setShowModalAbout] = useState(false);
  const [detailsForImages, SetDetailsForImages] = useState([]);
  const [currentPartIndex, setCurrentPartIndex] = useState(0);
  const [showSkip, setShowSkip] = useState(false);
  const [enlargedIndexes, setEnlargedIndexes] = useState([]);
  const [ShelfList, SetShelfList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [conditionList, setConditionList] = useState([]);
  const [inventoryList, setInventoryList] = useState([]);
  const [modifyLabel, setmodifyLabel] = useState(false);
  const [settingDetails, setSettingDetails] = useState("");
  const [allBrandList, SetAllBrandList] = useState([]);

  //save as label
  const [btnLoader, setBtnLoader] = useState(false);
  const [btnLoading, setbtnLoading] = useState(false);
  const [btnClosed, setBtnClosed] = useState(false);
  const [btnSaved, setBtnSaved] = useState(false);
  const [btnNext, setbtnNext] = useState(false);

  const [filteredSuggestionsMake, setFilteredSuggestionsMake] = useState([]);
  const [activeSuggestionIndexMake, setActiveSuggestionIndexMake] = useState(0);
  const [showSuggestionsMake, setShowSuggestionsMake] = useState(false);
  const [catalogMake, setCatalogMake] = useState("");
  const [vehicleMakeId, setVehicleMakeId] = useState("");

  const [filteredSuggestionsModel, setFilteredSuggestionsModel] = useState([]);
  const [activeSuggestionIndexModel, setActiveSuggestionIndexModel] =
    useState(0);
  const [showSuggestionsModel, setShowSuggestionsModel] = useState(false);
  const [vehicleModelAuto, setVehicleModelAuto] = useState("");
  const [vehicleModelId, setVehicleModelId] = useState("");
  const [selectedModels, setSelectedModels] = useState([]);

  const [filteredSuggestionsPart, setFilteredSuggestionsPart] = useState([]);
  const [activeSuggestionIndexPart, setActiveSuggestionIndexPart] = useState(0);
  const [showSuggestionsPart, setShowSuggestionsPart] = useState(false);
  const [catalogPart, setCatalogPart] = useState("");
  const [vehiclePartId, setVehiclePartId] = useState("");
  const [referenceList, setReferenceList] = useState([]);
  const [isChecked, setIsChecked] = useState(0);
  const [partETAIRerList, setPartETAIRerList] = useState("");
  const [partEtaiModify, setPartEtaiModify] = useState(false);
  const [partEtaiPriceModify, setPartEtaiPriceModify] = useState(false);
  const [partETAIPriceList, setPartETAIPriceList] = useState("");
  const [partETAIRefDetails, setPartETAIRefDetails] = useState([]);
  const [partETAIPriceDetail, setPartETAIPriceDetail] = useState([]);
  const [selectedReferenceCode, setSelectedReferenceCode] = useState(null);
  const [modifyImportModal, setModifyImportModal] = useState(false);

  const [selectedRows, setSelectedRows] = useState(
    categoryMasterPart?.part_list?.map(() => ({
      vehicle_year: null,
      vehicle_make: null,
      vehicle_model: null,
      vehicle_variant: null,
    })) || []
  );

  const [selectedRowIndices, setSelectedRowIndices] = useState([]);

  const handleCheckboxChangeMaster = (rowIndex) => {
    if (selectedRowIndices.includes(rowIndex)) {
      setSelectedRowIndices(
        selectedRowIndices.filter((index) => index !== rowIndex)
      );
    } else {
      setSelectedRowIndices([...selectedRowIndices, rowIndex]);
    }
  };

  // const sendSelectedData = (e) => {
  //   e.preventDefault();

  //   // if (!validateSelectedRows()) {
  //   //   // Handle validation error (e.g., display an error message)
  //   //   alert(
  //   //     "Please select Year, Make, Model, and Variant for all selected parts."
  //   //   );
  //   //   return;
  //   // }

  //   actionPartInventoryTempStore(data)
  //     .then((response) => {
  //       setTempData(response.data.data);
  //       setModifyMasterPart(false);

  //       setCategoryPartsData({
  //         catalog_id: "",
  //         vehicle_make: "",
  //         vehicle_year: "",
  //         vehicle_model: "",
  //         part_name: "",
  //         vehicle_variant: "",
  //       });

  //       setVehicleModelId("");
  //       setVehicleModelAuto("");
  //       setCatalogMake("");
  //       setVehicleMakeId("");
  //       setCatalogPart("");
  //       setVehiclePartId("");
  //     })
  //     .catch((err) => {
  //       toast.error(Utils.getErrorMessage(err));
  //     });
  // };

  const handleImportModal = () => setModifyImportModal(true);
  const handleImportCloseModal = () => setModifyImportModal(false);

  useEffect(() => {
    if (
      categoryMasterPart?.part_list &&
      selectedRows.length !== categoryMasterPart?.part_list.length
    ) {
      setSelectedRows(
        categoryMasterPart?.part_list?.map(() => ({
          vehicle_year: null,
          vehicle_make: null,
          vehicle_model: null,
          vehicle_variant: null,
        }))
      );
    }
  }, [categoryMasterPart?.part_list]);

  const handleSelectChangeMaster = (index, field, value) => {
    if (selectedRows[index]) {
      const updatedRows = [...selectedRows];
      updatedRows[index][field] = value;
      setSelectedRows(updatedRows);
    }
  };

  const handleMasterPartClose = () => {
    setModifyMasterPart(false);
  };

  const handleRadioChange = (referenceCode) => {
    setSelectedReferenceCode(referenceCode);
  };

  const handleCheckboxChange = () => {
    setIsChecked(isChecked === 0 ? 1 : 0);
  };
  const handleEtaiDetails = (event, etaiPartIds, index) => {
    event.preventDefault();
    setPartETAIRerList(etaiPartIds);
    setPartEtaiModify(true);
  };

  const handleEtaiPriceDetails = (event, etaiPartIds, index) => {
    event.preventDefault();
    setPartETAIPriceList(etaiPartIds);

    setPartEtaiPriceModify(true);
  };

  const handlePartEtaiClose = () => {
    setPartEtaiModify(false);
  };

  const handlePartEtaiPriceClose = () => {
    setPartEtaiPriceModify(false);
  };

  useEffect(() => {
    if (partETAIRerList) {
      const data = {
        etai_part_id: partETAIRerList,
      };

      partInvenoryPartETAI(data)
        .then((response) => {
          setPartETAIRefDetails(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [partETAIRerList]);

  useEffect(() => {
    if (partETAIPriceList) {
      const data = {
        etai_part_id: partETAIPriceList,
      };

      partInvenoryPartETAIPrice(data)
        .then((response) => {
          setPartETAIPriceDetail(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [partETAIPriceList]);

  const handleChangePart = (e) => {
    const userInput = e.target.value;

    let data = {
      limit: params.limit,
      page: params.page,
      search: userInput,
      is_reload: true,
    };
    getCatalogList(data)
      .then((response) => {
        setFilteredSuggestionsPart(response.data.data);
      })
      .catch((err) => {});

    setCatalogPart(userInput);
    setActiveSuggestionIndexPart(0);
    setShowSuggestionsPart(true);
  };

  useEffect(function () {
    var obj = {
      search: "",
      limit: tableLengthList[0],
      page: 1,
    };

    getDismentalList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }
        setDismetalList(list);
      })
      .catch((err) => {});
  }, []);

  const handleInputSelectedChangeDismetalList = (inputSelctedDismetalValue) => {
    setInputSelctedDismetalValue(inputSelctedDismetalValue);

    if (inputSelctedDismetalValue.length > 0) {
      const obj = {
        search: inputSelctedDismetalValue,
        limit: tableLengthList[0],
        page: 1,
      };

      getDismentalList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].name,
            });
          }
          setDismetalList(list);
        })
        .catch((err) => {});
    }
  };

  useEffect(function () {
    var obj = {
      search: "",
      limit: tableLengthList[0],
      page: 1,
    };

    getCatalogList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].namePartPT,
            is_master_part: response.data[i].is_master_part,
            part_list: response.data[i].part_list,
          });
        }
        setCategoryPartList(list);
      })
      .catch((err) => {});
  }, []);

  const handleInputSelectedChangeCategoryList = (inputSelctedValue) => {
    setInputSelctedValue(inputSelctedValue);

    if (inputSelctedValue.length > 0) {
      const obj = {
        search: inputSelctedValue,
        limit: tableLengthList[0],
        page: 1,
      };

      getCatalogList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].namePartPT,
              is_master_part: response.data[i].is_master_part,
              part_list: response.data[i].part_list,
            });
          }
          setCategoryPartList(list);
        })
        .catch((err) => {});
    }
  };

  const handleSelectWithoutChangeCategoryList = (option) => {
    setCategoryPartsData((prevData) => ({
      ...prevData,
      catalog_id: option.value,
    }));

    setCategoryMasterPart(option);
  };

  const handleSelectWithoutChangeDismetalList = (option) => {
    setDismentalData((prevData) => ({
      ...prevData,
      dismantle_id: option.value,
    }));
  };

  useEffect(() => {
    const obj = {
      search: "",
      limit: tableLengthList[0],
      page: 1,
    };

    getvehicleYearList(obj)
      .then((response) => {
        const list = response.data.data.map((year) => ({
          value: year,
          label: year,
        }));
        setvehicleYearMasterPartList(list); // Update year list for categoryMasterPart
      })
      .catch((err) => {
        console.error("Failed to fetch vehicle year list:", err);
      });
  }, [categoryMasterPart]); // Runs when categoryMasterPart changes

  useEffect(function () {
    var obj = {
      search: "",
      limit: tableLengthList[0],
      page: 1,
    };

    getvehicleYearList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i],
            label: response.data[i],
          });
        }
        setvehicleYearPartList(list);
      })
      .catch((err) => {});
  }, []);

  const handleInputSelectedChangeYearList = (inputSelctedYearValue) => {
    setInputSelctedYearValue(inputSelctedYearValue);

    if (inputSelctedYearValue.length > 0) {
      const obj = {
        search: inputSelctedYearValue,
        limit: tableLengthList[0],
        page: 1,
      };

      getvehicleYearList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i],
              label: response.data[i],
            });
          }
          setvehicleYearPartList(list);
        })
        .catch((err) => {});
    }
  };

  const handleSelectWithoutChangeYearList = (option) => {
    setCategoryPartsData((prevData) => ({
      ...prevData,
      vehicle_year: option.value,
    }));
  };

  useEffect(
    function () {
      if (categoryPartsData?.vehicle_year) {
        var obj = {
          search: "",
          limit: tableLengthList[0],
          page: 1,
          year: categoryPartsData?.vehicle_year,
        };

        getvehicleMakeAllList(obj)
          .then((response) => {
            response = response.data;
            let list = [];
            for (let i = 0; i < response.data.length; i++) {
              list.push({
                value: response.data[i].id,
                label: response.data[i].description,
              });
            }
            setvehicleMakePartList(list);
          })
          .catch((err) => {});
      }

      if (selectedRows?.some((item) => item?.vehicle_year)) {
        var obj = {
          search: "",
          limit: tableLengthList[0],
          page: 1,
          year: selectedRows
            ?.map((item) => item?.vehicle_year)
            .filter((year) => year !== null)[0], // Filter out null years
        };

        getvehicleMakeAllList(obj)
          .then((response) => {
            response = response.data;
            let list = [];
            for (let i = 0; i < response.data.length; i++) {
              list.push({
                value: response.data[i].id,
                label: response.data[i].description,
              });
            }
            setvehicleMakePartList(list);
          })
          .catch((err) => {});
      }
    },

    [categoryPartsData?.vehicle_year, selectedRows]
  );

  const handleInputSelectedChangeMakeList = (inputSelctedMakeValue) => {
    setInputSelctedMakeValue(inputSelctedMakeValue);

    if (inputSelctedMakeValue.length > 0) {
      const obj = {
        search: inputSelctedMakeValue,
        limit: tableLengthList[0],
        page: 1,
        year: categoryPartsData?.vehicle_year,
      };

      getvehicleMakeAllList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].description,
            });
          }
          setvehicleMakePartList(list);
        })
        .catch((err) => {});
    }

    if (selectedRows?.some((item) => item?.vehicle_year)) {
      var obj = {
        search: inputSelctedMakeValue,
        limit: tableLengthList[0],
        page: 1,
        year: selectedRows
          ?.map((item) => item?.vehicle_year)
          .filter((year) => year !== null)[0], // Filter out null years
      };

      getvehicleMakeAllList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].description,
            });
          }
          setvehicleMakePartList(list);
        })
        .catch((err) => {});
    }
  };

  const handleSelectWithoutChangeMakeList = (option) => {
    setCategoryPartsData((prevData) => ({
      ...prevData,
      vehicle_make: option.value,
    }));
  };

  useEffect(
    function () {
      if (categoryPartsData?.vehicle_make) {
        var obj = {
          search: "",
          limit: tableLengthList[0],
          page: 1,
          make_id: categoryPartsData?.vehicle_make,
        };

        getVehicleAllModalSerachList(obj)
          .then((response) => {
            response = response.data;
            let list = [];
            for (let i = 0; i < response.data.length; i++) {
              list.push({
                value: response.data[i].id,
                label: response.data[i].description,
              });
            }
            setvehicleModalPartList(list);
          })
          .catch((err) => {});
      }

      if (selectedRows?.some((item) => item?.vehicle_make)) {
        var obj = {
          search: "",
          limit: tableLengthList[0],
          page: 1,
          make_id: selectedRows
            ?.map((item) => item?.vehicle_make)
            .filter((make) => make !== null)[0], // Filter out null years
        };

        getVehicleAllModalSerachList(obj)
          .then((response) => {
            response = response.data;
            let list = [];
            for (let i = 0; i < response.data.length; i++) {
              list.push({
                value: response.data[i].id,
                label: response.data[i].description,
              });
            }
            setvehicleModalPartList(list);
          })
          .catch((err) => {});
      }
    },
    [categoryPartsData?.vehicle_make, selectedRows]
  );

  const handleInputSelectedChangeModelList = (inputSelctedModalValue) => {
    setInputSelctedModalValue(inputSelctedModalValue);

    if (inputSelctedModalValue.length > 0) {
      const obj = {
        search: inputSelctedModalValue,
        limit: tableLengthList[0],
        page: 1,
        make_id: categoryPartsData?.vehicle_make,
      };

      getVehicleAllModalSerachList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].model_name,
            });
          }
          setvehicleModalPartList(list);
        })
        .catch((err) => {});
    }

    if (selectedRows?.some((item) => item?.vehicle_make)) {
      var obj = {
        search: inputSelctedModalValue,
        limit: tableLengthList[0],
        page: 1,
        make_id: selectedRows
          ?.map((item) => item?.vehicle_make)
          .filter((make) => make !== null)[0], // Filter out null years
      };

      getVehicleAllModalSerachList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].description,
            });
          }
          setvehicleModalPartList(list);
        })
        .catch((err) => {});
    }
  };

  const handleSelectWithoutChangeModelList = (option) => {
    setCategoryPartsData((prevData) => ({
      ...prevData,
      vehicle_model: option.value,
    }));
  };

  useEffect(
    function () {
      if (categoryPartsData?.vehicle_model) {
        var obj = {
          search: "",
          limit: tableLengthList[0],
          page: 1,
          model_id: categoryPartsData?.vehicle_model,
        };

        getVehicleSerachVariantList(obj)
          .then((response) => {
            response = response.data;
            let list = [];
            for (let i = 0; i < response.data.length; i++) {
              list.push({
                value: response.data[i].id,
                label: response.data[i].variants_name,
              });
            }
            setvehicleVariantPartList(list);
          })
          .catch((err) => {});
      }

      if (selectedRows?.some((item) => item?.vehicle_model)) {
        var obj = {
          search: "",
          limit: tableLengthList[0],
          page: 1,
          model_id: selectedRows
            ?.map((item) => item?.vehicle_model)
            .filter((modal) => modal !== null)[0], // Filter out null years
        };

        getVehicleSerachVariantList(obj)
          .then((response) => {
            response = response.data;
            let list = [];
            for (let i = 0; i < response.data.length; i++) {
              list.push({
                value: response.data[i].id,
                label: response.data[i].variants_name,
              });
            }
            setvehicleVariantPartList(list);
          })
          .catch((err) => {});
      }
    },
    [categoryPartsData?.vehicle_model, selectedRows]
  );

  const handleInputSelectedChangeVariantList = (inputSelctedVariantValue) => {
    setInputSelctedVariantValue(inputSelctedVariantValue);

    if (inputSelctedVariantValue.length > 0) {
      const obj = {
        serach: inputSelctedVariantValue,
        limit: tableLengthList[0],
        page: 1,
        model_id: categoryPartsData?.vehicle_model,
      };

      getVehicleSerachVariantList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].variants_name,
            });
          }
          setvehicleVariantPartList(list);
        })
        .catch((err) => {});
    }

    if (selectedRows?.some((item) => item?.vehicle_model)) {
      var obj = {
        search: inputSelctedVariantValue,
        limit: tableLengthList[0],
        page: 1,
        model_id: selectedRows
          ?.map((item) => item?.vehicle_model)
          .filter((modal) => modal !== null)[0], // Filter out null years
      };

      getVehicleSerachVariantList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].variants_name,
            });
          }
          setvehicleVariantPartList(list);
        })
        .catch((err) => {});
    }
  };

  const handleSelectWithoutChangeVariantList = (option) => {
    setCategoryPartsData((prevData) => ({
      ...prevData,
      vehicle_variant: option.value,
    }));
  };

  useEffect(function () {
    var obj = {
      search: "",
      limit: tableLengthList[0],
      page: 1,
    };

    getVehicleEngineSerachList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].motor_code,
            kw: response.data[i].kw,
            hp: response.data[i].hp,
          });
        }
        setvehicleEngineList(list);
      })
      .catch((err) => {});
  }, []);

  const handleInputSelectedChangeEngineList = (inputSelctedEngineValue) => {
    setInputSelctedEngineValue(inputSelctedEngineValue);

    if (inputSelctedEngineValue.length > 0) {
      const obj = {
        serach: inputSelctedEngineValue,
        limit: tableLengthList[0],
        page: 1,
      };

      getVehicleEngineSerachList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].motor_code,
              kw: response.data[i].kw,
              hp: response.data[i].hp,
            });
          }
          setvehicleEngineList(list);
        })
        .catch((err) => {});
    }
  };

  const handleSelectWithoutChangeEngineList = (option) => {
    setEngineData((prevData) => ({
      ...prevData,
      engine_code: option.value,
      kw: option.kw,
      hp: option.hp,
    }));
  };

  const handleChangeModel = (e) => {
    const userInput = e.target.value;

    let data = {
      limit: params.limit,
      page: params.page,
      search: userInput,
      is_reload: true,
      make_id: vehicleMakeId ? vehicleMakeId : 0,
    };
    if (userInput.length > 0 && vehicleMakeId) {
      getVehicleAllModalSerachList(data)
        .then((response) => {
          setFilteredSuggestionsModel(response.data.data);
        })
        .catch((err) => {});
    }
    if (currentStep === 2) {
      let data = {
        limit: params.limit,
        page: params.page,
        search: userInput,
        is_reload: true,
        make_id: 0,
      };
      getVehicleAllModalSerachList(data)
        .then((response) => {
          setFilteredSuggestionsModel(response.data.data);
        })
        .catch((err) => {});
    }

    setVehicleModelAuto(userInput);
    setActiveSuggestionIndexModel(0);
    setShowSuggestionsModel(true);
  };

  const handleClickModel = (suggestion) => {
    setVehicleModelId(suggestion?.id);
    setVehicleModelAuto(suggestion.model_name);
    setFilteredSuggestionsModel([]);
    setShowSuggestionsModel(false);

    if (currentStep === 2) {
      const isAlreadySelected = selectedModels.some(
        (model) => model.id === suggestion.id
      );

      if (isAlreadySelected) {
        setSelectedModels(
          selectedModels.filter((model) => model.id !== suggestion.id)
        );
      } else {
        setSelectedModels([...selectedModels, suggestion]);
      }

      setVehicleModelAuto("");
      setFilteredSuggestionsModel([]);
      setShowSuggestionsModel(false);
    }
  };

  const handleKeyDownModel = (e) => {
    if (e.key === "ArrowDown") {
      if (
        filteredSuggestionsModel &&
        activeSuggestionIndexModel < filteredSuggestionsModel?.length - 1
      ) {
        setActiveSuggestionIndexModel(activeSuggestionIndexModel + 1);
      }
    } else if (e.key === "ArrowUp") {
      if (activeSuggestionIndexModel > 0) {
        setActiveSuggestionIndexModel(activeSuggestionIndexModel - 1);
      }
    } else if (e.key === "Enter") {
      setVehicleModelAuto(filteredSuggestionsModel[activeSuggestionIndexModel]);
      setShowSuggestionsModel(false);
      setFilteredSuggestionsModel([]);
    }
  };

  const handleSkip = () => {
    setCurrentPartIndex((prevIndex) => prevIndex + 1);
    setShowSkip(false);
  };

  const handleOpenSkip = () => {
    setShowSkip(true);
  };

  const handleCloseSkip = () => {
    setShowSkip(false);
  };

  const handleFinish = (event) => {
    event.preventDefault();

    const formData = new FormData();
    setBtnSaved(true);
    formData.append("part_id", currentPart?.id);
    images.forEach((file) => {
      formData.append("files", file);
    });

    actionPartInventoryImage(formData)
      .then((response) => {
        toast.success(response.data.message);
        setImage([]);
        SetPreviewUrl([]);
        navigate("/parts-inventory");
      })
      .catch((err) => {
        setBtnSaved(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const currentPart = detailsForImages[currentPartIndex];
  const fileInputRef = useRef(null);

  const hasNextPart = currentPartIndex < detailsForImages.length;

  const handleSubmits = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("part_id", currentPart?.id);
    images.forEach((file) => {
      formData.append("files", file);
    });

    actionPartInventoryImage(formData)
      .then((response) => {
        toast.success(response.data.message);
        setImage([]);
        SetPreviewUrl([]);
        setCurrentPartIndex((prevIndex) => prevIndex + 1);
        if (images.length === 1) {
          fileInputRef.current.value = "";
        }
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleAboutFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);

    if (selectedFiles.length > 0) {
      setImage((prevImages) => [...prevImages, ...selectedFiles]);

      const newPreviewUrls = selectedFiles.map((file) =>
        URL.createObjectURL(file)
      );
      SetPreviewUrl((prevUrls) => [...prevUrls, ...newPreviewUrls]);
    }
  };

  // const handleAboutFileChange = (event) => {
  //   const selectedFiles = Array.from(event.target.files);

  //   if (selectedFiles.length > 0) {
  //     // Append new files to the current state
  //     setImage((prevImages) => [
  //       ...prevImages,
  //       ...selectedFiles.map((file, index) => ({
  //         id: `${Date.now()}-${index}`, // Unique ID for each file
  //         file,
  //         url: URL.createObjectURL(file),
  //       })),
  //     ]);

  //     // Generate preview URLs
  //     SetPreviewUrl((prevUrls) => [
  //       ...prevUrls,
  //       ...selectedFiles.map((file) => URL.createObjectURL(file)),
  //     ]);
  //   }
  // };
  const isLastPart = currentPartIndex === detailsForImages?.length - 1;

  const handleRemoveFile = (indexToRemove) => {
    setImage((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
    SetPreviewUrl((prevUrls) =>
      prevUrls.filter((_, index) => index !== indexToRemove)
    );

    if (images.length === 1) {
      fileInputRef.current.value = "";
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedFiles = Array.from(images);
    const [movedFile] = reorderedFiles.splice(result.source.index, 1);
    reorderedFiles.splice(result.destination.index, 0, movedFile);

    setImage(reorderedFiles); // Update state with new order
  };

  const handleSaveOrder = () => {
    console.log(
      "Updated order:",
      images.map((file) => file.id)
    );
    // handleImportCloseModal();
  };

  useEffect(() => {
    const obj = {
      search: "",
      limit: 10,
      page: 1,
      placeName: "",
    };

    getAllBrandSerachList(obj)
      .then((response) => {
        response = response.data;
        let list = [];

        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].descriptionPT,
          });
        }
        SetAllBrandList(list);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleShowAbout = () => setShowModalAbout(true);
  const handleCloseAbout = () => setShowModalAbout(false);

  useEffect(() => {
    const obj = {
      search: "",
      limit: 10,
      page: 1,
      placeName: "",
    };

    getAllViewShelfList(obj)
      .then((response) => {
        response = response.data;
        let list = [];

        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
            bar_code: response.data[i].bar_code,
            place: response.data[i].place,
            from_iels: response.data[i].from_iels,
            to_iels: response.data[i].to_iels,
          });
        }
        SetShelfList(list);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    const isDuplicatePart = (part, newPart) => {
      return (
        part.vehicle_make === newPart.vehicle_make &&
        part.vehicle_model === newPart.vehicle_model &&
        part.vehicle_year === newPart.vehicle_year
      );
    };

    if (vehicleAddDetails?.id) {
      const newPart = {
        catalog_id: vehicleAddDetails.catalog_id,
        namePartPT: vehicleAddDetails.namePartPT,
        part_name: vehicleAddDetails?.part_name,
        quantity: vehicleAddDetails?.quantity,
        part_price: vehicleAddDetails?.part_price,
        inventory_status: vehicleAddDetails?.inventory_status,
        parts_associate: vehicleAddDetails?.parts_associate,
        part_description: vehicleAddDetails?.part_description,
        vehicle_make: vehicleAddDetails.vehicle_make,
        vehicle_model: vehicleAddDetails.vehicle_model,
        vehicle_model_id: vehicleAddDetails.vehicle_parts_id,
        vehicle_year: vehicleAddDetails.vehicle_year,
        print_label: vehicleAddDetails?.print_label,
        state_id: vehicleAddDetails?.state_id,
        condition_id: vehicleAddDetails?.condition_id,
        part_original_price: vehicleAddDetails?.part_original_price,
        part_cost_price: vehicleAddDetails?.part_cost_price,
        is_custom_size: vehicleAddDetails?.is_custom_size,
        parts_weight: vehicleAddDetails?.parts_weight,
        parts_width: vehicleAddDetails?.parts_width,
        parts_height: vehicleAddDetails?.parts_height,
        parts_length: vehicleAddDetails?.parts_length,
        brand_id: vehicleAddDetails?.brand_id,
        vat_included: vehicleAddDetails?.vat_included,
      };

      setPartsData((prevData) => {
        if (!prevData.some((part) => isDuplicatePart(part, newPart))) {
          return [...prevData, newPart];
        }
        return prevData;
      });
    } else if (selectedItems && selectedItems.length > 0) {
      const selectedParts = selectedItems.map((item) => ({
        tempId: item.id,
        temp_id: item.temp_id,
        id: item.catalog_id,
        etai_part_id: item?.etai_part_id,
        namePartPT: item.namePartPT,
        part_name: item.namePartPT,
        quantity: "1",
        part_price: "0",

        state_id: settingDetails?.state_id ? settingDetails?.state_id : "",
        condition_id: settingDetails?.condition_id
          ? settingDetails?.condition_id
          : "",
        part_original_price: "0",
        part_cost_price: "0",
        is_custom_size: "0",
        parts_weight: "0",
        parts_width: "0",
        parts_height: "0",
        parts_length: "0",
        inventory_status: "",
        print_label: settingDetails?.print_label
          ? settingDetails?.print_label
          : "0",
        vat_included: settingDetails?.vat_included
          ? settingDetails?.vat_included
          : "0",
        sponsor_price: "",
        part_description: "",
        parts_associate: [],

        images: [],
        vehicle_make: item.vehicle_make,
        vehicle_model: item.vehicle_model,
        vehicle_model_id: item.vehicle_model_id,
        vehicle_year: item.vehicle_year,
        part_qty_location_assign: [],
        brand_id: "",
        reference_code: "",
        type_id: null,
        is_main: 1,
        condition_id_ref: "",
        addedData: [],
        vehicle_model_name: item?.vehicle_model_name,
        vehicle_make_name: item?.vehicle_make_name,
        is_show_engine_record: item?.is_show_engine_record,

        engine_code: "",
        variants_id: item?.variants_id,
        cv: "",
        cc: "",
        kw: "",
        reg_number_dismantler: settingDetails?.reg_number_dismantler
          ? settingDetails?.reg_number_dismantler
          : "0",
      }));

      setPartsData(selectedParts);
    }
  }, [vehicleAddDetails, selectedItems]);

  const handleInputChangePartListRef = (e, pIndex) => {
    const { name, value } = e.target;
    setPartsData((prev) =>
      prev.map((part, index) =>
        index === pIndex ? { ...part, [name]: value } : part
      )
    );
  };

  const handleCheckboxChangeRef = (pIndex) => {
    setPartsData((prev) =>
      prev.map((part, index) =>
        index === pIndex
          ? { ...part, is_main: part.is_main === 0 ? 1 : 0 }
          : part
      )
    );
  };

  const handleAddPartRef = (pIndex) => {
    setPartsData((prev) =>
      prev?.map((part, index) => {
        if (index === pIndex) {
          const referenceCode = selectedReferenceCode || part?.reference_code;

          if (!referenceCode) {
            toast.error("Reference Code is required!");
            return part;
          }

          const isDuplicate = part?.addedData?.some(
            (data) => data?.reference_code === referenceCode
          );
          if (isDuplicate) {
            toast.error("Reference Code already added!");
            return part;
          }

          const selectedCondition = conditionList.find(
            (cond) => cond?.value === part?.condition_id_ref
          ) || { label: "Not Selected", value: "null" };

          const selectedType = conditionList.find(
            (cond) => cond?.value === part?.type_id
          ) || {
            label: "Not Selected",
            value: "null",
          };

          return {
            ...part,
            addedData: [
              ...(part?.addedData || []), // Ensure `addedData` is always an array
              {
                reference_code: referenceCode,
                type_id: part?.type_id,
                is_main: part?.is_main,
                condition_id_ref: part?.condition_id_ref,
                selectedCondition,
                selectedType,
              },
            ],
            reference_code: "",
            type_id: null,
            condition_id_ref: null,
            is_main: 0,
          };
        }
        return part;
      })
    );
    setSelectedReferenceCode(null);
  };

  const handleUpdatePartRef = (pIndex, rowIndex, key, value) => {
    setPartsData((prev) =>
      prev.map((part, index) => {
        if (index !== pIndex) return part;

        const updatedAddedData = part?.addedData?.map((data, idx) => {
          if (key === "is_main") {
            return { ...data, is_main: idx === rowIndex ? value : 0 };
          }

          if (idx !== rowIndex) return data;
          return { ...data, [key]: value };
        });

        return {
          ...part,
          addedData: updatedAddedData || [], // Ensure `addedData` is always valid
        };
      })
    );
  };

  const handleDeletePartRef = (pIndex, rowIndex) => {
    setPartsData((prev) =>
      prev.map((part, index) =>
        index === pIndex
          ? {
              ...part,
              addedData:
                part.addedData?.filter((_, idx) => idx !== rowIndex) || [],
            }
          : part
      )
    );
  };

  const handleEditPartRef = (pIndex, rowIndex) => {
    setPartsData((prev) =>
      prev.map((part, index) =>
        index === pIndex
          ? {
              ...part,
              addedData:
                part.addedData?.map((data, idx) =>
                  idx === rowIndex
                    ? { ...data, isEditing: !data.isEditing }
                    : data
                ) || [],
            }
          : part
      )
    );

    toast.success("Successfully updated!");
  };

  const handleConditionChangeRef = (pIndex, selectedOption) => {
    setPartsData((prev) =>
      prev.map((part, index) =>
        index === pIndex
          ? { ...part, type_id: selectedOption?.value || null }
          : part
      )
    );
  };

  const handleConditionSChangeRefs = (pIndex, selectedOption) => {
    setPartsData((prev) =>
      prev.map((part, index) =>
        index === pIndex
          ? { ...part, condition_id_ref: selectedOption?.value || null }
          : part
      )
    );
  };

  const handleInputChangePartList = (e, id) => {
    const { name, value, type, checked } = e.target;
    setPartsData((prevData) =>
      prevData.map((part) =>
        part.tempId === id
          ? { ...part, [name]: type === "checkbox" ? (checked ? 1 : 0) : value }
          : part
      )
    );
  };
  const handleAddClick = (e) => {
    e.preventDefault();

    if (categoryMasterPart?.is_master_part === "1") {
      setModifyMasterPart(true);
      return;
    }
    if (modifyMasterPart === true) {
      submitData();
    }
    if (categoryMasterPart?.is_master_part === "0") {
      const data = {
        temp_id: tempData?.temp_id || dismantleTempData?.temp_id || "",
        vehicle_id: 0,
        part_list: [
          {
            vehicle_make: categoryPartsData?.vehicle_make || "",
            vehicle_year: categoryPartsData?.vehicle_year || "",
            vehicle_model: categoryPartsData?.vehicle_model || "",
            catalog_id: categoryPartsData?.catalog_id || "",
            vehicle_variant: categoryPartsData?.vehicle_variant || "",
            master_part_id: 0,
          },
        ],
      };

      actionPartInventoryTempStore(data)
        .then((response) => {
          setTempData(response.data.data);
          toast.success("Data saved successfully!");
          resetFormState();
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  };

  const submitData = () => {
    const selectedData = selectedRowIndices?.map((index) => ({
      catalog_id: categoryMasterPart?.part_list[index]?.id || "",
      vehicle_year: selectedRows[index]?.vehicle_year || "",
      vehicle_make: selectedRows[index]?.vehicle_make || "",
      vehicle_model: selectedRows[index]?.vehicle_model || "",
      vehicle_variant: selectedRows[index]?.vehicle_variant || "",
      master_part_id: categoryMasterPart?.value || 0,
    }));

    const data = {
      temp_id: tempData?.temp_id || dismantleTempData?.temp_id || "",
      vehicle_id: 0,
      part_list: [
        ...(selectedData?.length > 0 ? selectedData : []),
        ...(categoryMasterPart?.is_master_part === "1"
          ? [
              {
                vehicle_make: categoryPartsData?.vehicle_make || "",
                vehicle_year: categoryPartsData?.vehicle_year || "",
                vehicle_model: categoryPartsData?.vehicle_model || "",
                catalog_id: categoryPartsData?.catalog_id || "",
                vehicle_variant: categoryPartsData?.vehicle_variant || "",
                master_part_id: categoryMasterPart?.is_master_part,
              },
            ]
          : []),
      ],
    };

    actionPartInventoryTempStore(data)
      .then((response) => {
        setTempData(response.data.data);
        toast.success("Data saved successfully!");
        setModifyMasterPart(false);
        resetFormState();
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleModalSubmit = (e) => {
    e.preventDefault();
    submitData();
  };

  const resetFormState = () => {
    setCategoryPartsData({
      catalog_id: "",
      vehicle_make: "",
      vehicle_year: "",
      vehicle_model: "",
      part_name: "",
      vehicle_variant: "",
    });

    setVehicleModelId("");
    setVehicleModelAuto("");
    setCatalogMake("");
    setVehicleMakeId("");
    setCatalogPart("");
    setVehiclePartId("");
  };

  const handleTempDismetalAddClick = (e) => {
    e.preventDefault();

    let data = {
      dismantle_id: dismentalData?.dismantle_id,
      temp_id: dismantleTempData?.temp_id || tempData?.temp_id || "", // Use the first available temp_id or default to ""
      vehicle_id: 0,
    };

    actionTempStoreDismental(data)
      .then((response) => {
        // toast.success(response.data.message);
        setDismantleTempData(response.data.data);

        setDismentalData({
          dismantle_id: "",
        });

        setInputSelctedDismetalValue(""); // Clear input value if needed
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleDeleteClick = (item) => {
    setBtnClosed(true);
    let data = {
      id: item.tempId ? item.tempId : item?.id,
    };

    setTempData(item);

    DestoryPartInventoryTemp(data)
      .then((response) => {
        toast.success(response.data.message);

        const fetchData = async () => {
          try {
            const obj = {
              search: searchQueryTemp,
              limit: tableLengthList[0],
              page: 1,
              temp_id: item?.temp_id,
            };
            const response = await getPartInventoryTempList(obj);
            setSelectedItems(response.data.data);
          } catch (err) {
            console.error("Error fetching part inventory:", err);
          }
        };

        fetchData();
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      })
      .finally(() => {
        // Reset button state to "close"
        setBtnClosed(false);
      });
  };

  const handleCheckboxPrintChanges = (pIndex, event) => {
    const updatedParts = [...partsData]; // Create a copy of the parts data
    const partToUpdate = updatedParts[pIndex];

    partToUpdate.print_label = event.target.checked ? 1 : 0; // Update the print_label to 1 if checked, or 0 if unchecked

    setPartsData(updatedParts); // Update the state with the new list
  };

  const handleCheckboxVatChanges = (pIndex, event) => {
    const updatedParts = [...partsData]; // Create a copy of the parts data
    const partToUpdate = updatedParts[pIndex];

    partToUpdate.vat_included = event.target.checked ? 1 : 0; // Update the print_label to 1 if checked, or 0 if unchecked

    setPartsData(updatedParts); // Update the state with the new list
  };

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const companyResponse = await getSettingsCompanyDetails();

        setSettingDetails(companyResponse.data.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchSettings();
  }, []);

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "state",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setStateList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "reference",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setReferenceList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "part_inventory",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setInventoryList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "condition",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setConditionList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (selectedItems?.length === 0) {
      setCurrentStep(1);
    }
  }, [selectedItems]);

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = "";
    } else if (type === "pagination") {
      paramsObj.page = e;
    }

    setParams(paramsObj);
  };

  useEffect(function () {
    var obj = {
      search: "",
      limit: tableLengthList[0],
      page: 1,
    };
    getVehicleList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: `${response.data[i].vehicle_make}/ ${response.data[i].vehicle_model} /${response.data[i].vehicle_year}`,
            vehicle_year: response.data[i].vehicle_year,
            vehicle_make: response.data[i].vehicle_make,
            vehicle_model: response.data[i].vehicle_model,
          });
        }
        setVehicleModelList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    var obj = {
      text: "",
      limit: tableLengthList[0],
      page: 1,
    };

    getVehicleModelAllList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].vehicle_model,
            vehicle_make: response.data[i].vehicle_make,
            vehicle_year: response.data[i].vehicle_end_year,
            vehicle_model: response.data[i].vehicle_model,
          });
        }
        setVehicleModelAllList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    const obj = {
      text: "",
      limit: tableLengthList[0],
      page: 1,
    };

    getCatalogList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        const LangData = localStorage.getItem("lng") || "en";

        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label:
              LangData === "pt"
                ? response.data[i].namePartPT
                : LangData === "fr"
                ? response.data[i].namePartFR
                : LangData === "es"
                ? response.data[i].namePartES
                : LangData === "it"
                ? response.data[i].namePartIT
                : LangData === "de"
                ? response.data[i].namePartDE
                : response.data[i].namePartEN,

            is_master_part: response.data[i].is_master_part,
            part_list: response.data[i].part_list,
          });
        }
        setCategoryPartList(list);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleConditionChange = (index, selectedOption) => {
    const updatedParts = [...partsData];

    updatedParts[index] = {
      ...updatedParts[index],
      condition_id: selectedOption.value,
    };

    setPartsData(updatedParts);
  };

  const handleStateListChange = (index, selectedOption) => {
    const updatedParts = [...partsData];

    updatedParts[index] = {
      ...updatedParts[index],
      state_id: selectedOption.value,
    };

    setPartsData(updatedParts);
  };

  const handleInventoryIdChange = (index, selectedOption) => {
    const updatedParts = [...partsData];

    updatedParts[index] = {
      ...updatedParts[index],
      inventory_status: selectedOption.value,
    };

    setPartsData(updatedParts);
  };

  const handleBrandIdChange = (index, selectedOption) => {
    const updatedParts = [...partsData];

    updatedParts[index] = {
      ...updatedParts[index],
      brand_id: selectedOption.value,
    };

    setPartsData(updatedParts);
  };

  useEffect(
    function () {
      var obj = {
        search: searchQuery,
        limit: tableLengthList[0],
        page: 1,
      };
      getCatalogList(obj)
        .then((response) => {
          response = response.data;
          setCatalogList(response);
        })
        .catch((err) => {});
    },
    [searchQuery]
  );

  useEffect(() => {
    if (tempData?.temp_id || dismantleTempData?.temp_id) {
      const fetchData = async () => {
        try {
          const tempIdToSend = tempData?.temp_id || dismantleTempData?.temp_id;

          const obj = {
            search: searchQueryTemp,
            limit: tableLengthList[0],
            page: 1,
            temp_id: tempIdToSend,
          };

          const response = await getPartInventoryTempList(obj);
          setSelectedItems(response.data.data);
          setTotalRecords(response.data.total);
        } catch (err) {
          console.error("Error fetching part inventory:", err);
        }
      };

      fetchData();
    }
  }, [searchQueryTemp, tempData, dismantleTempData]);

  const createPayload = () => {
    return partsData.map((part) => ({
      vehicle_id: vehiclePartsData?.vehicle_model_id || 0,
      part_catelog: part.id || "",
      part_name: part?.part_name,
      quantity: part?.quantity,
      part_price: part?.part_price,
      condition_id: part?.condition_id,
      part_original_price: part?.part_original_price,
      state_id: part?.state_id,
      is_custom_size: part?.is_custom_size,
      parts_weight: part?.parts_weight,
      parts_width: part?.parts_width,
      parts_height: part?.parts_height,
      parts_length: part?.parts_length,
      print_label: part?.print_label,
      vat_included: part?.vat_included,
      inventory_status: part.inventory_status ? part.inventory_status : 1,
      vehicle_make: part?.vehicle_make,
      vehicle_year: part?.vehicle_year,
      vehicle_model: part?.vehicle_model,
      sponsor_price: part?.sponsor_price,
      part_description: part?.part_description,
      brand_id: part?.brand_id,
      part_cost_price: part?.part_cost_price,
      parts_associate: selectedModels?.map((model) => ({
        vehicle_make: model.make_id,
        vehicle_year: model.initial_year,
        vehicle_model: model.id,
      })),

      parts_reference: part?.addedData?.map((item) => ({
        reference_code: item?.reference_code,
        is_main: item?.is_main,
        type_id: item?.type_id,
        condition_id: part?.condition_id_ref,
      })),

      engine_code: part?.engine_code,
      variants_id: part?.variants_id,
      cv: part?.cv,
      cc: part?.cc,
      kw: part?.kw,
      reg_number_dismantler: part?.reg_number_dismantler,
    }));
  };

  const createPayloads = () => {
    const part = partsData[0];

    return {
      uuid: vehicleAddDetails?.uuid,
      id: vehicleAddDetails?.id,
      vehicle_id: part?.vehicle_model_id ? part?.vehicle_model_id : 0,
      part_catelog: part.id || vehicleAddDetails?.part_catelog,
      part_name: part?.part_name,
      quantity: part?.quantity,
      part_price: part?.part_price,
      shelf_id: part?.shelf_id,
      condition_id: part?.condition_id,
      part_original_price: part?.part_original_price,
      state_id: part?.state_id,
      is_custom_size: part?.is_custom_size,
      parts_weight: part?.parts_weight,
      parts_width: part?.parts_width,
      parts_height: part?.parts_height,
      parts_length: part?.parts_length,
      print_label: part?.print_label,
      vat_included: part?.vat_included,
      inventory_status: part.inventory_status,
      vehicle_make: part?.vehicle_make,
      vehicle_year: part?.vehicle_year,
      vehicle_model: part?.vehicle_model,
      part_description: part?.part_description,
      sponsor_price: part?.sponsor_price,
      brand_id: part?.brand_id,
      part_cost_price: part?.part_cost_price,
      parts_associate:
        part?.parts_associate?.map((model) => ({
          uuid: model?.uuid,
          vehicle_make: model.vehicle_make,
          vehicle_year: model.vehicle_year,
          vehicle_model: model.vehicle_model,
        })) || [],

      parts_reference: part?.addedData?.map((item) => ({
        reference_code: item?.reference_code,
        is_main: item?.is_main,
        type_id: item?.type_id,
        condition_id: part?.condition_id_ref,
      })),

      engine_code: part?.engine_code,
      variants_id: part?.variants_id,
      cv: part?.cv,
      cc: part?.cc,
      kw: part?.kw,
      reg_number_dismantler: part?.reg_number_dismantler,
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let error = false;
    let errorObj = { ...errorsObj };
    partsData.forEach((part) => {
      // if (!part.part_name) {
      //     errorObj.part_name = `required.`;
      //     error = true;
      // }

      if (!part.quantity) {
        errorObj.quantity = `Quantity is required.`;
        error = true;
      }

      // if (!part.part_price) {
      //   errorObj.part_price = `Part Price is required.`;
      //   error = true;
      // }
    });

    setErrors(errorObj);
    if (error) return;

    const payloads = createPayload();
    const payloadsUpdated = createPayloads();

    setbtnLoading(true);

    if (vehicleAddDetails?.id) {
      updatePartsInventory(payloadsUpdated)
        .then((response) => {
          toast.success(response.data.message);
          // navigate("/parts-inventory");
          setCurrentStep(2);
          setbtnLoading(false);
        })
        .catch((err) => {
          setbtnLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    } else {
      let data = {
        temp_id: tempData?.temp_id || dismantleTempData?.temp_id,
        is_print: 0,
        part_list: payloads,
      };
      createPartsInventory(data)
        .then((response) => {
          SetDetailsForImages(response.data.data);
          toast.success(response.data.message);
          // navigate("/parts-inventory");

          setCurrentStep(3);
          setbtnLoading(false);
        })
        .catch((err) => {
          setbtnLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    }
  };

  const handleSubmitAsPrintLabel = (e) => {
    e.preventDefault();

    let error = false;
    let errorObj = { ...errorsObj };
    partsData.forEach((part) => {
      if (!part.quantity) {
        errorObj.quantity = `Quantity is required.`;
        error = true;
      }
      //
      // if (!part.part_price) {
      //   errorObj.part_price = `Part Price is required.`;
      //   error = true;
      // }
    });

    setErrors(errorObj);
    if (error) return;

    const payloads = createPayload();
    const payloadsUpdated = createPayloads();

    setBtnLoader(true);
    if (vehicleAddDetails?.id) {
      updatePartsInventory(payloadsUpdated)
        .then((response) => {
          toast.success(response.data.message);
          // navigate("/parts-inventory");
          setCurrentStep(2);
          setBtnLoader(false);
        })
        .catch((err) => {
          setBtnLoader(false);
          toast.error(Utils.getErrorMessage(err));
        });
    } else {
      let data = {
        temp_id: tempData?.temp_id || dismantleTempData?.temp_id,
        is_print: 1,
        part_list: payloads,
      };
      createPartsInventory(data)
        .then((response) => {
          SetDetailsForImages(response.data.data);
          toast.success(response.data.message);
          // navigate("/parts-inventory");

          setCurrentStep(3);
          setBtnLoader(false);
        })
        .catch((err) => {
          setBtnLoader(false);
          toast.error(Utils.getErrorMessage(err));
        });
    }
  };

  const handleNextClick = () => {
    if (selectedItems?.length === 0) {
      toast.error("Please select a vehicle part.");
    } else {
      setCurrentStep(2);
    }
  };

  const handleRemovePart = (e, index, partIndex) => {
    e.preventDefault();
    let list = [...partsData];
    let partList = list[index]["parts_associate"];

    partList = partList.filter((_, idx) => idx !== partIndex);

    list[index]["parts_associate"] = partList;
    setPartsData(list);
    setSelectedVehicleModel((prev) => ({
      ...prev,
      [index]: null,
    }));
  };

  const handleSelectWithoutChange = (option) => {
    setSelectedVehicleModel(option);
  };
  const handleSelectWithoutChangeStatus = (option) => {
    setselectedInventoryStatus(option);
  };

  const handleBackButtonClick = () => {
    if (currentStep === 2) {
      setCurrentStep(1);
      // setbtnNext(true);
    } else {
      navigate("/parts-inventory");
    }
  };

  const toggleEnlarge = (index) => {
    if (enlargedIndexes.includes(index)) {
      setEnlargedIndexes(enlargedIndexes.filter((i) => i !== index));
    } else {
      setEnlargedIndexes([...enlargedIndexes, index]);
    }
  };

  const handleSelectChange = (index, selectedOption) => {
    setSelectedVehicleModel((prev) => ({
      ...prev,
      [index]: selectedOption,
    }));
  };

  const handleAddPart = (e, index) => {
    e.preventDefault();
    const selectedVehicleModels = selectedVehicleModel[index];

    if (selectedVehicleModels) {
      let list = [...partsData];
      let partList = list[index]["parts_associate"];

      let idx = partList.findIndex(
        (x) => x.value == selectedVehicleModels.value
      );
      if (idx === -1) {
        partList.push(selectedVehicleModels);
        list[index]["parts_associate"] = partList;
        setPartsData(list);
      } else {
        toast.error(selectedVehicleModels.label + ` is already added.`);
      }
    } else {
      console.warn("No vehicle model selected for index " + index);
    }
  };

  const handleCheckboxCustomChange = (index, e) => {
    const updatedParts = [...partsData];

    updatedParts[index] = {
      ...updatedParts[index],
      is_custom_size: e.target.checked ? "1" : "0", // Update the specific part at that index
    };

    setPartsData(updatedParts);
  };

  const handleAddPartLocation = (pIndex) => {
    const updatedPartList = [...partsData];
    const part = updatedPartList[pIndex];

    if (!part.shelf_id || !part.quantity || part.quantity <= 0) {
      alert("Please select a shelf and enter a valid quantity.");
      return;
    }

    const quantity = parseInt(part.quantity, 10);
    let totalAssignedQty = 0;

    if (part && part.part_qty_location_assign) {
      totalAssignedQty = part.part_qty_location_assign.reduce(
        (acc, item) => acc + parseInt(item.part_qty, 10),
        0
      );
    }

    if (totalAssignedQty >= quantity) {
      toast.success(
        `The total ${quantity} has already been assigned. You cannot add more parts.`
      );

      return;
    }

    const remainingQuantity = quantity - totalAssignedQty;

    const existingShelf = part.part_qty_location_assign.find(
      (item) => item.shelf_id === part.shelf_id
    );

    if (existingShelf) {
      existingShelf.part_qty = (
        parseInt(existingShelf.part_qty, 10) + remainingQuantity
      ).toString();
    } else {
      part.part_qty_location_assign.push({
        shelf_id: part.shelf_id,
        part_qty: part.part_qty,
      });
    }

    setPartsData(updatedPartList);

    const updatedQuantity = quantity - totalAssignedQty;
    if (updatedQuantity === 0) {
      alert("All parts have been assigned!");
    }
  };

  const handleSelfIdChanges = (pIndex, selectedOption) => {
    const updatedPartList = [...partsData];
    const part = updatedPartList[pIndex];

    updatedPartList[pIndex] = {
      ...part,
      shelf_id: selectedOption.value,
    };

    setPartsData(updatedPartList);
  };

  const setPartCostPrice = (tempId, price1) => {
    const discountPercentage = settingDetails?.discount_percentage / 100;

    const discountedPrice = price1 * (1 - discountPercentage);

    const updatedParts = [...partsData];
    const partToUpdate = updatedParts.find((part) => part.tempId === tempId);

    if (partToUpdate) {
      partToUpdate.part_original_price = discountedPrice;
      setPartsData(updatedParts);
    }
  };

  const handleInputChangePartLists = (e, tempId) => {
    const { name, value } = e.target;
    const updatedParts = [...partsData];
    const partToUpdate = updatedParts.find((part) => part.tempId === tempId);

    if (partToUpdate) {
      partToUpdate[name] = value;
      setPartsData(updatedParts);

      if (name === "part_price") {
        const originalPrice = parseFloat(value) || 0;
        setPartCostPrice(tempId, originalPrice);
      }
    }
  };

  const inventoryStatusData = inventoryList.find(
    (item) => item.value === selectedInventoryStatus
  )
    ? inventoryList.find((item) => item.value === selectedInventoryStatus)
    : inventoryList.find((item) => item.value === 1);

  return (
    <div>
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                {/* <button
                  type="button"
                  className="back-btn"
                  onClick={() => navigate("/parts-inventory")}
                  className="beck-btn"
                  onClick={handleBackButtonClick}
                >
                  <i className="bi bi-chevron-left"></i>
                </button> */}
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    {/* <a href="#">Home</a> */}
                  </li>
                  {/* <li className="breadcrumb-item active">Fuel</li> */}
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>

        <section className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary card-primary-Vehicle">
                {vehicleAddDetails?.id ? (
                  <>
                    <div className="card-body vehicleparts-table mt-3">
                      <div className="vehicleparts-table-inner">
                        <Form method={`post`} onSubmit={handleSubmit}>
                          <div className="row">
                            {partsData?.map((part, pIndex) => {
                              return (
                                <React.Fragment key={pIndex}>
                                  <div className="col-lg-6" key={part.id}>
                                    <Card className="text-center">
                                      <Card.Header className="cardHeader">
                                        <div className="d-flex">
                                          <div className="AddPh6">
                                            <h6>
                                              Make : {part.vehicle_make}/ Model
                                              : {part.vehicle_model}/ Year :{" "}
                                              {part.vehicle_year}
                                            </h6>
                                          </div>
                                          {/* <div className="">
                                            <button
                                              type=""
                                              className="btnClose"
                                              onClick={() =>
                                                handleDeleteClick(part)
                                              }
                                            >
                                              <i className="bi bi-x-lg"></i>
                                            </button>
                                          </div> */}
                                        </div>
                                      </Card.Header>
                                      <Card.Body className="cardBody">
                                        <div className="row">
                                          <div className="col-lg-4 addPartField">
                                            <label className="partsLabel">
                                              Quantity
                                            </label>
                                            <input
                                              className="form-control mt-2"
                                              type="number"
                                              name="quantity"
                                              value={part.quantity || "1"}
                                              onChange={(e) =>
                                                handleInputChangePartList(
                                                  e,
                                                  part.id
                                                )
                                              }
                                              placeholder="Quantity"
                                            />
                                          </div>
                                          <div className="col-lg-4 addPartField">
                                            <label className="partsLabel">
                                              Inventory Status
                                            </label>
                                            <Select
                                              className="my-Select"
                                              options={inventoryList}
                                              name="inventory_status"
                                              value={
                                                inventoryList.find(
                                                  (item) =>
                                                    item.value ===
                                                    partsData[pIndex]
                                                      ?.inventory_status
                                                ) ||
                                                inventoryList.find(
                                                  (item) => item.value === 1
                                                )
                                              }
                                              onChange={
                                                handleSelectWithoutChangeStatus
                                              }
                                              placeholder={
                                                "Select Inventory Status"
                                              }
                                              isSearchable={true}
                                              inputSelctedValue={
                                                inputSelctedValue
                                              }
                                            ></Select>
                                          </div>
                                          <div className="col-lg-4 addPartField">
                                            <label className="partsLabel">
                                              Part Price
                                            </label>
                                            <input
                                              className="form-control mt-2"
                                              type="number"
                                              name="part_price"
                                              value={part.part_price || "0"}
                                              onChange={(e) =>
                                                handleInputChangePartList(
                                                  e,
                                                  part.id
                                                )
                                              }
                                              placeholder="Part Price"
                                            />
                                          </div>
                                          <div className="col-lg-4 addPartField">
                                            <label className="partsLabel">
                                              Part Original Price
                                            </label>
                                            <input
                                              className="form-control mt-2"
                                              type="number"
                                              name="part_original_price"
                                              value={
                                                part.part_original_price || "0"
                                              }
                                              onChange={(e) =>
                                                handleInputChangePartList(
                                                  e,
                                                  part.id
                                                )
                                              }
                                              placeholder="0"
                                            />
                                          </div>
                                          <div className="col-lg-4 addPartField">
                                            <label className="partsLabel">
                                              Sponsor Price
                                            </label>
                                            <input
                                              className="form-control mt-2"
                                              type="number"
                                              name="sponsor_price"
                                              value={part.sponsor_price}
                                              onChange={(e) =>
                                                handleInputChangePartList(
                                                  e,
                                                  part.id
                                                )
                                              }
                                              placeholder="0"
                                            />
                                          </div>

                                          <div className="col-lg-12 addPartField">
                                            <label className="partsLabel">
                                              Part Description
                                            </label>
                                            <textarea
                                              className="form-control mt-2"
                                              type="text"
                                              name="part_description"
                                              value={part.part_description}
                                              onChange={(e) =>
                                                handleInputChangePartList(
                                                  e,
                                                  part.id
                                                )
                                              }
                                              placeholder="Part Description"
                                              rows={3}
                                            />
                                          </div>

                                          <div className="col-lg-12">
                                            <div className="input-div SelectV selectA">
                                              <Select
                                                className="my-Select"
                                                options={VehicleModelAllList}
                                                value={VehicleModelAllList.find(
                                                  (item) =>
                                                    item.value ==
                                                    selectedVehicleModel
                                                )}
                                                onChange={
                                                  handleSelectWithoutChange
                                                }
                                                placeholder={
                                                  "Select Vehicle Model"
                                                }
                                                isSearchable={true}
                                              />
                                            </div>

                                            <button
                                              style={{ marginTop: "8px" }}
                                              type="button"
                                              className="addBtn ms-2"
                                              onClick={(e) =>
                                                handleAddPart(e, pIndex)
                                              }
                                            >
                                              <i className="bi bi-plus-lg"></i>
                                            </button>
                                          </div>
                                          <div className="col-lg-6">
                                            <Table
                                              className="rounded-table striped bordered hover mt-2 table mb-2"
                                              striped
                                              bordered
                                              hover
                                            >
                                              <tbody>
                                                {vehicleAddDetails
                                                  ?.parts_associate.length >
                                                  0 &&
                                                  vehicleAddDetails?.parts_associate?.map(
                                                    (row, index) => (
                                                      <React.Fragment
                                                        key={index}
                                                      >
                                                        <tr>
                                                          <td className="lisatingTable mb-1">
                                                            {row.vehicle_model}
                                                          </td>
                                                        </tr>
                                                      </React.Fragment>
                                                    )
                                                  )}
                                              </tbody>
                                            </Table>
                                          </div>
                                          <div className="col-lg-6"></div>
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  </div>
                                </React.Fragment>
                              );
                            })}
                          </div>
                          <div className="row">
                            <div className="col-md-12 text-end">
                              <button type="submit" className="btn btn-primary">
                                Save
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>

                    {currentStep === 2 && (
                      <>
                        <div className="card-body vehicleparts-table mt-3">
                          <div className="vehicleparts-table-inner">
                            <div className="row">
                              <>
                                <Form onSubmit={handleSubmits}>
                                  <React.Fragment>
                                    <div className="col-lg-6">
                                      <Card className="text-center">
                                        <Card.Header className="cardHeader">
                                          <div className="d-flex">
                                            <div className="AddPh6">
                                              <h6>Part Information</h6>
                                            </div>
                                          </div>
                                        </Card.Header>
                                        <Card.Body className="cardBody">
                                          <div class="container text-center">
                                            <div class="row">
                                              <div class="col">Part Name</div>
                                              <div class="col">
                                                {currentPart?.part_name}
                                              </div>
                                            </div>
                                            <div class="row">
                                              <div class="col">
                                                Part Description
                                              </div>
                                              <div class="col">
                                                {currentPart?.part_description}
                                              </div>
                                            </div>

                                            <div class="row">
                                              <div class="col">
                                                Part Quantity
                                              </div>
                                              <div class="col">
                                                {currentPart?.quantity}
                                              </div>
                                            </div>
                                            <div class="row">
                                              <div class="col">Part Price</div>
                                              <div class="col">
                                                {currentPart?.part_price}
                                              </div>
                                            </div>

                                            <div class="row">
                                              <div class="col">
                                                Part Original Price
                                              </div>
                                              <div class="col">
                                                {
                                                  currentPart?.part_original_price
                                                }
                                              </div>
                                            </div>

                                            <div class="row">
                                              <div class="col">
                                                Sponsor Price{" "}
                                              </div>
                                              <div class="col">
                                                {currentPart?.sponsor_price}
                                              </div>
                                            </div>
                                          </div>
                                        </Card.Body>
                                      </Card>
                                    </div>

                                    <div className="col-lg-6">
                                      <Card className="text-center">
                                        <Card.Header className="cardHeader">
                                          <div className="d-flex">
                                            <div className="AddPh6">
                                              <h6>Upload Part Image</h6>
                                            </div>
                                          </div>
                                        </Card.Header>
                                        <Card.Body className="cardBody">
                                          <div className="row">
                                            <div className="col-lg-12 addPartField">
                                              <div className="col-lg-12 addPartField">
                                                <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                  Upload Imagess
                                                  {/* {currentPart.part_name} */}
                                                </label>
                                                <div className="input-div">
                                                  <div className="avatar-upload">
                                                    <div className="avatar-edit">
                                                      <div className="input-div">
                                                        <input
                                                          className="form-control"
                                                          type="file"
                                                          id="imageuploadAbout"
                                                          onChange={
                                                            handleAboutFileChange
                                                          }
                                                          name="file"
                                                          accept=".jpg,.jpeg,.png"
                                                          multiple
                                                        />
                                                        {previewUrl && (
                                                          <i
                                                            style={{
                                                              position:
                                                                "absolute",
                                                              top: "46px",
                                                              right: "12px",
                                                              fontSize: "20px",
                                                              color: "#81bb42",
                                                            }}
                                                            className="bi bi-eye"
                                                            onClick={
                                                              handleShowAbout
                                                            }
                                                          ></i>
                                                        )}
                                                      </div>
                                                      <label htmlFor="imageuploadAbout"></label>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </Card.Body>
                                      </Card>
                                    </div>
                                  </React.Fragment>
                                  <div className="d-flex flex-row mt-3 justify-content-end">
                                    {!isLastPart && (
                                      <Button
                                        className="me-2 mb-2 btn-primary btn"
                                        onClick={handleOpenSkip}
                                      >
                                        Skip & Next
                                      </Button>
                                    )}

                                    {!isLastPart && (
                                      <Button
                                        className="me-2 mb-2 btn-primary"
                                        type="submit"
                                      >
                                        Save & Next
                                      </Button>
                                    )}

                                    {isLastPart && (
                                      <Button
                                        className="me-2 mb-2"
                                        onClick={handleFinish}
                                      >
                                        Finish
                                      </Button>
                                    )}
                                  </div>
                                </Form>
                              </>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {currentStep === 1 && (
                      <>
                        <div className="row">
                          <div
                            className="col-lg-12"
                            style={{ marginBottom: "40px" }}
                          >
                            <div className="input-div SelectV">
                              <div className="row">
                                <div className="col-md-6">
                                  <Select
                                    className="my-Select"
                                    options={DismetalList}
                                    value={
                                      DismetalList.find(
                                        (item) =>
                                          item.value ===
                                          dismentalData.dismantle_id
                                      ) || null
                                    }
                                    onChange={
                                      handleSelectWithoutChangeDismetalList
                                    }
                                    placeholder={"Select Dismental"}
                                    isSearchable={true}
                                    onInputChange={
                                      handleInputSelectedChangeDismetalList
                                    }
                                    inputSelctedValue={
                                      inputSelctedDismetalValue
                                    }
                                  />{" "}
                                </div>

                                <div
                                  className="col-md-6"
                                  style={{ marginTop: "10px" }}
                                >
                                  <div
                                    className="addBtn"
                                    style={{ cursor: "pointer" }}
                                    onClick={handleTempDismetalAddClick}
                                  >
                                    <i className="bi bi-plus-lg"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="vehicleThBg table-responsive-sm">
                              <Table
                                className="rounded-table"
                                striped
                                bordered
                                hover
                              >
                                <thead>
                                  <tr>
                                    <th>
                                      <div>
                                        <div className="input-div SelectV">
                                          <div className="col-md-12">
                                            <Select
                                              className="my-Select"
                                              options={categoryPartList}
                                              value={
                                                categoryPartList.find(
                                                  (item) =>
                                                    item.value ===
                                                    categoryPartsData.catalog_id
                                                ) || null
                                              }
                                              onChange={
                                                handleSelectWithoutChangeCategoryList
                                              }
                                              placeholder={"Select Part"}
                                              isSearchable={true}
                                              onInputChange={
                                                handleInputSelectedChangeCategoryList
                                              }
                                              inputSelctedValue={
                                                inputSelctedValue
                                              }
                                            />{" "}
                                          </div>
                                        </div>
                                      </div>
                                    </th>

                                    <th>
                                      <div className="input-div SelectV">
                                        <div className="col-md-12">
                                          <Select
                                            className="my-Select"
                                            options={vehicleYearPartList}
                                            value={
                                              vehicleYearPartList.find(
                                                (item) =>
                                                  item.value ===
                                                  categoryPartsData?.vehicle_year
                                              ) || null
                                            }
                                            onChange={
                                              handleSelectWithoutChangeYearList
                                            }
                                            placeholder={"Select Year"}
                                            isSearchable={true}
                                            onInputChange={
                                              handleInputSelectedChangeYearList
                                            }
                                            inputSelctedValue={
                                              inputSelctedYearValue
                                            }
                                          />
                                        </div>
                                      </div>
                                    </th>

                                    <th>
                                      <div className="input-div SelectV">
                                        <div className="col-md-12">
                                          <Select
                                            className="my-Select"
                                            options={vehicleMakePartList}
                                            value={
                                              vehicleMakePartList.find(
                                                (item) =>
                                                  item.value ===
                                                  categoryPartsData?.vehicle_make
                                              ) || null
                                            }
                                            onChange={
                                              handleSelectWithoutChangeMakeList
                                            }
                                            placeholder={"Select Make"}
                                            isSearchable={true}
                                            onInputChange={
                                              handleInputSelectedChangeMakeList
                                            }
                                            inputSelctedValue={
                                              inputSelctedMakeValue
                                            }
                                          />
                                        </div>
                                      </div>
                                    </th>

                                    <th>
                                      <div className="input-div SelectV">
                                        <div className="col-md-12">
                                          <Select
                                            className="my-Select"
                                            options={vehicleModalPartList}
                                            value={
                                              vehicleModalPartList.find(
                                                (item) =>
                                                  item.value ===
                                                  categoryPartsData.vehicle_model
                                              ) || null
                                            }
                                            onChange={
                                              handleSelectWithoutChangeModelList
                                            }
                                            placeholder={"Select modal"}
                                            isSearchable={true}
                                            onInputChange={
                                              handleInputSelectedChangeModelList
                                            }
                                            inputSelctedValue={
                                              inputSelctedModalValue
                                            }
                                          />
                                        </div>
                                      </div>
                                    </th>

                                    <th>
                                      <div className="input-div SelectV">
                                        <div className="col-md-12">
                                          <Select
                                            className="my-Select"
                                            options={vehicleVariantPartList}
                                            value={
                                              vehicleVariantPartList.find(
                                                (item) =>
                                                  item.value ===
                                                  categoryPartsData?.vehicle_variant
                                              ) || null
                                            }
                                            onChange={
                                              handleSelectWithoutChangeVariantList
                                            }
                                            placeholder={"Select Variant"}
                                            isSearchable={true}
                                            onInputChange={
                                              handleInputSelectedChangeVariantList
                                            }
                                            inputSelctedValue={
                                              inputSelctedVariantValue
                                            }
                                          />
                                        </div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="addBtn"
                                        style={{ cursor: "pointer" }}
                                        onClick={handleAddClick}
                                      >
                                        <i className="bi bi-plus-lg"></i>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {selectedItems?.length > 0 ? (
                                    selectedItems?.map((row, index) => (
                                      <tr key={index}>
                                        <td>{row.namePartPT}</td>
                                        <td>{row.vehicle_year}</td>

                                        <td>{row.vehicle_make_name}</td>
                                        <td>{row.vehicle_model_name}</td>
                                        <td>{row.vehicle_variant_name}</td>

                                        <td>
                                          <Button
                                            type="button"
                                            className="btn-danger-delete"
                                            onClick={() =>
                                              handleDeleteClick(row)
                                            }
                                          >
                                            <i class="bi bi-trash3"></i>
                                          </Button>
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td
                                        colSpan="100%"
                                        className="text-center"
                                      >
                                        No results found
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </div>
                        <div className="back-next back-next-custom">
                          <div className="row">
                            <div className="col">
                              <button
                                type="button"
                                className="back-btn ms-0"
                                onClick={handleBackButtonClick}
                              >
                                <i class="bi bi-arrow-left"></i> Back
                              </button>
                            </div>
                            <div className="col nxt-btn mr-0">
                              <Button
                                className="next-button"
                                type="button"
                                onClick={handleNextClick}
                              >
                                Next <i class="bi bi-arrow-right"></i>
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="fuelCF">
                          <div className="card-footer clearfix card-footer-part ">
                            <div className="pagination pagination-sm m-0 float-right">
                              <Pagination
                                activePage={params.page}
                                itemsCountPerPage={parseInt(params.limit)}
                                totalItemsCount={parseInt(totalRecords)}
                                pageRangeDisplayed={5}
                                itemClass={`page-item`}
                                linkClass={`page-link`}
                                onChange={(e) => handleParams(e, "pagination")}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {currentStep === 2 && (
                      <>
                        <div className="card-body vehicleparts-table mt-3">
                          <div className="vehicleparts-table-inner">
                            <Form method={`post`} onSubmit={handleSubmit}>
                              <div
                                className="row"
                                style={{ justifyContent: "flex-start" }}
                              >
                                {partsData?.map((part, pIndex) => {
                                  return (
                                    <React.Fragment key={pIndex}>
                                      <div className="col-lg-12" key={pIndex}>
                                        <div className="text-center cstm-scroll cstm-scroll-2">
                                          <Card.Header className="cardHeader partInfoHeader">
                                            <div
                                              className="partInfo"
                                              data-bs-toggle="collapse"
                                              href={`#multiCollapseExample-${part?.tempId}`}
                                              role="button"
                                              aria-expanded="true"
                                              aria-controls={`multiCollapseExample-${part?.tempId}`}
                                            >
                                              <div className="AddPh6">
                                                <h6>
                                                  {part?.part_name} | {part?.id}{" "}
                                                  | {part?.vehicle_make_name} |{" "}
                                                  {part?.vehicle_model_name}
                                                </h6>
                                              </div>
                                              <div className="">
                                                {btnClosed ? (
                                                  <button
                                                    type="button"
                                                    className="btnClose"
                                                  >
                                                    <i className="bi bi-arrow-repeat"></i>
                                                  </button>
                                                ) : (
                                                  <button
                                                    type="button"
                                                    className="btnClose"
                                                    onClick={() =>
                                                      handleDeleteClick(part)
                                                    }
                                                  >
                                                    <i className="bi bi-x-lg"></i>
                                                  </button>
                                                )}
                                              </div>
                                            </div>
                                          </Card.Header>
                                          <div className="row">
                                            <div
                                              className="col "
                                              Reference
                                              Code
                                            >
                                              <Card.Body
                                                className="cardBody collapse multi-collapse show card"
                                                id={`multiCollapseExample-${part?.tempId}`}
                                                // style={{overflowY:"scroll"}}
                                              >
                                                <div className="row row-mobile-3">
                                                  <div className="col-lg-3 addPartField col-lg-3-mob">
                                                    <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                      Quantity
                                                    </label>
                                                    <input
                                                      className="form-control input-search"
                                                      type="number"
                                                      name="quantity"
                                                      value={part?.quantity}
                                                      onChange={(e) =>
                                                        handleInputChangePartList(
                                                          e,
                                                          part?.tempId
                                                        )
                                                      }
                                                      placeholder="0"
                                                    />

                                                    {errors.quantity && (
                                                      <span className="input-error">
                                                        {errors.quantity}
                                                      </span>
                                                    )}
                                                  </div>

                                                  <div className="col-lg-3 addPartField col-lg-3-mob">
                                                    <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                      Price 1
                                                    </label>
                                                    <input
                                                      className="form-control input-search"
                                                      type="number"
                                                      name="part_price"
                                                      value={part?.part_price}
                                                      onChange={(e) => {
                                                        handleInputChangePartLists(
                                                          e,
                                                          part?.tempId
                                                        );
                                                      }}
                                                      placeholder="0"
                                                    />

                                                    {errors.part_price && (
                                                      <span className="input-error">
                                                        {errors.part_price}
                                                      </span>
                                                    )}
                                                  </div>
                                                  <div className="col-lg-3 addPartField col-lg-3-mob">
                                                    <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                      Price 2 (
                                                      {
                                                        settingDetails?.discount_percentage
                                                      }
                                                      % discount)
                                                    </label>
                                                    <input
                                                      className="form-control input-search"
                                                      type="number"
                                                      name="part_original_price"
                                                      value={
                                                        part?.part_original_price
                                                      }
                                                      onChange={(e) =>
                                                        handleInputChangePartList(
                                                          e,
                                                          part?.tempId
                                                        )
                                                      }
                                                      placeholder="0"
                                                    />
                                                  </div>

                                                  <div className="col-lg-3 addPartField col-lg-3-mob">
                                                    <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                      Part Cost Price
                                                    </label>
                                                    <input
                                                      className="form-control input-search"
                                                      type="number"
                                                      name="part_cost_price"
                                                      value={
                                                        part?.part_cost_price
                                                      }
                                                      onChange={(e) => {
                                                        handleInputChangePartLists(
                                                          e,
                                                          part?.tempId
                                                        );
                                                      }}
                                                      placeholder="0"
                                                    />
                                                  </div>

                                                  <div className="col-lg-3 addPartField col-lg-3-mob">
                                                    <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                      Inventory Status
                                                    </label>
                                                    <Select
                                                      className="my-Select"
                                                      options={inventoryList}
                                                      name="inventory_status"
                                                      value={
                                                        inventoryList.find(
                                                          (item) =>
                                                            item.value ===
                                                            partsData[pIndex]
                                                              ?.inventory_status
                                                        ) ||
                                                        inventoryList.find(
                                                          (item) =>
                                                            item.value === 1
                                                        )
                                                      }
                                                      onChange={(
                                                        selectedOption
                                                      ) =>
                                                        handleInventoryIdChange(
                                                          pIndex,
                                                          selectedOption
                                                        )
                                                      }
                                                      placeholder={
                                                        "Select Inventory Status"
                                                      }
                                                      isSearchable={true}
                                                    ></Select>
                                                  </div>

                                                  <div className="col-lg-3 col-lg-3-mob">
                                                    <label class="partsLabel col-form-label ml-2 Label-my form-label">
                                                      Vehicle Modal
                                                    </label>
                                                    <div className="selectDisplay">
                                                      <div className="input-div SelectV selectA">
                                                        <div className="col-xl-12">
                                                          <input
                                                            className="form-control mt-2 input-search"
                                                            type="text"
                                                            maxLength={50}
                                                            placeholder="Search Vehicle Model"
                                                            value={
                                                              vehicleModelAuto
                                                            }
                                                            onChange={
                                                              handleChangeModel
                                                            }
                                                            onKeyDown={
                                                              handleKeyDownModel
                                                            }
                                                            onClick={(e) =>
                                                              e.stopPropagation()
                                                            }
                                                          />
                                                          {showSuggestionsModel &&
                                                            vehicleModelAuto && (
                                                              <ul className="suggestions">
                                                                {filteredSuggestionsModel?.length >
                                                                0 ? (
                                                                  filteredSuggestionsModel.map(
                                                                    (
                                                                      suggestion,
                                                                      index
                                                                    ) => (
                                                                      <li
                                                                        key={
                                                                          suggestion
                                                                        }
                                                                        className={
                                                                          index ===
                                                                          activeSuggestionIndexModel
                                                                            ? "active"
                                                                            : ""
                                                                        }
                                                                        onClick={() =>
                                                                          handleClickModel(
                                                                            suggestion
                                                                          )
                                                                        }
                                                                      >
                                                                        {
                                                                          suggestion.model_name
                                                                        }
                                                                        /
                                                                        {
                                                                          suggestion.initial_year
                                                                        }
                                                                      </li>
                                                                    )
                                                                  )
                                                                ) : (
                                                                  <li>
                                                                    No
                                                                    suggestions
                                                                    available
                                                                  </li>
                                                                )}
                                                              </ul>
                                                            )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {selectedModels?.length >
                                                      0 && (
                                                      <div
                                                        className="tableScroll vehicleThBg"
                                                        style={{
                                                          height: "143px",
                                                          border:
                                                            "1px solid #eee",
                                                          borderRadius: "0px",
                                                          width: "284px",
                                                          marginLeft: "8px",
                                                          position: "absolute",
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            borderCollapse:
                                                              "separate",
                                                            borderSpacing:
                                                              "0 15px",
                                                            marginBottom:
                                                              "16px",
                                                          }}
                                                          className="striped
                                                  bordered
                                                  hover"
                                                        >
                                                          <div className="container">
                                                            <div className="">
                                                              {selectedModels?.length >
                                                                0 &&
                                                                selectedModels?.map(
                                                                  (
                                                                    row,
                                                                    partIndex
                                                                  ) => (
                                                                    <React.Fragment
                                                                      key={
                                                                        partIndex
                                                                      }
                                                                    >
                                                                      <div className="d-flex">
                                                                        <div className="lisatingTable mb-1 col vehicleModall">
                                                                          {
                                                                            row.model_name
                                                                          }{" "}
                                                                          /{" "}
                                                                          {
                                                                            row.initial_year
                                                                          }
                                                                        </div>
                                                                        <Button
                                                                          style={{
                                                                            borderRadius:
                                                                              "0px",
                                                                            margin:
                                                                              "7px",
                                                                            height:
                                                                              "30px",
                                                                            width:
                                                                              "30px",
                                                                            color:
                                                                              "#dd4b4b",
                                                                            backgroundColor:
                                                                              "#ffe9e9",
                                                                            display:
                                                                              "flex",
                                                                            justifyContent:
                                                                              "center",
                                                                            alignItems:
                                                                              "center",
                                                                            border:
                                                                              "transparent",
                                                                            marginTop:
                                                                              "12px",
                                                                          }}
                                                                          className=""
                                                                          onClick={() =>
                                                                            setSelectedModels(
                                                                              selectedModels.filter(
                                                                                (
                                                                                  m
                                                                                ) =>
                                                                                  m.id !==
                                                                                  row.id
                                                                              )
                                                                            )
                                                                          }
                                                                        >
                                                                          <i className="bi bi-trash3"></i>
                                                                        </Button>
                                                                      </div>
                                                                    </React.Fragment>
                                                                  )
                                                                )}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    )}
                                                  </div>

                                                  <div className="col-lg-3 addPartField col-lg-3-mob">
                                                    <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                      Condition
                                                    </label>
                                                    <Select
                                                      className="my-Select"
                                                      options={conditionList}
                                                      value={
                                                        conditionList.find(
                                                          (item) =>
                                                            item.value ===
                                                            partsData[pIndex]
                                                              ?.condition_id
                                                        ) ||
                                                        conditionList.find(
                                                          (item) =>
                                                            item.value ===
                                                            settingDetails?.condition_id
                                                        ) ||
                                                        conditionList.find(
                                                          (item) =>
                                                            item.value === 1
                                                        )
                                                      }
                                                      onChange={(
                                                        selectedOption
                                                      ) =>
                                                        handleConditionChange(
                                                          pIndex,
                                                          selectedOption
                                                        )
                                                      }
                                                      isSearchable
                                                      placeholder={
                                                        "Select Condition"
                                                      }
                                                    ></Select>
                                                  </div>

                                                  <div className="col-lg-3 addPartField col-lg-3-mob">
                                                    <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                      State
                                                    </label>
                                                    <Select
                                                      className="my-Select"
                                                      options={stateList}
                                                      value={
                                                        stateList.find(
                                                          (item) =>
                                                            item.value ===
                                                            partsData[pIndex]
                                                              ?.state_id
                                                        ) ||
                                                        stateList.find(
                                                          (item) =>
                                                            item.value ===
                                                            settingDetails?.state_id
                                                        ) ||
                                                        stateList.find(
                                                          (item) =>
                                                            item.value === 1
                                                        )
                                                      }
                                                      onChange={(
                                                        selectedOption
                                                      ) =>
                                                        handleStateListChange(
                                                          pIndex,
                                                          selectedOption
                                                        )
                                                      }
                                                      isSearchable
                                                      placeholder={
                                                        "Select State"
                                                      }
                                                    ></Select>
                                                  </div>

                                                  <div className="col-lg-3 addPartField col-lg-3-mob">
                                                    <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                      Part Brand
                                                    </label>
                                                    <Select
                                                      className="my-Select"
                                                      options={allBrandList}
                                                      name="brand_id"
                                                      value={allBrandList.find(
                                                        (option) =>
                                                          option.value ===
                                                          part?.brand_id
                                                      )}
                                                      onChange={(
                                                        selectedOption
                                                      ) =>
                                                        handleBrandIdChange(
                                                          pIndex,
                                                          selectedOption
                                                        )
                                                      }
                                                      placeholder={
                                                        "Select Part Brand"
                                                      }
                                                      isSearchable={true}
                                                    ></Select>
                                                  </div>
                                                  <div className="col-lg-3 addPartField offset-lg-3 col-lg-6-mob">
                                                    <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                      Notes
                                                    </label>
                                                    <textarea
                                                      className="form-control input-search"
                                                      type="text"
                                                      name="part_description"
                                                      value={
                                                        part?.part_description
                                                      }
                                                      onChange={(e) =>
                                                        handleInputChangePartList(
                                                          e,
                                                          part?.tempId
                                                        )
                                                      }
                                                      placeholder="Part Description"
                                                      rows={3}
                                                    />
                                                  </div>

                                                  <div className="col-lg-3 addPartField col-lg-3-mob">
                                                    <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                      Custome Size
                                                    </label>

                                                    <div className="addPartField">
                                                      <div className="form-check text-left">
                                                        <input
                                                          className="form-check-input"
                                                          type="checkbox"
                                                          checked={
                                                            part?.is_custom_size ===
                                                            "1"
                                                          }
                                                          onChange={(e) =>
                                                            handleCheckboxCustomChange(
                                                              pIndex,
                                                              e
                                                            )
                                                          }
                                                        />
                                                        <label className="form-check-label">
                                                          Custome Size
                                                        </label>
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <div className="row row-mobile-3">
                                                    {part?.is_custom_size ===
                                                      "1" && (
                                                      <>
                                                        <div className="col-lg-3 addPartField col-lg-3-mob">
                                                          <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                            Weight (kg){" "}
                                                          </label>
                                                          <input
                                                            className="form-control input-search"
                                                            type="number"
                                                            name="parts_weight"
                                                            value={
                                                              part?.parts_weight
                                                            }
                                                            onChange={(e) =>
                                                              handleInputChangePartList(
                                                                e,
                                                                part?.tempId
                                                              )
                                                            }
                                                            placeholder="0"
                                                          />
                                                        </div>

                                                        <div className="col-lg-3 addPartField col-lg-3-mob">
                                                          <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                            Width(cm){" "}
                                                          </label>
                                                          <input
                                                            className="form-control input-search"
                                                            type="number"
                                                            name="parts_width"
                                                            value={
                                                              part?.parts_width
                                                            }
                                                            onChange={(e) =>
                                                              handleInputChangePartList(
                                                                e,
                                                                part?.tempId
                                                              )
                                                            }
                                                            placeholder="0"
                                                          />

                                                          {errors.parts_width && (
                                                            <span className="input-error">
                                                              {
                                                                errors.parts_width
                                                              }
                                                            </span>
                                                          )}
                                                        </div>

                                                        <div className="col-lg-3 addPartField col-lg-3-mob">
                                                          <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                            Height(cm){" "}
                                                          </label>
                                                          <input
                                                            className="form-control input-search"
                                                            type="number"
                                                            name="parts_height"
                                                            value={
                                                              part?.parts_height
                                                            }
                                                            onChange={(e) =>
                                                              handleInputChangePartList(
                                                                e,
                                                                part?.tempId
                                                              )
                                                            }
                                                            placeholder="0"
                                                          />
                                                        </div>

                                                        <div className="col-lg-3 addPartField col-lg-3-mob">
                                                          <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                            Length(cm){" "}
                                                          </label>
                                                          <input
                                                            className="form-control input-search"
                                                            type="number"
                                                            name="parts_length"
                                                            value={
                                                              part?.parts_length
                                                            }
                                                            onChange={(e) =>
                                                              handleInputChangePartList(
                                                                e,
                                                                part?.tempId
                                                              )
                                                            }
                                                            placeholder="0"
                                                          />
                                                        </div>
                                                      </>
                                                    )}
                                                  </div>

                                                  {part?.state_id === 2 && (
                                                    <div className="col-lg-3 addPartField col-lg-3-mob">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        Reg Number Dismantler
                                                      </label>
                                                      <input
                                                        className="form-control input-search"
                                                        type="text"
                                                        name="reg_number_dismantler"
                                                        value={
                                                          settingDetails?.reg_number_dismantler
                                                            ? settingDetails?.reg_number_dismantler
                                                            : part?.reg_number_dismantler
                                                        }
                                                        onChange={(e) =>
                                                          handleInputChangePartList(
                                                            e,
                                                            part?.tempId
                                                          )
                                                        }
                                                        placeholder="0"
                                                      />

                                                      {errors.reg_number_dismantler && (
                                                        <span className="input-error">
                                                          {
                                                            errors.reg_number_dismantler
                                                          }
                                                        </span>
                                                      )}
                                                    </div>
                                                  )}
                                                  <div className="col-lg-3 addPartField col-lg-3-mob">
                                                    <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                      Print label
                                                    </label>
                                                    <div className="addPartField">
                                                      <div className="form-check text-left">
                                                        <input
                                                          className="form-check-input"
                                                          type="checkbox"
                                                          checked={
                                                            part?.print_label ===
                                                            1
                                                          }
                                                          onChange={(e) =>
                                                            handleCheckboxPrintChanges(
                                                              pIndex,
                                                              e
                                                            )
                                                          }
                                                        />
                                                        <label className="form-check-label">
                                                          Print label
                                                        </label>
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <div className="col-lg-3 addPartField col-lg-3-mob">
                                                    <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                      VAT included
                                                    </label>
                                                    <div className="addPartField">
                                                      <div className="form-check text-left">
                                                        <input
                                                          className="form-check-input"
                                                          type="checkbox"
                                                          checked={
                                                            part?.vat_included ===
                                                            1
                                                          }
                                                          onChange={(e) =>
                                                            handleCheckboxVatChanges(
                                                              pIndex,
                                                              e
                                                            )
                                                          }
                                                        />
                                                        <label className="form-check-label">
                                                          VAT included
                                                        </label>
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <div className="col-lg-3 addPartField col-lg-3-mob"></div>

                                                  <div className="col-lg-3 addPartField col-lg-3-mob"></div>

                                                  {part?.is_show_engine_record ===
                                                    true && (
                                                    <>
                                                      <div className="col-lg-3 addPartField col-lg-3-mob">
                                                        <div className="col-md-12">
                                                          <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                            Engine Code{" "}
                                                          </label>
                                                          <Select
                                                            className="my-Select"
                                                            options={
                                                              vehicleEngineList
                                                            }
                                                            value={vehicleEngineList.find(
                                                              (item) =>
                                                                item.value ===
                                                                EngineData?.vehicle_variant
                                                            )}
                                                            onChange={
                                                              handleSelectWithoutChangeEngineList
                                                            }
                                                            placeholder={
                                                              "Select Engine"
                                                            }
                                                            isSearchable={true}
                                                            onInputChange={
                                                              handleInputSelectedChangeEngineList
                                                            }
                                                            inputSelctedValue={
                                                              inputSelctedEngineValue
                                                            }
                                                          />
                                                        </div>
                                                      </div>

                                                      <div className="col-lg-3 addPartField col-lg-3-mob">
                                                        <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                          Cv{" "}
                                                        </label>
                                                        <input
                                                          className="form-control input-search"
                                                          type="text"
                                                          name="cv"
                                                          value={part?.cv}
                                                          onChange={(e) => {
                                                            handleInputChangePartLists(
                                                              e,
                                                              part?.tempId
                                                            );
                                                          }}
                                                          placeholder="0"
                                                        />
                                                      </div>

                                                      <div className="col-lg-3 addPartField col-lg-3-mob">
                                                        <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                          CC
                                                        </label>
                                                        <input
                                                          className="form-control input-search"
                                                          type="text"
                                                          name="cc"
                                                          value={part?.cc}
                                                          onChange={(e) => {
                                                            handleInputChangePartLists(
                                                              e,
                                                              part?.tempId
                                                            );
                                                          }}
                                                          placeholder="0"
                                                        />
                                                      </div>

                                                      <div className="col-lg-3 addPartField col-lg-3-mob">
                                                        <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                          KW
                                                        </label>
                                                        <input
                                                          className="form-control input-search"
                                                          type="text"
                                                          name="kw"
                                                          value={part?.kw}
                                                          onChange={(e) => {
                                                            handleInputChangePartLists(
                                                              e,
                                                              part?.tempId
                                                            );
                                                          }}
                                                          placeholder="0"
                                                        />
                                                      </div>
                                                    </>
                                                  )}

                                                  <div
                                                    className="col-lg-12"
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "end",
                                                      marginBottom: "10px",
                                                    }}
                                                  >
                                                    {part?.etai_part_id && (
                                                      <>
                                                        <button
                                                          style={{
                                                            marginTop: "8px",
                                                            marginRight: "10px",
                                                          }}
                                                          type="button"
                                                          className="btn btn-primary"
                                                          onClick={(e) =>
                                                            handleEtaiDetails(
                                                              e,
                                                              part?.etai_part_id,
                                                              pIndex
                                                            )
                                                          }
                                                        >
                                                          View Reference
                                                        </button>

                                                        <button
                                                          style={{
                                                            marginTop: "8px",
                                                          }}
                                                          type="button"
                                                          className="btn btn-primary"
                                                          onClick={(e) =>
                                                            handleEtaiPriceDetails(
                                                              e,
                                                              part?.etai_part_id,
                                                              pIndex
                                                            )
                                                          }
                                                        >
                                                          View Price
                                                        </button>
                                                      </>
                                                    )}
                                                  </div>

                                                  <div className="col-lg-12">
                                                    <div className=" table-responsive-custom-q">
                                                      <table className="table">
                                                        <thead>
                                                          <tr>
                                                            <th scope="col">
                                                              <div className="col-lg-12 addPartField">
                                                                <label className="partsLabel col-form-label ml-2">
                                                                  Reference Code
                                                                </label>
                                                                <input
                                                                  className="form-control input-search"
                                                                  type="text"
                                                                  name="reference_code"
                                                                  value={
                                                                    selectedReferenceCode
                                                                      ? selectedReferenceCode
                                                                      : part?.reference_code
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleInputChangePartListRef(
                                                                      e,
                                                                      pIndex
                                                                    )
                                                                  }
                                                                  placeholder="Reference Code"
                                                                />
                                                              </div>
                                                            </th>

                                                            <th scope="col">
                                                              <div className="col-lg-12 addPartField">
                                                                <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                                  Condition
                                                                </label>
                                                                <Select
                                                                  className="my-Select"
                                                                  options={
                                                                    conditionList
                                                                  }
                                                                  value={
                                                                    conditionList.find(
                                                                      (
                                                                        option
                                                                      ) =>
                                                                        option.value ===
                                                                          part?.condition_id_ref ||
                                                                        option.label ===
                                                                          part?.selectedCondition
                                                                    ) || null
                                                                  }
                                                                  onChange={(
                                                                    selectedOption
                                                                  ) =>
                                                                    handleConditionSChangeRefs(
                                                                      pIndex,
                                                                      selectedOption
                                                                    )
                                                                  }
                                                                  isSearchable
                                                                  placeholder={
                                                                    "Select Condition"
                                                                  }
                                                                ></Select>
                                                              </div>
                                                            </th>
                                                            <th scope="col">
                                                              <div className="col-lg-12 addPartField">
                                                                <label className="partsLabel col-form-label ml-2">
                                                                  Reference Type
                                                                </label>
                                                                <Select
                                                                  className="my-Select"
                                                                  options={
                                                                    referenceList
                                                                  }
                                                                  value={
                                                                    referenceList.find(
                                                                      (
                                                                        option
                                                                      ) =>
                                                                        option.value ===
                                                                          part?.type_id ||
                                                                        option.label ===
                                                                          part?.selectedType
                                                                    ) || null
                                                                  }
                                                                  onChange={(
                                                                    selectedOption
                                                                  ) =>
                                                                    handleConditionChangeRef(
                                                                      pIndex,
                                                                      selectedOption
                                                                    )
                                                                  }
                                                                  isSearchable
                                                                  placeholder="Select Type"
                                                                />
                                                              </div>
                                                            </th>
                                                            <th scope="col"></th>
                                                            <th>
                                                              <button
                                                                style={{
                                                                  marginTop:
                                                                    "8px",
                                                                }}
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={() =>
                                                                  handleAddPartRef(
                                                                    pIndex
                                                                  )
                                                                }
                                                              >
                                                                Add Reference
                                                              </button>
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          {part?.addedData.map(
                                                            (
                                                              data,
                                                              rowIndex
                                                            ) => {
                                                              return (
                                                                <>
                                                                  <tr
                                                                    key={
                                                                      rowIndex
                                                                    }
                                                                  >
                                                                    <td>
                                                                      <div className="col-lg-12 addPartField">
                                                                        <label className="partsLabel col-form-label ml-2">
                                                                          Reference
                                                                          Code
                                                                        </label>
                                                                        <input
                                                                          className="form-control"
                                                                          type="text"
                                                                          name="reference_code"
                                                                          value={
                                                                            data.reference_code
                                                                          }
                                                                          onChange={(
                                                                            e
                                                                          ) =>
                                                                            handleUpdatePartRef(
                                                                              pIndex,
                                                                              rowIndex,
                                                                              "reference_code",
                                                                              e
                                                                                .target
                                                                                .value
                                                                            )
                                                                          }
                                                                          placeholder="Reference Code"
                                                                        />
                                                                      </div>
                                                                    </td>

                                                                    <td>
                                                                      <Select
                                                                        className="my-Select"
                                                                        options={
                                                                          conditionList
                                                                        }
                                                                        value={conditionList.find(
                                                                          (
                                                                            option
                                                                          ) =>
                                                                            option.value ===
                                                                            data.condition_id_ref
                                                                        )}
                                                                        onChange={(
                                                                          selectedOption
                                                                        ) =>
                                                                          handleUpdatePartRef(
                                                                            pIndex,
                                                                            rowIndex,
                                                                            "condition_id_ref",
                                                                            selectedOption?.value ||
                                                                              null
                                                                          )
                                                                        }
                                                                        isSearchable
                                                                        placeholder="Select Condition"
                                                                      />
                                                                    </td>
                                                                    <td>
                                                                      <Select
                                                                        className="my-Select"
                                                                        options={
                                                                          referenceList
                                                                        }
                                                                        value={referenceList.find(
                                                                          (
                                                                            option
                                                                          ) =>
                                                                            option.value ===
                                                                            data.type_id
                                                                        )}
                                                                        onChange={(
                                                                          selectedOption
                                                                        ) =>
                                                                          handleUpdatePartRef(
                                                                            pIndex,
                                                                            rowIndex,
                                                                            "type_id",
                                                                            selectedOption?.value ||
                                                                              null
                                                                          )
                                                                        }
                                                                        isSearchable
                                                                        placeholder="Select Type"
                                                                      />
                                                                    </td>
                                                                    <td>
                                                                      <div className="form-check text-left">
                                                                        <label className="partsLabel col-form-label ml-2">
                                                                          Is
                                                                          Main
                                                                        </label>
                                                                        <input
                                                                          style={{
                                                                            marginLeft:
                                                                              "20px",
                                                                          }}
                                                                          className="form-check-input"
                                                                          type="radio" // Changed from checkbox to radio
                                                                          name={`isMainRadioGroup-${pIndex}-${rowIndex}`} // Unique name for each radio button group (to handle multiple groups)
                                                                          checked={
                                                                            data?.is_main ===
                                                                            1
                                                                          }
                                                                          onChange={() =>
                                                                            handleUpdatePartRef(
                                                                              pIndex,
                                                                              rowIndex,
                                                                              "is_main",
                                                                              1
                                                                            )
                                                                          }
                                                                        />
                                                                      </div>
                                                                    </td>
                                                                    <td>
                                                                      <i
                                                                        className="bi bi-pen-fill"
                                                                        style={{
                                                                          cursor:
                                                                            "pointer",
                                                                          marginRight:
                                                                            "10px",
                                                                        }}
                                                                        onClick={() =>
                                                                          handleEditPartRef(
                                                                            pIndex,
                                                                            rowIndex
                                                                          )
                                                                        }
                                                                      />
                                                                      <i
                                                                        class="bi bi-trash-fill"
                                                                        onClick={() =>
                                                                          handleDeletePartRef(
                                                                            pIndex,
                                                                            rowIndex
                                                                          )
                                                                        }
                                                                      ></i>{" "}
                                                                    </td>
                                                                  </tr>
                                                                </>
                                                              );
                                                            }
                                                          )}
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </Card.Body>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  );
                                })}
                              </div>
                              <div class="row" style={{ marginTop: "102px" }}>
                                <div class="col-lg-6">
                                  <button
                                    type="button"
                                    class="next-button btn btn-secondary"
                                    onClick={handleBackButtonClick}
                                  >
                                    <i class="bi bi-arrow-left"></i> Back
                                  </button>
                                </div>
                                <div class="col-lg-6 saveasLBtns">
                                  {btnLoader === true ? (
                                    <button
                                      type="button"
                                      disabled={true}
                                      className="btn btn-primary"
                                    >
                                      Loading...
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={handleSubmitAsPrintLabel}
                                    >
                                      Save As Label <i className="bi bi-save" />
                                    </button>
                                  )}
                                  <div>
                                    {btnLoading ? (
                                      <button
                                        type="button"
                                        disabled={true}
                                        className="btn btn-primary"
                                      >
                                        Loading...
                                      </button>
                                    ) : (
                                      <button
                                        type="submit"
                                        className="btn btn-primary addPArtsSave"
                                      >
                                        Save <i className="bi bi-save ms-2"></i>
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Form>
                          </div>
                        </div>
                      </>
                    )}

                    {currentStep === 3 && (
                      <>
                        <div className="card-body vehicleparts-table mt-3">
                          <div className="vehicleparts-table-inner">
                            <div className="row">
                              <>
                                <Form className="row" onSubmit={handleSubmits}>
                                  <React.Fragment>
                                    <div className="col-lg-6">
                                      <Card className="text-justify">
                                        <Card.Header className="cardHeader">
                                          <div className="d-flex">
                                            <div className="AddPh6">
                                              <h6>Part Information</h6>
                                            </div>
                                          </div>
                                        </Card.Header>
                                        <Card.Body
                                          className="cardBody"
                                          style={{ padding: "0" }}
                                        >
                                          <div class="container text-justify">
                                            <div class="row pt-2 pb-2 border-bottom">
                                              <div class="col-lg-6">
                                                Part Name:
                                              </div>
                                              <div class="col-lg-6">
                                                {currentPart?.part_name}
                                              </div>
                                            </div>
                                            <div class="row pt-2 pb-2 border-bottom">
                                              <div class="col-lg-6">
                                                Part Description:
                                              </div>
                                              <div
                                                class="col-lg-6"
                                                style={{ lineHeight: "25px" }}
                                              >
                                                {currentPart?.part_description}
                                              </div>
                                            </div>

                                            <div class="row pt-2 pb-2 border-bottom">
                                              <div class="col-lg-6">
                                                Part Quantity:
                                              </div>
                                              <div class="col-lg-6">
                                                {currentPart?.quantity}
                                              </div>
                                            </div>
                                            <div class="row pt-2 pb-2 border-bottom">
                                              <div class="col-lg-6">
                                                Part Price:
                                              </div>
                                              <div class="col-lg-6">
                                                {currentPart?.part_price}
                                              </div>
                                            </div>

                                            <div class="row pt-2 pb-2 border-bottom">
                                              <div class="col-lg-6">
                                                Part Original Price:
                                              </div>
                                              <div class="col-lg-6">
                                                {
                                                  currentPart?.part_original_price
                                                }
                                              </div>
                                            </div>

                                            <div class="row pt-2 pb-2 border-bottom">
                                              <div class="col-lg-6">
                                                Sponsor Price:{" "}
                                              </div>
                                              <div class="col-lg-6">
                                                {currentPart?.sponsor_price}
                                              </div>
                                            </div>
                                          </div>
                                        </Card.Body>
                                      </Card>
                                    </div>
                                    <div className="col-lg-6">
                                      <Card className="text-center">
                                        <Card.Header className="cardHeader">
                                          <div className="d-flex">
                                            <div className="AddPh6">
                                              <h6>Upload Part Image</h6>
                                            </div>
                                          </div>
                                        </Card.Header>
                                        <Card.Body className="cardBody">
                                          <div className="row">
                                            <div className="col-lg-12 addPartField">
                                              <div
                                                className="col-lg-12 addPartField"
                                                style={{
                                                  height: "221px",
                                                  overflowY: "auto",
                                                  overflowX: "hidden",
                                                }}
                                              >
                                                <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                  Upload Images
                                                  {/* {currentPart.part_name} */}
                                                </label>
                                                <div className="input-div">
                                                  <div className="avatar-upload">
                                                    <div className="avatar-edit">
                                                      <div className="input-div">
                                                        <input
                                                          ref={fileInputRef}
                                                          className="inputFile"
                                                          type="file"
                                                          id="imageuploadAbout"
                                                          onChange={
                                                            handleAboutFileChange
                                                          }
                                                          name="file"
                                                          accept=".jpg,.jpeg,.png"
                                                          multiple
                                                        />
                                                        <div className="partImages mt-2">
                                                          {images?.map(
                                                            (
                                                              { file, id },
                                                              index
                                                            ) => (
                                                              <div
                                                                key={id}
                                                                className="preview-item"
                                                              >
                                                                <div
                                                                  className="btn-Remove"
                                                                  style={{
                                                                    position:
                                                                      "absolute",
                                                                    width:
                                                                      "20px",
                                                                    height:
                                                                      "20px",
                                                                  }}
                                                                >
                                                                  <i
                                                                    className="bi bi-x"
                                                                    style={{
                                                                      color:
                                                                        "red",
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                    onClick={() =>
                                                                      handleRemoveFile(
                                                                        index
                                                                      )
                                                                    }
                                                                  ></i>
                                                                </div>
                                                                <img
                                                                  onClick={() =>
                                                                    toggleEnlarge(
                                                                      index
                                                                    )
                                                                  }
                                                                  className={`image ${
                                                                    enlargedIndexes.includes(
                                                                      index
                                                                    )
                                                                      ? "enlarged"
                                                                      : ""
                                                                  }`}
                                                                  src={
                                                                    previewUrl[
                                                                      index
                                                                    ]
                                                                  }
                                                                  alt={`Preview ${index}`}
                                                                  style={{
                                                                    width:
                                                                      "100px",
                                                                    margin:
                                                                      "5px",
                                                                    height:
                                                                      "100px",
                                                                    border:
                                                                      "1px solid",
                                                                    borderRadius:
                                                                      "5px",
                                                                    maxWidth:
                                                                      "100px",
                                                                    maxHeight:
                                                                      "100px",
                                                                  }}
                                                                />
                                                              </div>
                                                            )
                                                          )}
                                                        </div>
                                                      </div>
                                                      <label htmlFor="imageuploadAbout"></label>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </Card.Body>
                                      </Card>
                                    </div>
                                  </React.Fragment>

                                  <div className="d-flex flex-row mt-3 justify-content-end">
                                    {!isLastPart && (
                                      <Button
                                        className="me-2 mb-2 next-button"
                                        onClick={handleOpenSkip}
                                      >
                                        Skip & Next
                                      </Button>
                                    )}

                                    {!isLastPart && (
                                      <Button
                                        className="me-2 mb-2 btn-primary"
                                        type="submit"
                                      >
                                        Save & Next
                                      </Button>
                                    )}
                                  </div>
                                  {isLastPart && (
                                    <>
                                      <div className="row">
                                        <div className="col">
                                          <Button
                                            type="button"
                                            className="back-btn ms-0 "
                                            onClick={handleBackButtonClick}
                                          >
                                            <i class="bi bi-arrow-left"></i>{" "}
                                            Back
                                          </Button>
                                        </div>
                                        <div className="col infobtns">
                                          {btnSaved === true ? (
                                            <button
                                              type="button"
                                              disabled={true}
                                              className="btn btn-primary"
                                            >
                                              Loading...
                                            </button>
                                          ) : (
                                            <Button
                                              className="mb-2 next-button"
                                              onClick={handleFinish}
                                              style={{ marginRight: "10px" }}
                                            >
                                              Save <i class="bi bi-save"></i>
                                            </Button>
                                          )}

                                          <Button
                                            type="Button"
                                            className="btn btn-primary nxt-btn"
                                            onClick={handleImportModal}
                                          >
                                            Sort By Order{" "}
                                            <i class="bi bi-arrow-right"></i>
                                          </Button>

                                          <Button
                                            className="mb-2 next-button"
                                            onClick={() =>
                                              navigate("/parts-inventory")
                                            }
                                          >
                                            Finish{" "}
                                            <i class="bi bi-arrow-right"></i>
                                          </Button>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </Form>
                              </>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </section>

        {/* <Modal show={showModalAbout} onHide={handleCloseAbout}>
          <Modal.Header closeButton>
            <Modal.Title>Parts Images</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="preview-container">
              {images.map(({ file, id }, index) => (
                <div key={id} className="preview-item">
                  <img
                    src={previewUrl[index]}
                    alt={`Preview ${index}`}
                    style={{ width: "100px", margin: "5px" }}
                  />
                  <div className="btn-Remove">
                    <i
                      className="bi bi-x"
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={() => handleRemoveFile(index)}
                    ></i>
                  </div>
                </div>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseAbout}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showSkip} onHide={handleCloseSkip}>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            Are you sure you want to skip this part? You won't be able to upload
            images for this part.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseSkip}>
              Close
            </Button>
            <Button variant="secondary" onClick={handleSkip}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal> */}

        <Modal isOpen={modifyImportModal} toggle={handleImportCloseModal}>
          <ModalHeader toggle={handleImportCloseModal}>
            Sort By Order
          </ModalHeader>
          <ModalBody>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="fileList" direction="vertical">
                {(provided) => (
                  <div
                    className="file-list"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    {images.map((file, index) => (
                      <Draggable
                        key={file.id}
                        draggableId={file.id}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            className="file-item"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              border: "1px solid #ddd",
                              padding: "10px",
                              borderRadius: "5px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={file.url}
                              alt="Preview"
                              style={{
                                width: "50px",
                                height: "50px",
                                objectFit: "cover",
                                borderRadius: "5px",
                              }}
                            />
                            <span>{file.file.name}</span>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleImportCloseModal}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSaveOrder}
            >
              Save Order
            </button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={partEtaiModify}>
          <ModalHeader>{`Reference Code`}</ModalHeader>

          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-12">
                  <FormGroup>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Reference Code</th>
                          <th>Product Label</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {partETAIRefDetails?.map((item, index) => (
                          <tr key={index}>
                            <td>{item?.reference_code}</td>
                            <td>{item?.product_label}</td>
                            <td>
                              <input
                                type="radio"
                                className="form-check-input"
                                name="radio-select" // Group all radio buttons with the same name
                                checked={
                                  selectedReferenceCode === item?.reference_code
                                } // Check if the current item is selected
                                onChange={() =>
                                  handleRadioChange(item?.reference_code)
                                } // Set the selected reference code when changed
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </FormGroup>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-block btn-primary"
              onClick={handlePartEtaiClose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Submit <i class="bi bi-check2"></i>
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={partEtaiPriceModify}>
          <ModalHeader>{`Price`}</ModalHeader>

          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-12">
                  <FormGroup>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Price</th>
                          <th>Product Label</th>
                        </tr>
                      </thead>
                      <tbody>
                        {partETAIPriceDetail &&
                        partETAIPriceDetail.length > 0 ? (
                          partETAIPriceDetail.map((item, index) => (
                            <tr key={index}>
                              <td>{item?.source}</td>
                              <td>{item?.label}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="2" className="text-center">
                              No record found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </FormGroup>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-block btn-primary"
              onClick={handlePartEtaiPriceClose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-check2"></i>
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modifyMasterPart} size="lg">
          <ModalHeader>
            {categoryMasterPart ? categoryMasterPart?.label : ""}
          </ModalHeader>

          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-12">
                  <FormGroup>
                    {categoryMasterPart?.part_list?.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedRowIndices.includes(index)}
                            onChange={() => handleCheckboxChangeMaster(index)}
                          />
                        </td>
                        <td>
                          <div className="input-div SelectV">
                            <div className="col-md-12">{item?.namePartPT}</div>
                          </div>
                        </td>

                        <td>
                          <div className="input-div SelectV">
                            <div className="col-md-12">
                              <Select
                                className="my-Select"
                                options={vehicleYearPartList}
                                value={
                                  vehicleYearPartList?.find(
                                    (option) =>
                                      option?.value ===
                                      selectedRows[index]?.vehicle_year
                                  ) || null
                                }
                                onChange={(selected) =>
                                  handleSelectChangeMaster(
                                    index,
                                    "vehicle_year",
                                    selected?.value
                                  )
                                }
                                placeholder={"Select Year"}
                                isSearchable={true}
                              />
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className="input-div SelectV">
                            <div className="col-md-12">
                              <Select
                                className="my-Select"
                                options={vehicleMakePartList}
                                value={
                                  vehicleMakePartList?.find(
                                    (option) =>
                                      option?.value ===
                                      selectedRows[index]?.vehicle_make
                                  ) || null
                                }
                                onChange={(selected) =>
                                  handleSelectChangeMaster(
                                    index,
                                    "vehicle_make",
                                    selected?.value
                                  )
                                }
                                placeholder={"Select Make"}
                                isSearchable={true}
                              />
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className="input-div SelectV">
                            <div className="col-md-12">
                              <Select
                                className="my-Select"
                                options={vehicleModalPartList}
                                value={
                                  vehicleModalPartList?.find(
                                    (option) =>
                                      option?.value ===
                                      selectedRows[index]?.vehicle_model
                                  ) || null
                                }
                                onChange={(selected) =>
                                  handleSelectChangeMaster(
                                    index,
                                    "vehicle_model",
                                    selected?.value
                                  )
                                }
                                placeholder={"Select Model"}
                                isSearchable={true}
                              />
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className="input-div SelectV">
                            <div className="col-md-12">
                              <Select
                                className="my-Select"
                                options={vehicleVariantPartList}
                                value={
                                  vehicleVariantPartList?.find(
                                    (option) =>
                                      option?.value ===
                                      selectedRows[index]?.vehicle_variant
                                  ) || null
                                }
                                onChange={(selected) =>
                                  handleSelectChangeMaster(
                                    index,
                                    "vehicle_variant",
                                    selected?.value
                                  )
                                }
                                placeholder={"Select Variant"}
                                isSearchable={true}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </FormGroup>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-block btn-secondary"
              onClick={handleMasterPartClose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-block btn-primary"
              style={{ width: "100px", marginTop: "10px" }}
              onClick={handleModalSubmit}
            >
              Submit
            </button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default AddForParts;
