import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  createPartCatalog,
  deletePartCatalog,
  getPartCatalogList,
  importPartCatalog,
  getExportPartCatalogList,
  getEtaiSearchList,
  actionPartAssignEtai,
} from "../../services/LerCatalogService";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { actionPartCatalogList } from "../../services/ParkServices";
import Dropdown from "react-bootstrap/Dropdown";
import {
  actionDelDismental,
  getDismentalList,
} from "../../services/DismentalServices";

const Dismantle = () => {
  const tableLengthList = ConfigDB.data.dataTableLength;
  const navigate = useNavigate();
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const companyId = JSON.parse(data);
  const location = useLocation();
  const RolePermission = Utils.getRecordForCurrentPath(location);
  const LangData = localStorage.getItem("lng");

  const [totalRecords, setTotalRecords] = useState(0);
  const [partList, setPartList] = useState([]);

  const [delModal, setDelModal] = useState(false);
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [delpart, setDelPart] = useState("");

  let errorsObj = {
    ler_code: "",
    item_weight: "",
    item_weight_unit: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  let errorsObjImport = {
    file: "",
  };
  const [percentageModal, setPercentageModal] = useState(false);

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    search: "",
    is_reload: false,
    company_id: companyId.company_id || 0,
    ...(percentageModal === true ? { pages: 1 } : { page: 1 }),
  });

  const [vehicleVariantsAuto, setVehicleVariantsAuto] = useState("");

  const [partETAIVariant, setPartETAIVariant] = useState("");

  useEffect(() => {
    if (partETAIVariant) {
      setVehicleVariantsAuto(partETAIVariant?.etai_part_name);
    }
  }, [partETAIVariant]);

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    } else if (type === "paginations") {
      paramsObj.pages = e;
    }
    setParams(paramsObj);
  };

  const fetchPartCatLog = () => {
    let data = { ...params };
    getDismentalList(data)
      .then((response) => {
        setPartList(response.data.data);
        setTotalRecords(response.data.total);
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(
    function () {
      let data = { ...params };
      fetchPartCatLog(data);
    },
    [params]
  );

  const onDeleteModalClose = () => {
    setDelModal(false);
  };

  const onDeleteModalOpen = (e, item) => {
    e.preventDefault();
    setDelPart(item.id);
    setDelModal(true);
  };

  const onDestroyPartType = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { id: delpart };
    actionDelDismental(data)
      .then((response) => {
        let datas = { ...params };
        fetchPartCatLog(datas);
        toast.success(response.data.message);
        setDelPart("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <>
        <div>
          {/* Your existing content goes here */}
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">{/* <h1>Part Catalog</h1> */}</div>
                <div className="col-sm-6"></div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card fuelCard container-custom-height">
                    <div className="card-header">
                      <div
                        className="card-title m-0 float-left"
                        style={{ width: "50%" }}
                      >
                        <input
                          type="text"
                          className="form-control"
                          value={params.search}
                          onChange={(e) => handleParams(e, "search")}
                          placeholder="Search"
                        />
                      </div>
                      <div className="card-title m-0 float-right d-flex">
                        {/* {RolePermission?.findIndex(
                          (e) => e.permission_name === "add_part_catalog"
                        ) > -1 && ( */}
                        <button
                          type="button"
                          className="btn btn-icon-text mr-2"
                          onClick={() => navigate("/add-dismental")}
                        >
                          <i className="bi bi-plus-lg"></i>
                          <span className="ms-2">Add</span>
                        </button>
                        {/* )} */}
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="vehicleThBg table-responsive table-responsive-vehicle">
                        <table className="table table-bordered rounded-table table table-striped table-hover">
                          <thead>
                            <tr>
                              <th style={{ width: "0%" }}>#</th>
                              <th style={{ width: "10%" }}>Name</th>

                              <th style={{ width: "0%" }}>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {partList?.map((item, index) => (
                              <tr key={item.id}>
                                <td>{++index}</td>

                                <td>{item.name}</td>

                                <td>
                                  <div className="d-flex">
                                    <Dropdown className="iconDropdown">
                                      <Dropdown.Toggle
                                        variant="success"
                                        id="dropdown-basic"
                                        className="iconToggle"
                                      >
                                        <i className="bi bi-three-dots-vertical"></i>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          href="#/action-1"
                                          className="d-flex"
                                          onClick={() =>
                                            navigate(
                                              `/add-dismental/${item.id}`
                                            )
                                          }
                                        >
                                          <a className="" href="#"></a>
                                          <div className="edit-btn">
                                            <i className="bi bi-pencil"></i>
                                          </div>
                                          <span>Edit</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#/action-2"
                                          className="d-flex"
                                          onClick={(e) =>
                                            onDeleteModalOpen(e, item)
                                          }
                                        >
                                          <div className="">
                                            <a className="" href="#"></a>
                                          </div>
                                          <div className="trash-btn">
                                            <a className="delete-btn">
                                              <i className="bi bi-trash3"></i>
                                            </a>
                                          </div>
                                          <span>Delete</span>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </td>
                              </tr>
                            ))}
                            {partList && partList.length === 0 && (
                              <tr>
                                <td colSpan={5} className="text-center">
                                  Record Not Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer clearfix">
              <div className="pagination pagination-sm m-0 float-right card-footer-part">
                <Pagination
                  activePage={params.page}
                  itemsCountPerPage={parseInt(params.limit)}
                  totalItemsCount={parseInt(totalRecords)} // Total available records
                  pageRangeDisplayed={5} // Number of page numbers to show
                  itemClass={`page-item`}
                  linkClass={`page-link`}
                  onChange={(e) => handleParams(e, "pagination")}
                />
              </div>
            </div>
          </section>
        </div>
        {/* )} */}

        <Modal isOpen={delModal}>
          <ModalHeader>{`Delete Dismetal`}</ModalHeader>
          <ModalBody>
            <p
              className={`mb-0`}
            >{`Are you sure you want to delete this dismetal?`}</p>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-block btn-primary"
              disabled={isDelButtonLoad}
              onClick={onDeleteModalClose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i className="bi bi-x-lg"></i>
            </button>

            {!isDelButtonLoad && (
              <button
                type="button"
                className="btn btn-block bg-red"
                style={{ width: "100px" }}
                onClick={onDestroyPartType}
              >
                Delete <i className="bi bi-trash3"></i>
              </button>
            )}
            {isDelButtonLoad && (
              <button
                color="secondary"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
              </button>
            )}
          </ModalFooter>
        </Modal>
      </>
    </div>
  );
};

export default Dismantle;
