import axiosInstance from "./AxiosInstance";

export function actionAddFuel(data) {
  return axiosInstance.post(`/fuel-type/create`, data);
}

export function actionUpdateFuel(data) {
  return axiosInstance.post(`/fuel-type/update`, data);
}

export function getFuelList(data) {
  return axiosInstance.post(`/fuel-type/list`, data);
}

export function actionDelFuel(data) {
  return axiosInstance.post(`/fuel-type/delete`, data);
}

export function importFuelType(data) {
  return axiosInstance.post(`/fuel-type/import`, data);
}
export function exportFuelType(data) {
  return axiosInstance.post(`/fuel-type/export-list`, data);
}
