// import React, { useState, useEffect } from "react";
// import { useTranslation } from "react-i18next";
// import ConfigDB from "../../config";

// const LanguageSwitcher = () => {
//   const { i18n } = useTranslation();
//   const [isOpen, setIsOpen] = useState(false);
//   const data = localStorage.getItem(ConfigDB.data.login_data);
//   const languageData = JSON.parse(data);

//   useEffect(() => {
//     const savedLanguage = localStorage.getItem("lng") || "en";
//     i18n.changeLanguage(savedLanguage);
//   }, [i18n]);

//   const handleChangeLanguage = (lng) => {
//     localStorage.setItem("lng", lng);
//     i18n.changeLanguage(lng);
//     setIsOpen(false);
//     window.location.reload();
//   };

//   const getLanguageName = (langCode) => {
//     const language = languageData?.language_data?.find(
//       (lang) => lang.code === langCode
//     );
//     return language ? language.name : "Portuguese";
//   };
//   const languageDisplay = getLanguageName(i18n.language);

//   return (
//     <div className="dropdown">
//       <button
//         className="btn btn-primary dropdown-toggle"
//         type="button"
//         onClick={() => setIsOpen(!isOpen)}
//       >
//         {languageDisplay}
//       </button>
//       {isOpen && (
//         <ul className="dropdown-menu show" aria-labelledby="dropdownMenuButton">
//           {languageData?.language_data?.map((lang) => (
//             <li key={lang.code}>
//               <a
//                 className="dropdown-item"
//                 href="#!"
//                 onClick={() => handleChangeLanguage(lang.code)}
//               >
//                 {lang.name}
//               </a>
//             </li>
//           ))}
//         </ul>
//       )}
//     </div>
//   );
// };

// export default LanguageSwitcher;

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ConfigDB from "../../config";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const languageData = JSON.parse(data);

  useEffect(() => {
    const savedLanguage = localStorage.getItem("lng") || "en";
    i18n.changeLanguage(savedLanguage);
  }, [i18n]);

  const handleChangeLanguage = (lng) => {
    localStorage.setItem("lng", lng);
    i18n.changeLanguage(lng);
    window.location.reload();
  };

  const getLanguageName = (langCode) => {
    const language = languageData?.language_data?.find(
      (lang) => lang.code === langCode
    );
    return language ? language.name : "Portuguese";
  };

  const languageDisplay = getLanguageName(i18n.language);

  return (
    <div className="dropdown" style={{ position: "relative" }}>
      <button
        className="btn btn-primary dropdown-toggle"
        type="button"
        id="dropdownMenuButton"
      >
        {languageDisplay}
      </button>
      <ul
        className="dropdown-menu"
        aria-labelledby="dropdownMenuButton"
      >
        {languageData?.language_data?.map((lang) => (
          <li key={lang.code}>
            <a
              className="dropdown-item"
              href="#!"
              onClick={() => handleChangeLanguage(lang.code)}
            >
              {lang.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LanguageSwitcher;

