import React, { useEffect, useState } from "react";
import ConfigDB from "../../config";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import {
  SubscribeCompany,
  getSubscriptionList,
  profileDetail,
  profileDetailForWeb,
} from "../../services/AuthServices";
import { toast } from "react-toastify";
import Utils from "../../utils";
import moment from "moment";
import MbwayIcon from "../../asset/images/MbwayIcon.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { CreatePaymentMBpay } from "../../services/PaymentServices";
import { authActions } from "../../store";
import { useDispatch } from "react-redux";
import Select from "react-select";
import {
  actionCityList,
  actionCountryList,
  actionStateList,
} from "../../services/CommonService";

const Profile = () => {
  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const data = localStorage.getItem(ConfigDB.data.login_data);
  const companyId = JSON.parse(data);

  const [profileDatas, setProfileDatas] = useState(null);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  let errorsObj = {
    password: "",
    confirmPassword: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [image, setImage] = useState(null);
  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const [SubscriptionList, setSubscriptionList] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [purchaseModal, setPurchaseModal] = useState(false);
  const [purchaseDetailsModal, setPurchaseDetailsModal] = useState(false);
  const [purchaseData, setPurchaseData] = useState({
    amount: "",
    mobileNumber: "",
    email: "",
    description: "",
    type_payment: "mb_way",
  });

  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [country, setCountry] = useState("");

  console.log("country", country);
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");

  const [address, setAddress] = useState("");
  const [company_registration_number, setCompanyRegistrationNumber] =
    useState("");
  const [purchaseDetailsData, setPurchaseDetailsData] = useState("");

  const [purchaseMessageModal, setPurchaseMessageModal] = useState(false);

  let errorsPicObj = {
    image: "",
  };
  const [errorsPic, setErrorsPic] = useState(errorsPicObj);
  const [loading, setLoading] = useState(false);
  const [amountDetails, setAmountDetails] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [previewUrlAddress, setPreviewUrlAddress] = useState(null);
  const [imageAddress, setImageAddress] = useState(null);

  const dataaa = countryList.find((option) => option.value);
  console.log("dataaa", dataaa);

  useEffect(function () {
    actionCountryList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }
        setCountryList(list);
      })
      .catch((err) => {});
  }, []);

  const onUpdateCountry = (selectedOption) => {
    let countryId = selectedOption?.value;
    setCountry(countryId);
    setState("");

    actionStateList({ country_id: countryId })
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }
        setStateList(list);
      })
      .catch((err) => {});
  };

  const onUpdateState = (selectedOption) => {
    let stateId = selectedOption?.value;
    setState(stateId);
    setCity("");

    // actionCityList({ state_id: stateId })
    //   .then((response) => {
    //     response = response.data;
    //     let list = [];
    //     for (let i = 0; i < response.data.length; i++) {
    //       list.push({
    //         value: response.data[i].id,
    //         label: response.data[i].name,
    //       });
    //     }
    //     setCityList(list);
    //   })
    //   .catch((err) => { });
    actionCityList({ state_id: stateId })
      .then((response) => {
        const cityList = response.data.data.map((city) => ({
          value: city.id,
          label: city.name,
        }));
        setCityList(cityList); // Update the list of cities
      })
      .catch((err) => {});
  };

  // const valueChangedHandler = function () {
  //   setIsChecked(!isChecked);
  // };

  // const validateImage = (file) => {
  //   const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
  //   if (!allowedTypes.includes(file.type)) {
  //     return false;
  //   }

  //   if (file.size > 5 * 1024 * 1024) {
  //     return false;
  //   }

  //   return true;
  // };

  const handlePurchaseDetailsClose = () => {
    setPurchaseDetailsModal(false);
  };

  const handlePurchaseClose = () => {
    setPurchaseModal(false);
  };

  const handlePurchaseSubmit = () => {
    setPurchaseModal(false);
    setPurchaseDetailsModal(true);
  };

  const handlePurchaseMessageClose = () => {
    setPurchaseMessageModal(false);
    fetchProfileData();
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files[0];

    if (selectedFiles) {
      setImage(selectedFiles);
      const imageUrl = URL.createObjectURL(selectedFiles);
      setPreviewUrl(imageUrl);
    }
  };

  const handleFileChangeAddress = (event) => {
    const selectedFiles = event.target.files[0];

    if (selectedFiles) {
      setImageAddress(selectedFiles);
      const imageUrl = URL.createObjectURL(selectedFiles);
      setPreviewUrlAddress(imageUrl);
    }
  };

  const fetchProfileData = async () => {
    try {
      const response = await profileDetailForWeb();
      console.log("response", response.data.data);
      setProfileDatas(response.data.data);
      setProfileData({
        name: response.data.data.name || "",
        email: response.data.data.email || "",
        country: response.data.data.company_details.country || "",
        state: response.data.data.company_details.state || "",
        city: response.data.data.company_details.city || "",
        zipCode: response.data.data.company_details.zipcode || "",
        address: response.data.data.company_details.address || "",
        company_registration_number:
          response.data.data.company_details.company_registration_number || "",
      });
      setPreviewUrl(response.data.data.profile);
      setPreviewUrlAddress(response.data.data.company_details.address_proof);
      setImageAddress(response.data.data.company_details.address_proof);
      setImage(response.data.data.profile);
      setZipCode(response.data.data.company_details.zipcode);
      setCountry(response.data.data.company_details.country);
      setState(response.data.data.company_details.state);
      setCity(response.data.data.company_details.city);
      setCompanyRegistrationNumber(
        response.data.data.company_details.company_registration_number
      );
      setAddress(response.data.data.company_details.address);
    } catch (err) {
      toast.error(Utils.getErrorMessage(err));
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, []);

  function LoadList() {
    getSubscriptionList()
      .then((response) => {
        setSubscriptionList(response.data.data);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  }

  const valueChangedHandler = function () {
    setIsChecked(!isChecked);
  };

  // const onSubscription = (e, item) => {
  //   setAmountDetails(item);

  // };

  const onSubscription = (item) => {
    setAmountDetails(item);
    setPurchaseModal(true);
    setSubscriptionModal(false);
  };

  const handleChangeSubscriptionPlan = () => {
    setSubscriptionModal(true);
    LoadList();
  };

  const handleChangeSubscriptionPlanClose = () => {
    setSubscriptionModal(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateImage = (image) => {
    if (!image) {
      return false;
    }
    const allowedTypes = ["image/jpeg", "image/png"];
    const maxSize = 2 * 1024 * 1024; // 2MB
    return allowedTypes.includes(image.type) && image.size <= maxSize;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let error = false;
    let errorObj = { ...errorsObj };

    let errorObjPic = { ...errorsPicObj };
    let errorPic = false;

    if (password && password.length < 8) {
      errorObj.password = "Password must be at least 8 characters long.";
      error = true;
    }

    if (confirmPassword && confirmPassword.length < 8) {
      errorObj.confirmPassword = "Password must be at least 8 characters long.";
      error = true;
    } else if (password && password !== confirmPassword) {
      errorObj.confirmPassword = "Passwords do not match!";
      error = true;
    }

    if (!validateImage(image)) {
      errorObjPic.image =
        "Invalid file. Please upload a valid image. Size must be < 2mb";
      errorPic = true;
    }

    setErrors(errorObj);
    if (error) return;

    const formData = new FormData();
    formData.append("files", image);
    formData.append("password", password);
    formData.append("name", profileData.name);
    formData.append("email", profileData.email);
    formData.append("address_proof", imageAddress);
    formData.append("country", country);
    formData.append("state", state);
    formData.append("city", city);
    formData.append("zipcode", zipCode);
    formData.append("address", address);
    formData.append("company_registration_number", company_registration_number);

    profileDetail(formData)
      .then((response) => {
        toast.success(response.data.message);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };
  const API_URL = ConfigDB.data.api_url;

  const handleSubmitMBPayPayment = (e) => {
    e.preventDefault();

    setLoading(true);

    const amount = isChecked
      ? amountDetails?.yearly_price
      : amountDetails?.monthly_price;

    let data = {
      amount: amount,
      mobileNumber: purchaseData?.mobileNumber,
      email: purchaseData?.email,
      type_payment: "mb_way",
    };

    CreatePaymentMBpay(data)
      .then((response) => {
        setLoading(false); // Set loading to false

        if (response.data.data.Message === "Declined") {
          toast.success(response.data.message);
        } else {
          setPurchaseDetailsData(response.data.data);
          toast.success(response.data.message);

          setPurchaseDetailsModal(false);
          if (response.data.data.Message === "Success") {
            const transactionId = response.data.data.RequestId;

            const requestId = transactionId;
            const webHookData = {
              paid_date: new Date().toISOString(),
            };

            axios({
              method: "GET",
              url: `${API_URL}payment-webhook?request_id=${requestId}`,
              data: webHookData,
              headers: {
                "Access-Control-Allow-Origin": "*",
                Accept: "*/*",
                "Content-Type": "application/json",
              },
            })
              .then((webhookResponse) => {
                setPurchaseMessageModal(true);
              })
              .catch((err) => {
                toast.error("Error processing webhook: " + err.message);
              });

            let obj = {
              user_id: profileDatas?.id,
              plan_type: isChecked ? 2 : 1,
              uuid: amountDetails.uuid,
            };
            SubscribeCompany(obj)
              .then((response) => {
                let userData = response?.data?.data;
                if (userData) {
                  Utils.setDetail(userData);
                  dispatch(authActions.login(userData));
                }
              })
              .catch((error) => {
                toast.error(Utils.getErrorMessage(error));
              })
              .finally(() => {
                setIsLoading(false);
              });
          }
        }
      })
      .catch((err) => {
        // End loading even on error
        setLoading(false); // Set loading to false
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="">
                <h1>Profile</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  {/* <div className="card-header">
                    <h3 className="card-title">Update Profile</h3>
                  </div> */}

                  <Form method={`post`} onSubmit={handleSubmit}>
                    <div className="card-body">
                      <h3 className="">Update Profile</h3>
                      <div className="row">
                        <div className="col-md-6">
                          <FormGroup>
                            <Label className="col-form-label ml-2 Label-my form-label form-label">
                              {"Name"}
                            </Label>
                            <div className="col-xl-12">
                              <div className="input-div">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="name"
                                  name="name"
                                  placeholder="Enter name"
                                  value={profileData?.name}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                          </FormGroup>
                        </div>

                        <div className="col-md-6">
                          <FormGroup>
                            <Label className="col-form-label ml-2 Label-my form-label form-label">
                              {"Email"}
                            </Label>
                            <div className="col-xl-12">
                              <div className="input-div">
                                <input
                                  type="email"
                                  className="form-control"
                                  id="email"
                                  name="email"
                                  placeholder="Enter email"
                                  value={profileData?.email}
                                  disabled
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="padding-bottom--24">
                            <label
                              htmlFor="country"
                              className="label-my col-form-label ml-2 Label-my form-label form-label form-label"
                            >
                              {"Select Country"}
                            </label>

                            <Select
                              className="my-select-v"
                              aria-label="Default select example"
                              value={countryList.find(
                                (option) => option.value === country
                              )}
                              onChange={onUpdateCountry}
                              options={countryList}
                              isSearchable={true}
                              isClearable={true}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="padding-bottom--24">
                            <label
                              htmlFor="state"
                              className="col-form-label ml-2 Label-my form-label form-label form-label"
                            >
                              {"Select State"}
                            </label>

                            <Select
                              className="my-select-v"
                              value={stateList.find(
                                (option) => option.value === state
                              )}
                              onChange={onUpdateState}
                              options={stateList}
                              isSearchable={true}
                              isClearable={true}
                            />
                            {/* <>
                    <option value="">Select State</option>
                    {stateList?.length > 0 &&
                      stateList?.map((item, index) => {
                        return (
                          <option key={index} value={item.value}>
                            {item.label}
                          </option>
                        );
                      })}
                  </>
                </select> */}
                          </div>
                        </div>
                      </div>
                      <div className="row"></div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="padding-bottom--24">
                            <label
                              htmlFor="city"
                              className="col-form-label ml-2 Label-my form-label form-label form-label"
                            >
                              {"Select City"}
                            </label>

                            <Select
                              className="my-select-v"
                              value={cityList.find(
                                (option) => option.value === city
                              )} // Make sure city value matches one of the options
                              onChange={(selectedOption) =>
                                setCity(selectedOption?.value || "")
                              } // Update city state
                              options={cityList}
                              isSearchable={true} // Make it searchable
                              isClearable={true} // Option to clear selection
                              placeholder="Select City"
                            />
                            {/* <>
                      <option value="">Select City</option>
                      {cityList?.length > 0 &&
                        cityList?.map((item, index) => {
                          return (
                            <option key={index} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                    </>
                  </select> */}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="padding-bottom--24">
                            <label
                              htmlFor="zipCode"
                              className="col-form-label ml-2 Label-my form-label form-label form-label"
                            >
                              ZipCode
                            </label>
                            <input
                              type="text"
                              id="zipCode"
                              className="form-control input-my input-search"
                              placeholder="Enter Zipcode"
                              value={zipCode}
                              onChange={(e) => setZipCode(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="padding-bottom--24">
                            <label
                              htmlFor="company_registration_number"
                              className="label-my col-form-label ml-2 Label-my form-label form-label form-label"
                            >
                              Company Reg.Number
                            </label>
                            <input
                              type="text"
                              id="company_registration_number"
                              className="form-control input-my input-search"
                              placeholder="Enter your company registration number"
                              value={company_registration_number}
                              onChange={(e) =>
                                setCompanyRegistrationNumber(e.target.value)
                              }
                              disabled // This makes it permanently disabled
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="padding-bottom--24">
                            <label
                              htmlFor="address"
                              className="label-my col-form-label ml-2 Label-my form-label form-label form-label"
                            >
                              Address
                            </label>
                            <textarea
                              name="address"
                              className="form-control input-my input-search"
                              id="address"
                              cols="30"
                              rows="2"
                              placeholder="Enter your address"
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                            ></textarea>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <FormGroup>
                            <Label className="col-form-label ml-2 Label-my form-label form-label">
                              {"Password"}
                            </Label>
                            <div className="col-xl-12">
                              <div className="input-div">
                                <input
                                  type="password"
                                  className="form-control"
                                  id="password"
                                  placeholder="Enter new password"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                />
                              </div>
                              {errors.password && (
                                <span className="input-error">
                                  {errors.password}
                                </span>
                              )}
                            </div>
                          </FormGroup>
                        </div>

                        <div className="col-md-6">
                          <FormGroup>
                            <Label className="col-form-label ml-2 Label-my form-label form-label">
                              {"Confirm Password"}
                            </Label>
                            <div className="col-xl-12">
                              <div className="input-div">
                                <input
                                  type="password"
                                  className="form-control"
                                  id="confirmPassword"
                                  placeholder="Confirm new password"
                                  value={confirmPassword}
                                  onChange={(e) =>
                                    setConfirmPassword(e.target.value)
                                  }
                                />
                              </div>
                              {errors.confirmPassword && (
                                <span className="input-error">
                                  {errors.confirmPassword}
                                </span>
                              )}
                            </div>
                          </FormGroup>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Profile"}
                          </Label>
                          <div className="avatar-upload">
                            <div className="avatar-edit">
                              <div className="input-div">
                                <input
                                  className="inputFile"
                                  type="file"
                                  id="imageUpload"
                                  onChange={handleFileChange}
                                  name="file"
                                  accept=".jpg,.jpeg,.png"
                                />
                              </div>
                              <label htmlFor="imageUpload"></label>
                            </div>
                            <div className="avatar-preview profile-user">
                              <img src={previewUrl} className="preview-image" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="padding-bottom--24">
                            <label
                              htmlFor="address_proof"
                              className="label-my col-form-label ml-2 Label-my form-label form-label form-label"
                            >
                              Address Proof
                            </label>
                            <div className="avatar-upload">
                              <div className="avatar-edit">
                                <div className="input-div">
                                  <input
                                    className="inputFile"
                                    type="file"
                                    id="imageUploads"
                                    onChange={handleFileChangeAddress}
                                    name="file"
                                    accept=".jpg,.jpeg,.png"
                                  />
                                </div>
                                <label htmlFor="imageUploads"></label>
                              </div>
                              <div className="avatar-preview profile-user">
                                <img
                                  src={previewUrlAddress}
                                  className="preview-image"
                                />
                              </div>
                            </div>
                          </div>
                        </div>{" "}
                      </div>
                    </div>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="card">
                            <div className="profileHeader">
                              <h6>SubScription Details</h6>
                              {companyId?.role_id !== "1" && (
                                <button
                                  type="button"
                                  className="btn btn-primary changeBtn"
                                  onClick={handleChangeSubscriptionPlan}
                                >
                                  Change Plan
                                </button>
                              )}
                            </div>
                            <div className="card-body">
                              <div className="row border-bottom">
                                <div className="col-lg-6">Subscribed On :</div>
                                <div className="col-lg-6">
                                  <span style={{ fontWeight: "400px" }}>
                                    {moment(
                                      profileDatas?.subscription_data
                                        ?.subscribed_on
                                    ).format("DD/MM/YYYY")}
                                  </span>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-6">Subscribed End :</div>
                                <div className="col-lg-6">
                                  <span style={{ fontWeight: "400px" }}>
                                    {moment(
                                      profileDatas?.subscription_data
                                        ?.subscribed_ends
                                    ).format("DD/MM/YYYY")}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="card">
                            <div className="card-header">
                              <h6>Last Login Details</h6>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-lg-6">Last Login Date:</div>
                                <div className="col-lg-6">
                                  <span style={{ fontWeight: "400px" }}>
                                    {moment(
                                      profileDatas?.last_login_date
                                    ).format("DD/MM/YYYY HH:mm")}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer d-flex justify-content-end">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal isOpen={subscriptionModal} className="modal-dialog modal-xl">
          <ModalBody>
            <button
              type="button"
              className="close"
              onClick={handleChangeSubscriptionPlanClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="form">
              <div className="heading"></div>
              <form method={`post`}>
                <div className="heading mb-4">
                  Subscription
                  <div
                    className={`bootstrap-switch ${
                      isChecked ? "bootstrap-switch-on" : "bootstrap-switch-off"
                    }`}
                    style={{ marginLeft: "10px" }}
                  >
                    <div
                      className="bootstrap-switch-container"
                      onClick={(e) => valueChangedHandler()}
                    >
                      <span
                        className={`bootstrap-switch-handle-on ${
                          isChecked ? "bootstrap-switch-primary" : ""
                        }`}
                      >
                        Yearly
                      </span>
                      <span className="bootstrap-switch-label">&nbsp;</span>
                      <span
                        className={`bootstrap-switch-handle-off ${
                          !isChecked ? "bootstrap-switch-default" : ""
                        }`}
                      >
                        Monthly
                      </span>
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={(e) => setIsChecked(e.target.value)}
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                </div>

                <div className="padding-bottom--24">
                  <div className="container">
                    <div className="card-deck mb-3  card-deck-custome">
                      {SubscriptionList?.filter(
                        (item) =>
                          item.is_free === 0 &&
                          item.id !=
                            profileDatas.subscription_data?.subscription_id
                      ).map((item, index) => {
                        return (
                          <div key={index} className="card mb-4 box-shadow">
                            <div className="card-header">
                              <h4 className="my-0 font-weight-normal">
                                {item.name}
                              </h4>
                            </div>
                            <div className="card-body">
                              <h5 className="pricing-card-title">
                                <b>
                                  $
                                  {isChecked
                                    ? item.yearly_price + "/Year"
                                    : item.monthly_price + "/Month"}
                                </b>
                              </h5>
                              <div className="list-unstyled mt-3 mb-4">
                                {item.subscription_desc}
                              </div>
                            </div>
                            <div className="card-footer">
                              <div className="d-flex justify-content-center">
                                <button
                                  className="login-btn"
                                  type="button"
                                  onClick={(e) => onSubscription(item)}
                                >
                                  <span className="text">
                                    {item.is_free ? "Free" : "Purchase"}
                                  </span>
                                  <span className="icon">
                                    <i className="bi bi-currency-dollar" />
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={purchaseModal}>
          <ModalHeader>Payment Method</ModalHeader>
          <ModalBody>
            <div
              class="card"
              style={{ borderRadius: "8px", marginBottom: "0px" }}
            >
              <div
                class="card-body"
                style={{ display: "flex", cursor: "pointer" }}
                onClick={handlePurchaseSubmit}
              >
                <img src={MbwayIcon} alt="" style={{ width: "85px" }} />
                <h4 style={{ fontSize: "21px", marginTop: "10px" }}>MBWAY</h4>
              </div>
            </div>
          </ModalBody>
          {/* <ModalFooter>
            <button
              type="button"
              class="btn btn-block btn-secondary"
              onClick={handlePurchaseClose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-x-lg"></i>
            </button>
          </ModalFooter> */}
        </Modal>

        <Modal isOpen={purchaseDetailsModal}>
          <ModalHeader>MBWAY</ModalHeader>
          <Form method={`post`} onSubmit={handleSubmitMBPayPayment}>
            {loading ? (
              <div class="spinner-grow" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            ) : (
              <>
                <ModalBody>
                  <div className="row">
                    {/* <div className="col-md-12">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Amount"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={purchaseData.amount}
                        onChange={(e) => {
                          setPurchaseData({
                            ...purchaseData,
                            amount: e.target.value,
                          });
                        }}
                        name={`amount`}
                        maxLength={30}
                        placeholder="Amount"
                      />
                    </div>
                  </div>
                </FormGroup>
              </div> */}

                    <div className="col-md-12">
                      <FormGroup>
                        <Label className="col-form-label ml-2 Label-my">
                          {"Mobile Number"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control input-search"
                              type="text"
                              value={purchaseData.mobileNumber}
                              onChange={(e) => {
                                setPurchaseData({
                                  ...purchaseData,
                                  mobileNumber: e.target.value,
                                });
                              }}
                              name={`mobileNumber`}
                              maxLength={9}
                              placeholder="Mobile Number"
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>

                    <div className="col-md-12">
                      <FormGroup>
                        <Label className="col-form-label ml-2 Label-my">
                          {"Email"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control input-search"
                              type="text"
                              value={purchaseData.email}
                              onChange={(e) => {
                                setPurchaseData({
                                  ...purchaseData,
                                  email: e.target.value,
                                });
                              }}
                              name={`email`}
                              maxLength={100}
                              placeholder="Email"
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <button
                    type="button"
                    class="btn btn-block btn-secondary"
                    onClick={handlePurchaseDetailsClose}
                    style={{ width: "100px", marginTop: "10px" }}
                  >
                    Close <i class="bi bi-x-lg"></i>
                  </button>
                  <button
                    type="submit"
                    class="btn btn-block btn-primary"
                    style={{ width: "100px", marginTop: "10px" }}
                  >
                    Submit <i class="bi bi-check2"></i>
                  </button>
                </ModalFooter>
              </>
            )}
          </Form>
        </Modal>

        <Modal isOpen={purchaseMessageModal}>
          <ModalHeader className="d-flex justify-content-center">
            Payment Successful!!
          </ModalHeader>
          <ModalBody>
            <div
              class="card"
              style={{
                boxShadow: "none",
                marginBottom: "0px",
                textAlign: "center",
              }}
            >
              <div class="card-body">
                <div className="checked">
                  <i className="bi bi-check-lg"></i>
                </div>
                <h5 style={{ fontSize: "11px", marginTop: "12px" }}>
                  Your trascation Id: {purchaseDetailsData?.RequestId}
                </h5>
                <p
                  style={{ fontSize: "14px", color: "#000", marginTop: "12px" }}
                >
                  Payment was made successfully
                </p>
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="border-0" style={{ marginTop: "-18px" }}>
            <button
              type="button"
              class="btn btn-block btn-payment"
              onClick={handlePurchaseMessageClose}
              style={{ width: "auto", margin: "auto", padding: "5px 12px" }}
            >
              Ok <i class="bi bi-x-lg"></i>
            </button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default Profile;
