import axiosInstance from "./AxiosInstance";

export function getVehicleEngineList(data) {
  return axiosInstance.post(`/vehicle-engine/list`, data);
}

export function deleteVehicleEngine(data) {
  return axiosInstance.post(`/vehicle-engine/delete`, data);
}

export function createVehicleEngine(data) {
  return axiosInstance.post(`/vehicle-engine/create`, data);
}

export function updateVehicleEngine(data) {
  return axiosInstance.post(`/vehicle-engine/update`, data);
}

export function importVehicleEngine(data) {
  return axiosInstance.post(`/vehicle-engine/import`, data);
}

export function exportVehicleEngine(data) {
  return axiosInstance.post(`/vehicle-engine/export-list`, data);
}

export function getVehicleEngineSerachList(data) {
  return axiosInstance.post(`/vehicle-engine/search-list`, data);
}


