import axiosInstance from "./AxiosInstance";

export function getVehicleTypeList(data) {
  return axiosInstance.post(`/vehicle-type/list`, data);
}

export function deleteVehicleType(data) {
  return axiosInstance.post(`/vehicle-type/delete`, data);
}

export function createVehicleType(data) {
  return axiosInstance.post(`/vehicle-type/create`, data);
}

export function getVehicleTypeAllList(data) {
  return axiosInstance.get(`/get-vehicle-type`, data);
}

export function updateVehicleType(data) {
  return axiosInstance.post(`/vehicle-type/update`, data);
}

export function importVehicleType(data) {
  return axiosInstance.post(`/vehicle-type/import`, data);
}

export function exportVehicleType(data) {
  return axiosInstance.post(`/vehicle-type/export-list`, data);
}
