import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import {
  getPartVehicleLogHistory,
  getVehicleList,
} from "../../services/PartsInventory";
import ConfigDB from "../../config";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  actionAddDepollutionOpration,
  getLerCodeHistoryList,
  getLerCodeList,
} from "../../services/DepollutionService";
import { toast } from "react-toastify";
import Utils from "../../utils";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Pagination from "react-js-pagination";

const AddForVehicleDepollution = () => {
  const tableLengthList = ConfigDB?.data?.dataTableLength;
  const navigate = useNavigate();

  const [vehiclePartsData, setVehiclePartsData] = useState({
    vehicle_id: "",
    ler_code_id: "",
    wastege_value: "",
    description: "",
  });

  const [inputValue, setInputValue] = useState("");
  const [VehicleModelList, setVehicleModelList] = useState([]);
  const [LerCatalogList, setLerCatalogList] = useState([]);
  const [LerCatalogHistoryList, setLerCatalogHistoryList] = useState([]);

  const [addedParts, setAddedParts] = useState([]);

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  let errorsObj = {
    vehicle_id: "",
    ler_code_id: "",
    wastege_value: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const [selectedOption, setSelectedOption] = useState("1");

  const [vehicleLogHistory, setVehicleLogHistory] = useState([]);
  const [partHistoryQtyModal, setPartHistoryQtyModal] = useState(false);

  const [filteredSuggestionsModel, setFilteredSuggestionsModel] = useState([]);
  const [activeSuggestionIndexModel, setActiveSuggestionIndexModel] =
    useState(0);
  const [showSuggestionsModel, setShowSuggestionsModel] = useState(false);
  const [vehicleModelAuto, setVehicleModelAuto] = useState("");
  const [vehicleModelId, setVehicleModelId] = useState("");

  const handleChangeModel = (e) => {
    const userInput = e.target.value;

    let data = {
      limit: params.limit,
      page: params.page,
      search: userInput,
      is_reload: true,
      make_id: 0,
    };
    if (userInput.length > 0) {
      getVehicleList(data)
        .then((response) => {
          setFilteredSuggestionsModel(response.data.data);
        })
        .catch((err) => {});
    }

    setVehicleModelAuto(userInput);
    setActiveSuggestionIndexModel(0);
    setShowSuggestionsModel(true);
  };

  const handleClickModel = (suggestion) => {
    setVehicleModelId(suggestion?.id);
    setVehicleModelAuto(suggestion.vehicle_model_name);
    setFilteredSuggestionsModel([]);
    setShowSuggestionsModel(false);
  };

  const handleKeyDownModel = (e) => {
    if (e.key === "ArrowDown") {
      if (
        filteredSuggestionsModel &&
        activeSuggestionIndexModel < filteredSuggestionsModel?.length - 1
      ) {
        setActiveSuggestionIndexModel(activeSuggestionIndexModel + 1);
      }
    } else if (e.key === "ArrowUp") {
      if (activeSuggestionIndexModel > 0) {
        setActiveSuggestionIndexModel(activeSuggestionIndexModel - 1);
      }
    } else if (e.key === "Enter") {
      setVehicleModelAuto(filteredSuggestionsModel[activeSuggestionIndexModel]);
      setShowSuggestionsModel(false);
      setFilteredSuggestionsModel([]);
    }
  };

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const dataWaste = addedParts?.map((item) => item.unit_value);

  const ontpartHistoryQtyDetailsOpen = () => {
    setPartHistoryQtyModal(true);
  };

  const onpartHistoryQtyDetailsClose = () => {
    setPartHistoryQtyModal(false);
  };

  useEffect(
    function () {
      if (vehiclePartsData.vehicle_id) {
        var obj = {
          id: vehiclePartsData.vehicle_id,
        };

        getPartVehicleLogHistory(obj)
          .then((response) => {
            setVehicleLogHistory(response.data.data);
          })
          .catch((err) => {});
      }
    },
    [vehiclePartsData.vehicle_id]
  );

  const sumWastageValue = dataWaste?.reduce((acc, curr) => {
    const num = Number(curr);
    return !isNaN(num) ? acc + num : acc;
  }, 0);

  const handleAddClick = (id, ler_code, unit_value) => {
    const isAdded = addedParts.some((part) => part.id === id);

    if (isAdded) {
      setAddedParts((prev) => prev.filter((part) => part.id !== id));
    } else {
      setAddedParts((prev) => [...prev, { id, ler_code, unit_value }]);
    }
  };

  const filteredPartAssignList = LerCatalogList.filter(
    (item) => !addedParts.some((addedPart) => addedPart.id === item.id)
  );

  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    let error = false;
    let errorObj = { ...errorsObj };

    setErrors(errorObj);
    Utils.focusError();

    if (error) return;

    const dataWaste = addedParts?.map((item) => item.id);

    let data = {
      vehicle_id: selectedOption == "1" ? vehicleModelId : 0,
      ler_code_id: dataWaste,
      wastege_value: sumWastageValue,
      description: vehiclePartsData?.description,
    };

    actionAddDepollutionOpration(data)
      .then((response) => {
        toast.success(response.data.message);
        navigate("/depollution-operation");
      })
      .catch((err) => {
        setLoading(false);
        toast.dismiss();

        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(
    function () {
      if (vehiclePartsData?.ler_code_id) {
        let data = {
          id: vehiclePartsData?.ler_code_id,
        };
        getLerCodeHistoryList(data)
          .then((response) => {
            setLerCatalogHistoryList(response.data.data);
          })
          .catch((err) => {
            setLoading(false);
        toast.dismiss();

            toast.error(Utils.getErrorMessage(err));
          });
      }
    },
    [vehiclePartsData]
  );

  useEffect(function () {
    var obj = {
      search: "",
      limit: tableLengthList[0],
      page: 1,
    };
    getLerCodeList(obj)
      .then((response) => {
        setLerCatalogList(response.data.data);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    var obj = {
      search: "",
      limit: tableLengthList[0],
      page: 1,
    };
    getVehicleList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: `${response.data[i].vehicle_model_name} (${response.data[i].vehicle_year})`,
            vehicle_year: response.data[i].vehicle_year,
            vehicle_make: response.data[i].vehicle_make,
            vehicle_model: response.data[i].vehicle_model,
          });
        }
        setVehicleModelList(list);
      })
      .catch((err) => {});
  }, []);

  const handleInputChange = (inputValue) => {
    setInputValue(inputValue);

    if (inputValue.length > 2) {
      const obj = {
        search: inputValue,
        limit: tableLengthList[0],
        page: 1,
      };

      getVehicleList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: `${response.data[i].vehicle_model_name} (${response.data[i].vehicle_year})`,
            });
          }

          setVehicleModelList(list);
        })
        .catch((err) => {
          console.error("Error fetching vehicle model list", err);
        });
    } else {
      setVehicleModelList([]);
      let obj = {
        search: "",
        limit: tableLengthList[0],
        page: 1,
      };
      getVehicleList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: `${response.data[i].vehicle_model_name} (${response.data[i].vehicle_year})`,
            });
          }

          setVehicleModelList(list);
        })
        .catch((err) => {
          console.error("Error fetching vehicle model list", err);
          setLoading(false);
        });
    }
  };

  const handleSelectChange = (selectedOption) => {
    setVehiclePartsData({
      ...vehiclePartsData,
      vehicle_id: selectedOption ? selectedOption.value : "",
    });
  };

  return (
    <div>
      <div>
        <div>
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6"></div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"></li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-primary">
                    <Form method={`post`} onSubmit={handleSubmit}>
                      <div className="card-body">
                        <div className="row">
                          <div className="d-flex ms-3">
                            <div>
                              <label>
                                <input
                                  type="radio"
                                  name="options"
                                  value="1"
                                  checked={selectedOption === "1"}
                                  onChange={handleChange}
                                  className="me-1 ms-1"
                                />
                                Vehicle Part
                              </label>
                            </div>
                            <div className="ms-2">
                              <label>
                                <input
                                  type="radio"
                                  name="options"
                                  value="2"
                                  checked={selectedOption === "2"}
                                  onChange={handleChange}
                                  className="me-1"
                                />
                                Other
                              </label>
                            </div>
                          </div>

                          {selectedOption == "1" && (
                            <>
                              <div className="d-flex">
                                <div className="col-md-2">
                                  <div className="col-xl-12">
                                    <Label className="col-form-label ml-2 Label-my form-label form-label">
                                      {"Select Vehicle"}
                                    </Label>
                                    <input
                                      className="form-control mt-2 input-search"
                                      type="text"
                                      maxLength={50}
                                      placeholder="Search..."
                                      value={vehicleModelAuto}
                                      onChange={handleChangeModel}
                                      onKeyDown={handleKeyDownModel}
                                      onClick={(e) => e.stopPropagation()}
                                    />
                                    {showSuggestionsModel &&
                                      vehicleModelAuto && (
                                        <ul className="suggestions">
                                          {filteredSuggestionsModel?.length >
                                          0 ? (
                                            filteredSuggestionsModel.map(
                                              (suggestion, index) => (
                                                <li
                                                  key={suggestion}
                                                  className={
                                                    index ===
                                                    activeSuggestionIndexModel
                                                      ? "active"
                                                      : ""
                                                  }
                                                  onClick={() =>
                                                    handleClickModel(suggestion)
                                                  }
                                                >
                                                  {
                                                    suggestion.vehicle_model_name
                                                  }
                                                  /{suggestion.vehicle_year}
                                                </li>
                                              )
                                            )
                                          ) : (
                                            <li>No suggestions available</li>
                                          )}
                                        </ul>
                                      )}
                                  </div>
                                </div>
                                {vehicleModelId ? (
                                  <div
                                    style={{
                                      marginTop: "48px",
                                      marginLeft: "12px",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={ontpartHistoryQtyDetailsOpen}
                                    >
                                      Vehicle History
                                      <i class="bi bi-car-front-fill ms-1"></i>
                                    </button>
                                  </div>
                                ) : (
                                  <div className="col-md-4">
                                    <FormGroup>
                                      <Label className="col-form-label ml-2 Label-my form-label form-label">
                                        {"Wastage Value"}
                                      </Label>
                                      <div className="col-xl-12">
                                        <div
                                          className="input-div"
                                          style={{ width: "186px" }}
                                        >
                                          <input
                                            className="form-control input-search"
                                            type="number"
                                            value={
                                              vehiclePartsData.wastege_value
                                                ? vehiclePartsData.wastege_value
                                                : sumWastageValue
                                            }
                                            onChange={(e) => {
                                              setVehiclePartsData({
                                                ...vehiclePartsData,
                                                wastege_value: e.target.value,
                                              });
                                            }}
                                            name={`wastege_value`}
                                            maxLength={100}
                                            placeholder="Wastage Value"
                                          />
                                          {errors.wastege_value && (
                                            <span className="input-error">
                                              {errors.wastege_value}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </FormGroup>
                                  </div>
                                )}
                                <div className="col-md-6">
                                  <FormGroup>
                                    <Label className="col-form-label ml-2 Label-my form-label form-label">
                                      {"Description"}
                                    </Label>
                                    <div className="col-xl-12">
                                      <div className="input-div">
                                        <textarea
                                          className="form-control input-search"
                                          type="text"
                                          value={vehiclePartsData.description}
                                          onChange={(e) => {
                                            setVehiclePartsData({
                                              ...vehiclePartsData,
                                              description: e.target.value,
                                            });
                                          }}
                                          name={`description`}
                                          maxLength={100}
                                          placeholder="Description"
                                          rows={3}
                                        />
                                      </div>
                                    </div>
                                  </FormGroup>
                                </div>
                              </div>
                            </>
                          )}

                          <div className="row">
                            <div className="col-md-6">
                              <div className="vehicleThBg table-responsive tableScroll table-scroll-modal">
                                <table className="rounded-table table table-striped table-bordered table-hover ">
                                  <thead>
                                    <tr>
                                      {/* <th>Part</th> */}
                                      <th>LER Code</th>
                                      <th>Unit Value</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {filteredPartAssignList?.length > 0 ? (
                                      filteredPartAssignList?.map(
                                        (item, index) => (
                                          <tr key={index}>
                                            {/* <td>{item?.namePartPT}</td> */}
                                            <td>{item.ler_code}</td>
                                            <td>{item.unit_value}</td>

                                            <td>
                                              <Button
                                                className="addBtn"
                                                onClick={() =>
                                                  handleAddClick(
                                                    item.id,
                                                    // item.namePartPT,
                                                    item.ler_code,
                                                    item.unit_value
                                                  )
                                                }
                                                style={{
                                                  backgroundColor:
                                                    addedParts.some(
                                                      (part) =>
                                                        part.id === item.id
                                                    )
                                                      ? "red"
                                                      : "green",
                                                  color: "white",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                <i className="bi bi-plus-lg"></i>{" "}
                                              </Button>
                                            </td>
                                          </tr>
                                        )
                                      )
                                    ) : (
                                      <tr className="scrollBrdr">
                                        <td colSpan="100%">No results found</td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="vehicleThBg table-responsive tableScroll table-scroll-modal">
                                <table className="rounded-table table table-striped table-bordered table-hover">
                                  <thead>
                                    <tr>
                                      {/* <th>Part</th> */}
                                      <th>LER Code</th>
                                      <th>Unit Value</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {addedParts?.length > 0 ? (
                                      addedParts?.map((item, index) => (
                                        <tr key={index}>
                                          {/* <td>{item?.namePartPT}</td> */}
                                          <td>{item.ler_code}</td>
                                          <td>{item.unit_value}</td>

                                          <div className="trash-btn">
                                            <a
                                              className="delete-btn"
                                              href="#"
                                              onClick={() =>
                                                handleAddClick(
                                                  item.id,
                                                  // item.namePartPT,
                                                  item.ler_code,
                                                  item.unit_value
                                                )
                                              }
                                            >
                                              <i className="bi bi-trash3"></i>
                                            </a>
                                          </div>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr className="scrollBrdr">
                                        <td colSpan="100%">No results found</td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card-footer">
                        <div className="row">
                          <div className="col">
                            <button
                              type="button"
                              className="back-btn"
                              onClick={() => navigate("/depollution-operation")}
                            >
                              <i class="bi bi-arrow-left"> </i>Back
                            </button>
                          </div>
                          <div className="col">
                            <div className="d-flex justify-content-end">
                              {loading === true ? (
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  Loading...
                                </button>
                              ) : (
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  Submit <i className="bi bi-check2"></i>
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Modal isOpen={partHistoryQtyModal} size="lg">
            <ModalHeader>{`Vehicle History Details`}</ModalHeader>
            <ModalBody>
              <div className="row">
                <div className="col-md-12">
                  <div className="vehicleThBg table-responsive">
                    <table className="rounded-table table table-striped table-bordered table-hover">
                      <thead>
                        <tr>
                          <th>Wastage Value</th>
                          <th>LER Code</th>
                        </tr>
                      </thead>

                      <tbody>
                        {LerCatalogHistoryList?.map((item) => {
                          return (
                            <tr key={item.id}>
                              <td>{item.wastege_value}</td>
                              <td>{item.ler_code || "-"}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                className="btn btn-block btn-primary"
                onClick={onpartHistoryQtyDetailsClose}
                style={{ width: "100px", marginTop: "10px" }}
              >
                Close <i className="bi bi-x-lg"></i>
              </button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default AddForVehicleDepollution;
