import axiosInstance from "./AxiosInstance";

export function actionAddShelf(data) {
  return axiosInstance.post(`/shelf/create`, data);
}

export function actionUpdateShelf(data) {
  return axiosInstance.post(`/shelf/update`, data);
}

export function getShelfList(data) {
  return axiosInstance.post(`/shelf/list`, data);
}

export function actionDelShelf(data) {
  return axiosInstance.post(`/shelf/delete`, data);
}

export function getAllViewShelfList(data) {
  return axiosInstance.post(`/shelf/view-list`, data);
}

