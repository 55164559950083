import axiosInstance from "./AxiosInstance";

export function geCertificateList(data) {
  return axiosInstance.post(`/certificate/list`, data);
}

export function geCertificateDetails(data) {
  return axiosInstance.post(`/certificate/details`, data);
}

export function deleteCertificate(data) {
  return axiosInstance.post(`/certificate/delete`, data);
}
