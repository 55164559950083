import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
// import PatImg from "../../asset/images/img-01.jpg"

import Utils from "../../utils";
import {
  getVehicleDetailsList,
  getVehiclePartList,
} from "../../services/VehicleService";
import moment from "moment";

const VehicleDetails = () => {
  const state = useLocation();
  const navigate = useNavigate();
  const [vehiclePartDetails, setVehiclePartDetails] = useState({});

  useEffect(() => {
    if (state?.state?.id) {
      let data = {
        vehicle_id: state?.state?.id,
      };

      getVehicleDetailsList(data)
        .then((response) => {
          setVehiclePartDetails(response.data.data);
          //   setTotalRecords(response.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [state?.state?.id]);

  return (
    <div>
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">{/* <h1>Vehicle</h1> */}</div>
              <div className="col-sm-6"></div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="container-fluid">
            <div className="card fuelCard">
              <div className="cardHeader card-header card-header-custom">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <p>Vehicle Details</p>
                  </div>
                  <div className="col-sm-6">
                    <div className="d-flex  justify-content-end">
                      <button
                        type="button"
                        className="next-button btn btn-secondary"
                        onClick={() => navigate("/vehicle-process")}
                      >
                        <i className="bi bi-arrow-left"></i> Back
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card fuelCard">
                  <div className="cardHeader card-header card-header-custom">
                    <p>Vehicle Details</p>
                  </div>
                  <div className="card-body card-body-custom">
                    <div className="row justify-content-start">
                      <div className="col-sm-6">
                        <div className="invoice-col">
                          <b>Certificate Number:</b>{" "}
                          <address>
                            {vehiclePartDetails?.vehicle_data
                              ?.certificate_number || "-"}
                          </address>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="invoice-col">
                          <b> Registration Plate:</b>{" "}
                          <address>
                            {vehiclePartDetails?.vehicle_data
                              ?.registration_plate || ""}
                          </address>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="invoice-col">
                          <b> Registration:</b>{" "}
                          <address>
                            {vehiclePartDetails?.vehicle_data?.registration ||
                              ""}
                          </address>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="invoice-col">
                          <b> Model Name:</b>{" "}
                          <address>
                            {vehiclePartDetails?.vehicle_data
                              ?.vehicle_model_name || "-"}
                          </address>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="invoice-col">
                          <b> Year:</b>{" "}
                          <address>
                            {vehiclePartDetails?.vehicle_data?.vehicle_year ||
                              ""}
                          </address>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="invoice-col">
                          <b> Make Name:</b>{" "}
                          <address>
                            {vehiclePartDetails?.vehicle_data
                              ?.vehicle_make_name || ""}
                          </address>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="invoice-col">
                          <b>Variant Name:</b>{" "}
                          <address>
                            {vehiclePartDetails?.vehicle_data?.variants_name ||
                              "-"}
                          </address>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="invoice-col">
                          <b> Modal ETAI:</b>{" "}
                          <address>
                            {vehiclePartDetails?.vehicle_data
                              ?.vehicle_model_etai_name || "-"}
                          </address>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="invoice-col">
                          <b> Make ETAI:</b>{" "}
                          <address>
                            {vehiclePartDetails?.vehicle_data
                              ?.vehicle_make_etai_name || "-"}
                          </address>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="invoice-col">
                          <b> Variant ETAI:</b>{" "}
                          <address>
                            {vehiclePartDetails?.vehicle_data
                              ?.vehicle_variants_etai_name || "-"}
                          </address>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="invoice-col">
                          <b> Year ETAI:</b>{" "}
                          <address>
                            {vehiclePartDetails?.vehicle_data
                              ?.vehicle_year_etai || "-"}
                          </address>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="invoice-col">
                          <b>VIN Number:</b>{" "}
                          <address>
                            {vehiclePartDetails?.vehicle_data?.vehicle_frame ||
                              "-"}
                          </address>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="invoice-col">
                          <b>Fuel Type:</b>{" "}
                          <address>
                            {vehiclePartDetails?.vehicle_data?.fuel_type_name ||
                              "-"}
                          </address>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="invoice-col">
                          <b>Inventory Name:</b>{" "}
                          <address>
                            {vehiclePartDetails?.vehicle_data?.inventory_name ||
                              "-"}
                          </address>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="invoice-col">
                          <b>Motor Code:</b>{" "}
                          <address>
                            {vehiclePartDetails?.vehicle_data?.motor_code ||
                              "-"}
                          </address>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="invoice-col">
                          <b>KW :</b>{" "}
                          <address>
                            {vehiclePartDetails?.vehicle_data?.power_kw || "-"}
                          </address>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="invoice-col">
                          <b> Booklet Model:</b>{" "}
                          <address>
                            {vehiclePartDetails?.vehicle_data
                              ?.vehicle_booklet_model || "-"}
                          </address>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="invoice-col">
                          <b> Brand:</b>{" "}
                          <address>
                            {vehiclePartDetails?.vehicle_data?.vehicle_brand ||
                              "-"}
                          </address>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="invoice-col">
                          <b> Color:</b>{" "}
                          <address>
                            {vehiclePartDetails?.vehicle_data?.vehicle_color ||
                              "-"}
                          </address>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="invoice-col">
                          <b> Cyllinder:</b>{" "}
                          <address>
                            {vehiclePartDetails?.vehicle_data
                              ?.vehicle_cyllinder || "-"}
                          </address>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="invoice-col">
                          <b> EEI:</b>{" "}
                          <address>
                            {vehiclePartDetails?.vehicle_data?.vehicle_eei ||
                              "-"}
                          </address>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="invoice-col">
                          <b> Exp Value:</b>{" "}
                          <address>
                            {vehiclePartDetails?.vehicle_data
                              ?.vehicle_exp_value || "-"}
                          </address>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="invoice-col">
                          <b>Internl Ref:</b>{" "}
                          <address>
                            {vehiclePartDetails?.vehicle_data
                              ?.vehicle_internl_ref || "-"}
                          </address>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="invoice-col">
                          <b>Kms:</b>{" "}
                          <address>
                            {vehiclePartDetails?.vehicle_data?.vehicle_kms ||
                              "-"}
                          </address>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="invoice-col">
                          <b> Start Date:</b>{" "}
                          <address>
                            {vehiclePartDetails?.vehicle_data
                              ?.vehicle_process_start_date
                              ? moment(
                                  vehiclePartDetails.vehicle_data
                                    .vehicle_process_start_date
                                ).format("DD/MM/YYYY")
                              : "-"}
                          </address>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="invoice-col">
                          <b> End Date:</b>{" "}
                          <address>
                            {vehiclePartDetails?.vehicle_data
                              ?.vehicle_process_end_date
                              ? moment(
                                  vehiclePartDetails.vehicle_data
                                    .vehicle_process_end_date
                                ).format("DD/MM/YYYY")
                              : "-"}
                          </address>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="invoice-col">
                          <b> Provenance:</b>{" "}
                          <address>
                            {vehiclePartDetails?.vehicle_data
                              ?.vehicle_provenance || "-"}
                          </address>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="invoice-col">
                          <b> Tare:</b>{" "}
                          <address>
                            {vehiclePartDetails?.vehicle_data?.vehicle_tare ||
                              "-"}
                          </address>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="invoice-col">
                          <b> Total gross weight:</b>{" "}
                          <address>
                            {vehiclePartDetails?.vehicle_data
                              ?.vehicle_total_gross_weight || "-"}
                          </address>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="invoice-col">
                          <b> Type Name:</b>{" "}
                          <address>
                            {vehiclePartDetails?.vehicle_data
                              ?.vehicle_type_name || "-"}
                          </address>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="invoice-col">
                          <b> Category name:</b>{" "}
                          <address>
                            {vehiclePartDetails?.vehicle_data?.category_name ||
                              "-"}
                          </address>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="invoice-col">
                          <b> EEi:</b>{" "}
                          <address>
                            {vehiclePartDetails?.vehicle_data?.vehicle_eei ||
                              "-"}
                          </address>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="invoice-col">
                          <b> ccm3:</b>{" "}
                          <address>
                            {vehiclePartDetails?.vehicle_data?.ccm3 || "-"}
                          </address>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="invoice-col">
                          <b> hp:</b>{" "}
                          <address>
                            {vehiclePartDetails?.vehicle_data?.hp || "-"}
                          </address>
                        </div>
                      </div>
                    </div>
                  </div>

                  {vehiclePartDetails?.vehicle_data?.vehicle_image && (
                    <>
                      <div className="card-custom-heading">
                        <p>Vehicle images</p>
                      </div>
                      <div className="card-body card-body-custom">
                        <div className="row justify-content-start">
                          {vehiclePartDetails?.vehicle_data?.vehicle_image.map(
                            (item) => {
                              return (
                                <>
                                  <div className="col-sm-3">
                                    <div className="column-img">
                                      <img src={item?.vehicle_image} alt="" />
                                    </div>
                                  </div>
                                </>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  {vehiclePartDetails?.vehicle_data?.document_file && (
                    <>
                      <div className="card-custom-heading">
                        <p>Vehicle Document</p>
                      </div>
                      <div className="card-body card-body-custom">
                        <div className="row justify-content-start">
                          {vehiclePartDetails?.vehicle_data?.document_file.map(
                            (item) => {
                              return (
                                <>
                                  <div className="col-sm-3">
                                    <div className="column-img">
                                      <img src={item?.document_file} alt="" />
                                    </div>
                                  </div>
                                </>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default VehicleDetails;
